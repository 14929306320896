import React, { useEffect, useState } from 'react'
import Switch from 'react-switch';
import Select from 'react-select';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal'
import { useDispatch, useSelector } from 'react-redux';
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { GetMenuIdFromStorage } from '../../../commonClass/Menudect';
import { loaderActions } from '../../../../_actions/loader.actions';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../customControl/CustomTable';

const FAQCategory = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);

    const [serchdisable, setSerachDisable] = useState(false);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');
    const [clrshow, setsclshow] = useState(false);


    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);

    const [formFaqCategory, setFormFaqCategory] = useState({
        FAQTABId: 0,
        FAQTABName: '',
        IsActive: false,
    })
    const [formError, setFormError] = useState({
        duplicate: '',
        faqcategoryname: '',
    })

    let errors = {
        duplicate: '',
        faqcategoryname: '',
    }


    const [allFaqTab, setAllFaqTab] = useState([])

    const getallfaqtab = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const allfaqtab = await getAllData('/master/v1/getfaqalltab')
            if (allfaqtab.status && allfaqtab.data != null) {
                setAllFaqTab(allfaqtab.data)
            }
            else {
                setAllFaqTab([])
            }
        }
        else {
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }

    const handleCategoryName = (e) => {
        setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        setFormFaqCategory({ ...formFaqCategory, [e.target.name]: e.target.value })
        setEditDisabled(true);
    }

    //#region Active/Inactive button
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formFaqCategory.IsActive = e
            setActiveId(!activeId)
            setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formFaqCategory.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        setModalShow(false);
    }
    //#endregion

    //#region validations
    const validateData = () => {
        let error = []
        if (typeof formFaqCategory.FAQTABName === 'string' && formFaqCategory.FAQTABName?.trim()?.length === 0) {
            errors.faqcategoryname = 'Kindly enter the FAQ Category Name'
            error.push('faqcategoryname')
        }
        setFormError(errors)
        document.getElementById(error[0]).focus();

    }
    //#endregion

    //#region Add/Update button
    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (formFaqCategory.FAQTABName?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    if (typeof formFaqCategory.FAQTABName === 'string' && formFaqCategory.FAQTABName?.trim()?.length > 0) {
                        let filterdata;
                        filterdata = allFaqTab?.filter(issec => issec.FAQTABName.toLowerCase() === formFaqCategory.FAQTABName.toLowerCase());
                        if (filterdata?.length > 0) {
                            errors.duplicate = 'You can not enter duplicate value.'
                            setFormError(errors)
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const FaqTabData = await postUsersData('/master/v1/insertfaqtab', formFaqCategory)
                        if (!FaqTabData.status && !FaqTabData.data) {
                            errors.duplicate = FaqTabData.message
                            return;
                        }
                        if (FaqTabData.status) {
                            if (FaqTabData.data > 0) {
                                setitems();
                                dispatch({ type: 'ALERTING', message: FaqTabData.message })
                                clear();

                            }
                            else {
                                dispatch({ type: 'ALERTING', message: FaqTabData.message })
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: FaqTabData.message })
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (formFaqCategory.FAQTABName?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    if (typeof formFaqCategory.FAQTABName === 'string' && formFaqCategory.FAQTABName?.trim()?.length > 0) {
                        let filterdata;
                        filterdata = allFaqTab?.filter(issec => issec.FAQTABId !== formFaqCategory.FAQTABId && issec.FAQTABName.toLowerCase() === formFaqCategory.FAQTABName.toLowerCase());
                        if (filterdata?.length > 0) {
                            errors.duplicate = 'You can not enter duplicate value.'
                            setFormError(errors)
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const FaqTabData = await patchUsersData("/master/v1/updatefaqtab", formFaqCategory);
                        if (!FaqTabData.status && !FaqTabData.data) {
                            errors.duplicate = FaqTabData.message
                            return;
                        }
                        if (FaqTabData.status) {
                            setitems();
                            dispatch({ type: 'ALERTING', message: FaqTabData.message })
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: FaqTabData.message })
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }

        }
    }
    //#endregion

    //#region clear button
    const handleClearClick = () => {
        clear();
    }

    function clear() {
        setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        setFormFaqCategory({ ...formFaqCategory, FAQTABName: '', IsActive: false })
        setActiveId(false);
        getallfaqtab();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('4');
        setSearchValue('');
        setsclshow(false)
    }
    //#endregion

    //#region search FAQs by credentials
    const serachoption = [
        {
            label: 'Category Name',
            value: '1'
        },
        {
            label: 'Active',
            value: '2'
        },
        // {
        //     label: 'Created Date',
        //     value: '3'
        // },
        {
            label: 'All',
            value: '4'
        },

    ];
    const [filteritems, setFilterItems] = useState([]);

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const allfaqtab = await getAllData('/master/v1/getfaqalltab')
        setFilterItems(allfaqtab.data)
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getallfaqtab();
            setsclshow(false)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getallfaqtab();
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            // else if (searchby === '5') {
            //     filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            else if (searchby === '2') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '4') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.FAQTABName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setAllFaqTab(filterdata)
        }

    }
    //#endregion

    const columns = [
        {
            name: "Category Name",
            sortable: true,
            float: "left",
            selector: (faqCategory) => faqCategory.FAQTABName
        },
        {
            name: "Active",
            sortable: true,
            float: "left",
            selector: (faqCategory) => faqCategory.IsActive ? "Active" : "InActive"
        },
        {
            name: "Created Date",
            sortable: true,
            float: "left",
            selector: (faqCategory) => formatDate(faqCategory.CreatedDate)
        },
        {
            name: "Edit",
            selector: (faqCategory) => <button type="button" className='border-0 p-0 bg-body set_disableclr' disabled={!useredit} onClick={() => handleEditMode(faqCategory.FAQTABId)}><i className='fa fa-edit'></i> </button>
        },
        {
            name: "View",
            selector: (faqCategory) => <i className="fa fa-eye" onClick={() => handleViewMode(faqCategory.FAQTABId)}></i>
        }
    ]

    //#region Date format
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    //#endregion

    //#region Edit and View Button
    const handleEditMode = (id) => {
        
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }
    //#endregion

    function RetriveData(id) {
        allFaqTab?.map(obj => {
            if (obj.FAQTABId === id) {
                formFaqCategory.FAQTABName = obj.FAQTABName
                formFaqCategory.FAQTABId = obj.FAQTABId
                formFaqCategory.IsActive = obj.IsActive
                setActiveId(obj.IsActive);
            }
            setFormFaqCategory({ ...formFaqCategory, formFaqCategory })
        });
    }
    useEffect(() => {
        console.log(formFaqCategory, 'formFaqCategory')
    }, [formFaqCategory])

    //#region useEffects
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getallfaqtab().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        // });
        // if (!viewrights) {
        //     navigate('/Dashboard');
        // }
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);

        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
        });
    }, [user])

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>FAQ Category</h3>
            </div>
            <div className="container-fluid form_border pb-5 my-3">
                <div className="mt-4 mb-3 row mx-1"></div>
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        {formError.duplicate && <div className="text-center text-danger pb-3"> {formError.duplicate} </div>}
                                        <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label htmlFor="questionFaq1" className="col-form-label required">FAQ Category Name</label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <div className="col-lg-6 col-md-8">
                                                <input type='text' className='form-control' name="FAQTABName" id="faqcategoryname" value={formFaqCategory.FAQTABName} onChange={(e) => handleCategoryName(e)} maxLength={25} disabled={disabled ? true : false} placeholder='FAQ Category Name'></input>
                                                {formError.faqcategoryname && <div className="text-start text-danger"> {formError.faqcategoryname} </div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-12 col-sm-12">
                                            <label htmlFor="active" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-6 " disabled={false}>
                                            <Switch
                                                id='active'
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the datavalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={allFaqTab}
                        PdfExport={false}
                        ExportToExcel={false}
                        disabled={false}
                    />

                </form>
            </div>
        </>
    )
}

export default FAQCategory
