import React, { memo, useEffect, useState, useRef } from 'react';
import AddonmasterCreateForm from './Partial/AddonmasterCreateForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { GetMenuIdFromStorage } from '../../commonClass/Menudect';
import { getAllData } from '../../../services/ApiCallingUserServices'
import Select from 'react-select';
import { ExportExcel } from '../../commonClass/ExportExcel';
import { generatePDF } from '../../commonClass/CommonValidation';
import CustomTable from '../../../customControl/CustomTable';
import { loaderActions } from '../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';


function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}

function formatDateRequire(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}

function AddOnmaster() {
  const navigate = useNavigate();
    const dispatch = useDispatch();

  const user = useSelector(store => store.userstatus.tba);
  const [errors, setErrors] = useState({});
  const [buttonaddeditText, setButtonAddEditText] = useState("Add");
  const [editdisabled, setEditDisabled] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [addOnList, setAddOnList] = useState([]);
  const [clrshow, setsclshow] = useState(false);
  const [searchBy, setSearchBy] = useState('11');
  const [searchText, setSearchText] = useState('');
  const [filteritems, setFilterItems] = useState(addOnList);
  const [serchdisable, setSerachDisable] = useState(false);
  const [seletedImagePath, setSelectedImagePath] = useState('')


  const defaultObject = {
    LocationId: '',
    AddOnName: '',
    SupplierId: '',
    AllocateInventory: '',
    ImageName: '',
    ImagePath: '',
    AdultPrice: '',
    ChildPrice: '',
    IsActive: false,
    price: 'perPersion'
  }
  const [newAddOn, setNewAddOn] = useState(defaultObject)

  const [userRights, setUserRights] = useState({});

  useEffect(() => {
    dispatch(loaderActions.start());
    let token = GetLocalStorage();
    if (token && token.userName && token.token) {
      getAddOnList();
      dispatch(loaderActions.end());
    }
    else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }

}, [])


  const getAddOnList = async () => {
    await getAllData('/master/v1/getaddondata').then((addOnList) => {
      setAddOnList(addOnList.data);
      setFilterItems(addOnList.data);
    });
  }


  function PdfExport() {
    const columns = [
      "Continent",
      "Country",
      "Region",
      "State",
      "City",
      "Add On name",
      "Supplier Name",
      "Allocate Inventory",
      "Active",
      "Created Date"
    ];
    var rows = [];
    for (let i = 0; i < addOnList?.length; i++) {
      var temp = [
        addOnList[i].ContinentName,
        addOnList[i].CountryName,
        addOnList[i].RegionName,
        addOnList[i].StateName,
        addOnList[i].City,
        addOnList[i].AddOnName,
        addOnList[i].SupplierName,
        addOnList[i].AllocateInventory,
        `${addOnList[i].IsActive ? 'Active' : 'InActive'}`,
        formatDate(addOnList[i].CreatedOn)
      ];
      rows = [...rows, temp]
    }
    generatePDF(columns, rows, "AddOnMaster", "Add on master")
  };

  const ExportToExcel = () => {
    const displayname = [
      "Continent", "Country", "Region", "State", "City", "Add On name", "Supplier Name", "Allocate Inventory", "Active", "Created Date"
    ];
    const fields = ['ContinentName', 'CountryName', 'RegionName', 'StateName', 'City', 'AddOnName', 'SupplierName', 'AllocateInventory', 'IsActive', 'CreatedOn'];
    ExportExcel(displayname, fields, addOnList, 'AddOn', 'AddOnMaster.xls')
  }

  const getPriciesProperty = (currentaddOn) => {
    let obj;
    if (currentaddOn.hasOwnProperty('TotalPirce') && currentaddOn.TotalPirce !== 0) {
      obj = {
        TotalPirce: currentaddOn.TotalPirce,
        price: 'totalPrice'
      }
    } else {
      obj = {
        price: 'perPersion',
        AdultPrice: currentaddOn.AdultPrice,
        ChildPrice: currentaddOn.ChildPrice,
      }
    }
    return obj
  }

  const handleEditMode = (id) => {
    const currentaddOn = addOnList.find((addOn) => addOn.AddOnId === id);
    const priceData = getPriciesProperty(currentaddOn);
    setErrors({});
    setDisabled(false);
    setSerachDisable(true);
    setSelectedImagePath()
    setButtonAddEditText('Edit');
    document.getElementById("addonImange").value = "";

    if (currentaddOn.ImagePath.length > 10) {
      setSelectedImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + currentaddOn.ImagePath)
    }
    setNewAddOn(
      {
        AddOnId: currentaddOn.AddOnId,
        LocationId: currentaddOn.LocationId,
        AddOnName: currentaddOn.AddOnName,
        SupplierId: currentaddOn.SupplierId,
        AllocateInventory: currentaddOn.AllocateInventory,
        ImageName: currentaddOn.ImageName,
        ImagePath: currentaddOn.ImagePath,
        IsActive: currentaddOn.IsActive,
        ...priceData
      }
    );
    setEditDisabled(true);
  }

  const hadleViewMode = (AddOnId) => {
    const currentaddOn = addOnList.find((addOn) => addOn.AddOnId === AddOnId);
    const priceData = getPriciesProperty(currentaddOn);
    document.getElementById("addonImange").value = "";
    setDisabled(true);
    setSerachDisable(true);
    if (currentaddOn.ImagePath.length > 10) {
      setSelectedImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + currentaddOn.ImagePath)
    }
    setNewAddOn(
      {
        AddOnId: currentaddOn.AddOnId,
        LocationId: currentaddOn.LocationId,
        AddOnName: currentaddOn.AddOnName,
        SupplierId: currentaddOn.SupplierId,
        AllocateInventory: currentaddOn.AllocateInventory,
        ImageName: currentaddOn.ImageName,
        ImagePath: currentaddOn.ImagePath,
        IsActive: currentaddOn.IsActive,
        ...priceData
      }
    );
  }
  const serachoption = [
    {
      label: 'Continent',
      value: '1'
    },
    {
      label: 'Country',
      value: '2'
    },
    {
      label: 'Region',
      value: '3'
    },
    {
      label: 'State',
      value: '4'
    },
    {
      label: 'City',
      value: '5'
    },
    {
      label: 'Add On name',
      value: '6'
    },
    {
      label: 'Supplier Name',
      value: '7'
    },
    {
      label: 'Allocate Inventory',
      value: '8'
    },
    {
      label: 'Active',
      value: '9'
    },
    // {
    //   label: 'Created Date',
    //   value: '10'
    // },
    {
      label: 'All',
      value: '11'
    },
  ]

  const handleChangeSearchValue = (e) => {
    setSearchText(e.target.value);
    setsclshow(true)
    if (e.target.value === '') {
      getAddOnList();
      setsclshow(false);
    }
  }

  const columns = [
    {
      name: 'Continent',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.ContinentName,
    },
    {
      name: 'Country',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.CountryName,
    },
    {
      name: 'Region',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.RegionName,
    },
    {
      name: 'State',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.StateName,
    },
    {
      name: 'City',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.City,
    },
    {
      name: 'Add On name',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.AddOnName,
    },
    {
      name: 'Supplier Name',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.SupplierName,
    },
    {
      name: 'Allocate Inventory',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.AllocateInventory,
    },
    {
      name: 'Active',
      sortable: true,
      float: "left",
      selector: (addOn) => addOn.IsActive ? 'Active' : 'InActive',
    },
    {
      name: 'Created Date',
      sortable: true,
      float: "left",
      selector: (addOn) => formatDateRequire(addOn.CreatedOn),
    },
    {
      name: 'Edit',
      // float:"right",
      selector: (addOn) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!userRights?.URightEdit}><i className="fa fa-edit" onClick={() => handleEditMode(addOn.AddOnId)}></i></button>
    },
    {
      name: 'View',
      // float:"right",
      selector: addOn => <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} ><i className="fa fa-eye" onClick={() => hadleViewMode(addOn.AddOnId)}></i></td>
      // selector: (hotel) => <i className="fa fa-eye" onClick={() => handleViewMode(hotel.HotelId)}></i>
    }
  ];

  const setitems = async () => {
    await getAllData('/master/v1/getaddondata').then((busdata) => {
      setFilterItems(busdata.data);
    });
  }

  useEffect(() => {
    let MENUID = GetMenuIdFromStorage()
    let mid = user?.find(x => x.MenuId === MENUID);

    if (mid?.URightView === false && mid?.URightView !== undefined) {
      navigate('/dashboard');
    };

    setUserRights(mid);
  }, [user])

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (searchText.trim().length > 0 && searchBy) {
      setitems();
      var filteritem = filteritems;
      var filterdata;
      if (searchBy === '1') {
        filterdata = filteritem?.filter(issec => issec.ContinentName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '2') {
        filterdata = filteritem?.filter(issec => issec.CountryName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '3') {
        filterdata = filteritem?.filter(issec => issec.RegionName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '4') {
        filterdata = filteritem?.filter(issec => issec.StateName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '5') {
        filterdata = filteritem?.filter(issec => issec.City.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '6') {
        filterdata = filteritem?.filter(issec => issec.AddOnName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '7') {
        filterdata = filteritem?.filter(issec => issec.SupplierName !== 'null' && issec.SupplierName.toLowerCase().includes(searchText.toLowerCase()));
      }
      else if (searchBy === '8') {
        filterdata = filteritem?.filter(issec => issec.AllocateInventory.toString().includes(searchText));
      }
      else if (searchBy === '9') {
        if (searchText.toLowerCase() === 'active'.toLowerCase()) {
          filterdata = filteritem?.filter(issec => issec.IsActive === true);
        }
        else if (searchText.toLowerCase() === 'inactive'.toLowerCase()) {
          filterdata = filteritem?.filter(issec => issec.IsActive === false);
        }
        else if ('active'.toLowerCase().includes(searchText.toLowerCase())) {
          filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
        }
        else if ('inactive'.toLowerCase().includes(searchText.toLowerCase())) {
          filterdata = filteritem?.filter(issec => issec.IsActive === false);
        }
        else {
          filterdata = filteritem?.filter(issec => formatDateRequire(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
        }
      }
      else if (searchBy === '10') {
        filterdata = filteritem?.filter(issec => formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase()));
      }
      else if (searchBy === '11') {
        if (searchText.toLowerCase() === 'active'.toLowerCase()) {
          filterdata = filteritem?.filter(issec =>
            issec.ContinentName.toLowerCase().includes(searchText.toLowerCase())
            || issec.CountryName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.RegionName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.StateName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.City.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.AddOnName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec?.SupplierName?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase())
            || issec.AllocateInventory.toString().includes(searchText.trim())
            || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase())
            || issec.IsActive === true);
        }
        else if (searchText.toLowerCase() === 'inactive'.toLowerCase()) {
          filterdata = filteritem?.filter(issec =>
            issec.ContinentName.toLowerCase().includes(searchText.toLowerCase())
            || issec.CountryName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.RegionName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.StateName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.City.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.AddOnName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec?.SupplierName?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase())
            || issec.AllocateInventory.toString().includes(searchText.trim())
            || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase())
            || issec.IsActive === false);
        }
        else if ('active'.toLowerCase().includes(searchText.toLowerCase())) {
          filterdata = filteritem?.filter(issec =>
            issec.ContinentName.toLowerCase().includes(searchText.toLowerCase())
            || issec.CountryName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.RegionName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.StateName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.City.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.AddOnName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec?.SupplierName?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase())
            || issec.AllocateInventory.toString().includes(searchText.trim())
            || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase())
            || issec.IsActive === true
            || issec.IsActive === false);
        }
        else if ('inactive'.toLowerCase().includes(searchText.toLowerCase())) {
          filterdata = filteritem?.filter(issec => issec.ContinentName.toLowerCase().includes(searchText.toLowerCase())
            || issec.CountryName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.RegionName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.StateName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.City.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.AddOnName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec?.SupplierName?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase())
            || issec.AllocateInventory.toString().includes(searchText.trim())
            || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase())
            || issec.IsActive === false);
        }
        else {
          filterdata = filteritem?.filter(issec => issec.ContinentName.toLowerCase().includes(searchText.toLowerCase())
            || issec.CountryName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.RegionName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.StateName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.City.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec.AddOnName.toLowerCase().includes(searchText.trim().toLowerCase())
            || issec?.SupplierName?.toLowerCase()?.includes(searchText?.trim()?.toLowerCase())
            || issec.AllocateInventory.toString().includes(searchText.trim())
            || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchText?.toLowerCase())
          );
        }
      }
      setAddOnList(filterdata)
    }
  }

  const clearsearch = () => {
    setSearchText("");
    setsclshow(false);
    getAddOnList();
  }

  return (
    <>
      <div class="container-fluid mt-4 heading_color">
        <h3>Add on master</h3>
      </div>

      <div class="container-fluid form_border py-5 my-3">
        <div class="d-flex justify-content-start ms-lg-3">
          <AddonmasterCreateForm
            newAddOn={newAddOn}
            userRights={userRights}
            setNewAddOn={setNewAddOn}
            buttonaddeditText={buttonaddeditText}
            setButtonAddEditText={setButtonAddEditText}
            editdisabled={editdisabled}
            setEditDisabled={setEditDisabled}
            errors={errors}
            setErrors={setErrors}
            disabled={disabled}
            setDisabled={setDisabled}
            defaultObject={defaultObject}
            setSerachDisable={setSerachDisable}
            getAddOnList={getAddOnList}
            seletedImagePath={seletedImagePath}
            setSelectedImagePath={setSelectedImagePath}
            clearsearch={clearsearch}
          />
        </div>
        <br />

        <div class="row">
          <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
              <Select
                className="dropdown"
                options={serachoption}
                value={serachoption?.filter(function (serachoption) {
                  return serachoption.value === searchBy;
                })}// set list of the dataalue 
                onChange={(value) => setSearchBy(value.value)} // assign onChange function
                isDisabled={serchdisable ? true : false}
                isSearchable={false}
              />
            </div>
            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
              <div className='clrbutton'>
                <input type="text"
                  onKeyDown={onKeyDownHandler}
                  id="btnSearchText" class="form-control" name="SearchValue" placeholder='Enter keyword'
                  onChange={handleChangeSearchValue}
                  value={searchText}
                  disabled={serchdisable ? true : false}
                />
                <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
              </div>
            </div>
            <div class="p-2 text-center">
              <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                disabled={serchdisable ? true : false}
                onClick={handleSearch}
              >Search</button>
            </div>
          </div>

        </div>
        <CustomTable
          columns={columns}
          data={addOnList}
          PdfExport={PdfExport}
          ExportToExcel={ExportToExcel}
          disabled={!userRights?.URightExport}
        />
      </div>
    </>
  )
}

export default memo(AddOnmaster);