import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../../../../assets/css/master.css";
// import "../Hotelmaster.css"
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, Tab, Tabs, Typography } from '@mui/material';

const RoomAmenities = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const checkBoxData = [
        { label: "No View", id: 1 },
        { label: "Hill View", id: 2 },
        { label: "River View", id: 3 },
        { label: "Bay View", id: 4 },
        { label: "Landmark View", id: 5 },
        { label: "Beach View", id: 6 },
        { label: "Harbour View", id: 7 },
        { label: "Sea View", id: 8 },
        { label: "Pool View", id: 9 },
        { label: "Lake View", id: 10 },
        { label: "City Terrace View", id: 11 },
        { label: "Golf Course View", id: 12 },
        { label: "Backwater View", id: 13 },
        { label: "Park View", id: 14 },
        { label: "Airport View", id: 15 },
        { label: "Marine View", id: 16 },
        { label: "Valley View", id: 17 },
        { label: "Garden View", id: 18 },
        { label: "Palace View", id: 19 },
        { label: "Jungle View", id: 20 },
        { label: "Courtyard View", id: 21 },
        { label: "Resort View", id: 22 },
        { label: "Lagoon View", id: 23 },
        { label: "Country Side View", id: 24 },
        { label: "Inter-Costal View", id: 25 },
    ]
    return (
        <>

            <div class="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-lg-2 col-3'>
                        <Box className='Hotel_listing'
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                        >
                            <Tabs
                                className='w-100'
                                orientation="vertical"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Basic Facility" {...a11yProps(0)} />
                                <Tab label="Common Areas" {...a11yProps(1)} />
                                <Tab label="Staff Facilty" {...a11yProps(2)} />
                                <Tab label="Outdoor Activities & Sports" {...a11yProps(3)} />
                                <Tab label="Food & Drinks" {...a11yProps(4)} />
                                <Tab label="Health & Wellness" {...a11yProps(5)} />
                                <Tab label="Business Center & Conference" {...a11yProps(6)} />
                                <Tab label="Beauty & Spa" {...a11yProps(7)} />
                                <Tab label="Security" {...a11yProps(8)} />
                                <Tab label="Transfer Facility" {...a11yProps(9)} />
                                <Tab label="Shopping" {...a11yProps(10)} />
                                <Tab label="Entertainment" {...a11yProps(11)} />
                                <Tab label="Others" {...a11yProps(12)} />
                            </Tabs>
                        </Box>
                    </div>
                    <div className='col-lg-10 col-9'>
                        <form action="" className='py-3 my-1'>
                            <TabPanel value={value} index={0}>
                            <h6 className='mt-3 fw-bold room_header'>Room View</h6>
                                {checkBoxData.map((view) => (
                                    <FormControlLabel className='col-4 mx-0'
                                        control={
                                            <Checkbox checked={true} onChange={handleChange} name="gilad" />
                                        }
                                        label={view.label}
                                    />
                                ))}

                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {/* <CommonAreas /> */}1
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {/* <StaffFacilty /> */}2
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                {/* <OutdoorActivitiesandSports /> */}3
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                {/* <FoodandDrinks /> */}4
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                {/* <HealthandWellness /> */}5
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                {/* <BusinessCenterandConference /> */}6
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                {/* <BeautyandSpa /> */}7
                            </TabPanel>
                            <TabPanel value={value} index={8}>
                                {/* <Security /> */}8
                            </TabPanel>
                            <TabPanel value={value} index={9}>
                                {/* <TransferFacility /> */}9
                            </TabPanel>
                            <TabPanel value={value} index={10}>
                                {/* <Shopping /> */}10
                            </TabPanel>
                            <TabPanel value={value} index={11}>
                                {/* <Entertainment /> */}
                            </TabPanel>
                            <TabPanel value={value} index={12}>
                                {/* <Others /> */}12
                            </TabPanel>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomAmenities