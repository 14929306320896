import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumericspace, isValidEmail, onlyNumeric, generatePDF } from '../../commonClass/CommonValidation';
import { ExportExcel } from '../../commonClass/ExportExcel';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomSelection from '../../../customControl/CustomSelection';
import AccesspointmasterRailway from './Partial/AccesspointmasterRailway';
import AccesspointmasterAirport from './Partial/AccesspointmasterAirport';
import AccesspointmasterPort from './Partial/AccesspointmasterPort';
import { GetMenuIdFromStorage } from '../../commonClass/Menudect';
import CustomTable from '../../../customControl/CustomTable';
import AccesspointmasterBus from './Partial/AccesspointmasterBus';

const Accesspointmaster = () => {
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Access Point Master</h3>
            </div>
            <div className='container-fluid mt-3 tab_section px-0'>
                <ul class="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">
                    <li class="nav-item mx-lg-1 mx-md-1 mx-1 mt-1" role="presentation">
                        <a class="nav-link active" id="pills-Bus-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Bus" type="button" role="tab" aria-controls="pills-about"
                            aria-selected="true"><strong>Bus Stand</strong></a>
                    </li>
                    <li class="nav-item mx-lg-1 mx-md-1 mx-1  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-Railway-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Railway" type="button" role="tab" aria-controls="pills-tips"
                            aria-selected="false"><strong >Railway Station</strong></a>
                    </li>
                    <li class="nav-item mx-lg-1 mx-md-1 mx-1  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-Airport-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Airport" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Airport</strong></a>
                    </li>
                    <li class="nav-item mx-lg-1 mx-md-1 mx-1  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-Port-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Port" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Port</strong></a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-Bus" role="tabpanel"
                    aria-labelledby="pills-Bus-tab">
                    <AccesspointmasterBus/>
                </div>
                <div class="tab-pane fade show" id="pills-Railway" role="tabpanel"
                    aria-labelledby="pills-Railway-tab">
                    <AccesspointmasterRailway />
                </div>
                <div class="tab-pane fade show" id="pills-Airport" role="tabpanel"
                    aria-labelledby="pills-Airport-tab">
                    <AccesspointmasterAirport />
                </div>
                <div class="tab-pane fade show" id="pills-Port" role="tabpanel"
                    aria-labelledby="pills-Port-tab">
                    <AccesspointmasterPort />
                </div>
            </div>
        </>
    )
}

export default Accesspointmaster