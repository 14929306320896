import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { loaderActions } from "../../../_actions/loader.actions";
import { ClearLocalStorage, GetLocalStorage } from "../../commonClass/Session";
import { getAllData } from "../../../services/ApiCallingUserServices";

const ApplicationDetails = () => {

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const user = useSelector(store => store.userstatus.tba);
  const [userRights, setUserRights] = useState({});

  const [jobApplicationData, setJobApplicationData] = useState([])

  const getjobappbyid = async (id) => {
    const jobappbyid = await getAllData('/master/v1/getjobappbyid?jobVacancyId=' + id)
    if (jobappbyid.status) {
      setJobApplicationData(jobappbyid.data[0])
    }
  }


  useEffect(() => {
    dispatch(loaderActions.start());
    if (location?.state !== null) {
      let token = GetLocalStorage();

      if (token && token.userName && token.token) {
        getjobappbyid(location?.state?.JobAppId).then(() => { dispatch(loaderActions.end()) });
      }
      else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
    else {

      let pagelink = user?.find(x => {
        if (x.PageLink === "/JobApplication")
          return x.MenuId
      });
      let mid = user?.find(x => x.MenuId === pagelink.MenuId);

      if (mid?.URightView === false && mid?.URightView !== undefined) {
        navigate('/dashboard');
      }
      else {
        navigate('/JobApplication')
      }
      setUserRights(mid);
      dispatch(loaderActions.end())
    }
  }, [location, user]);


  return (
    <>
      <div class="container-fluid mt-4 heading_color">
        <h3>Application Details</h3>
      </div>
      <div class="container-fluid form_border py-5 my-3">
        <div className="mb-3 row mx-1">
          <div className="applicant-details col-lg-12 col-md-12 col-sm-12">
            <div className="row mb-2">
              <div className="col-md-2 col-4">
                <h5>Job Code</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5> {jobApplicationData?.JodeCode} </h5>
              </div>
              <div className="col-6"></div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 col-4">
                <h5>Name of Applicant</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5> {jobApplicationData?.NameOfApplicant} </h5>
              </div>
              <div className="col-md-2 col-4">
                <h5> Email ID</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5 className="text-break">{jobApplicationData?.EmailId} </h5>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 col-4">
                <h5>Mobile Number</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5>{jobApplicationData?.MobileNo} </h5>
              </div>
              <div className="col-md-2 col-4">
                <h5> Applied Position</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5> {jobApplicationData?.JobTitle} </h5>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 col-4">
                <h5>Total Experience (in year)</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5>{jobApplicationData?.TotalExp} </h5>
              </div>
              <div className="col-md-2 col-4">
                <h5> Job Location</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-3 col-7">
                <h5>{jobApplicationData?.City} </h5>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-2 col-6">
                <h5>Describe YourSelf</h5>
              </div>
              <div className="col-md-1 col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-9 col-12">
                <h5>{jobApplicationData?.YourSelf} </h5>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-2 col-6">
                <h5>Cover Letter</h5>
              </div>
              <div className="col-md-1 col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-9 col-12">
                <h5>{jobApplicationData?.CoverLetter} </h5>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-2 col-6">
                <h5>Resume</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-9 col-12">
                {
                  jobApplicationData.ResumePath !== "" ?
                    <h5 title={jobApplicationData?.ResumeName} className="preview-link text-truncate">
                      <a
                        href={"https://static-ethics.sgp1.digitaloceanspaces.com/etm/" + jobApplicationData.ResumePath}
                        target='_blank'
                        rel="noopener noreferrer"
                        className="cursor_pointer"
                      >
                        {jobApplicationData?.ResumeName}
                      </a>
                    </h5>
                    :
                    <h5>NA</h5>
                }
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-2 col-6">
                <h5>Other Documents</h5>
              </div>
              <div className="col-1">
                <h5>:</h5>
              </div>
              <div className="col-md-9 col-12">
                {
                  jobApplicationData.OtherDocPath !== "" ?
                    <h5 title={jobApplicationData?.OtherDocName} className="preview-link text-truncate">
                      <a
                        href={"https://static-ethics.sgp1.digitaloceanspaces.com/etm/" + jobApplicationData.OtherDocPath}
                        target='_blank'
                        rel="noopener noreferrer"
                        className="cursor_pointer"
                      >
                        {jobApplicationData?.OtherDocName}
                      </a>
                    </h5>
                    :
                    <h5>NA</h5>
                }
              </div>
            </div>
            <div className="row mt-5 mb-4">
              <div className="col-12">
                <div class="p-2 text-center">
                  <NavLink to="/JobApplication">
                    <button type="button" class="btn btn-primary">
                      Back
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationDetails;
