import React from 'react';
import { useEffect, useState } from "react";
import "../../../../../assets/css/master.css";
import "../Hotelmaster.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { isValidEmail, NumericWithDecimal, onlyNumeric, PhoneNmber, isValidImage, isValidVideo, onlyAlphabets, isValidPhonenumber } from '../../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { Rating } from 'react-simple-star-rating';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../../../customControl/ConfirmationModal';
import CustomSelection from '../../../../../customControl/CustomSelection';
import { TextField } from '@mui/material';

const Createchainhotel = () => {
    const navigate = useNavigate();
    const handleOpenSupplier = (type) => {
        localStorage.setItem('type', type)
        navigate(`/HotelListing/${type}/basicinfo`, { state: { disabled: false, editdisabled: false, viewtype: 'Add', HotelId: 0, type: type } });
    }
    return (
        <>
            <div class="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-8'>
                        <h3><i onClick={() => navigate("/Hotellistmaster")} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Create Chain Of Hotels</h3>
                    </div>
                    <div className='col-4 text-end'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" >Save</button>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => handleOpenSupplier('chain')}>Next</button>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-3 my-1">
                <form action="" >
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Account Name" variant="filled" className='w-100' type="text" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Brand Name" variant="filled" className='w-100' type="text" />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <h5>Contact Details</h5>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Account Owner" variant="filled" className='w-100' type="text" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Email" variant="filled" className='w-100' type="text" />
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Phone Number" variant="filled" className='w-100' type="text" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Createchainhotel