import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { getAllData } from '../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { loaderActions } from '../../_actions/loader.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExportExcel } from '../commonClass/ExportExcel';
import { generatePDF } from '../commonClass/CommonValidation';
import CustomTable from '../../customControl/CustomTable'

const BookingTransactionList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [serchdisable, setSerachDisable] = useState(false);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('11');
    const [clrshow, setsclshow] = useState(false);

    const [TransactionData, setTransactionData] = useState([])
    const getbookingtrans = async () => {
        const bookingtrans = await getAllData("/master/v1/getbookingtrans")
        setTransactionData(bookingtrans.data)
    }

    const user = useSelector((store) => store.userstatus.tba);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Name',
            value: '1'
        },
        {
            label: 'Mobile No',
            value: '2'
        },
        {
            label: 'Email',
            value: '3'
        },
        {
            label: 'Package Code',
            value: '4'
        },
        {
            label: 'Booking ID',
            value: '5'
        },
        {
            label: 'Amount',
            value: '6'
        },
        {
            label: 'Payment Mode',
            value: '7'
        },
        {
            label: 'Payment Status',
            value: '8'
        },
        {
            label: 'Transaction ID',
            value: '9'
        },
        {
            label: 'Transaction Date',
            value: '10'
        },
        {
            label: 'Response Message',
            value: '12'
        },
        {
            label: 'All',
            value: '11'
        },

    ];
    const [filteritems, setFilterItems] = useState([]);

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const allbookingtrans = await getAllData('/master/v1/getbookingtrans')
        setFilterItems(allbookingtrans.data)
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getbookingtrans();
            setsclshow(false)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getbookingtrans();
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.Name?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.MobileNo?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.EmailId?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.BookingNo?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.TxnAmount?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.PaymentMode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec.Status?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '9') {
                filterdata = filteritem?.filter(issec => issec.TxnId?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => formatDate(issec.TxnDate)?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '12') {
                filterdata = filteritem?.filter(issec => issec.RespMsg?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            // else if (searchby === '5') {
            //     filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            else if (searchby === '11') {
                filterdata = filteritem?.filter(issec => issec.Name?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.MobileNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.EmailId?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PackageCode?.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    issec.BookingNo?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.TxnAmount?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.PaymentMode?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Status?.toLowerCase().includes(searchvalue.toLowerCase()) ||
                    issec.TxnId?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.TxnDate)?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.RespMsg?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            setTransactionData(filterdata)
        }

    }

    const columns = [
        {
            name: "Name",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.Name
        },
        {
            name: "Mobile No",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.MobileNo
        },
        {
            name: "Email",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.EmailId
        },
        {
            name: "Package Code",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.PackageCode
        },
        {
            name: "Booking ID",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.BookingNo
        },
        {
            name: "Amount",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.TxnAmount
        },
        {
            name: "Payment Mode",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.PaymentMode
        },
        {
            name: "Payment Status",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.Status
        },
        {
            name: "Transaction ID",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.TxnId
        },
        {
            name: "Transaction Date",
            sortable: true,
            float: "left",
            selector: (transaction) => formatDate(transaction.TxnDate)
        },
        {
            name: "Response Message",
            sortable: true,
            float: "left",
            selector: (transaction) => transaction.RespMsg
        },
    ]

    // const TransactionData = [
    //     {
    //         Name: 'abc',
    //         MoboleNo: '9876543210',
    //         Email: 'abc@gmail.com',
    //         PackageCode: 'PackageCode',
    //         BookingID: 'BookingID',
    //         Amount: '1',
    //         PaymentMode: 'PaymentMode',
    //         PaymentStatus: 'PaymentStatus',
    //         TransactionID: 'TransactionID',
    //         TransactionDate: 'TransactionDate',
    //     }
    // ]

    //#region Date format
    function formatDate(date) {
        if (date !== null) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('/');
        }
        else {
            return null
        }
    }
    //#endregion

    //#region Exports
    const PDFExport = () => {
        const columns = ["Name", "Mobile No", "Email", "Package Code", "Booking ID", "Amount", "Payment Mode", "Payment Status", "Transaction ID", "Transaction Date", "Response Message"];

        let rows = [];
        for (let i = 0; i < TransactionData?.length; i++) {
            let temp = [
                TransactionData[i].Name,
                TransactionData[i].MobileNo,
                TransactionData[i].EmailId,
                TransactionData[i].PackageCode,
                TransactionData[i].BookingNo,
                TransactionData[i].TxnAmount,
                TransactionData[i].PaymentMode,
                TransactionData[i].Status,
                TransactionData[i].TxnId,
                formatDate(TransactionData[i].TxnDate),
                TransactionData[i].RespMsg,
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, 'BookingTransactionList', 'Booking Transaction List', "L")
    };

    const ExcelExport = () => {
        const dispalyname = ["Name", "Mobile No", "Email", "Package Code", "Booking ID", "Amount", "Payment Mode", "Payment Status", "Transaction ID", "Transaction Date", "Response Message"];
        const fields = ['Name', 'MobileNo', 'EmailId', 'PackageCode', 'BookingNo', 'TxnAmount', 'PaymentMode', 'Status', 'TxnId', 'TxnDate', 'RespMsg'];
        ExportExcel(dispalyname, fields, TransactionData, "BookingTransactionList", "BookingTransactionList.xls");
    };
    //#endregion

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getbookingtrans().then(() => {
                dispatch(loaderActions.end());
            });
            setitems();
        } else {
            ClearLocalStorage();
            dispatch({
                type: "SESSIONOUT",
                message: "Your Session Expired. Please Login Again!",
            });
        }
    }, []);

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find((x) => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate("/dashboard");
        }
        user?.filter((x) => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserExport(obj.URightExport);
        });
    }, [user]);

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Booking Transaction List</h3>
            </div>
            <div className="container-fluid form_border pb-5 my-3">
                <div className="row mx-1 mt-4 mb-3"></div>
                <form action="">
                    <br />
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                                <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                    <Select
                                        className="dropdown"
                                        options={serachoption}
                                        value={serachoption?.filter(function (serachoption) {
                                            return serachoption.value === searchby;
                                        })}// set list of the datavalue 
                                        onChange={handleSearchBy} // assign onChange function
                                        isDisabled={serchdisable ? true : false}
                                        isSearchable={false}
                                    />
                                </div>
                                <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                    <div className='clrbutton'>
                                        <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                        <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                    </div>
                                </div>
                                <div className="p-2 text-center">
                                    <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                                </div>
                            </div>
                            <CustomTable
                                className={"booking_transaction_table"}
                                columns={columns}
                                data={TransactionData}
                                PdfExport={PDFExport}
                                ExportToExcel={ExcelExport}
                                disabled={!userexport}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BookingTransactionList
