import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumeric, alphaNumericspace, isValidEmail, onlyNumeric, generatePDF } from '../../../commonClass/CommonValidation';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import CustomSelection from '../../../../customControl/CustomSelection'; import { GetMenuIdFromStorage } from '../../../commonClass/Menudect';
import { ExportExcel } from '../../../commonClass/ExportExcel';
import CustomTable from '../../../../customControl/CustomTable';


const AccesspointmasterPort = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [editdisabled, setEditDisabled] = useState(true);
    const [activeId, setActiveId] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [disabled, setDisabled] = useState();
    const [clrshow, setsclshow] = useState(false);
    const [portdata, setPortData] = useState([]);
    const [useredit, setUserEdit] = useState(true);
    const [useradd, setUserAdd] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [citydisable, setCityDisable] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [allCity, setallCity] = useState([]);
    const [citydata, setCityData] = useState([]);
    const [cityid, setCity] = useState(0);
    const [alllocation, setAllLocation] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        AccessPointPortId: 0,
        LocationId: 0,
        PortName: '',
        PortCode: '',
        Latitude: '',
        Longitude: '',
        EmailId: '',
        MobNoCode: '',
        MobNo: '',
        Address: '',
        Pincode: '',
        LandlineNoCode: '',
        LandlineNo: '',
        WebsiteURL: '',
        IsActive: false,
        UserId: 0
    });
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(form));
        //handleSubmitData();
    };
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            form.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }
    const handleConfirmation = (e) => {
        if (e === true) {
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormErrors('');
        }
        setModalShow(false);
    }
    //#region bind datatable
    const columns = [
        {
            name: 'Port Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => Port.PortName
        },
        {
            name: 'Port Code',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => Port.PortCode
        },
        {
            name: 'Latitude/Longitude',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => (Port.Latitude + '/' + Port.Longitude)
        },
        {
            name: 'City',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => Port.City
        },
        {
            name: 'State',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => Port.StateName
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (Port) => Port.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (Port) => formatDateRequire(Port.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (Port) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(Port.AccessPointPortId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (Port) => <i className="fa fa-eye" onClick={() => handleViewMode(Port.AccessPointPortId)}></i>// onClick={() => handleViewMode(banner.bannerId)}
        }
    ];

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    function PdfExport() {
        const columns = [
            "Port Name",
            "Port Code",
            "Latitude",
            "Longitude",
            "City",
            "State",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < portdata?.length; i++) {
            var temp = [
                portdata[i].PortName,
                portdata[i].PortCode,
                portdata[i].Latitude,
                portdata[i].Longitude,
                portdata[i].City,
                portdata[i].StateName,
                `${portdata[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(portdata[i].CreatedOn)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "port", "Port")
    };

    const ExportToExcel = () => {
        const displayname = [
            "Port Name",
            "Port Code",
            "Latitude",
            "Longitude",
            "City",
            "State",
            "Active",
            "Created Date"
        ];
        const fields = ['PortName', 'PortCode', 'Latitude', 'Longitude', 'City', 'StateName', 'IsActive', 'CreatedOn'];
        ExportExcel(displayname, fields, portdata, 'port', 'port.xls')
    }

    function formatDateRequire(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    //#endregion

    //#region handling search
    const serachoption = [
        {
            label: 'Port Name',
            value: '1'
        },
        {
            label: 'Port Code',
            value: '7'
        },
        {
            label: 'Latitude/Longitude',
            value: '2'
        },
        {
            label: 'City',
            value: '3'
        },
        {
            label: 'State',
            value: '4'
        },
        {
            label: 'Active',
            value: '5'
        },
        {
            label: 'All',
            value: '6'
        },

    ];

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            form.UserId = token?.userMasterID
            // (Window.$name && Window.$userToken) {
            getPortlist();
            getcitylist();
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        // let pagelink = user?.find(x => {
        //     if (x.PageLink === window.location.pathname)
        //         return x.MenuId
        // });
        if (window.location.pathname !== '/dashboard') {
            let pagelink = user?.find(x => {
                if (x.PageLink === window.location.pathname)
                    return x.MenuId
            });
            let mid = user?.find(x => x.MenuId === pagelink.MenuId);
            if (mid?.URightView === false && mid?.URightView !== undefined) {
                navigate('/dashboard');
            }
            user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
                setUserAdd(obj.URightAdd);
                setUserEdit(obj.URightEdit);
                setUserExport(obj.URightExport);

            });
        }
    }, [user])
    //#endregion

    const getPortlist = async () => {
        await getAllData('/master/v1/getallaccesspointport').then((portdata) => {
            setPortData(portdata.data);
        });
    }
    const LocationId = useRef(null);
    const getcitylist = async () => {
        await getAllData('/master/v1/getallcity').then((portdata) => {
            setallCity(portdata.data);
            setCityData(portdata.data?.filter(IsAct => IsAct.IsActive === true));
            dispatch(loaderActions.end());
        });
    }
    const cityList = citydata?.map(value => ({
        label: value.City,
        value: value.LocationId
    }));
    const getalllocationbyCity = async (locationid) => {
        const locid = { LocationId: locationid }
        await postUsersData('/master/v1/getlocationbyid', locid).then((AllLocationData) => {
            setAllLocation(AllLocationData.data);
        });
    }
    const continent = alllocation?.map(value => ({
        label: value.continentName,
        value: 1
    })
    );

    const country = alllocation?.map(value => ({
        label: value.countryName,
        value: 1
    })
    );

    const region = alllocation?.map(value => ({
        label: value.regionName,
        value: 1
    })
    );

    const state = alllocation?.map(value => ({
        label: value.stateName,
        value: 1
    })
    );
    const handleEditMode = (id) => {
        setDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
        setEditDisabled(false);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        setFormErrors('');
        portdata?.map(obj => {

            if (obj.AccessPointPortId === id) {
                form.AccessPointPortId = obj.AccessPointPortId
                form.LocationId = obj.LocationId
                form.PortName = obj.PortName
                form.PortCode = obj.PortCode
                form.Latitude = obj.Latitude
                form.Longitude = obj.Longitude
                form.EmailId = obj.EmailId
                form.MobNoCode = obj.MobNoCode
                form.MobNo = obj.MobNo
                form.Address = obj.Address
                form.Pincode = obj.Pincode
                form.LandlineNoCode = obj.LandlineNoCode
                form.LandlineNo = obj.LandlineNo
                form.WebsiteURL = obj.WebsiteURL
                form.IsActive = obj.IsActive
                // Check In-Active Data and add in Dropdown...
                let allcitylist = allCity?.filter(IsAct => IsAct.IsActive === true);
                setCityData(allCity?.filter(IsAct => IsAct.IsActive === true));
                setCityDisable(false);
                if (!allcitylist?.find(IsCity => IsCity.LocationId === obj.LocationId)) {
                    setCityDisable(true)
                    setCityData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, allCity?.find(isCity => isCity.LocationId === obj.LocationId)] : [])
                    // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

                }
                setCity(obj.LocationId);
                getalllocationbyCity(obj.LocationId);


                setActiveId(obj.IsActive);
            }
        });

    }
    const handlecity = (e) => {
        form.LocationId = e.value
        setCity(e.value);
        getalllocationbyCity(e.value);
        setFormErrors('');
        setEditDisabled(true);
    }
    const validate = (values) => {
        setIsSubmit(true);
        const error = {}
        if (values.LocationId === 0) {
            error.LocationId = "Kindly select the City name";
            setIsSubmit(false);
        }
        if (!values.PortName || values.PortName?.trim()?.length === 0) {
            error.PortName = "Kindly Enter The Port Name ";
            setIsSubmit(false);
        }
        if (values.EmailId?.trim()?.length > 0 && !isValidEmail(values.EmailId)) {
            error.EmailId = "Email id should in a format abc@xyz.com";
            setIsSubmit(false);
        }
        if (values.WebsiteURL?.length > 0 && !validator.isURL(values.WebsiteURL)) {
            if (!validator.isURL(values.WebsiteURL)) {
                error.WebsiteURL = "Only website links can be added here.";
                setIsSubmit(false);
            }
        }
        return error;
    }

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                const Portdata = await postUsersData('/master/v1/insertaccesspointport', form)
                const status = Portdata.status;
                if (!Portdata.status && !Portdata.data) {
                    dispatch({ type: 'ALERTING', message: Portdata.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: Portdata.message });
                    getPortlist();
                    setitems();
                    clear();
                    setDisabled(false);
                    setEditDisabled(true);
                }
                else {
                    dispatch({ type: 'ALERTING', message: Portdata.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const Portdata = await patchUsersData('/master/v1/updateaccesspointport', form)
                const status = Portdata.status;
                if (!Portdata.status && !Portdata.data) {
                    dispatch({ type: 'ALERTING', message: Portdata.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: Portdata.message });
                    getPortlist();
                    setitems();
                    clear();
                }
                else {
                    dispatch({ type: 'ALERTING', message: Portdata.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }
    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "LocationId") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'MobNo') {
            result = onlyNumeric(e.target.value)
        }
        else if ([e.target.name][0] === 'PortName') {
            result = alphaNumericspace(e.target.value)
        }
        else if ([e.target.name][0] === 'PortCode') {
            result = alphaNumeric(e.target.value)
        }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        setEditDisabled(true);
        setFormErrors('');
    }
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setButtonAddEditText('Add');
        setIsSubmit(false);
        setDisabled(false);
        setFormErrors('');
        setForm({
            ...form, ['PortName']: '', ['PortCode']: '', ['LocationId']: 0, ['Latitude']: '', ['Longitude']: '', ['EmailId']: '',
            ['MobNoCode']: '', ['IsActive']: false, ['AccessPointPortId']: 0, ['MobNo']: '', ['Address']: '',
            ['Pincode']: '', ['LandlineNoCode']: '', ['LandlineNo']: '', ['WebsiteURL']: ''
        });
        setCity(0);
        setAllLocation([]);
        setActiveId(false);
        getcitylist();
        getPortlist();
        setCityDisable(false);
        setCityData([]);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('6');
        setSearchValue('');
    }
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('6');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);

    const setitems = async () => {
        await getAllData("/master/v1/getallaccesspointport").then((portdata) => {
            setFilterItems(portdata.data);
        });
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getPortlist();
            setsclshow(false);
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.City.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.StateName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDateRequire(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchvalue?.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchvalue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchvalue?.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchvalue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.PortName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Latitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Longitude.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.City.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PortCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedOn)?.toLowerCase().includes(searchvalue?.toLowerCase())
                        || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setPortData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getPortlist();
    }
    //#endregion


    return (
        <>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Continent</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={continent?.find(({ value }) => value === 1)}
                                            options={continent}
                                        />
                                    </div>

                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Country</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={country?.find(({ value }) => value === 1)}
                                            options={country}
                                        />
                                    </div>

                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">Region</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={region?.find(({ value }) => value === 1)}
                                            options={region}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">State</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <Select className="dropdown select_dropdown"
                                            isDisabled
                                            value={state?.find(({ value }) => value === 1)}
                                            options={state}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-2 col-sm-6 mx-lg-auto'>
                                    <div class="col-lg-12 col-md-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <CustomSelection
                                            datalist={cityList}
                                            selId='LocationId'
                                            selRef={LocationId}
                                            fieldid={cityid}
                                            SelectionChange={handlecity}
                                            isdisable={disabled}
                                            isactivedisable={citydisable}
                                            issearchable={true}
                                        />
                                        {formErrors.LocationId && <p className='text-danger text-start'>{formErrors.LocationId}</p>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-1 mt-4 col-lg-11 mx-lg-5">
                        <div className='row'>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Port Name</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='PortName'
                                            name="PortName"
                                            value={form.PortName}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Port Name'
                                            maxLength={200}
                                        />
                                        {formErrors.PortName && <p className='text-danger text-start'>{formErrors.PortName}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Port Code</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='PortCode'
                                            name="PortCode"
                                            value={form.PortCode}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Port Code'
                                            maxLength={50}
                                        />
                                        {/* {formErrors.PortName && <p className='text-danger text-start'>{formErrors.PortName}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Latitude</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='Latitude'
                                            name="Latitude"
                                            value={form.Latitude}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Latitude'
                                            maxLength={20}
                                        />
                                        {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Longitude</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='Longitude'
                                            name="Longitude"
                                            value={form.Longitude}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Longitude'
                                            maxLength={20}
                                        />
                                        {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Email Id</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='EmailId'
                                            name="EmailId"
                                            value={form.EmailId}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Email Id'
                                            maxLength={70}
                                        />
                                        {formErrors.EmailId && <p className='text-danger text-start'>{formErrors.EmailId}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Landline Number</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <div className='input-group'>
                                            <input class="form-control"
                                                type="text"
                                                id='LandlineNoCode'
                                                name="LandlineNoCode"
                                                value={form.LandlineNoCode}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Code'
                                                maxLength={6}
                                            />
                                            <input class="form-control"
                                                type="text"
                                                id='LandlineNo'
                                                name="LandlineNo"
                                                value={form.LandlineNo}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Landline Number'
                                                maxLength={15}
                                            />
                                        </div>
                                        {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Mobile Number</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <div className='input-group'>
                                            <input class="form-control"
                                                type="text"
                                                id='MobNoCode'
                                                name="MobNoCode"
                                                value={form.MobNoCode}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Code'
                                                maxLength={6}
                                            />
                                            <input class="form-control"
                                                type="text"
                                                id='MobNo'
                                                name="MobNo"
                                                value={form.MobNo}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Mobile Number'
                                                maxLength={15}
                                            />
                                        </div>
                                        {/* {formErrors.SupplierName && <p className='text-danger text-start'>{formErrors.SupplierName}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Website URL</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input class="form-control"
                                            type="text"
                                            id='WebsiteURL'
                                            name="WebsiteURL"
                                            value={form.WebsiteURL}
                                            onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder='Website URL'
                                        />
                                        {formErrors.WebsiteURL && <p className='text-danger text-start'>{formErrors.WebsiteURL}</p>}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Pincode</label>
                                    </div>
                                    <div class=" col-lg-6 col-md-12 col-sm-12 mb-lg-1">
                                        <input type="text"
                                            id="Pincode"
                                            class="form-control"
                                            name="Pincode"
                                            value={form.Pincode}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            maxLength={15}
                                            placeholder='Pincode' />
                                        {/* {formErrors.Website && <p className='text-danger text-start'>{formErrors.Website}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Address</label>
                                    </div>
                                    <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1 mb-md-2">
                                        <textarea class="form-control"
                                            id="textAreaExample3"
                                            rows="2"
                                            name="Address"
                                            value={form.Address}
                                            disabled={disabled ? true : false}
                                            onChange={onChange}
                                            placeholder='Address'
                                            maxLength={300}
                                        >

                                        </textarea>
                                        {/* {formErrors.AboutSupplier && <p className='text-danger text-start'>{formErrors.AboutSupplier}</p>} */}
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class='row'>
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1 mb-md-2">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        />
                                    </div>
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />

                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 d-flex justify-content-center">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                    </div>
                    <div class="row mt-3">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={portdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default AccesspointmasterPort