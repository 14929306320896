import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../../assets/css/master.css"
import { useDispatch, useSelector } from 'react-redux';
import { NumericWithDecimal, onlyNumeric } from '../../../commonClass/CommonValidation';
import { errorActions } from '../../../../_actions/error.action';
import CustomSelection from '../../../../customControl/CustomSelection';
import { getAllData } from '../../../../services/ApiCallingUserServices';

const VendorPricing = (props) => {
    const {
        handlePricingAdd,
        handlePricingDisable,
        retriveflag,
        Pricingdatalist,
        editableflag,
        ischecked
    } = props

    const errorStatus = useSelector(state => state.errorStatus.isError);
    const sbmitStatus = useSelector(state => state.errorStatus.isSubmitting);
    const pricingStatus = useSelector(state => state.errorStatus.isPricing);
    const dispatch = useDispatch();

    const [formerror, setFormError] = useState({});
    const [supplierlist, setSupplierList] = useState([]);
    const [allSupplier, setAllSupplier] = useState([]);
    const [supplierDisable, setSupplierDisable] = useState(false)
    const [categorylist, setCategorylist] = useState([]);
    const [pricetypelist, setPriceTypelist] = useState([]);
    const [supplierid, setSupplierId] = useState(0);

    const [pricingobj, setPricingObj] = useState({
        supplierId: 0,
        categoryid: 0,
        transferPrice: null,
        directJoiningCostPrice: null,
        oprationcostId: 0,
        fixOperationCostPrice: null,
        tourTypeId: 0,
        tourManagementPrice: null,
        childrenPrice: null
    });


    const getSupplierllist = async () => {
        await getAllData('/master/vendor/v1/vengetsupllierlist').then((response) => {
            setSupplierList(response.data?.filter(IsAct => IsAct.IsActive === true))
            setAllSupplier(response.data)
        });
    }
    const supplierlistdata = supplierlist?.map((value) => ({
        label: value.Name,
        value: value.Id
    }));
    const getventransfercategory = async () => {
        await getAllData('/master/vendor/v1/ventransfercategorymaster').then((response) => {
            setCategorylist(response.data)
        })
    }
    const getvenpricetype = async () => {
        await getAllData('/master/vendor/v1/venpricetypemaster').then((response) => {
            setPriceTypelist(response.data)
        })
    }

    const handlesupplierchange = (e) => {
        setFormError({});
        setSupplierId(e.value);
        setPricingObj({ ...pricingobj, supplierId: e.value });
        handlePricingDisable(false);
        if (e.value > 0) {
            dispatch(errorActions.pricingsupplier());
        }
        else {
            dispatch(errorActions.pricingsupplierreset());
        }
        dispatch(errorActions.resetallstatusflag())
    }
    const handleCategoryChange = (e) => {
        setFormError({});
        setPricingObj({ ...pricingobj, categoryid: parseInt(e.target.value) });
        handlePricingDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }
    const handleOprationChange = (e) => {
        setFormError({});
        setPricingObj({ ...pricingobj, oprationcostId: parseInt(e.target.value) });
        handlePricingDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }
    const handleTourChange = (e) => {
        setFormError({});
        setPricingObj({ ...pricingobj, tourTypeId: parseInt(e.target.value) });
        handlePricingDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }
    const onChange = (e) => {
        setFormError({});
        handlePricingDisable(false);
        // dispatch(errorActions.overviewdurationset());
        dispatch(errorActions.resetallstatusflag())
        var result;
        if ([e.target.name][0] === 'transferPrice') {
            result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
            if (result >= 0 && result != null) {
                if (result === "") {
                    dispatch(errorActions.pricingtransferreset())
                }
                else {
                    dispatch(errorActions.pricingtransfer())
                }

            }
            else {
                dispatch(errorActions.pricingtransferreset())
            }
            // result >= 0 && result !=null ? dispatch(errorActions.pricingtransfer()) : dispatch(errorActions.pricingtransferreset())
        }
        else if ([e.target.name][0] === 'directJoiningCostPrice') {
            result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
            if (result >= 0 && result != null) {
                if (result === "") {
                    dispatch(errorActions.pricingjoiningcostreset())
                }
                else {
                    dispatch(errorActions.pricingjoiningcost())
                }

            }
            else {
                dispatch(errorActions.pricingjoiningcost())
            }
            // result >= 0 && result !=null ? dispatch(errorActions.pricingjoiningcost()) : dispatch(errorActions.pricingjoiningcostreset())
        }
        else if ([e.target.name][0] === 'fixOperationCostPrice') {
            result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
            if (result >= 0 && result != null) {
                if (result === "") {
                    dispatch(errorActions.pricingoperationcostreset())
                }
                else {
                    dispatch(errorActions.pricingoperationcost())
                }

            }
            else {
                dispatch(errorActions.pricingoperationcost())
            }
            // result >= 0 && result !=null ? dispatch(errorActions.pricingoperationcost()) : dispatch(errorActions.pricingoperationcostreset())
        }
        else if ([e.target.name][0] === 'tourManagementPrice') {
            result = onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value));
            if (result >= 0 && result != null) {
                if (result === "") {
                    dispatch(errorActions.pricingtourreset())
                }
                else {
                    dispatch(errorActions.pricingtour())
                }

            }
            else {
                dispatch(errorActions.pricingtour())
            }
            // result >= 0 && result !=null ? dispatch(errorActions.pricingtour()) : dispatch(errorActions.pricingtourreset())
        }
        else if ([e.target.name][0] === 'childrenPrice') {
            result = NumericWithDecimal(e.target.value) === '' ? '' : NumericWithDecimal(e.target.value);
            if (result >= 0 && result != null) {
                if (result === "") {
                    dispatch(errorActions.pricingchildrenreset())
                }
                else {
                    dispatch(errorActions.pricingchildren())
                }

            }
            else {
                dispatch(errorActions.pricingchildren())
            }
            // result >= 0 && result !=null ? dispatch(errorActions.pricingtour()) : dispatch(errorActions.pricingtourreset())
        }
        else {
            result = e.target.value
        }
        setPricingObj({ ...pricingobj, [e.target.name]: result });

    }

    useEffect(() => {
        getSupplierllist();
        getventransfercategory();
        getvenpricetype();
    }, []);
    useEffect(() => {
        if (!errorStatus) {
            setFormError({})
        }
    }, [errorStatus])

    useEffect(() => {
        handlePricingAdd(pricingobj);
    }, [pricingobj])

    useEffect(() => {
        if (retriveflag && Pricingdatalist != null) {
            if (Pricingdatalist.supplierId > 0 && Pricingdatalist.supplierId != null) {
                dispatch(errorActions.pricingsupplier())
                let allsuppierlist = allSupplier?.filter(IsAct => IsAct.IsActive === true);
                setSupplierList(allSupplier?.filter(IsAct => IsAct.IsActive === true));
                setSupplierDisable(false);
                if (!allsuppierlist?.find(Isfromlist => Isfromlist.Id === Pricingdatalist.supplierId)) {
                    setSupplierDisable(true)
                    setSupplierList((prvfromData) => prvfromData?.length > 0 ? [...prvfromData, allSupplier?.find(isFrom => isFrom.Id === Pricingdatalist.supplierId)] : [])
                }
            }
            if (Pricingdatalist.directJoiningCostPrice != null && Pricingdatalist.directJoiningCostPrice !== '') {
                dispatch(errorActions.pricingjoiningcost())
            }
            if (Pricingdatalist.transferPrice != null && Pricingdatalist.transferPrice !== '') {
                dispatch(errorActions.pricingtransfer())
            }
            if (Pricingdatalist.fixOperationCostPrice != null && Pricingdatalist.fixOperationCostPrice !== '') {
                dispatch(errorActions.pricingoperationcost())
            }
            if (Pricingdatalist.tourManagementPrice != null && Pricingdatalist.tourManagementPrice !== '') {
                dispatch(errorActions.pricingtour())
            }
            if (Pricingdatalist.childrenPrice != null && Pricingdatalist.childrenPrice !== '') {
                dispatch(errorActions.pricingchildren())
            }

            setSupplierId(Pricingdatalist.supplierId);
            setPricingObj(Pricingdatalist)
        }

    }, [retriveflag])

    const validation = () => {
        const error = {}
        if (supplierid === 0 || supplierid == null) {
            error.PackageSupplier = "Please select Supplier.";
        }
        if (pricingobj.transferPrice == null || pricingobj.transferPrice?.length === 0) {
            error.transferPrice = "Required field.";
        }
        if (pricingobj.directJoiningCostPrice == null || pricingobj.directJoiningCostPrice?.length === 0) {
            error.directJoiningCostPrice = "Required field.";
        }
        if (pricingobj.fixOperationCostPrice == null || pricingobj.fixOperationCostPrice?.length === 0) {
            error.fixOperationCostPrice = "Required field.";
        }
        if (pricingobj.tourManagementPrice == null || pricingobj.tourManagementPrice?.length === 0) {
            error.tourManagementPrice = "Required field.";
        }
        if (pricingobj.childrenPrice == null || pricingobj.childrenPrice?.length === 0) {
            error.childrenPrice = "Kindly add pricing for children.";
        }
        if (Object.keys(error).length > 0) {
            let incllopased = document?.getElementById("PricingError")
            if (incllopased.ariaExpanded === "false")
                document.querySelector("#PricingError").click();
            dispatch(errorActions.start())
            setFormError(error);
        }
    }
    useEffect(() => {
        if (pricingStatus) {
            validation()
        }
    }, [pricingStatus])
    //#endregion end input time type box    
    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingNine">
                    <button class="accordion-button collapsed border_set" id="PricingError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine">
                        Pricing
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingNine">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Supplier Details</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Select Supplier</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <CustomSelection
                                                        datalist={supplierlistdata}
                                                        fieldid={supplierid}
                                                        SelectionChange={handlesupplierchange}
                                                        isdisable={editableflag}
                                                        isactivedisable={supplierDisable}
                                                        issearchable={true}
                                                    />
                                                    {formerror.PackageSupplier && <p className='text-danger text-start'>{formerror.PackageSupplier}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Activities</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Select Addons</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <Select
                                                        className="dropdown select_dropdown"
                                                        // options={supplierlist}
                                                        // value={supplierlist?.filter(function (supplierlist) {
                                                        // return supplierlist.value === supplierid;
                                                        // })}// set list of the data
                                                        // onChange={handlesupplierchange} // assign onChange function
                                                        // onChange={() => showDivHandlerD(ele.PackageMenuId)}
                                                        isDisabled
                                                        isSearchable
                                                    />
                                                    {/* {formerror.PackageSupplier && <p className='text-danger text-start'>{formerror.PackageSupplier}</p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="ArrivalTimeD"
                                                        // value={formvalue.ArrivalTimeD}
                                                        // onChange={onChange}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Transfers</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Select Category</label>
                                                </div>
                                                <div class=" col-lg-8 mb-1 col-md-12">
                                                    <div className='mt-2'>
                                                        {
                                                            categorylist?.map((el) => {
                                                                return (
                                                                    <div className="form-check-inline">
                                                                        <input className="form-check-input" disabled={editableflag} checked={el.Id === pricingobj.categoryid} onChange={(e) => handleCategoryChange(e)} type="radio" value={el.Id} name="CategoryType" id={el.Name + el.Id} />
                                                                        <label className="form-check-label ms-1">{el.Name}</label>
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="transferPrice"
                                                        maxLength={6}
                                                        value={pricingobj.transferPrice}
                                                        onChange={onChange}
                                                        disabled={editableflag}
                                                    />
                                                    {formerror.transferPrice && <p className='text-danger text-start'>{formerror.transferPrice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Direct Joining Cost</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Total Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="directJoiningCostPrice"
                                                        value={pricingobj.directJoiningCostPrice}
                                                        maxLength={6}
                                                        onChange={onChange}
                                                        disabled={editableflag}
                                                    />
                                                    {formerror.directJoiningCostPrice && <p className='text-danger text-start'>{formerror.directJoiningCostPrice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Fixed Operation Cost</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Pricing Type</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <div className='mt-2'>
                                                        {
                                                            pricetypelist?.map((el) => {
                                                                return (
                                                                    <div className="form-check-inline">
                                                                        <input className="form-check-input" disabled={editableflag} checked={el.Id === pricingobj.oprationcostId} onChange={(e) => handleOprationChange(e)} value={el.Id} type="radio" name="PricingOprationType" id={'Operation' + el.Id} />
                                                                        <label className="form-check-label ms-1">{el.Name}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Total Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="fixOperationCostPrice"
                                                        value={pricingobj.fixOperationCostPrice}
                                                        onChange={onChange}
                                                        maxLength={6}
                                                        disabled={editableflag}
                                                    />
                                                    {formerror.fixOperationCostPrice && <p className='text-danger text-start'>{formerror.fixOperationCostPrice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Tour Manager</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Pricing Type</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <div className='mt-2'>
                                                        {
                                                            pricetypelist?.map((el) => {
                                                                return (
                                                                    <div className="form-check-inline">
                                                                        <input className="form-check-input" disabled={editableflag} checked={el.Id === pricingobj.tourTypeId} onChange={(e) => handleTourChange(e)} value={el.Id} type="radio" name="PricingTourType" id={'Manager' + el.Id} />
                                                                        <label className="form-check-label ms-1">{el.Name}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Total Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="tourManagementPrice"
                                                        value={pricingobj.tourManagementPrice}
                                                        onChange={onChange}
                                                        disabled={editableflag}
                                                        maxLength={6}
                                                    />
                                                    {formerror.tourManagementPrice && <p className='text-danger text-start'>{formerror.tourManagementPrice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row px-3 mb-3">
                                <div className='form_border'>
                                    <label class="col-form-label fw-bold">Children</label>
                                    <div class="row pb-3">
                                        <div className='col-lg-6 col-sm-6 '>
                                            <div className='row'>
                                                <div class="col-lg-4 col-md-12 mb-1">
                                                    <label class="col-form-label required">Price</label>
                                                </div>
                                                <div class="col-lg-8 mb-1 col-md-12">
                                                    <input type="text" id="inputPassword6" class="form-control"
                                                        name="childrenPrice"
                                                        value={pricingobj.childrenPrice}
                                                        maxLength={10}
                                                        onChange={onChange}
                                                        disabled={editableflag}
                                                    />
                                                    {formerror.childrenPrice && <p className='text-danger text-start'>{formerror.childrenPrice}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorPricing