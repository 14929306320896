import React, { useEffect, useMemo, useRef, useState } from 'react'
import CustomSelection from '../../../../customControl/CustomSelection'
import { onlyNumeric } from '../../../commonClass/CommonValidation'
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData } from '../../../../services/ApiCallingUserServices';
import { clearerrortrue, clearfalse, cleartrue, tripSupplierCollectionYes } from '../../../../_actions/user.action';


export default function CreateTripSupplier({ supplierList = [], setSupplier, location, setEditDisabled, disabled }) {
    const defaultSupplierData = {
        "seqNo": 0,
        "SupplierId": "",
        "SupplierName": "",
        "SupplierCode": "",
        "Sedan": "",
        "Innova": "",
        "Tempo": "",
        "Coach": ""
    }
    const isclear = useSelector(store => store.isClear.clear)
    const isCollection = useSelector(store => store.isTripSupplierCollection)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [newSupplier, setNewSuupplier] = useState(defaultSupplierData)
    const [errors, setErrors] = useState({});
    const [SupplierList, setSupplierList] = useState([])
    const [collectionError, setCollectionError] = useState('')
    const SupplierId = useRef()

    const handleSupplierChange = (e) => {
        setErrors({})
        setCollectionError('');
        setEditDisabled(false);
        dispatch(clearerrortrue());
        setEditDisabled(false)
        setNewSuupplier({ ...newSupplier, SupplierId: e?.value, SupplierName: e?.label, SupplierCode: e?.SupplierCode })
    }

    const handleOnChange = (name, value) => {
        const stringToNumber = onlyNumeric(value) ? Number(onlyNumeric(value)) : ''
        setErrors({})
        setCollectionError('')
        setEditDisabled(false);
        dispatch(clearerrortrue());
        setNewSuupplier({
            ...newSupplier,
            [name]: stringToNumber
        })
    }

    const checkValidation = (details) => {

        const validationRules = [
            { field: 'SupplierId', require: true, message: 'Kindly select a supplier.' },
            { field: 'Sedan', require: true, message: 'All vehicles pricing are required' },
            { field: 'Innova', require: true, message: 'All vehicles pricing are required' },
            { field: 'Tempo', require: true, message: 'All vehicles pricing are required' },
            { field: 'Coach', require: true, message: 'All vehicles pricing are required' },
        ];

        const errors = validationRules.reduce((acc, rule) => {
            if ((!details[rule.field].toString().trim() && rule.require)) {
                acc[rule.field] = rule.message;
            };
            return acc;
        }, {});

        if (Object.entries(errors).length > 0) {
            let id = Object.keys(errors)[0]
            if (id === "SupplierId") {
                SupplierId.current.focus();
            }
            else {
                document.getElementById(id)?.focus();
            }
        }

        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const addSupplier = (newSupplier) => {
        setCollectionError('')
        if (!checkValidation(newSupplier)) {
            dispatch(cleartrue())
            dispatch(clearerrortrue())
            setSupplier((prev) => (
                { ...prev, DataCollection: [...prev.DataCollection, { ...newSupplier, seqNo: supplierList?.length + 1 }] }
            ))
            setNewSuupplier(defaultSupplierData)
        }
    }

    const Roomsremovev = (seqno, e) => {
        setCollectionError('')
        dispatch(cleartrue());
        dispatch(clearerrortrue());
        setErrors({})
        let cloneSupplierList = [...supplierList]
        cloneSupplierList = cloneSupplierList?.filter(supplier => supplier.seqNo !== seqno).map((obj, index) => ({ ...obj, seqNo: index + 1 }));
        setEditDisabled(false);
        setSupplier((prev) => (
            { ...prev, DataCollection: cloneSupplierList }
        ))

    }

    const getplannersupplier = async () => {
        const supplier = await getAllData('/master/v1/getplannersupplier')
        setSupplierList(supplier?.data?.filter(sup => sup.IsActive === true)?.map(obj => ({
            label: obj?.SupplierName,
            value: obj?.SupplierId,
            SupplierCode: obj?.SupplierCode
        })))
    }


    useMemo(() => {
        if (isclear) {
            setErrors({})
            setCollectionError('')
            setNewSuupplier(defaultSupplierData)
            dispatch(clearfalse())
        }
    }, [isclear])

    useMemo(() => {
        if (isCollection?.supplierCollection) {
            setCollectionError('')
        }
        else {
            setCollectionError('Please add atleast one supplier')
            // dispatch(tripSupplierCollectionYes())
        }
    }, [isCollection])

    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if (location?.state != null) {
                getplannersupplier()
                dispatch(tripSupplierCollectionYes())
            }
            else {
                navigate('/Tripplannerlist')
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])


    return (
        <div class=" form_border mt-3">
            {collectionError && <p className='text-danger text-center mt-3 mb-0'> {collectionError} </p>}
            <div className="py-3 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                            <label htmlFor="" className='col-form-label required'>Select Supplier</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                            <CustomSelection
                                                selId="SupplierId"
                                                selRef={SupplierId}
                                                datalist={SupplierList}
                                                fieldid={newSupplier.SupplierId}
                                                SelectionChange={(e) => handleSupplierChange(e)}
                                                isdisable={disabled}
                                                // isactivedisable={citydisable}
                                                issearchable={true}
                                            />

                                            {errors.SupplierId && <p className="text-start text-danger mt-1">{errors.SupplierId} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                            <label htmlFor="" className='col-form-label'>Supplier Code</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-1">
                                            <input
                                                className='form-control'
                                                type="text"
                                                placeholder='Supplier Code'
                                                value={newSupplier.SupplierCode}
                                                // onChange={handleCityData}
                                                disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div className='col-lg-2'></div>
                                <div className='col-lg-10'>
                                    <div class="row">
                                        <div className="col-lg-3 col-md-2">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <label htmlFor="" className='col-form-label required'>Sedan</label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <input
                                                        type="text"
                                                        className='form-control'
                                                        placeholder='Sedan'
                                                        id='Sedan'
                                                        name='Sedan'
                                                        value={newSupplier.Sedan}
                                                        onChange={(e) => {
                                                            handleOnChange(e.target.name, e.target.value)
                                                        }}
                                                        maxLength={5}
                                                        autoComplete='off'
                                                        disabled={disabled ? true : false}
                                                    />
                                                    {/* {errors.Sedan && <p className="text-start text-danger">{errors.Sedan} </p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-2">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <label htmlFor="" className='col-form-label required'>Innova</label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id='Innova'
                                                        placeholder='Innova'
                                                        name='Innova'
                                                        value={newSupplier.Innova}
                                                        onChange={(e) =>
                                                            handleOnChange(e.target.name, e.target.value)
                                                        }
                                                        maxLength={5}
                                                        autoComplete='off'
                                                        disabled={disabled ? true : false}
                                                    />
                                                    {/* {errors.Innova && <p className="text-start text-danger">{errors.Innova} </p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-2">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <label htmlFor="" className='col-form-label required'>Tempo</label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id='Tempo'
                                                        placeholder='Tempo'
                                                        name='Tempo'
                                                        value={newSupplier.Tempo}
                                                        onChange={(e) =>
                                                            handleOnChange(e.target.name, e.target.value)
                                                        }
                                                        maxLength={5}
                                                        autoComplete='off'
                                                        disabled={disabled ? true : false}
                                                    />
                                                    {/* {errors.Tempo && <p className="text-start text-danger">{errors.Tempo} </p>} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <label htmlFor="" className='col-form-label required'>Coach</label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        placeholder='Coach'
                                                        id='Coach'
                                                        name='Coach'
                                                        value={newSupplier.Coach}
                                                        onChange={(e) =>
                                                            handleOnChange(e.target.name, e.target.value)
                                                        }
                                                        maxLength={5}
                                                        autoComplete='off'
                                                        disabled={disabled ? true : false}
                                                    />
                                                    {/* {errors.Coach && <p className="text-start text-danger">{errors.Coach} </p>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(errors.Sedan || errors.Innova || errors.Tempo || errors.Coach) && <p className="text-center text-danger mt-2 mb-0">{errors.Sedan || errors.Innova || errors.Tempo || errors.Coach} </p>}
                                </div>

                            </div>

                            <div className='row d-flex justify-content-center'>
                                <div class="col-12 d-flex justify-content-center mt-3">
                                    <button
                                        type="button"
                                        onClick={() => addSupplier(newSupplier)}
                                        className="btn btn-primary mx-1"
                                        id="btnroomAdd"
                                        disabled={disabled ? true : false}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="mb-3 row py-3">
                                        <div class="col-lg-12 mb-1">
                                            <div class="row">
                                                {/* <div className='mb-1 col-lg-12 col-md-12 col-sm-12 mt-3 table_scroll'> */}
                                                <div className='col-lg-12 mb-1 table-scrollable'>
                                                    <table id="data" className="table table-hover" >
                                                        <thead>
                                                            <tr>
                                                                <th>Supplier Name</th>
                                                                <th>Supplier Code</th>
                                                                <th>Sedan</th>
                                                                <th>Innova</th>
                                                                <th>Tempo</th>
                                                                <th>Coach</th>
                                                                <th>Remove</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="location-data">
                                                            {supplierList.length > 0 && supplierList?.map((supplier, index) => (
                                                                <tr class="ReactTable" key={index}>
                                                                    <td>{supplier.SupplierName}</td>
                                                                    <td>{supplier.SupplierCode}</td>
                                                                    <td>{supplier.Sedan}</td>
                                                                    <td>{supplier.Innova}</td>
                                                                    <td>{supplier.Tempo}</td>
                                                                    <td>{supplier.Coach}</td>
                                                                    <td><button type="button"
                                                                        onClick={(e) => { Roomsremovev(supplier.seqNo, e) }}
                                                                        className="btn btn-primary mx-1" id="btnroomremoveAdd"
                                                                        disabled={disabled ? true : false}
                                                                    >Remove</button></td>
                                                                </tr>)
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
