import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
// import exportFromJSON from 'export-from-json'
// import { onlyAlphabets, useSortableData, generatePDF } from '../commonClass/CommonValidation'
import { isValidImage, generatePDF } from '../../commonClass/CommonValidation';
import { useRef } from 'react';
import { ImageDelete, ImageUpload } from '../../../ImageUpload/ImageCommon';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomSelection from '../../../customControl/CustomSelection';
import CustomTable from '../../../customControl/CustomTable';


const Bannermaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef();
    // var store = useContext(ReactReduxContext).store.getState();

    const [bannerdata, setBannerData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(0);

    const [bannerfilename, setBannerFileName] = useState();
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('3');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [bannerdelete, setbannerDelete] = useState('');

    const [bannerpath, setBannerPath] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [imageError, setImageError] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    //const [bennerFileName , setBannerFileName] = useState('');

    const [bannerduplicateerrorMessage, setBannerDuplicateErrorMessage] = useState('');
    const serachoption = [
        {
            label: 'Banner Name',
            value: '1'
        },
        {
            label: 'City',
            value: '5'
        },
        {
            label: 'State',
            value: '6'
        },
        {
            label: 'Active',
            value: '2'
        },
        // {
        //     label: 'Created Date',
        //     value: '4'
        // },
        {
            label: 'All',
            value: '3'
        },

    ];
    const [formValue, setFormValue] = useState(
        {
            BannerId: 0,
            BannerName: '',
            BannerFileName: '',
            BannerPath: '',
            IsActive: false,
            LocationId: 0,
            StateId: 0,
        }
    );
    const columns = [

        {
            name: 'Banner Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (banner) => banner.bannerName
        },

        {
            name: 'City',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (banner) => banner.city
        },
        {
            name: 'State',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (banner) => banner.stateName
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (banner) => banner.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (banner) => formatDate(banner.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (banner) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(banner.bannerId)}></i></button> //onClick={() => handleEditMode(airport.airportId)}
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (banner) => <i className="fa fa-eye" onClick={() => handleViewMode(banner.bannerId)}></i>// onClick={() => handleViewMode(banner.bannerId)}
        }
    ];

    const LocationId = useRef()
    const [cityId, setCityId] = useState(0)
    const [stateName, setStateName] = useState('')
    const [cityAllData, setCityAllData] = useState([])
    const [citydisable, setCityDisable] = useState(false);
    const [list, setList] = useState([])
    const getbannercity = async () => {
        const bannercity = await getAllData('/master/v1/getbannercity')
        setList(bannercity.data)
        setCityAllData(bannercity.data?.filter(IsAct => IsAct.isActive === true))
    }
    const cityData = cityAllData?.map(obj => ({
        label: obj?.city,
        value: obj?.locationId
    }))

    const handleCityData = (e) => {
        setFormErrors('');
        setCityId(e.value)
        formValue.LocationId = e.value
        const state = list?.map(obj => {
            if (obj.locationId === e.value) {
                return { label: obj.stateName, value: obj.stateId }
            }
        }).find(x => x !== undefined)
        setStateName(state.label)
        formValue.StateId = state.value
        setEditDisabled(true);
    }

    const getBannerDataList = async () => {
        const bannerlistdata = await getAllData("/master/v1/getallbanner");
        setBannerData(bannerlistdata.data);
    }
    //#region handle getbannerdata
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getBannerDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
            getbannercity();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage();
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        // let viewrights= false;
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            // viewrights = obj.URightView;
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });

    }, [user])


    // useEffect(()=>{
    //     if(bannerdata?.length > 0)
    //     {
    //         dispatch(loaderActions.end());
    //     }
    // },[bannerdata?.length > 0 && store.loader['load']===true])
    //#endregion

    //#region image upload

    const [fileselected, setSelectedFile] = useState();

    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            // error.BannerFileName = "Please select only Image";
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            formValue.BannerFileName = filename
            formValue.BannerPath = 'CMS/Banner/' + filename
            setSelectedFile(e.target.files[0]);
            setEditDisabled(true);
            setBannerPath('');
            setBannerFileName('');
            setImageError('');
            setBannerDuplicateErrorMessage('');
        }
        else {
            document.getElementById("BannerPath").value = "";
            setFormValue({ ...formValue, BannerFileName: '', BannerPath: '' });
            setBannerPath('');
            setFormErrors('');
            setBannerFileName('');
            setBannerDuplicateErrorMessage('');
            setImageError("Please select Only Image")
            setIsSubmit(false);
            return;
        }
        //const file = e.target.files[0];
        //setUploadImage(current => [{ title: file.name, imageurl: URL.createObjectURL(file) }]);
    }
    useEffect(() => {
        //setSelectedFile(fileselected);
        setFormErrors('');
    }, [fileselected]);
    const removeImage = () => {

        //setUploadImage((oldState) => oldState.filter((item) => item.id !== id));

        document.getElementById("BannerPath").value = "";
        formValue.BannerPath = ''
        formValue.BannerFileName = ''
        setBannerPath('');
        setSelectedFile();
        setEditDisabled(true);
        setBannerFileName('');
    };
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }

    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    // const uploadimagelist = useState([{
    //     title: '',
    //     imageurl: '',
    // }]);
    // const [uploadimage, setUploadImage] = useState(uploadimagelist);
    // useEffect(() => {
    //     //uploadfileimage(uploadimage)
    // }, [uploadimage]);
    //#endregion

    //#region handle change and active value
    const onChange = (e) => {
        setBannerDuplicateErrorMessage('');
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
        setFormErrors('');
        setImageError('');
        setEditDisabled(true);
    }
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setFormErrors('');
    //     setImageError('');
    //     setEditDisabled(true);
    //     //setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setFormErrors('');
            setImageError('');
            setEditDisabled(true);
        }
        setModalShow(false);
    }
    //#endregion

    //#region handle Add,Retrive, Edit, View
    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                let status = false;
                const BannerData = await postUsersData('/master/v1/insertbanner', formValue)
                status = BannerData.status;
                if (status !== 400) {
                    try {
                        if (!BannerData.status && !BannerData.data) {
                            setBannerDuplicateErrorMessage(BannerData.message);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            return;
                        }
                        if (BannerData.status) {
                            if (BannerData.data > 0) {
                                await handleimageupload('CMS/Banner/', formValue.BannerFileName, fileselected);
                                setitems();
                                dispatch({ type: 'ALERTING', message: BannerData.message })
                                // alert('Record saved successfully.');
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: BannerData.message })
                                // alert('Record not saved successfully')
                                setIsSubmit(false);
                                return;
                            }
                        }

                    }
                    catch (err) {
                        dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setEditDisabled(true);
                        return;
                    }

                }
                else {
                    dispatch({ type: 'ALERTING', message: BannerData.message })
                    // alert('Try again later!');
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                let status1 = false;
                const BannerData = await patchUsersData('/master/v1/updatebanner', formValue)
                status1 = BannerData.status;
                if (status1 !== 400) {
                    try {
                        if (!BannerData.status && !BannerData.data) {
                            setBannerDuplicateErrorMessage(BannerData.message);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            return;
                        }
                        if (BannerData.status) {
                            // if (BannerData.data > 0) {

                            if (bannerpath.length === 0) {
                                await handleimageupload('CMS/Banner/', formValue.BannerFileName, fileselected);
                            }

                            try {
                                if (bannerpath.length === 0) {
                                    await handleimageDelete(bannerdelete);
                                }
                            }
                            catch (err) {
                                dispatch({ type: 'ALERTING', message: err })
                                // alert(err);
                                setDisabled(false);
                                setEditDisabled(true);
                                setIsSubmit(false);
                                return;
                            }
                            setitems();
                            dispatch({ type: 'ALERTING', message: BannerData.message })
                            // alert('Record updated successfully.');
                            clear();
                            // }
                            // else {
                            //     alert('Record not updated successfully')
                            //     setDisabled(false);
                            //     setEditDisabled(true);
                            //     return;
                            // }
                        }

                    }
                    catch (err) {
                        dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setEditDisabled(true);
                        setIsSubmit(false);
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTING', message: BannerData.message })
                    // alert('Try again later!');
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validateData(formValue));
        //handleSubmitData();
    };
    const validateData = (values) => {
        setIsSubmit(true);
        const error = {}
        if (values.LocationId === 0) {
            error.LocationId = "Kindly select the City Name";
            setIsSubmit(false);
        }
        if (!values.BannerName || values.BannerName?.trim()?.length === 0) {
            error.BannerName = "Please enter Banner Name";
            setIsSubmit(false);
        }
        if (values.BannerName?.length > 50) {
            error.BannerName = "Maximum length is 50 characters";
            setIsSubmit(false);
        }
        if (!values.BannerPath) {
            error.BannerPath = "Please select Banner Image";
            setImageError('');
            setIsSubmit(false);
        }
        return error;
    }

    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors)?.length > 0) {
            let id = Object.keys(formErrors)[0];
            if (id === 'LocationId') {
                eval(id).current.focus();
            }
            // LocationId
            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
        }
    }, [formErrors]);
    useEffect(() => {

    }, [isSubmit]);

    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        document.getElementById("BannerPath").value = "";
        setSelectedFile('');
        setFormErrors('');
        setImageError('');
        setBannerDuplicateErrorMessage('');
        setImageError('');
        bannerdata?.map(obj => {
            if (obj.bannerId === id) {
                console.log(obj, 'obj')
                // let active = !obj.isActive;
                formValue.BannerName = obj.bannerName
                formValue.BannerPath = obj.bannerPath
                formValue.BannerId = obj.bannerId
                formValue.IsActive = obj.isActive
                formValue.BannerFileName = obj.bannerFileName
                formValue.StateId = obj.stateId
                formValue.LocationId = obj.locationId
                setActiveId(obj.isActive);
                setBannerPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.bannerPath);
                setBannerFileName(obj.bannerFileName);
                setbannerDelete(obj.bannerPath);
                setCityId(obj.locationId)
                setStateName(obj.stateName)

                let allcitylist = list?.filter(IsAct => IsAct.isActive === true);

                setCityAllData(list?.filter(IsAct => IsAct.isActive === true));
                setCityDisable(false);
                if (obj.locationId !== 0) {
                    if (!allcitylist?.find(IsCity => IsCity.locationId === obj.locationId)) {
                        setCityDisable(true)
                        setCityAllData((prvCityData) => prvCityData?.length > 0 ? [...prvCityData, list?.find(isCity => isCity.locationId === obj.locationId)] : [])
                        // setCityData((prvCityData) => [...prvCityData, allCity?.find(isCity => isCity.locationId === obj.locationId)])

                    }
                }
            }
        });
        setFormValue({ ...formValue, formValue })
    }

    //#endregion

    //#region handle clear value
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setIsSubmit(false);
        setFormErrors('');
        setImageError('');
        setBannerFileName('');
        setFormValue({ ...formValue, BannerId: 0, BannerName: '', BannerFileName: '', BannerPath: '', IsActive: false, LocationId: 0, StateId: 0 });
        setCityId(0)
        setStateName('')
        setActiveId(0);
        setSelectedFile();
        setSerachBy('3');
        setSearchValue('');
        setSerachDisable(false);
        setBannerDuplicateErrorMessage('');
        setImageError('');
        setDisabled(false);
        setEditDisabled(true);
        getBannerDataList();
        setbannerDelete('');
        setButtonAddEditText('Add');
        setBannerPath('');
        document.getElementById("BannerPath").value = "";
        setsclshow(false)
        setCityDisable(false)
    }
    //#endregion

    //#region handle serach value
    const setitems = async () => {
        const BannerListData = await getAllData("/master/v1/getallbanner");
        setFilterItems(BannerListData.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getBannerDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.city?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.bannerName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue) || 'inactive'.toLowerCase().includes(searchvalue));
                }
            }
            setBannerData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getBannerDataList();
    }
    //#endregion

    //#region handle pdf and excel import
    function PdfExport() {
        //print
        const columns = [
            "Banner Name",
            "City",
            "State",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < bannerdata?.length; i++) {
            var temp = [
                bannerdata[i].bannerName,
                bannerdata[i].city,
                bannerdata[i].stateName,
                `${bannerdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(bannerdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "BannerMaster", "Banner Master")
    }
    // function ExportToExcel() {
    //     const fields = ['bannerName', 'isActive'];
    //     const data = bannerdata;
    //     const fileName = 'Bannermasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }
    const ExportToExcel = () => {
        const displayname = ['Banner Name', 'City', 'State', 'Active', ' Created Date'];
        const fields = ['bannerName', 'city', 'stateName', 'isActive', 'createdDate'];
        ExportExcel(displayname, fields, bannerdata, 'BannerMaster', 'BannerMaster.xls')
    }
    //#endregion
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Banner Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {bannerduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {bannerduplicateerrorMessage} </div>}

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                            <label htmlFor="" className='col-form-label required'>Select City</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                            {/* <Select
                                                ref={LocationId}
                                                className="dropdown select_dropdown"
                                                options={cityData}
                                                value={cityData?.filter(function (city) {
                                                    return city.value === cityId;
                                                })}
                                                onChange={(e) => { handleCityData(e) }}
                                                isDisabled={disabled ? true : false}
                                            /> */}
                                            <CustomSelection
                                                selId="LocationId"
                                                selRef={LocationId}
                                                datalist={cityData}
                                                fieldid={cityId}
                                                SelectionChange={handleCityData}
                                                isdisable={disabled}
                                                isactivedisable={citydisable}
                                                issearchable={true}
                                            />
                                            {formErrors.LocationId && <p className="text-start text-danger">{formErrors.LocationId} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                                            <label htmlFor="" className='col-form-label required'>State</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-1">
                                            <input
                                                className='form-control'
                                                type="text"
                                                value={stateName}
                                                onChange={handleCityData}
                                                disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='row'>

                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Banner Name</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            <input type="text" id="BannerName" class="form-control"
                                                name="BannerName"
                                                value={formValue.BannerName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Banner Name"
                                            />
                                            {formErrors.BannerName && <p className='text-danger text-start'>{formErrors.BannerName}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Upload Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                            <div class="input-group">

                                                <input
                                                    ref={inputRef}
                                                    name='BannerImage'
                                                    type="file"
                                                    id="BannerPath"
                                                    onChange={handleUploadImageLoad}
                                                    class="form-control btn_add"
                                                    accept=".png,.jpg"
                                                    disabled={disabled ? true : false}
                                                />
                                                <label class="input-group-text" for="BannerPath">Browse</label>



                                            </div>
                                            {formErrors.BannerPath && <p className='text-danger text-start'>{formErrors.BannerPath}</p>}
                                            {imageError && <p className='text-danger text-start'>{imageError}</p>}
                                            {/* {bannerfilename && <p className='text-start'> {bannerfilename}</p>} */}
                                            {bannerfilename && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={bannerfilename}>
                                                {bannerfilename}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-8">

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {fileselected &&
                                (
                                    <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2">
                                        <img
                                            src={URL.createObjectURL(fileselected)} className="img-fluid w-100" alt=''
                                        />
                                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                    </div>
                                )}
                            {(!fileselected && bannerpath?.trim()?.length > 0) && (
                                <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2" >
                                    <img
                                        src={bannerpath} className="img-fluid w-100" alt=''
                                    />
                                    <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={bannerdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div >
        </>
    )
}

export default Bannermaster