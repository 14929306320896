import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { generatePDF, onlyNumeric } from '../../commonClass/CommonValidation'
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomSelection from '../../../customControl/CustomSelection';
import { useNavigate } from 'react-router-dom';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import CustomTable from '../../../customControl/CustomTable';


const Flightmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // var store = useContext(ReactReduxContext).store.getState();
    // const disablePastDate = () => {
    //     const today = new Date();
    //     const dd = String(today.getDate()).padStart(2, "0");
    //     // const dd = String(today.getDate() + 1).padStart(2, "0");
    //     const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    //     const yyyy = today.getFullYear();
    //     return yyyy + "-" + mm + "-" + dd;
    // };

    const [departureDate, setDepartureDate] = useState(null);
    const [arrivalDate, setArrivalDate] = useState(null);
    const [pnrDate, setPNRDate] = useState(null);
    const [departureTime, setDepartureTime] = useState(null);
    const [arrivalTime, setArrivalTime] = useState();
    const [flightdata, setFlightData] = useState([]);

    const [formErrors, setFormErrors] = useState({});

    const [isSubmit, setIsSubmit] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();

    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);

    const [buttonaddeditText, setButtonAddEditText] = useState("Add");

    const [supplierlist, setSupplierName] = useState([]);
    const [supplierid, setSupplierId] = useState(0);
    const [suppliercode, setSupplierCode] = useState([]);

    const [airlinelist, setAirlineList] = useState([]);
    const [airlineid, setAirlineId] = useState(0);

    const [airlineclasslist, setAirlineClassList] = useState([]);
    const [classid, setClassId] = useState(0);

    const [departurecitylist, setDepartureCityList] = useState([]);
    const [departurecityid, setDepartureCityId] = useState(0);

    const [airportlist, setAirportList] = useState([]);
    const [airportnamelist, setAirportNameList] = useState([]);
    const [airportid, setAirportId] = useState(0);

    const [terminal, setTerminal] = useState([]);
    const [departuralterminalid, setDepartualTerminalId] = useState(0);

    const [arrivalcity, setArrivalCity] = useState([]);
    const [arrivalcityid, setArrivalCityId] = useState(0);

    const [arrivalairport, setArrivalAirport] = useState([]);
    const [arrivalairportid, setArrivalAirportId] = useState(0);

    const [arrivalterminalid, setArrivalTerminalId] = useState(0);

    const [flightduplicateerrorMessage, setFlightDuplicateErrorMessage] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const [allsuppliername, setAllSupplierName] = useState([]);
    const [suppliernamedisable, setsuppliernameDisable] = useState(false);
    const [allairline, setallAirline] = useState([]);
    const [airlinedisable, setAirlineDisable] = useState(false);
    const [alldeparturecity, setAllDepartureCity] = useState([]);
    const [departurecitydisable, setDepartureCityDisable] = useState(false);
    const [alldepartureairport, setAllDepartureAirport] = useState([]);
    const [departureairportdisable, setDepartureAirportDisable] = useState(false);
    const [allarrivalcity, setAllArrivalCity] = useState([]);
    const [arrivalcitydisable, setArrivalCityDisable] = useState(false);
    const [allarrivalairport, setAllArrivalAirport] = useState([]);
    const [arrivalairportdisable, setArrivalAirportDisable] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [formvalue, setFormValue] = useState({
        FlightId: 0,
        SupplierId: 0,
        AirlineId: 0,
        FlightNo: '',
        AirlineClassId: 0,
        DepartureDate: '',
        DepartureCity: 0,
        DepartureAirportId: 0,
        DepartureAirportName: '',
        DepTime: '',
        DepartureTime: '',
        DepartureTerminalId: 0,
        ArrivalDate: '',
        AirrvalCity: 0,
        AirrivalAirportId: 0,
        AirrivalAirportName: '',
        AirrTime: '',
        AirrivalTime: '',
        ArrivalTerminalId: 0,
        Duration: '',
        Meal: '',
        Baggage: '',
        Seat: '',
        PNRNo: '',
        PNRDate: '',
        IsActive: false,
        UserId: 0
    });


    //#region bind dropdown 
    const getlistofsupplier = async () => {
        const supplierlist = await getAllData('/master/v1/getflightsupplier');
        setSupplierName(supplierlist.data?.filter(IsAct => IsAct.IsActive === true));
        setAllSupplierName(supplierlist.data);
    }

    const suppliernamelist = supplierlist?.map(value => ({
        value: value.SupplierId,
        label: value.SupplierName
    }));

    const getlistofairlinename = async () => {
        const AirlineList = await getAllData('/master/v1/getflightairline');
        setAirlineList(AirlineList.data?.filter(IsAct => IsAct.IsActive === true));
        setallAirline(AirlineList.data)
    }
    const airlinenamelist = airlinelist?.map(value => ({
        value: value.AirlineId,
        label: value.AirlineName
    }));

    const getlistofairlineclass = async () => {
        const airlineclasslist = await getAllData('/master/v1/getflightairlineclass');
        setAirlineClassList(airlineclasslist.data);
    }

    const classlist = airlineclasslist?.map(value => ({
        value: value.ClassId,
        label: value.ClassName
    }));



    const getDepartureCity = async () => {
        await getAllData('/master/v1/getflightcity').then((departurecity) => {
            setDepartureCityList(departurecity.data?.filter(IsAct => IsAct.IsActive === true));
            setAllDepartureCity(departurecity.data);
            setArrivalCity(departurecity.data?.filter(IsAct => IsAct.IsActive === true));
            setAllArrivalCity(departurecity.data);
            getflightlist();
            dispatch(loaderActions.end());
        });
    }

    const departurelistcity = departurecitylist?.map(value => ({
        value: value.LocationId,
        label: value.City
    }));

    const getairportdata = async () => {
        const airportdata = await getAllData('/master/v1/getflightairport');
        setAirportList(airportdata.data?.filter(IsAct => IsAct.IsActive === true));
        setAllDepartureAirport(airportdata.data);
        setAllArrivalAirport(airportdata.data)
    }

    const getterminal = async () => {
        const terminallist = await getAllData('/master/v1/getflightterminal');
        setTerminal(terminallist.data)
    };

    const departuralterminallist = terminal?.map(value => ({
        value: value.TerminalId,
        label: value.TerminalName
    }));

    // const getarrivalcity = async () => {
    //     const arrivalcitylist = await getAllData('/general/v1/getflightcity');
    //     setArrivalCity(arrivalcitylist.data?.filter(IsAct => IsAct.IsActive === true));
    //     setAllArrivalCity(arrivalcitylist.data);
    // }

    const arrivalcitylist = arrivalcity?.map(value => ({
        value: value.LocationId,
        label: value.City
    }));

    const arrivalterminallist = terminal?.map(value => ({
        value: value.TerminalId,
        label: value.TerminalName
    }));

    const getflightlist = async () => {
        const flightdata = await getAllData('/master/v1/getallflight');
        setFlightData(flightdata.data);
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            // (Window.$name && Window.$userToken) {
            getlistofsupplier();
            getlistofairlinename();
            getlistofairlineclass();
            getDepartureCity();
            getairportdata();
            getterminal();
            // getarrivalcity();
            // getflightlist().then(() => { dispatch(loaderActions.end()) });;;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    //#endregion

    //#region supplier,AirlineName change , onchange
    const handlesupplierchange = (e) => {
        setSupplierId(e.value);
        supplierlist?.filter(supplier => supplier.SupplierId === e.value)?.map(value => (
            setSupplierCode(value.SupplierCode)
        ));
        formvalue.SupplierId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleAirlineNameChange = (e) => {
        setAirlineId(e.value)
        formvalue.AirlineId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleClassChange = (e) => {
        setClassId(e.value);
        formvalue.AirlineClassId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleDepartureCityChange = (e) => {
        setDepartureCityId(e.value);
        formvalue.DepartureCity = e.value

        const airportnamedata = airportlist?.filter(airport => airport.LocationId === e.value)?.map(value => ({
            value: value.AirportId,
            label: value.AirportName
        }));
        formvalue.DepartureAirportId = 0;
        setAirportId(0)
        setAirportNameList(airportnamedata);
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleAirportChange = (e) => {
        formvalue.DepartureAirportId = e.value
        setAirportId(e.value)
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleDepaturalTerminalChange = (e) => {
        setDepartualTerminalId(e.value);
        formvalue.DepartureTerminalId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleArrivalCityChange = (e) => {
        setArrivalCityId(e.value);
        formvalue.AirrvalCity = e.value

        const arrivalairportname = airportlist?.filter(airport => airport.LocationId === e.value)?.map(value => ({
            value: value.AirportId,
            label: value.AirportName
        }));
        formvalue.AirrivalAirportId=0;
        setArrivalAirportId(0);
        setArrivalAirport(arrivalairportname);
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleArrivalAirportChange = (e) => {
        setArrivalAirportId(e.value);
        formvalue.AirrivalAirportId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    const handleArrivalTerminalChange = (e) => {
        setArrivalTerminalId(e.value);
        formvalue.ArrivalTerminalId = e.value
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }
    // const handleisActive = (e) => {
    //     { formvalue.IsActive = e }
    //     setActiveId(!activeId)
    //     setFormErrors('');
    //     setFlightDuplicateErrorMessage('');
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formvalue.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
            setFlightDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formvalue.IsActive = !activeId
            setActiveId(!activeId)
            setFormErrors('');
            setFlightDuplicateErrorMessage('');
            setEditDisabled(true);
        }
        setModalShow(false);
    }

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'DepTime') {
            //result = formvalue.DepartureDate + "T" + e.target.value
            formvalue.DepartureTime = formvalue.DepartureDate + "T" + e.target.value
        }
        if ([e.target.name][0] === 'AirrTime') {
            formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + e.target.value
        }
        if ([e.target.name][0] === 'DepartureDate') {
            formvalue.DepartureDate = e.target.value
            setDepartureDate(e.target.value);
            formvalue.DepartureTime = e.target.value + "T" + formvalue.DepTime
        }
        // if ([e.target.name][0] === 'ArrivalDate') {
        //     { formvalue.ArrivalDate = ConvertformatDate(e.target.value) }
        //     { formvalue.AirrivalTime = e.target.value + "T" + formvalue.AirrTime }
        // }
        if ([e.target.name][0] === 'Seat') {
            result = Number(onlyNumeric(e.target.value));
        }
        else {
            result = e.target.value;
        }
        setFormValue({ ...formvalue, [e.target.name]: result });
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);
    }

    //#endregion

    //#region date formate
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    function formatDateRequire(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    // function ConvertformatDate(date) {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2)
    //         month = '0' + month;
    //     if (day.length < 2)
    //         day = '0' + day;

    //     return [day, month, year].join('-');
    // }
    function formatTime(date) {
        var d = new Date(date),
            // month = '' + (d.getMonth() + 1),
            // day = '' + d.getDate(),
            // year = d.getFullYear();
            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        // if (month.length < 2) 
        // month = '0' + month;
        // if (day.length < 2) 
        // day = '0' + day;

        return [hour, minu].join(':');
    }
    //#endregion

    //#region handling clear
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setIsSubmit(false);
        setFormErrors('');
        setFormValue({
            ...formvalue, FlightId: 0, SupplierId: 0, AirlineId: 0, FlightNo: '',
            AirlineClassId: 0, DepartureDate: '', DepartureCity: 0,
            DepartureAirportId: 0, DepTime: '', DepartureTime: '',
            DepartureTerminalId: 0, ArrivalDate: '', AirrvalCity: 0,
            AirrivalAirportId: 0, AirrTime: '', AirrivalTime: '',
            ArrivalTerminalId: 0, Duration: '', Meal: '',
            Baggage: '', Seat: '', PNRNo: '', PNRDate: '',
            IsActive: false
        });
        setActiveId(false);
        getflightlist();
        setButtonAddEditText('Add');
        setDisabled(false);
        setArrivalCityId(0);
        setArrivalAirportId(0);
        setArrivalTerminalId(0);
        setDepartualTerminalId(0);
        setAirportId(0);
        setDepartureCityId(0);
        setClassId(0);
        setAirlineId(0);
        setSupplierId(0);
        setSupplierCode('');
        setAirportNameList([]);
        setArrivalAirport([]);
        setDepartureDate(null);
        setArrivalDate(null);
        setPNRDate(null);
        setDepartureTime(null);
        setArrivalTime(null);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('13');
        setSearchValue('');
        setFlightDuplicateErrorMessage('');
        setsclshow(false);
        setAirlineDisable(false);
        setsuppliernameDisable(false);
        setDepartureCityDisable(false);
        setDepartureAirportDisable(false);
        setArrivalCityDisable(false);
        setArrivalAirportDisable(false);
    }
    //#endregion

    //#region Add,retrive and edit and view

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                let status = false;
                const FlightData = await postUsersData('/master/v1/insertflight', formvalue)
                status = FlightData.status;
                if (status !== 400 && typeof status != 'undefined') {
                    try {
                        if (!FlightData.status && !FlightData.data) {
                            setFlightDuplicateErrorMessage(FlightData.message);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            return;
                        }
                        //setitems();
                        dispatch({ type: 'ALERTING', message: FlightData.message })
                        // alert('Record saved successfully.');
                        clear();
                    }
                    catch (err) {
                        dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setEditDisabled(true);
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTING', message: FlightData.message })
                    // alert('Try again later!');
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                let status1 = false;

                const FlightData = await patchUsersData('/master/v1/updateflight', formvalue)
                status1 = FlightData.status;
                if (status1 !== 400 && typeof status1 != 'undefined') {
                    try {
                        if (!FlightData.status && !FlightData.data) {
                            setFlightDuplicateErrorMessage(FlightData.message);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            return;
                        }
                        //setitems();
                        dispatch({ type: 'ALERTING', message: FlightData.message })
                        // alert('Record updated successfully.');
                        clear();
                    }
                    catch (err) {
                        dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setEditDisabled(true);
                        setIsSubmit(false);
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTING', message: FlightData.message })
                    // alert('Try again later!');
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(ValidateData(formvalue));
        //handleSubmitData();
    };
    const Supplierid = useRef(null);
    const AirlineId = useRef(null);
    const Classid = useRef(null);
    const DepartureCity = useRef(null);
    const DepartureAirport = useRef(null);
    const DepartureTerminal = useRef(null);
    const ArrivalCity = useRef(null);
    const ArrivalAirport = useRef(null);
    const AirrvalTerminal = useRef(null);
    // const DepartureTime = useRef(null);

    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors)?.length > 0) {
            let id = Object.keys(formErrors)[0];

            if (id === "Supplierid" || id === "AirlineId" || id === "Classid" || id === "DepartureCity" || id === "DepartureAirport"
                || id === "DepartureTerminal" || id === "ArrivalCity" || id === "ArrivalAirport" || id === "AirrvalTerminal") {
                eval(id).current.focus();
            }
            else if (id === "DepartureDate") {
                let doc = document.getElementById("Departuredateopen");
                if (doc !== null)
                    doc.focus();
            }
            else if (id === "DepartureTime") {
                const clickpass = document.getElementById("DepTime")
                clickpass.focus();
            }
            else if (id === "AirrTime") {
                const clickpass = document.getElementById("AirrTime")
                clickpass.focus();
            }
            else if (id === "ArrivalDate") {
                let doc = document.getElementById("Arrivaldateopen");
                if (doc !== null)
                    doc.focus();
            }
            else if (id === "PNRDate") {
                let doc = document.getElementById("pnrdateopen");
                if (doc !== null)
                    doc.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }

        }
    }, [formErrors]);
    useEffect(() => {

    }, [isSubmit]);
    const handleEditMode = (id) => {
        setDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
        setEditDisabled(false);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        flightdata?.map(obj => {

            if (obj.FlightId === id) {
                // let active = !obj.isActive;[SupplierCode]
                formvalue.FlightId = obj.FlightId
                formvalue.SupplierId = obj.SupplierId
                formvalue.AirlineId = obj.AirlineId
                formvalue.FlightNo = obj.FlightNo
                formvalue.AirlineClassId = obj.AirlineClassId
                formvalue.DepartureDate = formatDate(obj.DepartureDate)
                formvalue.DepartureCity = obj.DepartureCity
                formvalue.DepartureAirportId = obj.DepartureAirportId
                formvalue.DepTime = formatTime(obj.DepartureTime)
                formvalue.DepartureTime = formatDate(obj.DepartureDate) + "T" + formatTime(obj.DepartureTime)
                // {formvalue.DepartureTime		= formatTime(obj.DepartureTime)}
                formvalue.DepartureTerminalId = obj.DepartureTerminalId
                formvalue.ArrivalDate = formatDate(obj.ArrivalDate)
                formvalue.AirrvalCity = obj.AirrvalCity
                formvalue.AirrivalAirportId = obj.AirrivalAirportId
                formvalue.AirrTime = formatTime(obj.AirrivalTime)
                formvalue.AirrivalTime = formatDate(obj.ArrivalDate) + "T" + formatTime(obj.AirrivalTime)
                formvalue.ArrivalTerminalId = obj.ArrivalTerminalId
                formvalue.Duration = obj.Duration
                formvalue.Meal = obj.Meal
                formvalue.Baggage = obj.Baggage
                formvalue.Seat = obj.Seat
                formvalue.PNRNo = obj.PNRNo
                formvalue.PNRDate = formatDate(obj.PNRDate)
                formvalue.IsActive = obj.IsActive
                // Check In-Active Data and add in Dropdown...
                let allairlinelist = allairline?.filter(IsAct => IsAct.IsActive === true);

                setAirlineList(allairline?.filter(IsAct => IsAct.IsActive === true));
                setAirlineDisable(false);
                if (!allairlinelist?.find(IsAirlineName => IsAirlineName.AirlineId === obj.AirlineId)) {
                    setAirlineDisable(true)
                    setAirlineList((prvAirlinedata) => prvAirlinedata?.length > 0 ? [...prvAirlinedata, allairline?.find(isCity => isCity.AirlineId === obj.AirlineId)] : [])

                }
                let allsupplierlist = allsuppliername?.filter(IsAct => IsAct.IsActive === true);

                setSupplierName(allsuppliername?.filter(IsAct => IsAct.IsActive === true));
                setsuppliernameDisable(false);
                if (!allsupplierlist?.find(IsSupplierName => IsSupplierName.SupplierId === obj.SupplierId)) {
                    setsuppliernameDisable(true)
                    setSupplierName((prvsupplierdata) => prvsupplierdata?.length > 0 ? [...prvsupplierdata, allsuppliername?.find(isSupplier => isSupplier.SupplierId === obj.SupplierId)] : [])

                }
                allsuppliername?.find(function (item) {
                    if (item.SupplierId === obj.SupplierId) {
                        setSupplierCode(item.SupplierCode)
                    }
                })
                let alldeparturecitylist = alldeparturecity?.filter(IsAct => IsAct.IsActive === true);

                setDepartureCityList(alldeparturecity?.filter(IsAct => IsAct.IsActive === true));
                setDepartureCityDisable(false);
                if (!alldeparturecitylist?.find(Isdeparturecity => Isdeparturecity.LocationId === obj.DepartureCity)) {
                    setDepartureCityDisable(true)
                    setDepartureCityList((prvCitydata) => prvCitydata?.length > 0 ? [...prvCitydata, alldeparturecity?.find(isCity => isCity.LocationId === obj.DepartureCity)] : [])

                }

                let alldepartureAirportlist = alldepartureairport?.filter(IsAct => IsAct.IsActive === true);

                setAirportList(alldepartureairport?.filter(IsAct => IsAct.IsActive === true));
                setDepartureAirportDisable(false);
                if (!alldepartureAirportlist?.find(Isdepartureairport => Isdepartureairport.AirportId === obj.DepartureAirportId)) {
                    setDepartureAirportDisable(true)
                    setAirportList((prvAirportdata) => prvAirportdata?.length > 0 ? [...prvAirportdata, alldepartureairport?.find(isCity => isCity.AirportId === obj.DepartureAirportId)] : [])
                    const airportnamedata = alldepartureairport?.filter(airport => airport.AirportId === obj.DepartureAirportId)?.map(value => ({
                        value: value.AirportId,
                        label: value.AirportName
                    }));
                    setAirportNameList(airportnamedata);
                }
                else {
                    const airportnamedata = alldepartureairport?.filter(airport => airport.AirportId === obj.DepartureAirportId)?.map(value => ({
                        value: value.AirportId,
                        label: value.AirportName
                    }));
                    setAirportNameList(airportnamedata);
                }

                let allarrivalcitylist = allarrivalcity?.filter(IsAct => IsAct.IsActive === true);

                setArrivalCity(allarrivalcity?.filter(IsAct => IsAct.IsActive === true));
                setArrivalCityDisable(false);
                if (!allarrivalcitylist?.find(Isarrivalcity => Isarrivalcity.LocationId === obj.AirrvalCity)) {
                    setArrivalCityDisable(true)
                    setArrivalCity((prvCitydata) => prvCitydata?.length > 0 ? [...prvCitydata, allarrivalcity?.find(isCity => isCity.LocationId === obj.AirrvalCity)] : [])

                }

                let allarrivalAirportlist = allarrivalairport?.filter(IsAct => IsAct.IsActive === true);

                setAirportList(allarrivalairport?.filter(IsAct => IsAct.IsActive === true));
                setArrivalAirportDisable(false);
                if (!allarrivalAirportlist?.find(Isarrivalairport => Isarrivalairport.AirportId === obj.AirrivalAirportId)) {
                    setArrivalAirportDisable(true)
                    setAirportList((prvAirportdata) => prvAirportdata?.length > 0 ? [...prvAirportdata, allarrivalairport?.find(isCity => isCity.AirportId === obj.AirrivalAirportId)] : [])
                    const arrivalairportname = allarrivalairport?.filter(airport => airport.AirportId === obj.AirrivalAirportId)?.map(value => ({
                        value: value.AirportId,
                        label: value.AirportName
                    }));
                    setArrivalAirport(arrivalairportname);
                }
                else {
                    const arrivalairportname = allarrivalairport?.filter(airport => airport.AirportId === obj.AirrivalAirportId)?.map(value => ({
                        value: value.AirportId,
                        label: value.AirportName
                    }));
                    setArrivalAirport(arrivalairportname);
                }
                // const arrivalairportname = airportlist?.filter(airport => airport.LocationId === obj.AirrvalCity)?.map(value => ({
                //     value: value.AirportId,
                //     label: value.AirportName
                // }));
                // setArrivalAirport(arrivalairportname);


                setActiveId(obj.IsActive);
                setArrivalCityId(obj.AirrvalCity);
                setArrivalAirportId(obj.AirrivalAirportId);
                setArrivalTerminalId(obj.ArrivalTerminalId);
                setDepartualTerminalId(obj.DepartureTerminalId);
                setAirportId(obj.DepartureAirportId);
                setDepartureCityId(obj.DepartureCity);
                setClassId(obj.AirlineClassId);
                setAirlineId(obj.AirlineId);
                setSupplierId(obj.SupplierId);
                setDepartureDate(new Date(formatDate(obj.DepartureDate)))
                setArrivalDate(new Date(formatDate(obj.ArrivalDate)))
                setPNRDate(new Date(formatDate(obj.PNRDate)));
                setDepartureTime(dayjs(obj.DepartureTime))
                setArrivalTime(dayjs(obj.AirrivalTime))
            }
        });

    }
    //#endregion

    //#region handling error
    function ValidateData(values) {
        setIsSubmit(true);
        const error = {}
        if (supplierid === 0) {
            error.Supplierid = "Please Select Name of the Supplier";
            setIsSubmit(false);
        }
        if (airlineid === 0) {
            error.AirlineId = "Please Select Airline Name";
            setIsSubmit(false);
        }
        if (values.FlightNo?.trim()?.length === 0) {
            error.FlightNo = "Please enter Flight No";
            setIsSubmit(false);
        }
        if (values.FlightNo?.trim()?.length > 6) {
            error.FlightNo = "maximum limit is 6 digits only";
            setIsSubmit(false);
        }
        if (classid === 0) {
            error.Classid = "Please Select Class";
            setIsSubmit(false);
        }
        if (values.DepartureDate?.length === 0 || values.DepartureDate === null) {
            error.DepartureDate = "Please enter Departure Date";
            setIsSubmit(false);
        }
        if (departurecityid === 0) {
            error.DepartureCity = "Please select Departure City";
            setIsSubmit(false);
        }
        if (airportid === 0) {
            error.DepartureAirport = "Please select Departure Airport";
            setIsSubmit(false);
        }
        if (values.DepTime.length === 0 || values.DepTime === "--:--") {
            error.DepartureTime = "Please enter Departure Time";
            setIsSubmit(false);
        }
        if (valuepass(formvalue.DepTime)) {
            error.DepartureTime = "Please enter correct Departure Time";
            setIsSubmit(false);
        }
        if (departuralterminalid === 0) {
            error.DepartureTerminal = "Please Select Departure Terminal";
            setIsSubmit(false);
        }
        if (values.ArrivalDate?.length === 0 || values.ArrivalDate === null) {
            error.ArrivalDate = "Please enter Arrival Date";
            setIsSubmit(false);
        }
        if (values.DepartureDate > values.ArrivalDate) {
            error.ArrivalDate = 'Arrival date should be greater than or equal to Departure date';
            setIsSubmit(false);
        }
        if (arrivalcityid === 0) {
            error.ArrivalCity = "Please Select Arrival City";
            setIsSubmit(false);
        }
        if (arrivalairportid === 0) {
            error.ArrivalAirport = "Please Select Arrival Airport";
            setIsSubmit(false);
        }
        if (values.AirrTime?.length === 0 || values.AirrTime === "--:--") {
            error.AirrTime = "Please enter Arrival Time";
            setIsSubmit(false);
        }
        if (valuepass(formvalue.AirrTime)) {
            error.AirrTime = "Please enter correct Arrival Time";
            setIsSubmit(false);
        }
        if (values.DepTime > values.AirrTime && values.DepartureDate >= values.ArrivalDate) {
            error.AirrTime = "Please enter valid Arrival Time";
            setIsSubmit(false);
        }
        if (arrivalterminalid === 0) {
            error.AirrvalTerminal = "Please Select Arrival Terminal";
            setIsSubmit(false);
        }
        if (values.Meal?.length > 50) {
            error.AirrvalMeal = "Maximum 50 characters are allowed";
            setIsSubmit(false);
        }
        if (values.Baggage?.trim()?.length === 0) {
            error.Baggage = "Please enter Baggage";
            setIsSubmit(false);
        }
        if (values.Baggage?.trim()?.length > 50) {
            error.Baggage = "Maximum 50 characters are allowed";
            setIsSubmit(false);
        }
        if (!values.Seat) {
            error.Seat = "Please enter no. of seats";
            setIsSubmit(false);
        }
        if (values.Seat) {
            if (Number(values.Seat) > 400) {
                error.Seat = "Maximum 400 seats can be added";
                setIsSubmit(false);
            }
        }
        if (values.PNRNo?.trim()?.length === 0) {
            error.PNRNo = "Please enter PNR No";
            setIsSubmit(false);
        }
        if (values.PNRNo?.trim()?.length > 15) {
            error.PNRNo = "Maximum 15 characters are allowed";
            setIsSubmit(false);
        }
        if (values.PNRDate?.length === 0 || values.PNRDate === null) {
            error.PNRDate = "Please enter PNR Date";
            setIsSubmit(false);
        }
        if (values.DepartureDate < values.PNRDate) {
            error.PNRDate = 'PNR date should be less than or equal to Departure date';
            setIsSubmit(false);
        }
        return error;
    }
    //#endregion

    //#region bind datatable
    const columns = [
        {
            name: 'Supplier Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.SupplierName
        },
        {
            name: 'Supplier Code',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.SupplierCode
        },
        {
            name: 'Airline Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.AirlineName
        },
        {
            name: 'Flight No',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.FlightNo
        },
        {
            name: 'Class',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.ClassName
        },

        {
            name: 'Departure Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatDateRequire(flight.DepartureDate)
        },
        {
            name: 'Departure City',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.DepartureCityName
        },
        {
            name: 'Departure Airport Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.DepartureAirportName
        },

        {
            name: 'Departure Time',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatTime(flight.DepartureTime)
        },

        {
            name: 'Departure Terminal',
            sortable: true,
            reorder: true,
            selector: (flight) => flight.DepartureTerminal
        },

        {
            name: 'Arrival Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatDateRequire(flight.ArrivalDate)
        },
        {
            name: 'Arrival City',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.AirrvalCityName
        },
        {
            name: 'Arrival Airport Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.AirrivalAirportName
        },
        {
            name: 'Arrival Time',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatTime(flight.AirrivalTime)
        },
        {
            name: 'Arrival Terminal',
            sortable: true,
            reorder: true,
            selector: (flight) => flight.ArrivalTerminal
        },
        {
            name: 'Tour Duration',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.Duration
        },
        {
            name: 'Meals',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.Meal
        },
        {
            name: 'Baggage',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.Baggage
        },
        {
            name: 'No of Seats',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.Seat
        },
        {
            name: 'PNR No',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => flight.PNRNo
        },
        {
            name: 'PNR Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatDateRequire(flight.PNRDate)
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (flight) => flight.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (flight) => formatDateRequire(flight.CreatedDate)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (flight) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(flight.FlightId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (flight) => <i className="fa fa-eye" onClick={() => handleViewMode(flight.FlightId)}></i>// onClick={() => handleViewMode(banner.bannerId)}
        }
    ];

    //#endregion

    //#region handling search
    const serachoption = [
        {
            label: 'Supplier Name',
            value: '1'
        },
        {
            label: 'Supplier Code',
            value: '2'
        },
        {
            label: 'Airline Name',
            value: '3'
        },
        {
            label: 'Flight No',
            value: '4'
        },
        {
            label: 'Class',
            value: '14'
        },
        {
            label: 'Departure Date',
            value: '5'
        },
        {
            label: 'Departure City',
            value: '15'
        },
        {
            label: 'Departure Airport Name',
            value: '16'
        },
        {
            label: 'Departure Time',
            value: '17'
        },
        {
            label: 'Departure Terminal',
            value: '18'
        },

        {
            label: 'Arrival Date',
            value: '6'
        },
        {
            label: 'Arrival City',
            value: '19'
        },
        {
            label: 'Arrival Airport Name',
            value: '20'
        },
        {
            label: 'Arrival Time',
            value: '21'
        },
        {
            label: 'Arrival Terminal',
            value: '22'
        },

        {
            label: 'Tour Duration',
            value: '23'
        },
        {
            label: 'Meals',
            value: '7'
        },
        {
            label: 'Baggage',
            value: '8'
        },
        {
            label: 'No of Seats',
            value: '9'
        },
        {
            label: 'PNR No',
            value: '10'
        },
        {
            label: 'PNR Date',
            value: '11'
        },
        {
            label: 'Active',
            value: '12'
        },
        // {
        //     label: 'Created Date',
        //     value: '24'
        // },
        {
            label: 'All',
            value: '13'
        },

    ];
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('13');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);

    const setitems = async () => {
        const FlightListData = await getAllData("/master/v1/getallflight");
        setFilterItems(FlightListData.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getflightlist();
            setsclshow(false);
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();

            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.Meal.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '9') {
                filterdata = filteritem?.filter(issec => issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '11') {
                filterdata = filteritem?.filter(issec => formatDateRequire(issec.PNRDate).toString().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '14') {
                filterdata = filteritem?.filter(issec => issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '15') {
                filterdata = filteritem?.filter(issec => issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '16') {
                filterdata = filteritem?.filter(issec => issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '17') {
                filterdata = filteritem?.filter(issec => issec.DepartureTime.toString().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '18') {
                filterdata = filteritem?.filter(issec => issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '19') {
                filterdata = filteritem?.filter(issec => issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '20') {
                filterdata = filteritem?.filter(issec => issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '21') {
                filterdata = filteritem?.filter(issec => issec.AirrivalTime.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '22') {
                filterdata = filteritem?.filter(issec => issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '23') {
                filterdata = filteritem?.filter(issec => issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '24') {
                filterdata = filteritem?.filter(issec => formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '12') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '13') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Meal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureTime.toString().includes(searchvalue.toLowerCase())
                        || issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalTime.toString().includes(searchvalue.toLowerCase())
                        || issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Meal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureTime.toString().includes(searchvalue.toLowerCase())
                        || issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalTime.toString().includes(searchvalue.toLowerCase())
                        || issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Meal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureTime.toString().includes(searchvalue.toLowerCase())
                        || issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalTime.toString().includes(searchvalue.toLowerCase())
                        || issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Meal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureTime.toString().includes(searchvalue.toLowerCase())
                        || issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalTime.toString().includes(searchvalue.toLowerCase())
                        || issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirlineName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Meal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Baggage.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Seat.toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PNRNo.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.ClassName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.DepartureTime.toString().includes(searchvalue.toLowerCase())
                        || issec.DepartureTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrvalCityName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalAirportName.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.AirrivalTime.toString().includes(searchvalue.toLowerCase())
                        || issec.ArrivalTerminal.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.Duration.toLowerCase().toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.DepartureDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.ArrivalDate).toString().toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.CreatedDate).toString().includes(searchvalue.toLowerCase())
                        || formatDateRequire(issec.PNRDate).toString().includes(searchvalue.toLowerCase())
                        || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setFlightData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getflightlist();
    }
    //#endregion

    //#region handle pdf and excel import
    function PdfExport() {
        //print
        const columns = ['Supplier Name', 'Supplier Code', 'Airline Name', 'Flight No', 'Class', 'Departure Date', 'Departure City Name', 'Departure Airport Name', 'Departure Time', 'Departure Terminal', 'Arrival Date', 'Airrval City Name', 'Airrival Airport Name', 'Airrival Time', 'Airrival Terminal', 'Tour Duration', 'Meal',
            'Baggage', 'No of Seats', 'PNR No', 'PNR Date', 'Active', 'Created Date'
        ];

        var rows = [];

        for (let i = 0; i < flightdata?.length; i++) {
            var temp = [
                flightdata[i].SupplierName,
                flightdata[i].SupplierCode,
                flightdata[i].AirlineName,
                flightdata[i].FlightNo,
                flightdata[i].ClassName,
                formatDateRequire(flightdata[i].DepartureDate),
                flightdata[i].DepartureCityName,
                flightdata[i].DepartureAirportName,
                formatTime(flightdata[i].DepartureTime),
                flightdata[i].DepartureTerminal,
                formatDateRequire(flightdata[i].ArrivalDate),
                flightdata[i].AirrvalCityName,
                flightdata[i].AirrivalAirportName,
                formatTime(flightdata[i].AirrivalTime),
                flightdata[i].ArrivalTerminal,
                flightdata[i].Duration,
                flightdata[i].Meal,
                flightdata[i].Baggage,
                flightdata[i].Seat,
                flightdata[i].PNRNo,
                formatDateRequire(flightdata[i].PNRDate),
                `${flightdata[i].IsActive ? 'Active' : 'InActive'}`,
                formatDateRequire(flightdata[i].CreatedDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "FlightMaster", "Flight Master", "l")
    }
    // function ExportToExcel() {
    //     const fields = ['SupplierName', 'SupplierCode', 'AirlineName', 'FlightNo', 'ClassName', 'DepartureDate', 'DepartureCityName', 'DepartureAirportName', 'DepartureTime', 'ArrivalDate', 'AirrvalCityName', 'AirrivalAirportName', 'AirrivalTime', 'Duration', 'Meal',
    //         'Baggage', 'Seat', 'PNRNo', 'PNRDate', 'IsActive'];
    //     const data = flightdata;
    //     const fileName = 'Flightmasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }
    const ExportToExcel = () => {
        const dispalyname = ['Supplier Name', 'Supplier Code', 'Airline Name', 'Flight No', 'Class', 'Departure Date', 'Departure City', 'Departure Airport Name', 'Departure Time', 'Departure Terminal', 'Arrival Date', 'Airrval City', 'Airrival Airport Name', 'Airrival Time', 'Airrival Terminal', 'Tour Duration', 'Meal',
            'Baggage', 'No of Seats', 'PNR No', 'PNR Date', 'Active', 'Created Date'];
        const fields = ['SupplierName', 'SupplierCode', 'AirlineName', 'FlightNo', 'ClassName', 'DepartureDate', 'DepartureCityName', 'DepartureAirportName', 'DepartureTime', 'DepartureTerminal', 'ArrivalDate', 'AirrvalCityName', 'AirrivalAirportName', 'AirrivalTime', 'ArrivalTerminal', 'Duration', 'Meal',
            'Baggage', 'Seat', 'PNRNo', 'PNRDate', 'IsActive', 'CreatedDate'];

        ExportExcel(dispalyname, fields, flightdata, "FlightdataMaster", "FlightdataMaster.xls");
        //ExportExcels(dispalyname,fields,flightdata,"FlightdataMaster","FlightdataMaster.xls");
    }
    // const ExportExcels = (displayName,headerName,dataCollection,sheetName,fileName) =>{
    //     let headerrow = displayName
    //     let rowcolname =headerName
    //     let coldata =[];
    //     if(headerrow != null)
    //     {
    //          coldata.push(headerrow)
    //          dataCollection.forEach(element => {
    //             let celldata =[]
    //             rowcolname.map((obj)=>{
    //                 Object.keys(element).filter(ele => ele === obj).map((key)=>{
    //                     if(key.toLowerCase() === 'isactive')
    //                     {
    //                         celldata.push(element[key] === true ? 'Active' : 'InActive')
    //                     }
    //                     else
    //                     {

    //                         celldata.push(element[key])
    //                     }
    //                 })
    //             })
    //             coldata.push(celldata)
    //         });
    //         // var wb = XLSX.utils.book_new(),
    //         // ws = XLSX.utils.aoa_to_sheet(coldata);

    //         // XLSX.utils.book_append_sheet(wb, ws, sheetName);
    //         // XLSX.writeFile(wb,fileName);
    //     }
    // }
    //#endregion
    // const [year, setYear] = useState();
    const handleDateChange = (fieldname, e) => {
        if (fieldname === 'ArrivalDate') {
            formvalue.AirrivalTime = formatDate(e) + "T" + formvalue.AirrTime
            formvalue.ArrivalDate = formatDate(e)
            setArrivalDate(e);

            // const today = new Date();
            // const year = today.getFullYear()+10;
        }
        else if (fieldname === 'DepartureDate') {
            formvalue.DepartureTime = formatDate(e) + "T" + formvalue.DepTime
            formvalue.DepartureDate = formatDate(e)
            setDepartureDate(e)
        }
        else if (fieldname === 'PNRDate') {
            // { formvalue.DepartureTime = formatDate(e) + "T" + formvalue.DepTime }
            formvalue.PNRDate = formatDate(e)
            setPNRDate(e)
        }
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        setEditDisabled(true);

    }
    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    }
    function valuepass(fildvalue) {
        let status = false;
        const checkvalue = /[-]/g;
        const result = fildvalue.match(checkvalue);
        if (result?.length > 0 && result?.length < 4 && result !== null && result !== undefined) {
            status = true;
        }
        else {
            status = false;
        }
        return status

    }
    const handleDeparture = (time) => {
        setEditDisabled(true);
        let x = new Date(time)
        let hours = x.getHours();
        let minut = x.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minut < 10) {
            minut = '0' + minut
        }
        formvalue.DepartureTime = formvalue.DepartureDate + "T" + hours + ':' + minut + ":00"
        formvalue.DepTime = hours + ':' + minut
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        if (time == null) {
            formvalue.DepTime = ''
        }
        setDepartureTime(time);
    }
    const handleArrival = (time) => {
        setEditDisabled(true);
        let x = new Date(time)
        let hours = x.getHours();
        let minut = x.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minut < 10) {
            minut = '0' + minut
        }
        formvalue.AirrivalTime = formvalue.ArrivalDate + "T" + hours + ':' + minut + ":00"
        formvalue.AirrTime = hours + ':' + minut
        setFormErrors('');
        setFlightDuplicateErrorMessage('');
        if (time == null) {
            formvalue.AirrTime = ''
        }
        setArrivalTime(time);
    }
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Flight Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {flightduplicateerrorMessage && <p className='text-danger pb-3 text-center'>{flightduplicateerrorMessage}</p>}
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Supplier Name</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                id="Supplierid"
                                                ref={Supplierid}
                                                className="dropdown select_dropdown"
                                                options={suppliernamelist}
                                                value={suppliernamelist?.filter(function (suppliernamelist) {
                                                    return suppliernamelist.value === supplierid;
                                                })}// set list of the data
                                                onChange={handlesupplierchange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="Supplierid"
                                                selRef={Supplierid}
                                                datalist={suppliernamelist}
                                                fieldid={supplierid}
                                                SelectionChange={handlesupplierchange}
                                                isdisable={disabled}
                                                isactivedisable={suppliernamedisable}
                                                issearchable={true}
                                            />
                                            {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Supplier Code</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type='text' disabled value={suppliercode} class="form-control" />
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Airline Name</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={airlinenamelist}
                                                value={airlinenamelist?.filter(function (airlinenamelist) {
                                                    return airlinenamelist.value === airlineid;
                                                })}// set list of the data
                                                onChange={handleAirlineNameChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="AirlineId"
                                                selRef={AirlineId}
                                                datalist={airlinenamelist}
                                                fieldid={airlineid}
                                                SelectionChange={handleAirlineNameChange}
                                                isdisable={disabled}
                                                isactivedisable={airlinedisable}
                                                issearchable={true}
                                            />
                                            {formErrors.AirlineId && <p className='text-danger text-start'>{formErrors.AirlineId}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Flight No</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="FlightNo" class="form-control"
                                                name="FlightNo"
                                                value={formvalue.FlightNo}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Flight No"
                                            />
                                            {formErrors.FlightNo && <p className='text-danger text-start'>{formErrors.FlightNo}</p>}
                                            {/* {tagerrorMessage && <div className="text-danger"> {tagerrorMessage} </div>} */}
                                            {/* {taglengerrorMessage && <div className="text-danger"> {taglengerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Class</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="Classid"
                                                ref={Classid}
                                                className="dropdown select_dropdown"
                                                options={classlist}
                                                value={classlist?.filter(function (classlist) {
                                                    return classlist.value === classid;
                                                })}// set list of the data
                                                onChange={handleClassChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formErrors.Classid && <p className='text-danger text-start'>{formErrors.Classid}</p>}
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Departure Date</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type="date" id="inputPassword6" class="form-control" min={disablePastDate()}
                                                name="DepartureDate"
                                                value={formvalue.DepartureDate}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            /> */}
                                            <div class="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='Departuredateopen'
                                                    selected={departureDate}
                                                    onChange={(e) => handleDateChange('DepartureDate', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    // onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Departuredateopen")} id="calendarBtn1" Disabled={disabled ? true : false} ><i class="fa fa-calendar"></i></span>
                                            </div>
                                            {formErrors.DepartureDate && <p className='text-danger text-start'>{formErrors.DepartureDate}</p>}
                                            {/* {tagerrorMessage && <div className="text-danger"> {tagerrorMessage} </div>} */}
                                            {/* {taglengerrorMessage && <div className="text-danger"> {taglengerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Departure City</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={departurelistcity}
                                                value={departurelistcity?.filter(function (departurelistcity) {
                                                    return departurelistcity.value === departurecityid;
                                                })}// set list of the data
                                                onChange={handleDepartureCityChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selRef={DepartureCity}
                                                selId="DepartureCity"
                                                datalist={departurelistcity}
                                                fieldid={departurecityid}
                                                SelectionChange={handleDepartureCityChange}
                                                isdisable={disabled}
                                                isactivedisable={departurecitydisable}
                                                issearchable={true}
                                            />
                                            {formErrors.DepartureCity && <p className='text-danger text-start'>{formErrors.DepartureCity}</p>}
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Departure Airport Name</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={airportnamelist}
                                                value={airportnamelist?.filter(function (airportnamelist) {
                                                    return airportnamelist.value === airportid;
                                                })}// set list of the data
                                                onChange={handleAirportChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="DepartureAirport"
                                                selRef={DepartureAirport}
                                                datalist={airportnamelist}
                                                fieldid={airportid}
                                                SelectionChange={handleAirportChange}
                                                isdisable={disabled}
                                                isactivedisable={departureairportdisable}
                                                issearchable={true}
                                            />
                                            {formErrors.DepartureAirport && <p className='text-danger text-start'>{formErrors.DepartureAirport}</p>}
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Departure Time</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type='time' id="inputPassword6" class="form-control"
                                                name="DepTime"
                                                value={formvalue.DepTime}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            // placeholder="Flight No"
                                            /> */}
                                            <TimePicker id='DepTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={departureTime} format={'HH:mm'} onChange={handleDeparture} />
                                            {/* <CustomTimePicker classid="DepTime" filedvalue={departureTime} isDisable={disabled ? true : false} handleOnChange={(e) => handleonchangeofTime("DepTime", e)} /> */}
                                            {formErrors.DepartureTime && <p className='text-danger text-start'>{formErrors.DepartureTime}</p>}
                                            {/* {tagerrorMessage && <div className="text-danger"> {tagerrorMessage} </div>} */}
                                            {/* {taglengerrorMessage && <div className="text-danger"> {taglengerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Departure Terminal</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="DepartureTerminal"
                                                ref={DepartureTerminal}
                                                className="dropdown select_dropdown"
                                                options={departuralterminallist}
                                                value={departuralterminallist?.filter(function (departuralterminallist) {
                                                    return departuralterminallist.value === departuralterminalid;
                                                })}// set list of the data
                                                onChange={handleDepaturalTerminalChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formErrors.DepartureTerminal && <p className='text-danger text-start'>{formErrors.DepartureTerminal}</p>}
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Arrival Date</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <div class="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='Arrivaldateopen'
                                                    selected={arrivalDate}
                                                    onChange={(e) => handleDateChange('ArrivalDate', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Arrivaldateopen")} id="calendarBtn1" Disabled={disabled ? true : false} ><i class="fa fa-calendar"></i></span>
                                            </div>
                                            {/* <CustomDatePicker className='form-control' field ='ArrivalDate'
                                                fieldvalue={arrivalDate} OnChangeofDate={(e) => handleDateChange('ArrivalDate',e)} 
                                                format="dd-MM-yyyy" isDisabled={disabled ? true : false}/> */}
                                            {formErrors.ArrivalDate && <p className='text-danger text-start'>{formErrors.ArrivalDate}</p>}
                                            {/* {tagerrorMessage && <div className="text-danger"> {tagerrorMessage} </div>} */}
                                            {/* {taglengerrorMessage && <div className="text-danger"> {taglengerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Arrival City</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={arrivalcitylist}
                                                value={arrivalcitylist?.filter(function (arrivalcitylist) {
                                                    return arrivalcitylist.value === arrivalcityid;
                                                })}// set list of the data
                                                onChange={handleArrivalCityChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="ArrivalCity"
                                                selRef={ArrivalCity}
                                                datalist={arrivalcitylist}
                                                fieldid={arrivalcityid}
                                                SelectionChange={handleArrivalCityChange}
                                                isdisable={disabled}
                                                isactivedisable={arrivalcitydisable}
                                                issearchable={true}
                                            />
                                            {formErrors.ArrivalCity && <p className='text-danger text-start'>{formErrors.ArrivalCity}</p>}
                                            {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Arrival Airport Name</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <Select
                                                className="dropdown select_dropdown"
                                                options={arrivalairport}
                                                value={arrivalairport?.filter(function (arrivalairport) {
                                                    return arrivalairport.value === arrivalairportid;
                                                })}// set list of the data
                                                onChange={handleArrivalAirportChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            /> */}
                                            <CustomSelection
                                                selId="ArrivalAirport"
                                                selRef={ArrivalAirport}
                                                datalist={arrivalairport}
                                                fieldid={arrivalairportid}
                                                SelectionChange={handleArrivalAirportChange}
                                                isdisable={disabled}
                                                isactivedisable={arrivalairportdisable}
                                                issearchable={true}
                                            />
                                            {formErrors.ArrivalAirport && <p className='text-danger text-start'>{formErrors.ArrivalAirport}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Arrival Time</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type="time" id="inputPassword6" class="form-control"
                                                name="AirrTime"
                                                value={formvalue.AirrTime}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            /> */}
                                            <TimePicker id='AirrTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={arrivalTime} format={'HH:mm'} onChange={handleArrival} />
                                            {/* <CustomTimePicker classid="AirrTime" filedvalue={arrivalTime} isDisable={disabled ? true : false} handleOnChange={(e) => handleonchangeofTime("AirrTime", e)} /> */}
                                            {formErrors.AirrTime && <p className='text-danger text-start'>{formErrors.AirrTime}</p>}
                                            {/* {tagerrorMessage && <div className="text-danger"> {tagerrorMessage} </div>} */}
                                            {/* {taglengerrorMessage && <div className="text-danger"> {taglengerrorMessage} </div>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Arrival Terminal</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="AirrvalTerminal"
                                                ref={AirrvalTerminal}
                                                className="dropdown select_dropdown"
                                                options={arrivalterminallist}
                                                value={arrivalterminallist?.filter(function (arrivalterminallist) {
                                                    return arrivalterminallist.value === arrivalterminalid;
                                                })}// set list of the data
                                                onChange={handleArrivalTerminalChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formErrors.AirrvalTerminal && <p className='text-danger text-start'>{formErrors.AirrvalTerminal}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label">Tour Duration</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Duration" class="form-control"
                                                name="Duration"
                                                value={formvalue.Duration}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Duration"
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label">Meals</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Meal" class="form-control"
                                                name="Meal"
                                                value={formvalue.Meal}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Meal"
                                            />
                                            {formErrors.AirrvalMeal && <p className='text-danger text-start'>{formErrors.AirrvalMeal}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">Baggage</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Baggage" class="form-control"
                                                name="Baggage"
                                                value={formvalue.Baggage}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Baggage"
                                            />
                                            {formErrors.Baggage && <p className='text-danger text-start'>{formErrors.Baggage}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">No of Seats</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Seat" class="form-control"
                                                name="Seat"
                                                value={formvalue.Seat}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="No of Seats"
                                            />
                                            {formErrors.Seat && <p className='text-danger text-start'>{formErrors.Seat}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">PNR No</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PNRNo" class="form-control"
                                                name="PNRNo"
                                                value={formvalue.PNRNo}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="PNR No"
                                            />
                                            {formErrors.PNRNo && <p className='text-danger text-start'>{formErrors.PNRNo}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label required">PNR Date</label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            {/* <input type="date" id="inputPassword6" class="form-control" min={disablePastDate()}
                                                name="PNRDate"
                                                value={formvalue.PNRDate}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            /> */}
                                            <div class="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='pnrdateopen'
                                                    selected={pnrDate}
                                                    onChange={(e) => handleDateChange('PNRDate', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("pnrdateopen")} id="calendarBtn1" Disabled={disabled ? true : false} ><i class="fa fa-calendar"></i></span>
                                            </div>
                                            {formErrors.PNRDate && <p className='text-danger text-start'>{formErrors.PNRDate}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-8" disabled={false}>
                                            {/* <input class="form-check-input mt-2" type="checkbox" id="checkboxNoLabel" checked={activeId}
                                        aria-label="..." onChange={handleisActive} disabled={disabled} /> */}
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {tagduplicateerrorMessage && <div className="text-danger"> {tagduplicateerrorMessage} </div>} */}
                    </div>
                    <div class="d-flex justify-content-center">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                            </div>
                        </div>

                    </div>
                    <CustomTable
                        columns={columns}
                        data={flightdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
           
                </form>
            </div>
        </>
    )
}

export default Flightmaster