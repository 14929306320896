import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { getAllData } from '../../../../services/ApiCallingUserServices';



const PackageMenuMaster = (props) => {
    // const dispatch = useDispatch();
    const {
        handleChange,
        menudata,
        handleMenuDisable,
        retriveflag,
        editableflag
    }=props
    const [menulist, setMenuList] = useState([]);

    const getMenulist = async () => {
        const listofmenudata = await getAllData('/master/vendor/v1/vengetpackagemenu');
        setMenuList(listofmenudata.data);
    }

    const showDivHandlerD = (id) => {
        const newList = menulist?.map((item) => {
            if (item.PackageMenuId === id) {
            // setShown({
            //         ...Shown,
            //         [item.PackageMenuName]: !item.IsDefault
            // });
                const updatedItem = {
                    ...item,
                    IsDefault: !item.IsDefault,
                };
                return updatedItem;
            }
            return item;
        });
        setMenuList(newList);
        handleChange(newList);
        handleMenuDisable(false)
        // Number(formvalue.PackageTypeId) === 1 ? setFlightVisibled(false) : setFlightVisibled(true);
    }
    useEffect(()=>{
        getMenulist()
    },[])
    useEffect(()=>{
        if(retriveflag)
        {
            setMenuList(menudata)
        }
        
    },[menudata, retriveflag])
    
    //#endregion end input time type box    
    return (
        <>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div className='col-lg-6 col-sm-6 ' >
                        <div className='row'>
                            <div class="col-lg-12 col-md-12 mb-1">
                                <label class="col-form-label required">Package Menu Master</label>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-12 col-12 col-md-12 '>
                        <div className='row'>
                            {menulist && menulist?.map((ele, index) => {
                                return (
                                    <>
                                        <div class="col-lg-2 col-md-3 col-6 mb-1">
                                            <label class="col-form-label">{ele.PackageMenuUseName}</label>
                                        </div>
                                        <div class="col-lg-2 col-md-3 col-6 mb-1">
                                            {/* <input className="form-check-input mt-2 me-2" name='menutype' type="checkbox" id="checkboxNoLabelm" checked={ele.IsDefault ? true : false}
                                                //aria-label="..." value={ele.PackageMenuId} key={ele.PackageMenuId} onChange={() => showDivHandlerD(ele.PackageMenuId)} disabled={ele.PackageMenuId === 3 ? true : false}/>
                                                aria-label="..." value={ele.PackageMenuId} key={ele.PackageMenuId} onChange={() => showDivHandlerD(ele.PackageMenuId)} disabled={packageD?.state?.viewtype !== 'View' ? (ele.IsDisabled ? true : false) : true} /> */}
                                              <input className="form-check-input mt-2 me-2" type='checkbox'  value={ele.PackageMenuId}
                                                        checked={ele.IsDefault}
                                                        onChange={() => showDivHandlerD(ele.PackageMenuId)}
                                                        disabled={!editableflag  ? (ele.IsDisabled ? true : false) : true}  />
                                                {ele.MenuType}
                                        </div>
                                    </>

                                    //              <input type='checkbox' name="menutype" value={ele.MenuTypeId} key={ele.MenuTypeId} checked={ele.IsChecked ? true :false} onChange ={() => handlecheckboxchange(ele.MenuTypeId)}/>
                                    // <label>{ele.MenuType}</label>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackageMenuMaster