import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import UploadButton from './UploadButton';

function HotelAndRoomVideos() {
    return (
        <div>
            <Accordion>

                <AccordionSummary
                    expandIcon={<i class="fa-solid fa-plus" style={{ color: "#B22500" }}></i>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Hotel Images</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className='row'>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<i class="fa-solid fa-plus" style={{ color: "#B22500" }}></i>}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Room 1 Images</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    <div className='row'>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                                <UploadButton/>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

export default HotelAndRoomVideos