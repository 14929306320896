import React from "react";
import Switch from "react-switch";
import { ConfirmationModal } from "../../../../customControl/ConfirmationModal";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { GetMenuIdFromStorage } from "../../../commonClass/Menudect";
import { useNavigate } from "react-router-dom";
import {
  getAllData,
  patchUsersData,
  postUsersData,
  verifytoken,
} from "../../../../services/ApiCallingUserServices";
import { ClearLocalStorage, GetLocalStorage } from "../../../commonClass/Session";
import { loaderActions } from "../../../../_actions/loader.actions";
import { ExportExcel } from "../../../commonClass/ExportExcel";
import { generatePDF } from "../../../commonClass/CommonValidation";
import CustomTable from "../../../../customControl/CustomTable";

const AttractionsFacilities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //jnklsjdkjsk;dj
  const [userexport, setUserExport] = useState(true);
  const [themedata, setThemeData] = useState([]);
  //sdnjlsfsjpkcfjp;ds
  const [buttonaddeditText, setButtonAddEditText] = useState("Add");
  const [editdisabled, setEditDisabled] = useState(true);

  const [activeId, setActiveId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [serchdisable, setSerachDisable] = useState(false);
  const [searchvalue, setSearchValue] = useState();
  const [searchby, setSerachBy] = useState("4");
  const [clrshow, setsclshow] = useState(false);
  //

  const user = useSelector((store) => store.userstatus.tba);
  const [useradd, setUserAdd] = useState(true);
  const [useredit, setUserEdit] = useState(true);
  //

  // state use to update attraxction facil,ity. initial valuer assigned
  const [formAttractionFacility, setFormAttractionFacility] = useState({
    AttractionFacilityId: 0,
    FacilityName: "",
    IsActive: false,
    UserId: 0,
  });

  //state use to update error
  const [formError, setFormError] = useState({
    duplicate: "",
    attractionfacility: "",
  });

  //initial errors
  let errors = {
    duplicate: "",
    attractionfacility: "",
  };

  //getting initial available facilities data
  const [attractionsFacilityData, setAttractionsFacilityData] = useState([]);
  const getallattractionsfacilities = async () => {
    const allattractionsfacilities = await getAllData(
      "/master/v1/getallattractionfacility" //url
    );
    setAttractionsFacilityData((allattractionsfacilities.data !== null && allattractionsfacilities.data !== undefined) ? allattractionsfacilities.data : [])
    //pdf0-excel state
    setThemeData(allattractionsfacilities.data);
  };

  //input text field
  const handleFacilityNameChange = (e) => {
    setFormError({ ...formError, duplicate: "", attractionfacility: "" });
    setFormAttractionFacility({
      ...formAttractionFacility,
      [e.target.name]: e.target.value,
    });
    setEditDisabled(true);
  };

  //sdnfjkdhklfjsdkfjhkisdvbfgsfdgfhfjersdnfkjdfjfnlkdsfnkjdsbfnjhsvjhuvvfjhzgvjhcf
  function PdfExport() {
    //print
    const columns = ["Attraction Facility", "Active", "Created Date"];
    var rows = [];

    for (let i = 0; i < themedata?.length; i++) {
      //makwe changes inside
      var temp = [
        themedata[i].FacilityName,
        `${themedata[i].IsActive ? "Active" : "InActive"}`,
        formatDate(themedata[i].CreatedOn),
      ];
      rows = [...rows, temp];
    }
    generatePDF(columns, rows, "AttractionFacilities", "Attraction Facilities");
  }

  const ExportToExcel = () => {
    const dispalyname = ["Attraction Facility", "Active", "Created Date"];
    //make chnages here
    const fields = ["FacilityName", "IsActive", "CreatedOn"];
    ExportExcel(
      dispalyname,
      fields,
      themedata,
      "AttractionFacilities",
      "AttractionFacilities.xls"
    );
  };
  //ljhsdcfnjklsdmvcbndsjkdnmvcbkjbcfkjxzcbfvsdjhgbfjkvcfsjklhfklhclksdjflksjlfjfl

  //#region Active/Inactive button
  const handleisActive = (e) => {
    if (buttonaddeditText === "Add") {
      formAttractionFacility.IsActive = e;
      setActiveId(!activeId);
      setFormError({ ...formError, duplicate: "", attractionfacility: "" });
    } else {
      setModalShow(true);
    }
  };

  const handleConfirmation = (e) => {
    if (e === true) {
      formAttractionFacility.IsActive = !activeId;
      setActiveId(!activeId);
      setEditDisabled(true);
      setFormError({ ...formError, duplicate: "", attractionfacility: "" });
    }
    setModalShow(false);
  };
  //#endregion

  //#region validations to generaste errors for empty attractions field
  const validateData = () => {
    let error = [];
    if (
      typeof formAttractionFacility.FacilityName === "string" &&
      formAttractionFacility.FacilityName?.trim()?.length === 0
    ) {
      errors.attractionfacility = "Kindly enter the Attraction facilitiy name";
      error.push("attractionfacility");
    }
    setFormError(errors);
    document.getElementById(error[0]).focus();
  };
  //#endregion

  // ADD button : logic to add new faciltires
  const handleAddClick = async () => {
    let statusoftoken = await verifytoken();
    if (buttonaddeditText === "Add") {
      if (statusoftoken) {
        if (formAttractionFacility.FacilityName?.trim()?.length === 0) {
          validateData();
          return;
        } else {
          if (statusoftoken) {
            const AttractionsFacilityData = await postUsersData(
              "/master/v1/insertattractionfacility", // url of backend
              formAttractionFacility
            );

            if (
              !AttractionsFacilityData.status &&
              !AttractionsFacilityData.data
            ) {
              errors.duplicate = AttractionsFacilityData.message;
              setFormError(errors);

              return;
            }
            if (AttractionsFacilityData.status) {
              if (AttractionsFacilityData.data > 0) {
                setitems();
                dispatch({
                  type: "ALERTING",
                  message: AttractionsFacilityData.message,
                });
                clear();
              } else {
                dispatch({
                  type: "ALERTING",
                  message: AttractionsFacilityData.message,
                });
                return;
              }
            } else {
              dispatch({
                type: "ALERTING",
                message: AttractionsFacilityData.message,
              });
              return;
            }
          } else {
            ClearLocalStorage();
            dispatch({
              type: "SESSIONOUT",
              message: "Your Session Expired. Please Login Again!",
            });
          }
        }
      } else {
        ClearLocalStorage();
        dispatch({
          type: "SESSIONOUT",
          message: "Your Session Expired. Please Login Again!",
        });
      }
    } else {
      if (statusoftoken) {
        if (formAttractionFacility.FacilityName?.trim()?.length === 0) {
          validateData();
          return;
        } else {
          if (statusoftoken) {
            const AttractionsFacilityData = await patchUsersData(
              "/master/v1/updateattractionfacility",
              formAttractionFacility
            );
            if (
              !AttractionsFacilityData.status &&
              !AttractionsFacilityData.data
            ) {
              errors.duplicate = AttractionsFacilityData.message;
              setFormError(errors);
              return;
            }
            if (AttractionsFacilityData.status) {
              setitems();
              dispatch({
                type: "ALERTING",
                message: AttractionsFacilityData.message,
              });
              clear();
            } else {
              dispatch({
                type: "ALERTING",
                message: AttractionsFacilityData.message,
              });
              return;
            }
          } else {
            ClearLocalStorage();
            dispatch({
              type: "SESSIONOUT",
              message: "Your Session Expired. Please Login Again!",
            });
          }
        }
      } else {
        ClearLocalStorage();
        dispatch({
          type: "SESSIONOUT",
          message: "Your Session Expired. Please Login Again!",
        });
      }
    }
  };

  //#region clear button
  const handleClearClick = () => {
    clear();
  };

  function clear() {
    setFormError({ ...formError, duplicate: "", attractionfacility: "" });
    setFormAttractionFacility({
      ...formAttractionFacility,
      AttractionFacilityId: 0,
      FacilityName: "",
      IsActive: false,
    });
    setActiveId(false);
    getallattractionsfacilities();
    setButtonAddEditText("Add");
    setDisabled(false);
    setEditDisabled(true);
    setSerachDisable(false);
    setSerachBy("4");
    setSearchValue("");
    setsclshow(false);
  }
  //#endregion

  //#region search FAQs by credentials
  const serachoption = [
    {
      label: "Attractions Facility",
      value: "1",
    },
    {
      label: "Active",
      value: "2",
    },
    // {
    //     label: 'Created Date',
    //     value: '3'
    // },
    {
      label: "All",
      value: "4",
    },
  ];

  const [filteritems, setFilterItems] = useState([]);

  const handleSearchBy = (e) => {
    setSerachBy(e.value);
  };

  const setitems = async () => {
    const allattractionsfacilities = await getAllData(
      "/master/v1/getallattractionfacility" // url from backend
    );

    setFilterItems(allattractionsfacilities.data);
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
    setsclshow(true);
    if (e.target.value === "") {
      getallattractionsfacilities();
      setsclshow(false);
    }
  };

  const clearsearch = () => {
    setSearchValue("");
    setsclshow(false);
    getallattractionsfacilities();
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleClickSearchValue();
    }
  };
  const handleClickSearchValue = () => {
    if (
      typeof searchvalue === "string" &&
      searchvalue?.trim()?.length !== 0 &&
      searchby > 0
    ) {
      setitems();
      var filteritem = filteritems;
      var filterdata;
      if (searchby === "1") {
        filterdata = filteritem?.filter((issec) =>
          issec.FacilityName.toLowerCase().includes(searchvalue.toLowerCase())
        );
      }
      // else if (searchby === '5') {
      //     filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase()));
      // }
      else if (searchby === "2") {
        if (searchvalue.toLowerCase() === "active".toLowerCase()) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === true);
        } else if (searchvalue.toLowerCase() === "inactive".toLowerCase()) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === false);
        } else if ("active".toLowerCase().includes(searchvalue.toLowerCase())) {
          filterdata = filteritem?.filter(
            (issec) => issec.IsActive === true || issec.IsActive === false
          );
        } else if (
          "inactive".toLowerCase().includes(searchvalue.toLowerCase())
        ) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === false);
        } else {
          filterdata = filteritem?.filter((issec) =>
            formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^")
          );
        }
      } else if (searchby === "4") {
        if (searchvalue.toLowerCase() === "active".toLowerCase()) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.FacilityName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === true
          );
        } else if (searchvalue.toLowerCase() === "inactive".toLowerCase()) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.FacilityName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === false
          );
        } else if ("active".toLowerCase().includes(searchvalue.toLowerCase())) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.FacilityName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === true ||
              issec.IsActive === false
          );
        } else if (
          "inactive".toLowerCase().includes(searchvalue.toLowerCase())
        ) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.FacilityName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === false
          );
        } else {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.FacilityName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              "active".toLowerCase().includes(searchvalue.toLowerCase()) ||
              "inactive".toLowerCase().includes(searchvalue.toLowerCase())
          );
        }
      }
      setAttractionsFacilityData(filterdata);
    }
  };

  const columns = [
    {
      name: "Attraction Facility",
      sortable: true,
      float: "left",
      selector: (theme) => theme.FacilityName,
    },
    {
      name: "Active",
      sortable: true,
      float: "left",
      selector: (theme) => (theme.IsActive ? "Active" : "InActive"),
    },
    {
      name: "Created Date",
      sortable: true,
      float: "left",
      selector: (theme) => formatDate(theme.CreatedOn),
    },
    {
      name: "Edit",
      selector: (theme) => (
        <button
          type="button"
          className="border-0 p-0 bg-body set_disableclr"
          disabled={!useredit}
          onClick={() => handleEditMode(theme.AttractionFacilityId)}
        >
          <i className="fa fa-edit"></i>{" "}
        </button>
      ),
    },
    {
      name: "View",
      selector: (theme) => (
        <i
          className="fa fa-eye"
          onClick={() => handleViewMode(theme.AttractionFacilityId)}
        ></i>
      ),
    },
  ];

  //#region Date format
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }
  //#endregion

  //#region Edit and View Button
  const handleEditMode = (id) => {
    setDisabled(false);
    setEditDisabled(false);
    setSerachDisable(true);
    setButtonAddEditText("Update");
    RetriveData(id);
  };

  const handleViewMode = (id) => {
    setEditDisabled(false);
    setDisabled(true);
    setSerachDisable(true);
    RetriveData(id);
  };
  //#endregion

  function RetriveData(id) {
    setFormError({ ...formError, duplicate: "", attractionfacility: "" });
    attractionsFacilityData?.map((obj) => {
      if (obj.AttractionFacilityId === id) {
        formAttractionFacility.FacilityName = obj.FacilityName;
        formAttractionFacility.AttractionFacilityId = obj.AttractionFacilityId;
        formAttractionFacility.IsActive = obj.IsActive;
        setActiveId(obj.IsActive);
      }
    });
  }

  useEffect(() => {
    dispatch(loaderActions.start());
    let token = GetLocalStorage();
    if (token && token.userName && token.token) {
      formAttractionFacility.UserId = token?.userMasterID;
      getallattractionsfacilities().then(() => {
        dispatch(loaderActions.end());
      });
      setitems();
    } else {
      ClearLocalStorage();
      dispatch({
        type: "SESSIONOUT",
        message: "Your Session Expired. Please Login Again!",
      });
    }
  }, []);

  useEffect(() => {
    // let MENUID = GetMenuIdFromStorage();
    // let mid = user?.find((x) => x.MenuId === MENUID);

    let pagelink = user?.find(x => {
      if (x.PageLink === window.location.pathname)
        return x.MenuId
    });
    let mid = user?.find(x => x.MenuId === pagelink.MenuId);

    if (mid?.URightView === false && mid?.URightView !== undefined) {
      navigate("/dashboard");
    }
    user
      ?.filter((x) => x.MenuId === pagelink.MenuId)
      .map((obj) => {
        setUserAdd(obj.URightAdd);
        setUserEdit(obj.URightEdit);
        //hbjkh
        setUserExport(obj.URightExport);
        //vbgjhuiu
      });
  }, [user]);
  return (
    <>
      <div className="container-fluid mt-4 heading_color">
        <h3>Attraction Facilities</h3>
      </div>
      <div className="container-fluid form_border pb-5 my-3">
        <div className="row mx-1 mt-4 mb-3"></div>
        <form action="">
          <div className="row mb-3 mx-1">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    {formError.duplicate && (
                      <div className="text-center text-danger pb-3">
                        {" "}
                        {formError.duplicate}{" "}
                      </div>
                    )}
                    <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                      <label
                        className="col-form-label required"
                        htmlFor="attractionsfacilities"
                      >
                        Attraction Facility
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                      <div className="col-lg-6 col-md-8">
                        <input
                          type="text"
                          className="form-control"
                          name="FacilityName"
                          id="attractionfacility"
                          value={formAttractionFacility.FacilityName} //logic to enter the facility
                          onChange={(e) => handleFacilityNameChange(e)}
                          maxLength={100}
                          disabled={disabled ? true : false}
                          placeholder="Attraction Facility"
                        />
                        {formError.attractionfacility && (
                          <div className="text-start text-danger">
                            {formError.attractionfacility}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12">
                      <label htmlFor="active" className="col-form-label">
                        Active
                      </label>
                    </div>
                    <div className="col-auto col-lg-6 " disabled={false}>
                      <Switch
                        id="active"
                        onChange={handleisActive}
                        checked={activeId}
                        className="react-switch"
                        disabled={disabled ? true : false}
                      />
                    </div>
                    <ConfirmationModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      noconfirm={() => handleConfirmation(false)}
                      yesconfirm={() => handleConfirmation(true)}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center mb-4">
                {useradd === false && buttonaddeditText === "Add" ? (
                  <button
                    type="button"
                    class="btn btn-primary mx-1"
                    id="btnAdd"
                    onClick={handleAddClick}
                    disabled={!useradd}
                  >
                    {buttonaddeditText}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    id="btnAdd"
                    onClick={handleAddClick}
                    disabled={editdisabled ? false : true}
                  >
                    {buttonaddeditText}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  id="btnClear"
                  onClick={handleClearClick}
                >
                  Clear
                </button>
              </div>

              <div className="row">
                <div className="d-lg-flex d-md-flex d-sm-block justify-content-end px-0">
                  <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                    <Select
                      className="dropdown"
                      options={serachoption}
                      value={serachoption?.filter(function (serachoption) {
                        return serachoption.value === searchby;
                      })} // set list of the datavalue
                      onChange={handleSearchBy} // assign onChange function
                      isDisabled={serchdisable ? true : false}
                      isSearchable={false}
                    />
                  </div>
                  <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                    <div className="clrbutton">
                      <input
                        type="text"
                        onKeyDown={onKeyDownHandler}
                        id="btnSearchText"
                        className="form-control"
                        placeholder="Enter keyword"
                        name="SearchValue"
                        onChange={handleChangeSearchValue}
                        value={searchvalue}
                        disabled={serchdisable ? true : false}
                      />
                      <button
                        className={`${clrshow === true ? "d-block" : "d-none"}`}
                        disabled={serchdisable ? true : false}
                        onClick={clearsearch}
                        type="reset"
                      >
                        &times;
                      </button>
                    </div>
                  </div>

                  <div className="p-2 text-center">
                    <button
                      type="button"
                      className="btn btn-primary mx-1"
                      id="btnSearch"
                      disabled={serchdisable ? true : false}
                      onClick={handleClickSearchValue}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>

              <CustomTable
                columns={columns}
                data={attractionsFacilityData}
                PdfExport={PdfExport}
                ExportToExcel={ExportToExcel}
                disabled={!userexport}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AttractionsFacilities;
