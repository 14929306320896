import Switch from 'react-switch';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail, onlyAlphabets, onlyNumeric } from '../../../commonClass/CommonValidation';
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import RichTextBox from '../../../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';
import CustomSelection from '../../../../customControl/CustomSelection';
import Select from 'react-select';
import { loaderActions } from '../../../../_actions/loader.actions';
import PopUp from './PopUp';

const NoOfVacancyOption = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
]


const CreateNewJobVacancy = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate();
    const user = useSelector(store => store.userstatus.tba);
    const [dropDownOption, setDropDownOption] = useState({});
    const [cityOption, setCityOption] = useState([])
    const userdata = GetLocalStorage()
    const [newJob, setNewJob] = useState({
        UserId: userdata?.userMasterID,
        StateId: '',
        LocationId: '',
        DepartmentId: '',
        ExpId: '',
        ShiftId: '',
        NoOfVacancy: '',
        QuaId: '',
        JobTitle: '',
        KeyRoleResp: '',
        KeySkills: '',
        ApplyLimit: null,
        IsActive: false
    });
    const refId = useRef({
        StateId: null,
        LocationId: null,
        DepartmentId: null,
        ExpId: null,
        ShiftId: null,
        QuaId: null,
        NoOfVacancy: null,
        JobTitle: null,
        ApplyLimit: null
    })
    const [richboxheight, setRichBoxHeight] = useState(false);
    const [errors, setError] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isEditable, setIsEditable] = useState(location?.state?.viewtype === 'edit' ? true : false);
    const [disabled, setDisable] = useState(location?.state?.disabled);
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [userRights, setUserRights] = useState(false)

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === '/JobVacancy');

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        };

        setUserRights(mid);
    }, [user]);



    const getDropDownOption = async () => {
        const dropDownData = await getAllData('/master/v1/binddropdown');
        if (dropDownData.status) {
            let data = { ...dropDownOption }
            Object.entries(dropDownData?.data)?.forEach(dropDown => {
                const label = getlabelWise(dropDown[0]);
                if (label?.length > 0) {
                    data[dropDown[0]] = createSelectedOption(dropDown[1], label[0], label[1])
                }
            });

            setDropDownOption(data)
        }
    };

    const getlabelWise = (type) => {
        switch (type) {
            case 'departmentCollection':
                return ['departmentId', 'departmentName']
            case 'experienceCollection':
                return ['expId', 'expName']
            case 'qualificationCollection':
                return ['quaId', 'quaName']
            case 'shiftCollection':
                return ['shiftId', 'shiftName']
            case 'stateCollection':
                return ['stateId', 'stateName']
            case 'departmentCollection':
                return ['departmentId', 'departmentName']
            default:
                return false
        }
    }

    const createSelectedOption = (option, valueId, displayLable) => {
        return option?.reduce(
            (acc, dropDownoption) => {
                acc.push({ value: dropDownoption[valueId], label: dropDownoption[displayLable] })
                return acc;
            }, [],
        );
    }

    const getDropDownCity = async (stateId) => {
        const dropDownCityOption = await getAllData(`/master/v1/bindlocationbystate?stateId=${stateId}`);
        if (dropDownCityOption.status) {
            let data = { ...cityOption }
            data = createSelectedOption(dropDownCityOption?.data?.locationCollection, 'locationId', 'city');
            setCityOption(data)
        }
    }

    const retriveJob = async (stateId) => {
        const selectedJob = await getAllData(`/master/v1/getjobvacancybyid?jobVacancyId=${stateId}`);
        if (selectedJob.status) {

            const jobDetails = selectedJob?.data[0];
            let object = {
                UserId: userdata?.userMasterID,
                JobVacancyId: jobDetails?.JobVacancyId,
                StateId: jobDetails?.StateId,
                LocationId: jobDetails?.LocationId,
                DepartmentId: jobDetails?.DepartmentId,
                ExpId: jobDetails?.ExpId,
                ShiftId: jobDetails?.ShiftId,
                NoOfVacancy: jobDetails?.NoOfVacancy,
                QuaId: jobDetails?.QuaId,
                JobTitle: jobDetails?.JobTitle,
                KeyRoleResp: jobDetails?.KeyRoleResp,
                KeySkills: jobDetails?.KeySkills,
                ApplyLimit: jobDetails?.ApplyLimit,
                IsActive: jobDetails?.IsActive
            }
            setNewJob(object);
        }
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if ((location?.state?.JobVacancyId && (location?.state?.viewtype === 'edit' || location?.state?.viewtype === 'view')) || (location?.state?.viewtype === 'add' || location?.state?.JobVacancyId === null)) {
                getDropDownOption();
                if (location?.state?.viewtype !== 'Add') {
                    retriveJob(location?.state?.JobVacancyId);
                }
            } else {
                navigate('/dashboard');
            }
            const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
            if (richtexteboxheight > 1253) {
                setRichBoxHeight(true)
            }
            else {
                setRichBoxHeight(false)
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        if (newJob?.StateId) {
            getDropDownCity(newJob?.StateId);
        }
    }, [newJob?.StateId])

    const handleOnChangeOfJob = (name, value) => {
        if (name === 'StateId') {
            setNewJob({ ...newJob, [name]: value, LocationId: null })
        } else if (name === "ApplyLimit") {
            const ApplyLimitValue = value.length ? Number(value) : ''
            setNewJob({ ...newJob, [name]: ApplyLimitValue })
        } else {
            setNewJob({ ...newJob, [name]: value });
        }
        setIsEditable(false)
        setError({});
        setIsChanged(true);
    };

    const handleOnQualification = (name, value) => {
        const quaIdMul = value.map((quaId) => quaId.value)
        setNewJob({ ...newJob, [name]: quaIdMul.join(',') })
        setIsEditable(false)
        setError({});
        setIsChanged(true);
    };


    const handleRichaTextEditor = (name, value) => {
        const str = value.replace('<p><br></p>', '')
        // setNewJob({ ...newJob, [name]: value });
        const html = htmlToText(str);
        if (html.length !== htmlToText(newJob?.[name]).length) {
            // handleOnChangeOfJob(name, value)
            setIsEditable(false)
            setIsChanged(true);
        }
        newJob[name] = str
    };

    const policydetailsconfig = useMemo(
        () => ({
            readonly: false,
            height: '100%',
            maxHeight: richboxheight === true ? 200 : 500,
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "image", "link", "select", "video"],
            toolbarAdaptive: false,
            defaultMode: "1",
            disabled: disabled ? true : false
        }),
        [disabled, richboxheight]
    );

    const isCheckIsValidData = () => {
        let errors = {}
        if (!newJob?.StateId) {
            errors['StateId'] = 'Please select State'
        }
        if (!newJob?.LocationId) {
            errors['LocationId'] = 'Please select City'
        }
        if (!newJob?.DepartmentId) {
            errors['DepartmentId'] = 'Please select Department'
        }
        if (!newJob?.JobTitle.trim()) {
            errors['JobTitle'] = 'Please enter the Job Title name'
        } else {
            if (newJob?.JobTitle?.trim().split(' ')?.length < 2) {
                errors['JobTitle'] = 'Please enter atleast 2 words'
            }
        }
        if (!newJob?.ExpId) {
            errors['ExpId'] = 'Please select Experience'
        }
        if (!newJob?.ShiftId) {
            errors['ShiftId'] = 'Please select Shift Time'
        }
        if (!newJob?.NoOfVacancy) {
            errors['NoOfVacancy'] = 'Please select No. of vacancy'
        }
        if (!newJob?.QuaId) {
            errors['QuaId'] = 'Please select Qualification'
        }
        if (!newJob?.ApplyLimit) {
            errors['ApplyLimit'] = 'Please enter the Apply limitation per vacancy'
        }
        if (Object.entries(errors).length > 0) {
            refId.current[Object.entries(errors)[0][0]]?.focus();
        }
        setError(errors)
        return Object.entries(errors).length > 0 ? true : false
    }
    const state = [{ value: 1, label: "Bihar" }, { value: 2, label: "Gujarat" }];

    const addNewJob = async () => {
        const isValid = isCheckIsValidData();
        let statusoftoken = await verifytoken();
        if (!isValid) {
            const removeExtraSpace = newJob.JobTitle.split(' ').reduce((acc, data) => {
                if (data) {
                    acc.push(data.trim())
                }
                return acc
            }, [])
            let newJobTitle = removeExtraSpace.join(' ');
            if (statusoftoken) {
                if (location?.state?.viewtype === 'Add') {
                    const insertJob = await postUsersData('/master/v1/insertjobvacancy', {
                        ...newJob, JobTitle: newJobTitle
                    });
                    if (insertJob?.status) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: insertJob.message })
                    }

                } else if (location?.state?.viewtype === 'edit') {

                    const updateJob = await postUsersData('/master/v1/updatejobvacancy', { ...newJob, JobTitle: newJobTitle });
                    if (updateJob?.status) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: updateJob.message })
                    }
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }

    const handleClearJob = () => {
        setNewJob({
            State: '',
            City: '',
            Department: '',
            Experience: '',
            ShiftTime: '',
            NoOfVacancy: '',
            Qualification: '',
            JobTitle: '',
            KeyRoleResp: '',
            KeySkills: '',
            ApplyLimit: '',
            IsActive: false
        });
        
        // if (document.querySelector('.jodit-wysiwyg') !== null) {
        //     document.querySelector('.jodit-wysiwyg').innerHTML = '';
        // }
        if(document.getElementsByClassName('jodit-wysiwyg') !== null){
            document.getElementsByClassName('jodit-wysiwyg')[0].innerHTML = '';
            document.getElementsByClassName('jodit-wysiwyg')[1].innerHTML = '';
        }
        setError({})
    }
    const handleBackButton = () => {
        if (isChanged) {
            setModalShow(true);
        } else {
            navigate('/JobVacancy');
        }
    };

    const handleBackBtnConfirmation = (action) => {
        if (action) {
            navigate('/JobVacancy');
        } else {
            setModalShow(false);
        }
    };

    const handleIsActive = (value) => {
        if (value === true) {
            handleOnChangeOfJob('IsActive', !newJob.IsActive)
            setIsActiveModel(false);
        } else {
            setIsActiveModel(false)
        }
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>ETM Career List</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div className='row mx-lg-3 mx-md-1'>
                                <div className="col-lg-6 col-sm-6  mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>State</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={dropDownOption?.stateCollection}
                                                selId='StateId '
                                                selRef={(el) => (refId.current.StateId = el)}
                                                fieldid={newJob?.StateId}
                                                SelectionChange={(val) => {
                                                    handleOnChangeOfJob('StateId', val.value);

                                                }}
                                                isdisable={disabled}
                                                // isactivedisable={citydisable}
                                                issearchable={true}
                                            />
                                            {errors.StateId && <div className="text-danger">{errors.StateId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>City</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={cityOption}
                                                selId='LocationId'
                                                selRef={(el) => (refId.current.LocationId = el)}
                                                fieldid={newJob?.LocationId}
                                                SelectionChange={(val) => handleOnChangeOfJob('LocationId', val.value)}
                                                isdisable={disabled}
                                                // isactivedisable={LocationId disable}
                                                issearchable={true}
                                                isMulti={false}
                                            />
                                            {errors.LocationId && <div className="text-danger">{errors.LocationId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Department</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={dropDownOption?.departmentCollection}
                                                selId='DepartmentId'
                                                selRef={(el) => (refId.current.DepartmentId = el)}
                                                fieldid={newJob?.DepartmentId}
                                                SelectionChange={(val) => handleOnChangeOfJob('DepartmentId', val.value)}
                                                isdisable={disabled}
                                                // isactivedisable={DepartmentIddisable}
                                                issearchable={true}
                                                isMulti={false}
                                            />
                                            {errors.DepartmentId && <div className="text-danger">{errors.DepartmentId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Job Title</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <input class="form-control"
                                                type="text"
                                                id='JobTitle'
                                                name="JobTitle"
                                                ref={(el) => (refId.current.JobTitle = el)}
                                                value={newJob?.JobTitle}
                                                onChange={(e) => handleOnChangeOfJob('JobTitle', e.target.value)}
                                                disabled={disabled ? true : false}
                                                maxLength={150}
                                                placeholder='Job Title'
                                            />
                                            {errors.JobTitle && <div className="text-danger">{errors.JobTitle} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Experience</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={dropDownOption?.experienceCollection}
                                                selId='ExpId'
                                                selRef={(el) => (refId.current.ExpId = el)}
                                                fieldid={newJob?.ExpId}
                                                SelectionChange={(val) => handleOnChangeOfJob('ExpId', val.value)}
                                                isdisable={disabled}
                                                // isactivedisable={ExpId disable}
                                                issearchable={true}
                                            />
                                            {errors.ExpId && <div className="text-danger">{errors.ExpId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Shift Time</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={dropDownOption?.shiftCollection}
                                                selId='ShiftId '
                                                selRef={(el) => (refId.current.ShiftId = el)}
                                                fieldid={newJob?.ShiftId}
                                                SelectionChange={(val) => handleOnChangeOfJob('ShiftId', val.value)}
                                                isdisable={disabled}
                                                // isactivedisable={Experiencedisable}
                                                issearchable={true}
                                            />
                                            {errors.ShiftId && <div className="text-danger">{errors.ShiftId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 mb-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>No. of Vacancy</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <CustomSelection
                                                datalist={NoOfVacancyOption}
                                                selId='NoOfVacancy'
                                                selRef={(el) => (refId.current.NoOfVacancy = el)}
                                                fieldid={newJob?.NoOfVacancy}
                                                SelectionChange={(val) => handleOnChangeOfJob('NoOfVacancy', val.value)}
                                                isdisable={disabled}
                                                // isactivedisable={Experiencedisable}
                                                issearchable={true}
                                            />
                                            {errors.NoOfVacancy && <div className="text-danger">{errors.NoOfVacancy} </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Qualification</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <Select
                                                id='QuaId'
                                                ref={(el) => (refId.current.QuaId = el)}
                                                isMulti
                                                className="dropdown select_dropdown"
                                                options={dropDownOption?.qualificationCollection}
                                                value={dropDownOption?.qualificationCollection?.length > 0 && dropDownOption?.qualificationCollection?.filter((qul) => newJob?.QuaId?.includes(qul.value))}// set list of the data
                                                onChange={(val) => handleOnQualification('QuaId', val)} // assign onChange function
                                                isDisabled={disabled}
                                                isSearchable={true}
                                                //  styles={customStyles}
                                                isClearable={true}
                                            //  placeholder={placeholder}

                                            />
                                            {errors.QuaId && <div className="text-danger">{errors.QuaId} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6  mb-5 ">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label required'>Apply Limitation</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1">
                                            <input class="form-control"
                                                type="text"
                                                id='ApplyLimit'
                                                name="ApplyLimit"
                                                ref={(el) => (refId.current.ApplyLimit = el)}
                                                value={newJob?.ApplyLimit}
                                                onChange={(e) => handleOnChangeOfJob('ApplyLimit', onlyNumeric(e.target.value))}
                                                disabled={disabled ? true : false}
                                                maxLength={150}
                                                placeholder='Apply Limitation'
                                            />

                                            {errors.ApplyLimit && <div className="text-danger">{errors.ApplyLimit} </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 mb-5">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label '>Key Role Responsibilities</label>
                                        </div>
                                        <div className="col-lg-10 col-md-12 col-sm-12 mb-lg-1">
                                            <RichTextBox field="KeyRole"
                                                fieldvalue={newJob.KeyRoleResp}
                                                tabIndex={1}
                                                OnChangeofRichBox={(value) => { handleRichaTextEditor('KeyRoleResp', value) }}
                                                textconfig={policydetailsconfig}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 mb-5">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <label className='col-form-label'>Key Skill</label>
                                        </div>
                                        <div className="col-lg-10 col-md-12 col-sm-12 mb-lg-1">
                                            <RichTextBox field="KeySkills "
                                                fieldvalue={newJob.KeySkills}
                                                tabIndex={1}
                                                OnChangeofRichBox={(value) => { handleRichaTextEditor('KeySkills', value) }}
                                                textconfig={policydetailsconfig}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-12 mb-lg-1">
                                            <label className='col-form-label'>Active</label>
                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-12 mb-lg-1">
                                            <Switch
                                                checked={newJob?.IsActive}
                                                name='IsActive'
                                                className="react-switch"
                                                value={newJob.IsActive}
                                                onChange={(e) => {

                                                    if (location?.state?.viewtype === 'Add') {
                                                        handleOnChangeOfJob('IsActive', !newJob?.IsActive)
                                                    }
                                                    else {
                                                        setIsActiveModel(true)
                                                    }
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="d-flex justify-content-center mt-3">
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd"
                                onClick={addNewJob}
                                disabled={isEditable || location?.state?.viewtype === 'view' ? true : false}
                            >{location?.state?.viewtype === 'edit' ? 'Update' : 'Add'}</button>
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd"
                                onClick={handleClearJob}
                                disabled={(location?.state?.viewtype === 'view' || location?.state?.viewtype === 'edit') ? true : false}
                            >Clear</button>
                            <button type="button" class="btn btn-primary mx-1" id="btnClear"
                                onClick={handleBackButton}
                            // disabled={location?.state?.viewtype === 'view' ? true : false}
                            >Back</button>
                        </div>
                    </div>
                </form>
                <ConfirmationModal
                    show={isActiveModel}
                    onHide={() => setIsActiveModel(false)}
                    noconfirm={() => handleIsActive(false)}
                    yesconfirm={() => handleIsActive(true)}
                />

                <PopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    noconfirm={() => handleBackBtnConfirmation(false)}
                    yesconfirm={() => handleBackBtnConfirmation(true)}
                />
            </div>
        </>
    )
}

export default CreateNewJobVacancy