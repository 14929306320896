import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RestrictionsModal = (props) => {
    const { isEdit, selectedCellDate, RoomAvailabilityDates } = props;
    useEffect(() => {
        if (selectedCellDate !== null) {
            setDateRangeValue(`${formatDate(new Date(selectedCellDate))} - ${formatDate(new Date(selectedCellDate))}`)
        }
    }, [selectedCellDate])
    const [formValue, setFormValue] = useState({
        id: 1,
        RoomType: null,
        FromDate: selectedCellDate || null,
        ToDate: selectedCellDate || null,
        Days: "",

        MinLengthStay: "",
        MaxLengthStay: "",
        MinOffset: "",
    })
    const roomtype = [
        { id: 1, label: "Single" },
        { id: 2, label: "Double" }
    ]
    const mealtype = [
        { id: 1, label: "Meal 1" },
        { id: 2, label: "Meal 2" }
    ]
    const days = [
        { id: 1, label: "Day 1" },
        { id: 2, label: "Day 2" }
    ]

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? "" : [day, month, year].join('/');
    }


    const onChange = (name, value) => {
        setFormValue(form => ({ ...form, [name]: value }))
    }

    const [dateRangeValue, setDateRangeValue] = useState('')

    const handleDateRange = (update, isEdit) => {
        console.log(update, 'update')
        if (isEdit) {
            setFormValue(form => ({ ...form, FromDate: update }))
            setDateRangeValue(`${formatDate(update)} - ${formatDate(update)}`)
        }
        else {
            setFormValue(form => ({ ...form, FromDate: update[0], ToDate: update[1] }))
            setDateRangeValue(`${formatDate(update[0])} - ${formatDate(update[1])}`)
        }
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };


    return (
        <>
            <form action="" className='py-2'>
                <div className='row'>
                    {
                        !isEdit &&
                        <div className="col-12 mb-4">
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={roomtype}
                                    id="select-roomtype"
                                    value={roomtype?.find((obj) => obj.id === formValue.RoomType) || null}
                                    name='RoomType'
                                    onChange={(e, newValue) => onChange("RoomType", newValue.id)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Room Type" variant="filled"
                                            className={`${formValue.RoomType <= 0 ? 'red_border' : ''}`}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                    }

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-12 lengthfull">
                                <div className="text-container">
                                    <TextField
                                        fullWidth
                                        id="select-date"
                                        variant='filled'
                                        // label={`${isEdit ? "Select Date" : "Date Range (From-To)"}`}
                                        label="Select Date"
                                        value={dateRangeValue}
                                        onClick={(e) => openDatePicker("selectdate")}
                                        disabled={selectedCellDate !== null}
                                        className={`${(isEdit ? formValue.FromDate !== null : (formValue.FromDate !== null && formValue.ToDate !== null)) ? '' : 'red_border'}`}
                                    />
                                    <div>
                                        {
                                            isEdit ?
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue.FromDate}
                                                    placeholderText="Select Date"
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue.FromDate}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                                    maxDate={new Date(RoomAvailabilityDates.EndDate)}
                                                />
                                                :
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue.FromDate}
                                                    placeholderText="Date Range (From-To)"
                                                    selectsRange={true}
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue.FromDate}
                                                    endDate={formValue.ToDate}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                                    maxDate={new Date(RoomAvailabilityDates.EndDate)}
                                                />
                                        }

                                    </div>
                                    <span className={`example-custom-input bulkCalendarBtn ${selectedCellDate !== null ? 'disableCalBtn' : ''}`}
                                        onClick={(e) => openDatePicker("select-date")}
                                    ><i className="fa fa-calendar"></i></span>
                                </div>
                            </div>

                            {/* <div className="col-6">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={days}
                                        id="select-days"
                                        value={days?.find((obj) => obj.id === formValue.Days) || null}
                                        name='Days'
                                        onChange={(e, newValue) => onChange("Days", newValue.id)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Days" variant="filled" />
                                        )}
                                    />
                                </FormControl>
                            </div> */}

                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="minimum-length-of-stay"
                                    variant='filled'
                                    label="Minimum Length of Stay"
                                    name='MinLengthStay'
                                    value={formValue.MinLengthStay}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="maximum-length-of-stay"
                                    variant='filled'
                                    label="Maximum Length of Stay"
                                    name='MaxLengthStay'
                                    value={formValue.MaxLengthStay}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12 mb-4">
                        <TextField
                            fullWidth
                            id="minimum-advance-booking-offset"
                            variant='filled'
                            label="Minimum Advance Booking Offset"
                            name='MinOffset'
                            value={formValue.MinOffset}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                        />
                    </div> */}

                    <div className="col-12">
                        <button type="button" className='btn bulk_apply_btn btn-primary w-100'>Apply</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default RestrictionsModal
