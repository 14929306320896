import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
// import exportFromJSON from 'export-from-json'
import { generatePDF } from '../../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomTable from '../../../customControl/CustomTable';


const Inclusionmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // var store = useContext(ReactReduxContext).store.getState();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [InclusionerrorMessage, setInclusionErrorMessage] = useState('');
    const [InclusionduplicateerrorMessage, setInclusionDuplicateErrorMessage] = useState('');
    const [formValue, setFormValue] = useState(
        {
            InclusionId: 0,
            InclusionName: '',
            IsActive: false,
        }
    );
    const [inclusionlistdata, setInclusionListData] = useState([]);

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('3');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const serachoption = [
        {
            label: 'Inclusion Name',
            value: '1'
        },
        {
            label: 'Active',
            value: '2'
        },
        // {
        //     label: 'Created Date',
        //     value: '4'
        // },
        {
            label: 'All',
            value: '3'
        },

    ];


    ///////////////////get list data///////////////////////////////////////////////////
    //#region List data
    const getInclusionDataList = async () => {
        const InclusionListdata = await getAllData("/master/v1/getallinclusion");
        setInclusionListData(InclusionListdata.data);
    }
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getInclusionDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    const columns = [

        {
            name: 'Inclusion Name',
            sortable: true,
            float: "left",
            selector: (inclusion) => inclusion.inclusionName,
            // style:  { backgroundColor: 'green' }
        },


        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (inclusion) => inclusion.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (inclusion) => formatDate(inclusion.createdDate),
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Edit',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.destinationId)}> <i className="fa fa-edit"></i></td >
            selector: (inclusion) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(inclusion.inclusionId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.destinationId)}><i className="fa fa-eye"></i></td>
            selector: (inclusion) => <i className="fa fa-eye" onClick={() => handleViewMode(inclusion.inclusionId)}></i>
        }
    ];


    //#endregion
    //////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section

    useEffect(() => {

    }, [InclusionerrorMessage]);


    useEffect(() => {

    }, [buttonaddeditText]);
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                if (!validateData()) {
                    return;
                }
                else {
                    // if ((typeof formValue.Inclusiontext === 'string' && formValue.Inclusiontext.trim().length > 0) ) {
                    let filterdata;
                    filterdata = inclusionlistdata ? inclusionlistdata?.filter(issec => issec.inclusionName.toLowerCase() === formValue.InclusionName.toLowerCase()) : [];
                    if (filterdata?.length > 0) {
                        setInclusionDuplicateErrorMessage('You can not enter duplicate value.');

                        return;
                    }
                    if (statusoftoken) {
                        const InclusionData = await postUsersData("/master/v1/insertinclusion", formValue);
                        //setInclusionListData(InclusionData.data);
                        if (!InclusionData.status && !InclusionData.data) {
                            setInclusionDuplicateErrorMessage(InclusionData.message);
                            return;
                        }
                        if (InclusionData.status) {
                            if (InclusionData.data > 0) {
                                //setitems();
                                dispatch({ type: 'ALERTING', message: InclusionData.message })
                                // alert('Inclusion master Record saved successfully');
                                //window.location.reload(false);
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: InclusionData.message })
                                // alert('Inclusion master Record not saved successfully')
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (!validateData()) {
                    return;
                }
                else {
                    let filterdata;
                    filterdata = inclusionlistdata ? inclusionlistdata?.filter(issec => issec.inclusionId !== formValue.InclusionId && issec.inclusionName.toLowerCase() === formValue.InclusionName.toLowerCase()) : [];
                    if (filterdata?.length > 0) {
                        setInclusionDuplicateErrorMessage('You can not enter duplicate value.');
                        return;
                    }
                    if (statusoftoken) {
                        const InclusionData = await patchUsersData("/master/v1/updateInclusion", formValue);
                        //setInclus(Inclusion.data);

                        if (!InclusionData.status && !InclusionData.data) {
                            setInclusionDuplicateErrorMessage(InclusionData.message);
                            return;
                        }
                        if (InclusionData.status) {
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            // alert('Inclusion master Record updated successfully');
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }
    const validateData = () => {
        if (typeof formValue.InclusionName === 'string' && formValue.InclusionName?.trim()?.length === 0) {
            setInclusionErrorMessage('Please enter Inclusion Name');
            document.getElementById('txtinclusion').focus()
            return false;
        }
        if (typeof formValue.InclusionName === 'string' && formValue.InclusionName?.trim()?.length > 500) {
            setInclusionErrorMessage('Maximum length allowed for inclusion is 500 characters.');
            document.getElementById('txtinclusion').focus()
            return false;
        }
        return true;
    }
    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        //setFormValue({ ...formValue,['InclusionId'] : 0, ['InclusionName']: '', ['IsActive']: false });

        setInclusionErrorMessage('');
        setInclusionDuplicateErrorMessage('');


        inclusionlistdata?.map(obj => {
            if (obj.inclusionId === id) {
                // let active = !obj.isActive;
                formValue.InclusionId = obj.inclusionId
                formValue.InclusionName = obj.inclusionName
                formValue.IsActive = obj.isActive
                setActiveId(obj.isActive);
            }
        });
        setFormValue({ ...formValue, formValue })
    }
    useEffect(() => { }, [formValue]);
    //#region image upload
    const onChange = (e) => {
        setEditDisabled(true);
        setInclusionErrorMessage('');
        setInclusionDuplicateErrorMessage('');
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setInclusionErrorMessage('');
            setInclusionDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setInclusionErrorMessage('');
            setInclusionDuplicateErrorMessage('');
        }
        setModalShow(false);
    }
    //#endregion

    //#region handle clear operation
    const handleClearClick = () => {
        // window.location.reload(true);
        clear();
    }
    function clear() {

        setFormValue({ ...formValue, InclusionId: 0, InclusionName: '', IsActive: false });
        setActiveId(false);
        //{ formValue.InActive = false}
        getInclusionDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setInclusionErrorMessage('');
        setInclusionDuplicateErrorMessage('');
        setSerachDisable(false);
        setSerachBy('3');
        setSearchValue('');
        setsclshow(false)
    }
    //#endregion

    //#region handle pdf and excel import
    const PdfExport = () => {
        //print
        const columns = [
            "Inclusion Name",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < inclusionlistdata?.length; i++) {
            var temp = [
                inclusionlistdata[i].inclusionName,
                `${inclusionlistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(inclusionlistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "InclusionMaster", "Inclusion Master")
    }

    const ExportToExcel = () => {
        const dispalyname = ['Inclusion Name', 'Active', 'Created Date'];
        const fields = ['inclusionName', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, inclusionlistdata, "Inclusion", "InclusionMaster.xls");
    }
    //#endregion

    //#region handle search value
    const setitems = async () => {
        const InclusionListData = await getAllData("/master/v1/getallinclusion");
        setFilterItems(InclusionListData.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getInclusionDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                if (searchvalue.toLocaleLowerCase() === 'active'.toLocaleLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLocaleLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }

            }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.inclusionName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setInclusionListData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getInclusionDataList();
    }
    //#endregion
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Inclusion Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        {InclusionduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {InclusionduplicateerrorMessage} </div>}
                                        <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Inclusion Name</label>
                                        </div>
                                        <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <textarea type="Rich Text Box" rows={2} id="txtinclusion" class="form-control"
                                                name="InclusionName"
                                                value={formValue.InclusionName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Inclusion"
                                            >
                                            </textarea>
                                            {InclusionerrorMessage && <div className="text-danger"> {InclusionerrorMessage} </div>}
                                        </div>
                                        <div class="col-lg-3 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-6" disabled={false}>

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={inclusionlistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div >
        </>
    )
}

export default Inclusionmaster