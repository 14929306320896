import React, { useState } from 'react'
import '../assets/css/commoncontrol.css'

function CountComponent({ count = 0, getCount = () => { }, option = {}, id }) {

    const handleCounte = (type, data) => {
        let counters = Number(count);
        if (type && counters < data.MaxValue) {
            counters = counters + 1
        } else if (!type && counters > data.MinValue) {
            counters = counters - 1
        }
        getCount(counters)
    }

    return (
        <div key={id || option.Id} className='d-flex justyfy-content-center align-items-center my-1 w-fit rounded-pill counter_btn_main px-2 py-1'>
            <button type="button"
                className='rounded-circle counter_btn border-0'
                style={{ color: "#ffffff", backgroundColor: "#0777A9" }}
                onClick={(e) => handleCounte(false, option)}
                disabled={count === option.MinValue ? true : false}
            >
                <i className="fa fa-minus" aria-hidden="true"></i>
            </button>

            <label className={`text-center  fs-5  px-2`} style={{ width: '40px', color: "#000000" }}>{count}</label>

            <button type="button"
                className='rounded-circle counter_btn border-0'
                style={{ color: "#ffffff", backgroundColor: "#0777A9" }}
                onClick={(e) => handleCounte(true, option)}>
                <i className="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    )
}
export default CountComponent