import React from "react";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from "react-select";
import "../../../assets/css/master.css";
import { getAllData } from "../../../services/ApiCallingUserServices";
import { generatePDF } from "../../commonClass/CommonValidation";
import { useNavigate } from "react-router-dom";
import { ClearLocalStorage, GetLocalStorage } from "../../commonClass/Session";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from "../../commonClass/ExportExcel";
import CustomTable from "../../../customControl/CustomTable";

const Tripplannerlist = () => {
  const dispatch = useDispatch();
  /////////////////////declartion of variable ///////////////////////////////////////
  //#region declartion of variable
  const navigate = useNavigate();
  const [tripplannerdata, setTripPlannerData] = useState([]);
  const [searchvalue, setSearchValue] = useState();
  const [searchby, setSerachBy] = useState("7");
  const [serchdisable, setSerachDisable] = useState(false);
  const [filteritems, setFilterItems] = useState([]);
  const [clrshow, setsclshow] = useState(false);
  const user = useSelector((store) => store.userstatus.tba);
  const [useradd, setUserAdd] = useState(true);
  const [useredit, setUserEdit] = useState(true);
  const [userexport, setUserExport] = useState(true);

  const serachoption = [
    {
      label: "Services Code",
      value: "1",
    },
    {
      label: "Service Type",
      value: "2",
    },
    {
      label: "State",
      value: "3",
    },
    {
      label: "City",
      value: "4",
    },
    {
      label: "Active",
      value: "5",
    },
    // {
    //   label: "Created Date",
    //   value: "6",
    // },
    {
      label: "All",
      value: "7",
    },
  ];
  //#endregion
  /////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////set form date for add, edit and view////////////////////

  //////////////////////////////use effect section //////////////////////////////////
  //#region  use effect section
  useEffect(() => {
    dispatch(loaderActions.start());
    let token = GetLocalStorage();
    if (token && token.userName && token.token) {
      getTripPlannerList().then(() => {
        dispatch(loaderActions.end());
      });
      setitems();
    } else {
      ClearLocalStorage();
      dispatch({
        type: "SESSIONOUT",
        message: "Your Session Expired. Please Login Again!",
      });
    }
  }, []);

  useEffect(() => {
    let pagelink = user?.find((x) => {
      if (x.PageLink === window.location.pathname) return x.MenuId;
    });
    let mid = user?.find((x) => x.MenuId === pagelink.MenuId);

    if (mid?.URightView === false && mid?.URightView !== undefined) {
      navigate("/dashboard");
    }
    user
      ?.filter((x) => x.MenuId === pagelink.MenuId)
      .map((obj) => {
        setUserAdd(obj.URightAdd);
        setUserEdit(obj.URightEdit);
        setUserExport(obj.URightExport);
      });
  }, [user]);
  //#endregion
  //////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////fill dropdown and handle input value//////////////////////////
  //#region fill dropdown and handle input value

  const getTripPlannerList = async () => {
    const tripplannerdata = await getAllData("/master/v1/getallplannerservice");
    setTripPlannerData((tripplannerdata.data !== null && tripplannerdata.data !== undefined) ? tripplannerdata.data : [])
  };

  //#endregion
  /////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////serch and export sction////////////////////////////////////////////
  //#region handling search

  const handleSearchBy = (e) => {
    setSerachBy(e.value);
  };

  const setitems = async () => {
    const tripplannerdata = await getAllData("/master/v1/getallplannerservice");

    setFilterItems(tripplannerdata.data);
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
    setsclshow(true);
    if (e.target.value === "") {
      getTripPlannerList();
      setsclshow(false);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleClickSearchValue();
    }
  };

  const handleClickSearchValue = () => {
    if (
      typeof searchvalue === "string" &&
      searchvalue?.trim()?.length !== 0 &&
      searchby > 0
    ) {
      setitems();
      var filteritem = filteritems;
      var filterdata;
      if (searchby === "1") {
        filterdata = filteritem?.filter((issec) =>
          issec.ServiceCode.toLowerCase().includes(searchvalue.toLowerCase())
        );
      } else if (searchby === "2") {
        filterdata = filteritem?.filter((issec) =>
          issec.PlannerServiceType.toLowerCase().includes(
            searchvalue.toLowerCase()
          )
        );
      } else if (searchby === "3") {
        filterdata = filteritem?.filter((issec) =>
          issec.StateName.toLowerCase().includes(searchvalue.toLowerCase())
        );
      } else if (searchby === "4") {
        filterdata = filteritem?.filter((issec) =>
          issec.City.toLowerCase().includes(searchvalue.toLowerCase())
        );
      }
      //    else if (searchby === "6") {
      //     filterdata = filteritem?.filter((issec) => {
      //       formatDate(issec.CreatedOn)
      //         .toLowerCase()
      //         .includes(searchvalue.toLowerCase());
      //     });
      //}
      else if (searchby === "5") {
        if (searchvalue.toLowerCase() === "active".toLowerCase()) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === true);
        } else if (searchvalue.toLowerCase() === "inactive".toLowerCase()) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === false);
        } else if ("active".toLowerCase().includes(searchvalue.toLowerCase())) {
          filterdata = filteritem?.filter(
            (issec) => issec.IsActive === true || issec.IsActive === false
          );
        } else if (
          "inactive".toLowerCase().includes(searchvalue.toLowerCase())
        ) {
          filterdata = filteritem?.filter((issec) => issec.IsActive === false);
        } else {
          filterdata = filteritem?.filter((issec) =>
            formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^")
          );
        }
      } else if (searchby === "7") {
        if (searchvalue.toLowerCase() === "active".toLowerCase()) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.ServiceCode.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.PlannerServiceType.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.StateName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.City.toLowerCase().includes(searchvalue.toLowerCase()) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === true
          );
        } else if (searchvalue.toLowerCase() === "inactive".toLowerCase()) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.ServiceCode.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.PlannerServiceType.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.StateName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.City.toLowerCase().includes(searchvalue.toLowerCase()) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === false
          );
        } else if ("active".toLowerCase().includes(searchvalue.toLowerCase())) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.ServiceCode.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.PlannerServiceType.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.StateName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.City.toLowerCase().includes(searchvalue.toLowerCase()) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === true ||
              issec.IsActive === false
          );
        } else if (
          "inactive".toLowerCase().includes(searchvalue.toLowerCase())
        ) {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.ServiceCode.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.PlannerServiceType.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.StateName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.City.toLowerCase().includes(searchvalue.toLowerCase()) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              issec.IsActive === false
          );
        } else {
          filterdata = filteritem?.filter(
            (issec) =>
              issec.ServiceCode.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.PlannerServiceType.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.StateName.toLowerCase().includes(
                searchvalue.toLowerCase()
              ) ||
              issec.City.toLowerCase().includes(searchvalue.toLowerCase()) ||
              formatDate(issec.CreatedOn)
                .toLowerCase()
                .includes(searchvalue.toLowerCase()) ||
              "active".toLowerCase().includes(searchvalue.toLowerCase()) ||
              "inactive".toLowerCase().includes(searchvalue.toLowerCase())
          );
        }
      }

      setTripPlannerData(filterdata);
    }
  };

  function PdfExport() {
    //print
    const columns = [
      "Services Code",
      "Service Type",
      "State",
      "City",
      "Created Date",
      "Active",
    ];
    var rows = [];

    for (let i = 0; i < tripplannerdata?.length; i++) {
      var temp = [
        tripplannerdata[i].ServiceCode,
        tripplannerdata[i].PlannerServiceType,
        tripplannerdata[i].StateName,
        tripplannerdata[i].City,
        formatDate(tripplannerdata[i].CreatedOn),
        `${tripplannerdata[i].IsActive ? "Active" : "InActive"}`,
      ];
      rows = [...rows, temp];
    }
    generatePDF(columns, rows, "TripPlannerMaster", "Trip Planner Master", "l");
  }

  const ExportToExcel = () => {
    const dispalyname = [
      "Services Code",
      "Service Type",
      "State",
      "City",
      "Created Date",
      "Active",
    ];
    const fields = [
      "ServiceCode",
      "PlannerServiceType",
      "StateName",
      "City",
      "CreatedOn",
      "IsActive",
    ];
    ExportExcel(
      dispalyname,
      fields,
      tripplannerdata,
      "Trip Planner Master",
      "TripPlannerMaster.xls"
    );
  };
  //#endregion
  //////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////handle table Pagination///////////////////////////////////
  //#region table Pagination
  const columns = [
    {
      name: "Services Code",
      sortable: true,
      float: "left",
      reorder: true,
      selector: (supplier) => supplier.ServiceCode,
    },
    {
      name: "Service Type",
      sortable: true,
      float: "left",
      reorder: true,
      selector: (supplier) => supplier.PlannerServiceType,
    },
    {
      name: "State",
      sortable: true,
      float: "left",
      reorder: true,
      selector: (supplier) => supplier.StateName,
    },
    {
      name: "City",
      sortable: true,
      float: "left",
      reorder: true,
      selector: (supplier) => supplier.City,
    },
    {
      name: "Created Date",
      sortable: true,
      float: "left",
      selector: (supplier) => formatDate(supplier.CreatedOn),
    },
    {
      name: "Active",
      sortable: true,
      float: "left",
      selector: (supplier) => (supplier.IsActive ? "Active" : "InActive"),
    },

    {
      name: "Edit",
      // float:"right",
      selector: (supplier) => (
        <button
          type="button"
          className="border-0 p-0 bg-body set_disableclr"
          disabled={!useredit}
        >
          <i
            className="fa fa-edit"
            onClick={() => handleEditTrip(supplier.PlannerId)}
          ></i>
        </button>
      ),
    },
    {
      name: "View",
      // float:"right",
      selector: (supplier) => (
        <i
          className="fa fa-eye"
          onClick={() => handleViewTrip(supplier.PlannerId)}
        ></i>
      ),
    },
  ];

  const clearsearch = () => {
    setSearchValue("");
    setsclshow(false);
    getTripPlannerList();
  };
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////

  const handleOpenTrip = () => {
    navigate("/tripplanner", {
      state: {
        disabled: false,
        editdisabled: false,
        viewtype: "Add",
        tripplannerId: 0,
      },
    });
  };

  const handleEditTrip = (id) => {
    let supplierId = 0;

    tripplannerdata?.map((obj) => {
      supplierId = obj.supplierId;
    });
    navigate("/tripplanner", {
      state: {
        disabled: false,
        editdisabled: true,
        viewtype: "Update",
        tripplannerId: id,
        cleardisable: true,
      },
    });
  };

  const handleViewTrip = (id) => {
    let supplierId = 0;

    tripplannerdata?.map((obj) => {
      supplierId = obj.supplierId;
    });
    navigate("/tripplanner", {
      state: {
        disabled: true,
        editdisabled: true,
        viewtype: "View",
        tripplannerId: id,
      },
    });
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  return (
    <>
      <div class="container-fluid mt-4 heading_color">
        <h3>Trip Planner List</h3>
      </div>
      <div class="container-fluid form_border py-5 my-3">
        <form action="">
          <div class="d-flex justify-content-start ms-lg-3">
            <button
              type="button"
              disabled={!useradd}
              class="btn btn-primary mx-1"
              to="Suppliermaster"
              id="btnAdd"
              onClick={handleOpenTrip}
            >
              Create Trip Planner
            </button>
          </div>
          <br />
          <div class="row">
            <div className="d-lg-flex d-md-flex d-sm-block justify-content-end">
              <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                <Select
                  className="dropdown"
                  options={serachoption}
                  value={serachoption?.filter(function (serachoption) {
                    return serachoption.value === searchby;
                  })} // set list of the dataalue
                  onChange={handleSearchBy} // assign onChange function
                  isDisabled={serchdisable ? true : false}
                  isSearchable={false}
                />
              </div>
              <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                <div className="clrbutton">
                  <input
                    onKeyDown={onKeyDownHandler}
                    type="text"
                    id="btnSearchText"
                    class="form-control"
                    placeholder="Enter keyword"
                    name="SearchValue"
                    onChange={handleChangeSearchValue}
                    value={searchvalue}
                    disabled={serchdisable ? true : false}
                  />
                  <button
                    className={`${clrshow === true ? "d-block" : "d-none"}`}
                    disabled={serchdisable ? true : false}
                    onClick={clearsearch}
                    type="reset"
                  >
                    &times;
                  </button>
                </div>
              </div>
              <div class="p-2 text-center">
                <button
                  type="button"
                  class="btn btn-primary mx-1"
                  id="btnSearch"
                  disabled={serchdisable ? true : false}
                  onClick={handleClickSearchValue}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          <CustomTable
            columns={columns}
            data={tripplannerdata}
            PdfExport={PdfExport}
            ExportToExcel={ExportToExcel}
            disabled={!userexport}
          />

        </form>
      </div>
    </>
  );
};

export default Tripplannerlist;
