import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css";
import './Partial/Hotelmaster.css';
import { getAllData, verifytoken } from '../../../services/ApiCallingUserServices';
import { generatePDF } from '../../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import CustomTable from '../../../customControl/CustomTable';


const Hotellistmaster = () => {
    const dispatch = useDispatch();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const navigate = useNavigate();
    const [hotellistD, setHotelList] = useState([]);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('7');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Hotel Name',
            value: '1'
        },
        {
            label: 'City',
            value: '2'
        },
        {
            label: 'Type',
            value: '3'
        },
        // {
        //     label: 'Created Date',
        //     value: '8'
        // },
        {
            label: 'All',
            value: '7'
        },

    ];

    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section
    useEffect(() => {
        let token = GetLocalStorage();
        dispatch(loaderActions.start());
        if (token && token.userName && token.token) {
            gethoteldatalist().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    useEffect(() => {
        if (window.location.pathname !== '/dashboard') {

            let pagelink = user?.find(x => {
                if (x.PageLink === window.location.pathname)
                    return x.MenuId
            });
            let mid = user?.find(x => x.MenuId === pagelink.MenuId);
            if (mid?.URightView === false && mid?.URightView !== undefined) {
                navigate('/dashboard');
            }
            user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
                setUserAdd(obj.URightAdd);
                setUserEdit(obj.URightEdit);
                setUserExport(obj.URightExport);

            });
        }
    }, [user])
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value


    const gethoteldatalist = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const hotellistD = await getAllData('/master/v1/getallhotellist');
            if (hotellistD.status) {
                setHotelList(hotellistD?.data?.length > 0 ? hotellistD?.data : []);
            }
            else {
                dispatch({ type: 'ALERTING', message: hotellistD.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }



    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////serch and export sction////////////////////////////////////////////
    //#region handling search 

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const hotellistD = await getAllData('/master/v1/getallhotellist');
        setFilterItems(hotellistD.data);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            gethoteldatalist();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.HotelName?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.City?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.HotelType?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => formatDate(issec?.CreatedDate)?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec?.HotelName?.toLowerCase()?.includes(searchvalue?.toLowerCase())
                    || issec?.City?.toLowerCase()?.includes(searchvalue?.toLowerCase())
                    || issec?.HotelType?.toLowerCase()?.includes(searchvalue?.toLowerCase())
                    || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase())
                );

            }
            setHotelList(filterdata)
        }


    }

    function PdfExport() {
        //print
        const columns = [
            "Hotel name",
            "City",
            "Type",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < hotellistD?.length; i++) {
            var temp = [
                hotellistD[i].HotelName,
                hotellistD[i].City,
                hotellistD[i].HotelType,
                formatDate(hotellistD[i].CreatedDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "HotellistMaster", "Hotel List Master")
    }
    const ExportToExcel = () => {
        const dispalyname = ['Hotel name', 'City', 'Type', 'Created Date'];
        const fields = ['HotelName', 'City', 'HotelType', 'CreatedDate'];
        ExportExcel(dispalyname, fields, hotellistD, "HotellistMaster", "HotellistMaster.xls");
    }

    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Hotel Name',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.HotelName,
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.City,
        },
        {
            name: 'Type',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.HotelType,
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (hotel) => formatDate(hotel.CreatedDate),
        },
        {
            name: 'Edit',
            selector: hotel => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(hotel?.HotelBasicId, hotel?.IsChainHotel)}></i></button>
        },
        {
            name: 'View',
            selector: hotel => <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} onClick={() => handleViewMode(hotel?.HotelBasicId, hotel?.IsChainHotel)}><i className="fa fa-eye"></i></td>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        gethoteldatalist();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    const handleOpenSupplier = (type) => {
        localStorage.setItem('type', type)
        if (type === 'chain') {
            navigate(`/${type}/AddChainhotel`, { state: { disabled: false, editdisabled: false, viewtype: 'Add', HotelId: 0, type: type } });
        }
        else {
            navigate(`/HotelListing/${type}/basicinfo`, { state: { disabled: false, editdisabled: false, viewtype: 'Add', HotelId: 0, type: type } });
        }
    }

    const handleEditMode = (id, isChain) => {
        let type = isChain ? 'chain' : 'individual'
        localStorage.setItem('type', type)
        dispatch(loaderActions.start());
        let hotelId = id;

        navigate(`/HotelListing/${type}/basicinfo`, { state: { disabled: false, editdisabled: true, viewtype: 'Update', HotelId: hotelId } });
    }

    const handleViewMode = (id, isChain) => {
        let type = isChain ? 'chain' : 'individual'
        localStorage.setItem('type', type)
        dispatch(loaderActions.start());
        let hotelId = id;

        navigate(`/HotelListing/${type}/basicinfo`, { state: { disabled: true, editdisabled: true, viewtype: 'View', HotelId: hotelId } });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date?.length > 0 ? [day, month, year].join('/') : '';
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Hotel List Master</h3>
            </div>
            <div class="container-fluid form_border py-4 my-3">
                <form action="" >
                    <div className='col-12 mb-2'>
                        <span className='font-light ms-5 text_color_lighter'>Create New Hotel</span>
                    </div>
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button disabled={!useradd} type="button" class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={() => handleOpenSupplier('individual')} >Individual Hotel</button>
                        <button disabled={!useradd} type="button" class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={() => handleOpenSupplier('chain')} >Chain Hotel</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} maxLength={150} id="btnSearchText" class="form-control" name="SearchValue" placeholder='Enter keyword' onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={hotellistD}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default Hotellistmaster