import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumeric, onlyNumeric } from '../../commonClass/CommonValidation';
import validator from 'validator';
import { ImageDelete, ImageUpload } from '../../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomSelection from '../../../customControl/CustomSelection';
import SupplierAccount from '../Suppliermaster/Partial/SupplierAccount';
import SupplierAccountManagement from '../Suppliermaster/Partial/SupplierAccountManagement'
import DatePicker from 'react-datepicker';


const APIconfig = () => {
    const openDatePicker = (e) => {
        document.querySelector("#" + e).click();
    };
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>API Config</h3>
            </div>
            <div className='container-fluid mt-3 tab_section px-0'>
                <ul class="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                        <a class="nav-link active" id="pills-bus-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-bus" type="button" role="tab" aria-controls="pills-about"
                            aria-selected="true"><strong>Bus</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-Flight-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Flight" type="button" role="tab" aria-controls="pills-tips"
                            aria-selected="false"><strong >Flight</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-Hotel-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Hotel" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Hotel</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-Activities-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Activities" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Activities</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-Holidays-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Holidays" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Holidays</strong></a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-Taxi-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-Taxi" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"><strong >Taxi</strong></a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-bus" role="tabpanel"
                    aria-labelledby="pills-bus-tab">
                    <div class="container form_border py-5 my-3">
                        <form action="" >
                            Bus
                        </form>
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Flight" role="tabpanel"
                    aria-labelledby="pills-Flight-tab">
                    <div class="container form_border py-5 my-3">
                        <div class="d-flex justify-content-end mb-4">
                            <button type="button" class="btn btn-primary mx-1" >Add New Supplier</button>
                        </div>
                        <div class="accordion mb-3" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                    <button class="accordion-button collapsed border_set" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                    <div class="accordion-body">
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Accessing Url</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            //disabled={disabled ? true : false}
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Consumer Key</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            //disabled={disabled ? true : false}
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Consumer Secret</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            //disabled={disabled ? true : false}
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-3">
                                                        <label class="col-form-label"></label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <div className='d-flex'>
                                                            <div class="mx-auto">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                                    <label class="form-check-label" for="invalidCheck">
                                                                        Active
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="mx-auto modify_button">
                                                                <button type="button" class="btn btn-primary mx-1" id="btnClear">List of Operator</button>
                                                                <button type="button" class="btn btn-primary mx-1" id="btnClear">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button class="accordion-button collapsed" type="button" id="ItineraryError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div class="accordion-body">
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Accessing Url</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            disabled
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Consumer Key</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            disabled
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-1">
                                                        <label class="col-form-label required">API Consumer Secret</label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <input type="text" id="inputPassword6" class="form-control"
                                                            name="PackageBasicPrice"
                                                            // value={formvalue.PackageBasicPrice}
                                                            // onChange={onChange}
                                                            disabled
                                                            placeholder="Package Basic price"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div class="col-12 mb-3">
                                                        <label class="col-form-label"></label>
                                                    </div>
                                                    <div class="col-12 mb-1">
                                                        <div className='d-flex'>
                                                            <div class="me-3">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                                    <label class="form-check-label" for="invalidCheck">
                                                                        Active
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class=" modify_button">
                                                                <button type="button" class=" mx-2 border-0 p-0 bg-body" id="btnClear" data-bs-toggle="modal" data-bs-target="#operatorModal"><i class="fa fa-2x fa-pencil-square-o" aria-hidden="true"></i></button>
                                                                <button type="button" class=" mx-2 border-0 p-0 bg-body" id="btnClear" data-bs-toggle="modal" data-bs-target="#downloadModal"><i class="fa fa-2x fa-download" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                    <button class="accordion-button collapsed" type="button" id="InclusionError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                    <div class="accordion-body">

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                                    <button class="accordion-button collapsed" id="ExclusionError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                    <div class="accordion-body">

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                                    <button class="accordion-button collapsed" id="PolicyError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                    <div class="accordion-body">

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item mb-3">
                                <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                                    <button class="accordion-button collapsed" type="button" id="AboutPlaceError" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                        TBC
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                                    <div class="accordion-body">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Hotel" role="tabpanel"
                    aria-labelledby="pills-Hotel-tab">
                    <div class="container form_border py-5 my-3">
                        Hotel
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Activities" role="tabpanel"
                    aria-labelledby="pills-Activities-tab">
                    <div class="container form_border py-5 my-3">
                        Activities
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Holidays" role="tabpanel"
                    aria-labelledby="pills-Holidays-tab">
                    <div class="container form_border py-5 my-3">
                        Holidays
                    </div>
                </div>
                <div class="tab-pane fade show" id="pills-Taxi" role="tabpanel"
                    aria-labelledby="pills-Taxi-tab">
                    <div class="container form_border py-5 my-3">
                        Taxi
                    </div>
                </div>
                <div className="modal fade" id="operatorModal" tabIndex="1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header px-1 border-0 inventory_headermodal">
                                <div className="float-start col-11">
                                    <h5 className="modal-title inventory_title text-center ms-5" id="exampleModalLabel">Select Operator</h5>
                                </div>
                                <div className="float-end text-end col-1">
                                    <button type="button" id='closemodal1' className="btn-close closebtn_position" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body px-1">
                                <div className=" row mx-0">
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div class="col-12 mb-1">
                                                <div className='d-flex'>
                                                    <div class="mx-auto">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck" />
                                                            <label class="form-check-label" for="invalidCheck">
                                                                Air India
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="mx-auto modify_button">
                                        <button type="button" class="btn btn-primary mx-1" id="btnClear" data-bs-toggle="modal" data-bs-target="#operatorModal">List of Operator</button>
                                        <button type="button" class="btn btn-primary mx-1" id="btnClear">Save</button>
                                    </div> */}
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button type="button" className="btn btn-primary mx-1" id="btnAdd">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="downloadModal" tabIndex="1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header px-1 border-0 inventory_headermodal">
                                <div className="float-start col-11">
                                    <h5 className="modal-title inventory_title text-center ms-5" id="exampleModalLabel">Download Report</h5>
                                </div>
                                <div className="float-end text-end col-1">
                                    <button type="button" id='closemodal1' className="btn-close closebtn_position" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body px-1">
                                <div className=" row mx-0">
                                    <div className='col-lg-5 col-md-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-4 mb-1">
                                                <label class="col-form-label required">From</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-8">
                                                <div className="text-container">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className='form-control'
                                                        id='startdate'
                                                        // selected={packageValidateStart}
                                                        // onChange={(e) => handleDateChange('PackageValidateStartD', e)}
                                                        // disabled={disabled ? true : false}
                                                        placeholderText="dd/mm/yyyy"
                                                        // onInputClick={true}
                                                        minDate={new Date()}
                                                        maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                    />
                                                    <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("startdate")} id="calendarBtn1"><i className="fa fa-calendar"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-4 mb-1">
                                                <label class="col-form-label required">To</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-8">
                                                <div className="text-container">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className='form-control'
                                                        id='startdate'
                                                        // selected={packageValidateStart}
                                                        // onChange={(e) => handleDateChange('PackageValidateStartD', e)}
                                                        // disabled={disabled ? true : false}
                                                        placeholderText="dd/mm/yyyy"
                                                        // onInputClick={true}
                                                        minDate={new Date()}
                                                        maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                    />
                                                    <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("startdate")} id="calendarBtn1"><i className="fa fa-calendar"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-12 mt-2 mt-lg-0'>
                                        <div className='row'>
                                            <div class="col-lg-12 col-md-12 mb-1 text-center">
                                                <button type="button" className="btn btn-primary mx-auto" id="btnAdd">Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
        </>
    )
}

export default APIconfig