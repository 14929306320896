import React, { useEffect, useMemo, useRef, useState } from 'react'
import CustomSelection from '../../../../customControl/CustomSelection'
import CreateTripSupplier from './CreateTripSupplier'
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { clearerrorfalse, cleartrue, tripSupplierCollectionNo, tripSupplierCollectionYes } from '../../../../_actions/user.action';




const Usergeneratedservice = (props) => {
  const { id, location } = props;
  const {
    disabled,
    editdisabled,
    viewtype,
    tripplannerId
  } = location.state;

  const defaulGenratedService = {
    PlannerServiceTypeId: id,
    FromLocationId: '',
    ToLocationId: '',
    DataCollection: [],
    NightCollection: [],
    IsActive: false
  }

  const defaultNightObj = {
    LocationId: '',
    City: '',
    PlannerNightId: '',
    PlannerNight: '',
    StateName: '',

  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isclearerror = useSelector(store => store.isClear.clearerror);
  const [generatedServices, setGeneratedServices] = useState(defaulGenratedService);
  const [cityOption, setCityOption] = useState([])
  const [numberOfNightOption, setNumberOfNightOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [nightPlanning, setNightPlanning] = useState(defaultNightObj);

  const [isdisabled, setIsDisabled] = useState(false);
  const [isEditdisabled, setIsEditDisabled] = useState(false);
  const [cleardisable, setClearDisabled] = useState(false);
  const [attractionDisabled, setAttractionDisabled] = useState(false);



  const inputRef = useRef({
    FromLocationId: null,
    ToLocationId: null
  });

  const nightPlanningRef = useRef({
    LocationId: null,
    PlannerNightId: null
  })


  useEffect(() => {
    let token = GetLocalStorage();
    // dispatch(loaderActions.start());

    if (token && token.userName && token.token) {
      callApiOnUseEffect();
    } else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }

  }, [])

  const callApiOnUseEffect = async () => {
    const cityData = await getallCity();
    const nigthDataOption = await getNightPlanner();
    if (location?.state != null) {
      setIsDisabled(disabled);
      setIsEditDisabled(editdisabled);
      setClearDisabled(viewtype === 'Add' ? false : true);

      if (tripplannerId) {
        RetriveData(tripplannerId, cityData, nigthDataOption)
      } else {
        setGeneratedServices(defaulGenratedService)
      }
    }
    else {
      navigate('/Tripplannerlist')
    }
  }


  const RetriveData = async (PlannerId, cityData, nigthData) => {
    let token = GetLocalStorage();
    if (PlannerId > 0) {
      const tripData = await postUsersData('/master/v1/getplannerbyid', { PlannerId });
      if (tripData.status) {
        const { PlannerId, PlannerServiceTypeId, FromLocationId, ToLocationId, DataCollection, NightCollection, IsActive } = tripData.data[0]
        const data = {
          PlannerId: PlannerId,
          UserId: token?.userMasterID,
          PlannerServiceTypeId: PlannerServiceTypeId,
          FromLocationId: FromLocationId,
          ToLocationId: ToLocationId,
          DataCollection: DataCollection?.map((el) => ({
            seqNo: el.seqNo,
            SupplierId: el.supplierId,
            SupplierName: el.supplierName,
            SupplierCode: el.supplierCode,
            Sedan: el.sedan,
            Innova: el.innova,
            Tempo: el.tempo,
            Coach: el.coach
          })),
          NightCollection: NightCollection.map((night) => ({
            seqNo: night.seqNo,
            LocationId: night.locationId,
            City: night.city,
            PlannerNightId: night.plannerNightId,
            PlannerNight: nigthData.find((city) => city.value === night.plannerNightId).label,
            StateName: cityData.find((city) => city.value === night.locationId).StateName,
          })),
          IsActive: IsActive
        }
        setGeneratedServices(data)
      }
    }
  }

  const getallCity = async () => {
    const cityData = await getAllData('/master/v1/GetPlannercity');

    const selecteOption = cityData?.data?.filter((city) =>
      city.IsActive).map((city) => ({
        value: city.LocationId,
        StateName: city.StateName,
        label: city.City
      }));

    setCityOption(selecteOption);
    return selecteOption;
  }

  const getNightPlanner = async () => {
    const getNightDetails = await getAllData('/master/v1/getplannernight');

    const numberOfNightOptions = getNightDetails?.data?.filter((night) => night.IsActive)
      .map((night) => ({
        value: night.PlannerNightId,
        label: night.PlannerNight,
        SeqNo: night.SeqNo
      }));

    setNumberOfNightOption(numberOfNightOptions);
    return numberOfNightOptions
  }

  const handleOnChange = (name, value) => {
    setIsEditDisabled(false);
    setErrors({});
    dispatch(tripSupplierCollectionYes());
    dispatch(cleartrue());
    setGeneratedServices({
      ...generatedServices,
      [name]: value
    })
  }

  const handleOnChangeNightPlaning = (val) => {
    setIsEditDisabled(false);
    setErrors({});
    // dispatch(tripSupplierCollectionYes());
    dispatch(cleartrue());
    if (val.StateName) {
      setNightPlanning({
        ...nightPlanning,
        LocationId: val.value,
        StateName: val.StateName,
        City: val.label
      })
    } else {
      setNightPlanning({
        ...nightPlanning,
        PlannerNightId: val.value,
        PlannerNight: val.label
      })
    }
  }

  const checkValidation = (details) => {
    const validationRules = [
      { field: 'LocationId', require: true, message: 'Kindly select the city' },
      { field: 'PlannerNightId', require: true, message: ' Kindly select the number of nights' },
    ];

    const errors = validationRules.reduce((acc, rule) => {
      if ((!details[rule.field].toString().trim() && rule.require)) {
        acc[rule.field] = rule.message;
      };
      return acc;
    }, {});

    if (Object.keys(errors).length > 0) {
      let id = Object.keys(errors)[0];
      nightPlanningRef.current[id]?.focus();
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const addNightPlaning = (nightPlaning) => {

    if (!checkValidation(nightPlaning)) {
      setGeneratedServices((prev) => ({
        ...prev,
        NightCollection: [...prev.NightCollection, {
          ...nightPlaning, seqNo: prev.NightCollection?.length + 1
        }]
      }))
      dispatch(cleartrue());
      setNightPlanning(defaultNightObj)
    }

  }

  const removeNightPlaning = (seqNo) => {
    const { NightCollection } = generatedServices;

    const planningNights = NightCollection?.reduce((acc, night) => {
      if (night.seqNo !== seqNo) {
        acc = [...acc, { ...night, seqNo: acc.length + 1 }];
      }
      return acc
    }, []);
    setIsEditDisabled(false);
    setErrors({});
    dispatch(tripSupplierCollectionYes());
    setGeneratedServices((prev) => ({
      ...prev, NightCollection: planningNights
    }));

  };

  const handleConfirmation = (val) => {
    if (val) {
      handleOnChange("IsActive", !generatedServices.IsActive);
    }
    setModalShow(false);
  };

  const checkAllValidation = (data) => {
    let errors = {}

    if (!data['FromLocationId']) {
      errors['FromLocationId'] = "kindly select the start city"
    }
    if (!data['ToLocationId']) {
      errors['ToLocationId'] = "kindly select the end city"
    }
    if (data['NightCollection'].length > 0) {
      let plannerData = generatedServices.NightCollection;
      const maxNight = Math.max.apply(null, plannerData.map((night) => night.PlannerNightId));

      for (let i = 1; i <= maxNight; i++) {
        let checkIsNigth = plannerData.some((night) => night.PlannerNightId === i)
        if (!checkIsNigth) {
          errors['NightCollection'] = "Kindly select valid  night"
        }
      }

    } else {
      errors['NightCollection'] = "Please add atleast one night"
    }
    if (!data['DataCollection'].length > 0) {
      errors['DataCollection'] = "Kindly select the supplier";
      // if (value.DataCollection?.length === 0) {
      dispatch(tripSupplierCollectionNo());
      // }
    }
    if (Object.keys(errors).length > 0) {
      let id = Object.keys(errors)[0];
      inputRef.current[id]?.focus();
    }
    setErrors(errors)
    return Object.keys(errors).length > 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let statusoftoken = await verifytoken();

    if (!checkAllValidation(generatedServices) && statusoftoken) {

      const planningNights = generatedServices.NightCollection.reduce((acc, night) => {
        return [...acc,
        {
          LocationId: night.LocationId,
          PlannerNightId: night.PlannerNightId,
        }];
      }, []);
      dispatch(tripSupplierCollectionYes())
      if (viewtype === 'Add') {

        const userGeneratedService = await postUsersData('/master/v1/insertugsplanner',
          { ...generatedServices, NightCollection: planningNights }
        );

        dispatch({ type: 'ALERTINGMESSAGE', message: userGeneratedService.message });
      } else {

        const userGeneratedService = await patchUsersData('/master/v1/updateusgplanner',
          { ...generatedServices, NightCollection: planningNights }
        );

        dispatch({ type: 'ALERTINGMESSAGE', message: userGeneratedService.message });
        return
      }
    }
  }

  const filterNightOption = useMemo(() => {
    let options = [...numberOfNightOption]
    if (generatedServices.NightCollection.length > 0) {
      const selectedOption = generatedServices.NightCollection.map((night) => night.PlannerNightId);
      options = options.filter((night) => !selectedOption.includes(night.value))
      return options
    } else {
      return options
    }

  }, [generatedServices.NightCollection, numberOfNightOption])

  useMemo(() => {
    if (isclearerror) {
      setErrors({})
      dispatch(clearerrorfalse());
    }
  }, [isclearerror])

  const handleClear = () => {
    setGeneratedServices(defaulGenratedService);
    setNightPlanning(defaultNightObj)
    // nightPlanningRef.current.LocationId=null
    // nightPlanningRef.current.PlannerId=null
    setErrors({});
    dispatch(cleartrue())
  }

  const handlebackClick = () => {
    navigate(-1)
  }

  return (
    <form action=""  >
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row">
            <div className="col-md-6">

              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                  <label htmlFor="" className='col-form-label required'>Start City</label>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <CustomSelection
                    selId="FromLocationId"
                    selRef={(el) => (inputRef.current.FromLocationId = el)}
                    datalist={cityOption}
                    fieldid={generatedServices.FromLocationId}
                    SelectionChange={(val) => handleOnChange("FromLocationId", val.value)}
                    isdisable={disabled}
                    // isactivedisable={citydisable}
                    issearchable={true}
                  />
                  {errors.FromLocationId && <p className="text-start text-danger mt-1">{errors.FromLocationId} </p>}
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 mb-1">
                  <label htmlFor="" className='col-form-label required'>End City</label>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 mb-1">
                  <CustomSelection
                    selId="ToLocationId"
                    selRef={(el) => (inputRef.current.ToLocationId = el)}
                    datalist={cityOption}
                    fieldid={generatedServices.ToLocationId}
                    SelectionChange={(val) => handleOnChange("ToLocationId", val.value)}
                    isdisable={disabled}
                    // isactivedisable={citydisable}
                    issearchable={true}
                  />
                  {errors.ToLocationId && <p className="text-start text-danger mt-1">{errors.ToLocationId} </p>}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='row mt-lg-2'>
        <div className="col-lg-6 col-sm-6">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <label htmlFor="active" className="col-form-label">Active</label>
            </div>
            <div className="col-auto col-lg-8 " disabled={disabled}>
              <Switch
                id='active'
                name='IsActive'
                onChange={((val) => {
                  if (location.state.viewtype === 'Add') {
                    handleOnChange("IsActive", !generatedServices.IsActive)
                  } else {
                    setModalShow(true)
                  }
                })}
                checked={generatedServices.IsActive}
                className="react-switch"
                disabled={disabled}
              />
            </div>
            <ConfirmationModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              noconfirm={() => handleConfirmation(false)}
              yesconfirm={() => handleConfirmation(true)}
            />
          </div>
        </div>
      </div>

      <div class=" form_border py-3 my-3">
        <div class="mb-3 row mx-1">
          {errors.NightCollection && <p className="text-center text-danger">{errors.NightCollection} </p>}
          <div class={`col-lg-12 col-md-12 col-sm-12 ${!errors.NightCollection && 'mt-3'}`}>
            <div class="row">

              <div className='col-lg-4 col-sm-6 mx-lg-auto'>
                <div className='row'>
                  <div class="col-lg-4 col-md-12">
                    <label for="inputPassword6" class="col-form-label">State</label>
                  </div>
                  <div class="col-lg-8 mb-1 col-md-12">
                    <input
                      className='form-control'
                      type="text"
                      placeholder='State'
                      value={nightPlanning.StateName}
                      disabled />
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-sm-6 mx-lg-auto'>
                <div className='row'>
                  <div class="col-lg-4 col-md-12">
                    <label for="inputPassword6" class="col-form-label required">City</label>
                  </div>
                  <div class="col-lg-8 mb-1 col-md-12">
                    <CustomSelection
                      selId="LocationId"
                      selRef={(el) => (nightPlanningRef.current.LocationId = el)}
                      datalist={cityOption}
                      fieldid={nightPlanning.LocationId}
                      SelectionChange={(val) => {
                        handleOnChangeNightPlaning(val);
                      }}
                      isdisable={disabled}
                      // isactivedisable={citydisable}
                      issearchable={true}
                    />
                    {errors.LocationId && <p className="text-start text-danger mt-1">{errors.LocationId} </p>}
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-sm-6 mx-lg-auto'>
                <div className='row'>
                  <div class="col-lg-5 col-md-12">
                    <label for="inputPassword6" class="col-form-label required">Number of Nights</label>
                  </div>
                  <div class="col-lg-7 mb-1 col-md-12">
                    <CustomSelection
                      selId="PlannerNightId"
                      selRef={(el) => (nightPlanningRef.current.PlannerNightId = el)}
                      datalist={
                        filterNightOption
                      }
                      fieldid={nightPlanning.PlannerNightId}
                      SelectionChange={(val) => handleOnChangeNightPlaning(val)}
                      isdisable={disabled}
                      // isactivedisable={citydisable}
                      issearchable={true}
                    />
                    {errors.PlannerNightId && <p className="text-start text-danger mt-1">{errors.PlannerNightId} </p>}
                  </div>
                </div>
              </div>

              <div className='row d-flex justify-content-center'>
                <div class="col-12 d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    onClick={() => addNightPlaning(nightPlanning)}
                    className="btn btn-primary mx-1"
                    id="btnroomAdd"
                    disabled={disabled}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div>
                <div className='mb-1 col-lg-12 col-md-12 col-sm-12 mt-3 table_scroll'>
                  <table id="data" className="table table-hover" >
                    <thead>
                      <tr>
                        <th className='text-start'>State</th>
                        <th className='text-center'>City</th>
                        <th className='text-center'>Number of Nights</th>
                        <th className='text-center'>Remove</th>
                      </tr>
                    </thead>
                    <tbody id="location-data">
                      {generatedServices.NightCollection.length > 0
                        && generatedServices.NightCollection?.map((nightPlan, index) => (
                          <tr class="ReactTable" key={index}>
                            <td>{nightPlan.StateName}</td>
                            <td className='text-center'>{nightPlan.City}</td>
                            <td className='text-center'>{nightPlan.PlannerNight}</td>
                            <td className='text-center'><button type="button"
                              onClick={(e) => { removeNightPlaning(nightPlan.seqNo) }}
                              className="btn btn-primary mx-1" id="btnroomremoveAdd"
                              disabled={disabled}
                            >Remove</button></td>
                          </tr>)
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className=''>
        <CreateTripSupplier
          supplierList={generatedServices?.DataCollection}
          setSupplier={setGeneratedServices}
          location={location}
          setEditDisabled={setIsEditDisabled}
          disabled={isdisabled}
        />
      </div>


      <div className="mt-5 d-flex justify-content-center">
        <button type="button"
          className="btn btn-primary mx-1"
          id="btnAdd"
          onClick={(e) => handleSubmit(e)}
          disabled={isEditdisabled}
        >
          {location.state.viewtype === 'Add' ? "Save" : "Update"}
        </button>
        <button type="button"
          className="btn btn-primary mx-1"
          // id="btnClear"
          onClick={handleClear}
          disabled={cleardisable}
        >Clear</button>

        <button type="button"
          className="btn btn-primary mx-1"
          id="btnVisible"
          onClick={() => handlebackClick()}
        >Back</button>
      </div>

    </form>
  )
}

export default Usergeneratedservice
