import React, { useState } from 'react';
import Select from 'react-select';
import AccessPointAndList from './AccessPointAndList';

function AccessPoints({
    additionInfo,
    setAdditionInfo,
    accessPointList,
    setAccessPointList, isEditDisable, setIsEditDisable, disabled
}) {

    return (
        <div className='container-fluid form_border py-2 my-4'>
            <form action=''>
                <div className='col-lg-12 col-md-12 col-sm-12 px-lg-4 px-md-4'>

                    {/* train */}
                    <AccessPointAndList
                        stationType="trainStation"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        accessPointList={accessPointList}
                        setAccessPointList={setAccessPointList}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />


                    {/* bus */}
                    <AccessPointAndList
                        stationType="busStation"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        accessPointList={accessPointList}
                        setAccessPointList={setAccessPointList}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                    {/* Airport */}
                    <AccessPointAndList
                        stationType="airPort"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        accessPointList={accessPointList}
                        setAccessPointList={setAccessPointList}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />


                    {/* port */}
                    <AccessPointAndList
                        stationType="port"
                        additionInfo={additionInfo}
                        setAdditionInfo={setAdditionInfo}
                        accessPointList={accessPointList}
                        setAccessPointList={setAccessPointList}
                        isEditDisable={isEditDisable}
                        setIsEditDisable={setIsEditDisable}
                        disabled={disabled}
                    />

                </div>
            </form>
        </div>
    )
}

export default AccessPoints