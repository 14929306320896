import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import HotelAndRoomImages from './Partial/HotelAndRoomImages';
import HotelAndRoomVideos from './Partial/HotelAndRoomVideos';

function ImageAndVideo() {
    const HotelType = useLocation();
    const navigate = useNavigate()
    let type = localStorage.getItem('type')
    const checkNullable = [null, "", undefined];
    const [value, setValue] = React.useState("images");
    const [formValue, setFormValue] = useState({
        imageSection: [{ name: "Hotel List", images: [], id: 1 }, { name: "Room 1 Images", images: [], id: 2 }, { name: "Room 2 Images", images: [], id: 3 }],
        videoSection: [{ name: "Hotel List", videos: [], id: 1 }, { name: "Room 1 Images", videos: [], id: 2 }, { name: "Room 2 Images", images: [], id: 3 }]
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("formValue", formValue)
    return (
        <div class="container-fluid mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-8'>
                    <h3><i onClick={() => navigate(`/HotelListing/${type}/rooms`)} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Images</h3>
                </div>
                <div className='col-4 text-end'>
                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" >Save</button>
                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => navigate(`/HotelListing/${type}/rateandinventory`)}>Next</button>
                </div>
            </div>
            <div>
                <div className='w-100'>
                    <Box sx={{ width: '100%', borderBottom: 2 }}>
                        <Tabs value={value}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        // onChange={(e) => handleChange(e, "basic-info")}

                        >
                            <Tab
                                onClick={(e) => { handleChange(e, "images") }}
                                label="Images"
                                value="images"
                                sx={{ width: '100%', textAlign: 'center' }}
                                className='room-tabs'
                            />

                            <Tab
                                onClick={(e) => { handleChange(e, "videos") }}
                                value="videos"
                                label="Videos"
                                sx={{ width: '100%', textAlign: 'center' }}
                                className='room-tabs'
                            />
                        </Tabs>

                    </Box>
                    {
                        value === 'images' ?
                            (
                                <HotelAndRoomImages
                                    formValue={formValue}
                                    setFormValue={setFormValue}
                                />
                            ) : (
                                <HotelAndRoomVideos
                                    formValue={formValue}
                                    setFormValue={setFormValue}
                                />
                            )
                    }
                </div>

            </div>
        </div>
    )
}

export default ImageAndVideo