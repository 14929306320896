import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumericspace, } from '../../commonClass/CommonValidation';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomTable from '../../../customControl/CustomTable';



const Role = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [editdisabled, setEditDisabled] = useState(true);
    const [activeId, setActiveId] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [disabled, setDisabled] = useState();
    const [clrshow, setsclshow] = useState(false);
    const [roledata, setRoleData] = useState([]);
    const [useredit, setUserEdit] = useState(true);
    const [useradd, setUserAdd] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [citydisable, setCityDisable] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        RoleId: 0,
        RoleName: '',
        IsActive: false,
        UserId: 0,
        UserType: 0
    });
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(form));
    };
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            form.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }
    const handleConfirmation = (e) => {
        if (e === true) {
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormErrors('');
        }
        setModalShow(false);
    }
    //#region bind datatable
    const columns = [
        {
            name: 'Role Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (department) => department.RoleName
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (department) => department.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (department) => formatDateRequire(department.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (department) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(department.RoleId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (department) => <i className="fa fa-eye" onClick={() => handleViewMode(department.RoleId)}></i>// onClick={() => handleViewMode(banner.bannerId)}
        }
    ];
    function formatDateRequire(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    //#endregion

    //#region handling search
    const serachoption = [
        {
            label: 'Role Name',
            value: '1'
        },
        {
            label: 'Active',
            value: '5'
        },
        {
            label: 'All',
            value: '6'
        },

    ];

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            form.UserId = token?.userMasterID
            getdepartmentlist();
            setitems();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        if (window.location.pathname !== '/dashboard') {
            let pagelink = user?.find(x => {
                if (x.PageLink === window.location.pathname)
                    return x.MenuId
            });
            let mid = user?.find(x => x.MenuId === pagelink.MenuId);
            if (mid?.URightView === false && mid?.URightView !== undefined) {
                navigate('/dashboard');
            }
            user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
                setUserAdd(obj.URightAdd);
                setUserEdit(obj.URightEdit);
                setUserExport(obj.URightExport);

            });
        }
    }, [user])
    //#endregion

    const getdepartmentlist = async () => {
        await getAllData('/auth/v1/getallrole?UserType=' + form.UserType).then((roledata) => {
            setRoleData(roledata.data);
            dispatch(loaderActions.end());
        });
    }
    const handleEditMode = (id) => {
        setDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
        setEditDisabled(false);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        setFormErrors('');
        roledata?.map(obj => {
            if (obj.RoleId === id) {
                setForm({ ...form, RoleId: obj.RoleId, RoleName: obj.RoleName, IsActive: obj.IsActive })
                setActiveId(obj.IsActive);
            }
        });

    }
    const validate = (values) => {
        setIsSubmit(true);
        const error = {}
        if (!values.RoleName || values.RoleName?.trim()?.length === 0) {
            error.RoleName = "Kindly enter the role name";
            setIsSubmit(false);
        }
        return error;
    }

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                const RoleData = await postUsersData('/auth/v1/insertrole', form)
                const status = RoleData.status;
                if (!RoleData.status && !RoleData.data) {
                    dispatch({ type: 'ALERTING', message: RoleData.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: RoleData.message });
                    setitems();
                    clear();
                    setDisabled(false);
                    setEditDisabled(true);
                }
                else {
                    dispatch({ type: 'ALERTING', message: RoleData.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const RoleData = await patchUsersData('/auth/v1/updaterole', form)
                const status = RoleData.status;
                if (!RoleData.status && !RoleData.data) {
                    dispatch({ type: 'ALERTING', message: RoleData.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: RoleData.message });
                    setitems();
                    clear();
                }
                else {
                    dispatch({ type: 'ALERTING', message: RoleData.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }
    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "LocationId") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'RoleName') {
            result = alphaNumericspace(e.target.value)
        }
        // else if ([e.target.name][0] === 'GSTNNo') {
        //     result = alphaNumeric(e.target.value)
        // }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        setEditDisabled(true);
        setFormErrors('');
    }
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setButtonAddEditText('Add');
        setIsSubmit(false);
        setDisabled(false);
        setFormErrors('');
        setForm({
            ...form, ['RoleName']: '', ['IsActive']: false, ['RoleId']: 0,
        });
        setActiveId(false);
        getdepartmentlist();
        setCityDisable(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('6');
        setSearchValue('');
    }
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('6');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);

    const setitems = async () => {
        await getAllData("/auth/v1/getallrole?UserType=" + form.UserType).then((roledata) => {
            setFilterItems(roledata.data);
        });
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getdepartmentlist();
            setsclshow(false);
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.RoleName.toLowerCase().includes(searchvalue.toLowerCase())
                        || formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setRoleData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getdepartmentlist();
    }
    //#endregion


    return (
        <>
            {/* <div class="container form_border py-5 my-3"> */}
            <form action="" >
                <div class="mb-1 row mx-1">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div class="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                        <label for="inputPassword6" class="col-form-label required">Role Name</label>
                                    </div>
                                    <div class=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                        <div className="col-lg-6 col-md-8">
                                            <input class="form-control"
                                                type="text"
                                                id='RoleName'
                                                name="RoleName"
                                                value={form.RoleName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Role Name'
                                                maxLength={35}
                                            />
                                        </div>
                                        {formErrors.RoleName && <p className='text-danger text-start'>{formErrors.RoleName}</p>}
                                    </div>

                                    <div class="col-lg-3 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class="col-auto col-lg-6">
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={disabled ? true : false}
                                        />
                                    </div>
                                    <ConfirmationModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        noconfirm={() => handleConfirmation(false)}
                                        yesconfirm={() => handleConfirmation(true)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                    {useradd === false && buttonaddeditText === "Add" ?
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                        <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                    <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                </div>
                <div class="row mt-3">
                    <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                        <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                            <Select
                                className="dropdown"
                                options={serachoption}
                                value={serachoption?.filter(function (serachoption) {
                                    return serachoption.value === searchby;
                                })}// set list of the dataalue 
                                onChange={handleSearchBy} // assign onChange function
                                isDisabled={serchdisable ? true : false}
                                isSearchable={false}

                            />
                        </div>
                        <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                            <div className='clrbutton'>
                                <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                            </div>
                        </div>
                        <div class="p-2 text-center">
                            <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                        </div>
                    </div>

                </div>

                <CustomTable
                    columns={columns}
                    data={roledata}
                    PdfExport={false}
                    ExportToExcel={false}
                    disabled={false}
                />
            </form>
            {/* </div > */}
        </>
    )
}

export default Role