import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../../assets/css/master.css"
import { getAllData } from '../../../../services/ApiCallingUserServices';
import CustomSelection from '../../../../customControl/CustomSelection';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../../../_actions/error.action';
import { loaderActions } from '../../../../_actions/loader.actions';

const VendorOverview = (props) => {
    const {
        overViewCity,
        handleOverViewAdd,
        handleOverViewDisable,
        retriveflag,
        Overviewdatalist,
        editableflag,
        ischecked
    } = props

    const errorStatus = useSelector(state => state.errorStatus.isError);
    const sbmitStatus = useSelector(state => state.errorStatus.isSubmitting);
    const OverViewstatus = useSelector(state => state.errorStatus.isOverView);

    // const overviewdurationcnt = useSelector(state=>state.errorStatus.overviewduration);
    // const overviewdurationfromcnt = useSelector(state=>state.errorStatus.overviewdurationfrom);
    // const overviewdurationtocnt = useSelector(state=>state.errorStatus.overviewdurationto);
    // const overviewvisitfromcnt = useSelector(state=>state.errorStatus.overviewvisitfrom);
    // const overviewvisittocnt = useSelector(state=>state.errorStatus.overviewvisitto);
    // const overviewthemecnt = useSelector(state=>state.errorStatus.overviewtheme);


    const dispatch = useDispatch();

    const [formerror, setFormError] = useState({});
    const [durationlist, setDurationList] = useState([]);
    const [visitfromduration, setVisitFromDurationList] = useState([]);
    const [visittoduration, setVisitToDurationList] = useState([]);
    const [packagepoint, setPackageoint] = useState([]);
    const [endpackagepoint, setEndPackageoint] = useState([]);
    const [packagetheme, setPackageTheme] = useState([]);
    const [packagethemeList, setPackageThemeList] = useState([]);

    const [durationid, setDurationId] = useState(0);
    const [visitfromdurationid, setVisitFromDurationId] = useState(0);
    const [visittodurationid, setVisitToDurationId] = useState(0);
    const [visitpointfromid, setVisitFromId] = useState(0);
    const [visitpointtoid, setVisitToId] = useState(0);
    const [themebyid, setThemeById] = useState(0);

    const [allstartpoint, setAllStartpoint] = useState([]);
    const [startpointdisabled, setStartPointDisabled] = useState(false);
    const [allendpoint, setAllEndpoint] = useState([]);
    const [endpointdisabled, setEndPointDisabled] = useState(false);

    const [themeidlist, setThemeIdList] = useState([]);
    const [themeimgpath, setThemeImgPath] = useState([]);
    const [themebycounter, setThemeCounter] = useState(0);

    const [nightlist, setNightList] = useState([]);
    const [nightid, setNightId] = useState(0);
    const [overviewCityid, setOverviewCityId] = useState(0);

    const [overviewCityDetails, setOverviewCityDetails] = useState([]);
    const [overviewCity, setOverviewCity] = useState([])
    const [overviewNight, setOverviewNight] = useState([])
    const [overviewbtntext, setOverviewBtnText] = useState('Add');
    const [overviewCityIdEdit, setOverviewCityIdEdit] = useState(0)

    const [overviewCityNightError, setOverviewCityNightError] = useState('')
    // const [overviewCityError, setOverviewCityError] = useState('')
    // const [overviewNightError, setOverviewNightError] = useState('')
    const [overviewCityLengthError, setOverviewCityLengthError] = useState('')
    const [overviewId, setOverviewId] = useState(0);
    const [citydisable, setCityDisable] = useState(false);


    const [overviewobj, setOverViewObj] = useState({
        durationId: 0,
        bestTimeVisitFromId: 0,
        bestTimeVisitToId: 0,
        packageStartPointLocationId: 0,
        packageEndPointLocationId: 0,
        packageOverviewTheme: [],
        packageOverviewCities: []
    });

    const durationlistofoverview = durationlist?.map((value) => ({
        label: value.Duration,
        value: value.DurationId
    }));

    const getDurationdatallist = async () => {
        await getAllData('/master/vendor/v1/vengetpackageduration').then((response) => {
            setDurationList(response?.data)
        });
    }

    const getdurationtovisit = async () => {
        await getAllData('/master/vendor/v1/vengetpackagemonth').then((response) => {
            setVisitFromDurationList(response?.data?.map((ele) => ({
                label: ele.MonthName,
                value: ele.MonthId
            })));
            setVisitToDurationList(response?.data?.map((ele) => ({
                label: ele.MonthName,
                value: ele.MonthId
            })))
        });
        // setVisitFromDurationList(visitduration.data);
        // setVisitToDurationList(visitduration.data);
    }

    const getpackagethemelist = async () => {
        await getAllData('/master/vendor/v1/vengetpackagetheme').then((response) => {

            setPackageTheme(response?.data?.map((ele) => ({
                label: ele.ThemeName,
                value: ele.ThemeId
            })))
            setPackageThemeList(response?.data);
        });
        // setPackageTheme(packagethemelistD.data);
    }

    const getpackagepoint = async () => {
        await getAllData('/master/vendor/v1/vengetpackagelocationcity').then((packagepointlist) => {
            setPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setEndPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            setAllStartpoint(packagepointlist.data);
            setAllEndpoint(packagepointlist.data);
            // setFlightfrom(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            // setFlightTo(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            // setAllFilghtFrom(packagepointlist.data);
            // setAllFilghtTo(packagepointlist.data);
            // handleRetrive();
            getDurationdatallist()
            getdurationtovisit()
            getpackagethemelist()
            dispatch(loaderActions.end());
        });
    }

    const packagepointfromlist = packagepoint?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));

    const packagepointtolist = endpackagepoint?.map((value) => ({
        label: value.City,
        value: value.LocationId
    }));


    const [totalNights, setTotalNights] = useState(0)
    const handledurationchange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setDurationId(e.value);
        setOverViewObj({ ...overviewobj, durationId: e.value });

        const nightid = durationlist?.map(id => {
            if (id.DurationId === e.value) {
                return id.NightId
            }
        }).find(x => x != undefined ? x : '')
        setTotalNights(nightid)
        const nightlistdata = durationlist?.filter(obj => obj.NightId <= nightid)?.map((value) => ({
            label: value.Night,
            value: value.NightId
        }));
        setNightList(nightlistdata)

        handleOverViewDisable(false);
        dispatch(errorActions.overviewdurationset())
        dispatch(errorActions.resetallstatusflag())
    }

    const handleNightChange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')
        const night = [
            { label: e.label, value: e.value }
        ]
        setNightId(e.value)
        setOverviewNight(night)
        // reseterrormessage();
        // setEditDisabled(false);
        handleOverViewDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }

    const handlecityDatachange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')
        const city = [
            { label: e.label, value: e.value }
        ]
        setOverviewCityId(e.value)
        setOverviewCity(city)
        // reseterrormessage();
        // setEditDisabled(false);
        handleOverViewDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }

    const [curTotalNight, setCurTotalNight] = useState(0)
    const handleOverviewAdd = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        if (overviewbtntext === 'Add') {
            let totalnight = 0
            overviewCityDetails?.map(value => {
                let temp = parseInt(value.night)
                totalnight = temp + totalnight
            })
            let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
            // let temp2 = parseInt(overviewNight[0]?.label)
            totalnight = temp2 + totalnight
            setCurTotalNight(totalnight)
            if (totalnight > totalNights) {
                setOverviewCityLengthError('Only ' + totalNights + ' nights are allowed')
            }
            else {
                if (overviewCityid === 0 || nightid === 0) {
                    setOverviewCityNightError('Kindly select city with no. of nights')
                }
                // if (overviewCityid === 0) {
                //     setOverviewCityError('Kindly select city with no. of nights')
                // }
                // if (nightid === 0) {
                //     setOverviewNightError('Kindly select city with no. of nights')
                // }

                setOverviewId(overviewId + 1)
                setOverviewCityDetails([...overviewCityDetails, {
                    venPackageOverviewCityId: overviewId + 1,
                    city: overviewCity[0].label,
                    cityId: overviewCity[0].value,
                    night: overviewNight[0].label,
                    nightId: overviewNight[0].value
                }
                ]);
                setOverviewCityId(0)
                setNightId(0)
                setOverviewCity([])
                setOverviewNight([])
            }
        }
        else {
            if (overviewCityIdEdit > 0) {
                handleeditofoverview(overviewCityIdEdit);
            }
        }
    }
    const handleEditOverviewCity = (id, e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')
        setOverviewBtnText('Update');
        setOverviewCityIdEdit(id)
        overviewCityDetails?.filter(obj => obj.venPackageOverviewCityId === id)?.map(value => {
            setOverviewCityId(value.cityId)
            setNightId(value.nightId)
            const city = [
                { label: value.city, value: value.cityId }
            ]
            const night = [
                { label: value.night, value: value.nightId }
            ]
            setOverviewCity(city)
            setOverviewNight(night)
            overViewCity?.filter(el => el.value === value.cityId)?.map((ele) => {
                if (ele.IsActive === true) {
                    setCityDisable(false)
                }
                else {
                    setCityDisable(true)
                }
            })
        })
        // reseterrormessage();
        // setEditDisabled(false);
        handleOverViewDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }

    const handleeditofoverview = (id) => {
        let totalnight = 0
        let tid = 0
        overviewCityDetails?.map(value => {
            if (value.venPackageOverviewCityId === id) {
                tid = parseInt(value.night)
            }
            let temp = parseInt(value.night)
            totalnight = temp + totalnight
        })
        let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
        // let temp2 = parseInt(overviewNight[0]?.label)
        totalnight = temp2 + totalnight - tid
        setCurTotalNight(totalnight)
        if (totalnight > totalNights) {
            setOverviewCityLengthError('Only ' + totalNights + ' nights are allowed')
        }
        else {
            const overviewData = overviewCityDetails?.map(value => {
                if (value.venPackageOverviewCityId === id) {
                    value.city = overviewCity[0].label
                    value.night = overviewNight[0].label
                    value.cityId = overviewCity[0].value
                    value.nightId = overviewNight[0].value
                }
                return value
            })
            setFormError({});
            setOverviewCityLengthError('')
            setOverviewCityNightError('')
            // setOverviewCityError('')
            // setOverviewNightError('')

            setOverviewCityDetails(overviewData)
            setOverviewBtnText('Add');
            setOverviewCityId(0)
            setNightId(0)
            setOverviewCity([])
            setOverviewNight([])
            // reseterrormessage();
            // setEditDisabled(false);
            handleOverViewDisable(false);
            dispatch(errorActions.resetallstatusflag())
        }
    }

    const removeverviewCity = (id, e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setOverviewCityDetails((oldState) => oldState?.filter((item) => item.venPackageOverviewCityId !== id))
        setOverviewBtnText('Add');
        setOverviewCityId(0)
        setNightId(0)
        // reseterrormessage();
        // setEditDisabled(false);
        handleOverViewDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }

    useEffect(() => {
        let totalnight = 0
        overviewCityDetails?.map(value => {
            let temp = parseInt(value.night)
            totalnight = temp + totalnight
        })
        // let temp2 = parseInt(overviewNight[0]?.label != undefined ? overviewNight[0]?.label : 0)
        // let temp2 = parseInt(overviewNight[0]?.label)
        // totalnight = temp2 + totalnight
        setCurTotalNight(totalnight)
    }, [overviewCityDetails, overviewNight, curTotalNight])

    const handledurationfromchange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setVisitFromDurationId(e.value);
        setOverViewObj({ ...overviewobj, bestTimeVisitFromId: e.value });
        handleOverViewDisable(false);
        dispatch(errorActions.overviewdurationfromset());
        dispatch(errorActions.resetallstatusflag())
    }
    const handledurationtochange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setVisitToDurationId(e.value);
        setOverViewObj({ ...overviewobj, bestTimeVisitToId: e.value });
        handleOverViewDisable(false);
        dispatch(errorActions.overvievdurationtoset());
        dispatch(errorActions.resetallstatusflag())
    }
    const handlevisitfrompoint = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setVisitFromId(e.value);
        setOverViewObj({ ...overviewobj, packageStartPointLocationId: e.value });
        handleOverViewDisable(false);
        dispatch(errorActions.overviewvisitfromset());
        dispatch(errorActions.resetallstatusflag())
    }
    const handlevisittopoint = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setVisitToId(e.value);
        setOverViewObj({ ...overviewobj, packageEndPointLocationId: e.value });
        handleOverViewDisable(false);
        dispatch(errorActions.overviewvisittoset());
        dispatch(errorActions.resetallstatusflag())
    }
    const handlethemebychange = (e) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setThemeById(e.value);
        GetThemeImage(e.value);
        dispatch(errorActions.overviewthemeset());
        dispatch(errorActions.resetallstatusflag())
        handleOverViewDisable(false);
    }

    const GetThemeImage = (themeid) => {

        const themeimagerepeat = themeimgpath?.filter((obj) => obj.themeId === themeid);

        if (themeimagerepeat.length === 0) {
            packagethemeList?.filter((obj) => obj.ThemeId === themeid).map((value) => {
                setThemeCounter(themebycounter + 1)
                setThemeIdList([...themeidlist, { themeId: themeid, Id: themebycounter + 1 }]);
                setThemeImgPath([...themeimgpath, { themeId: themeid, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: themebycounter + 1 }]);
            });
        }

    }

    useEffect(()=>{

        if (Overviewdatalist?.packageOverviewTheme?.length > 0) {
            Overviewdatalist?.packageOverviewTheme?.map((val, index) => {
                packagethemeList?.filter((obj) => obj.ThemeId === val.themeId).map((value) => {

                    setThemeIdList(current => [...current, { themeId: val.themeId, Id: index + 1 }]);
                    setThemeImgPath(current => [...current, { themeId: val.themeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: index + 1 }]);
                });
            });
            setThemeCounter(Overviewdatalist.packageOverviewTheme?.length)
        }
    },[retriveflag,packagethemeList])

    useEffect(() => {

        const themeidlistD = themeidlist?.filter(obj => obj.themeId > 0).map(obj => ({
            themeId: obj.themeId
        }));
        setOverViewObj({ ...overviewobj, packageOverviewTheme: themeidlistD });
        themeidlistD.length > 0 ? dispatch(errorActions.overviewthemeset()) : dispatch(errorActions.overviewthemereset());
    }, [themeidlist])

    const removeThmeImage = (id, ThemeId) => {
        setFormError({});
        setOverviewCityLengthError('')
        setOverviewCityNightError('')
        // setOverviewCityError('')
        // setOverviewNightError('')

        setThemeImgPath((oldState) => oldState?.filter((item) => item.Id !== id));
        setThemeIdList((oldState) => oldState?.filter((item) => item.Id !== id));
        setThemeById(-1);
        handleOverViewDisable(false);
        dispatch(errorActions.resetallstatusflag())
    }
    useEffect(() => {
        getpackagepoint()
    }, []);

    useEffect(() => {
        if (!errorStatus) {
            setFormError({})
            setOverviewCityLengthError('')
            setOverviewCityNightError('')
            // setOverviewCityError('')
            // setOverviewNightError('')
        }
    }, [errorStatus])

    useEffect(() => {
        setOverViewObj({ ...overviewobj, packageOverviewCities: overviewCityDetails });
        overviewCityDetails?.length > 0 ? dispatch(errorActions.overviewcitydetailset()) : dispatch(errorActions.overviewcitydetailreset());
    }, [overviewCityDetails])

    useEffect(() => {
        curTotalNight === totalNights ? dispatch(errorActions.overviewnightpendingset()) : dispatch(errorActions.overviewnightpendingreset());
    }, [curTotalNight, totalNights])

    useEffect(() => {
        handleOverViewAdd(overviewobj);
    }, [overviewobj])
    const resetDisableStateofcontrol = () => {
        setStartPointDisabled(false);
    }

    useEffect(() => {
        resetDisableStateofcontrol();
        if (retriveflag && Overviewdatalist != null) {
            if (Overviewdatalist.durationId > 0 && Overviewdatalist.durationId != null) {
                dispatch(errorActions.overviewdurationset())
            }
            if (Overviewdatalist.bestTimeVisitFromId > 0 && Overviewdatalist.bestTimeVisitFromId != null) {
                dispatch(errorActions.overviewvisitfromset())
            }
            if (Overviewdatalist.bestTimeVisitToId > 0 && Overviewdatalist.bestTimeVisitToId != null) {
                dispatch(errorActions.overviewvisittoset())
            }
            if (Overviewdatalist.packageStartPointLocationId > 0 && Overviewdatalist.packageStartPointLocationId != null) {
                dispatch(errorActions.overviewdurationfromset())
                let allpointlist = allstartpoint?.filter(IsAct => IsAct.IsActive === true);
                setPackageoint(allstartpoint?.filter(IsAct => IsAct.IsActive === true));
                setStartPointDisabled(false);
                if (!allpointlist?.find(IsStartPoint => IsStartPoint.LocationId === Overviewdatalist.packageStartPointLocationId)) {
                    setStartPointDisabled(true)
                    setPackageoint((prvStartdata) => prvStartdata?.length > 0 ? [...prvStartdata, allstartpoint?.find(isStartPoint => isStartPoint.LocationId === Overviewdatalist.packageStartPointLocationId)] : [])

                }
            }
            if (Overviewdatalist.packageEndPointLocationId > 0 && Overviewdatalist.packageEndPointLocationId != null) {
                dispatch(errorActions.overvievdurationtoset())
                let allEndpointlist = allendpoint?.filter(IsAct => IsAct.IsActive === true);
                setEndPackageoint(allendpoint?.filter(IsAct => IsAct.IsActive === true));
                setEndPointDisabled(false);
                if (!allEndpointlist?.find(IsEndPoint => IsEndPoint.LocationId === Overviewdatalist.packageEndPointLocationId)) {
                    setEndPointDisabled(true)
                    setEndPackageoint((prvEnddata) => prvEnddata?.length > 0 ? [...prvEnddata, allendpoint?.find(isEndPoint => isEndPoint.LocationId === Overviewdatalist.packageEndPointLocationId)] : [])

                }
            }
            if (Overviewdatalist.packageOverviewTheme?.length > 0) {
                dispatch(errorActions.overviewthemeset())
            }
            setDurationId(Overviewdatalist.durationId);
            setVisitFromDurationId(Overviewdatalist.bestTimeVisitFromId);
            setVisitToDurationId(Overviewdatalist.bestTimeVisitToId);
            setVisitFromId(Overviewdatalist.packageStartPointLocationId);
            setVisitToId(Overviewdatalist.packageEndPointLocationId);
            // if (Overviewdatalist.packageOverviewTheme?.length > 0) {
            //     Overviewdatalist.packageOverviewTheme?.map((val, index) => {
            //         packagethemeList?.filter((obj) => obj.ThemeId === val.themeId).map((value) => {

            //             setThemeIdList(current => [...current, { themeId: val.themeId, Id: index + 1 }]);
            //             setThemeImgPath(current => [...current, { themeId: val.themeId, ThemePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: index + 1 }]);
            //         });
            //     });
            //     setThemeCounter(Overviewdatalist.packageOverviewTheme?.length)
            // }

            const nightid = durationlist?.map(id => {
                if (id.DurationId === Overviewdatalist.durationId) {
                    return id.NightId
                }
            }).find(x => x != undefined ? x : '')
            setTotalNights(nightid)
            const nightlistdata = durationlist?.filter(obj => obj.NightId <= nightid)?.map((value) => ({
                label: value.Night,
                value: value.NightId
            }));
            setNightList(nightlistdata)

            if (Overviewdatalist.packageOverviewCities?.length > 0) {
                dispatch(errorActions.overviewcitydetailset())
                setOverviewCityDetails(Overviewdatalist.packageOverviewCities)
            }

            setOverViewObj(Overviewdatalist)
        }

    }, [retriveflag,Overviewdatalist,packagethemeList])

 

    const validation = () => {
        const error = {}
        if (durationid === 0) {
            error.PackageDuration = "Please select package duration.";

        }
        if (visitfromdurationid === 0) {
            error.BestTimeVisitFrom = "Please select Best time visit from.";
        }
        if (visittodurationid === 0) {
            error.BestTimeToVisit = "Please select Best time visit to.";
        }
        if (visitpointfromid === 0) {
            error.PackageStartPoint = "Please select Package start point.";
        }
        if (visitpointtoid === 0) {
            error.PackageEndPoint = "Please select Package end point.";
        }
        const themeidcount = themeidlist?.filter(obj => obj.Id > 0);
        if (themeidcount.length === 0) {
            error.ThemeList = 'Please select package theme';
        }
        if (overviewCityDetails?.length === 0) {
            error.overviewCityDetail = 'Please select city with required no. of nights';
        }
        if (overviewCityDetails?.length > 0 && curTotalNight !== totalNights) {
            if (curTotalNight < totalNights) {
                if ((totalNights - curTotalNight) > 1) {
                    error.totalNightsPending = 'Kindly add ' + JSON.stringify(totalNights - curTotalNight) + ' nights more';
                }
                else {
                    error.totalNightsPending = 'Kindly add ' + JSON.stringify(totalNights - curTotalNight) + ' night more';
                }
            }
            else {
                if (totalNights > 1) {
                    error.totalNightsPending = 'Only ' + JSON.stringify(totalNights) + ' nights are allowed';
                }
                else {
                    error.totalNightsPending = 'Only ' + JSON.stringify(totalNights) + ' night are allowed';
                }
            }
        }
        if (Object.keys(error).length > 0) {
            let incllopased = document?.getElementById("OverviewError")
            if (incllopased.ariaExpanded === "false")
                document.querySelector("#OverviewError").click();
            dispatch(errorActions.start())
            // dispatch(errorActions.submitend());
            setFormError(error);
        }

    }
    useEffect(() => {
        if (OverViewstatus && ischecked) {
            validation()
        }
    }, [OverViewstatus])
    //#endregion end input time type box    
    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button collapsed border_set" id="OverviewError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                        Overview
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                {/* <label for="inputPassword6" class="col-form-label">Overview</label> */}
                                <div class="row">
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-12 mb-1">
                                                <label class="col-form-label required">Package Duration</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-12">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    options={durationlistofoverview}
                                                    value={durationlistofoverview?.filter(function (durationlist) {
                                                        return durationlist.value === durationid;
                                                    })}// set list of the data
                                                    onChange={handledurationchange} // assign onChange function
                                                    // onChange={() => showDivHandlerD(ele.PackageMenuId)}
                                                    isDisabled={editableflag}
                                                    isSearchable
                                                />
                                                {formerror.PackageDuration && <p className='text-danger text-start'>{formerror.PackageDuration}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-2 col-md-12 mb-1">
                                                <label class="col-form-label required">Best Time To Visit</label>
                                            </div>
                                            <div class="col-lg-1 col-md-4 mb-1">
                                                <label class="col-form-label required">From</label>
                                            </div>
                                            <div class="col-lg-3 mb-1 col-md-8">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    options={visitfromduration}
                                                    value={visitfromduration?.filter(function (visitfromduration) {
                                                        return visitfromduration.value === visitfromdurationid;
                                                    })}// set list of the data
                                                    onChange={handledurationfromchange} // assign onChange function
                                                    isDisabled={editableflag}
                                                    isSearchable
                                                />
                                                {formerror.BestTimeVisitFrom && <p className='text-danger text-start'>{formerror.BestTimeVisitFrom}</p>}
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-2 col-md-4 mb-1">
                                                <label class="col-form-label required">To</label>
                                            </div>
                                            <div class="col-lg-3 mb-1 col-md-8">
                                                <Select
                                                    className="dropdown select_dropdown"
                                                    options={visittoduration}
                                                    value={visittoduration?.filter(function (visittoduration) {
                                                        return visittoduration.value === visittodurationid;
                                                    })}// set list of the data
                                                    onChange={handledurationtochange} // assign onChange function
                                                    isDisabled={editableflag}
                                                    isSearchable
                                                />
                                                {formerror.BestTimeToVisit && <p className='text-danger text-start'>{formerror.BestTimeToVisit}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-12 mb-1">
                                                <label class="col-form-label required">Package Start Point</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-12">

                                                <CustomSelection
                                                    datalist={packagepointfromlist}
                                                    fieldid={visitpointfromid}
                                                    SelectionChange={handlevisitfrompoint}
                                                    isdisable={editableflag}
                                                    isactivedisable={startpointdisabled}
                                                    issearchable={true}
                                                />
                                                {formerror.PackageStartPoint && <p className='text-danger text-start'>{formerror.PackageStartPoint}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-12 mb-1">
                                                <label class="col-form-label required">Package End Point</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-12">
                                                <CustomSelection
                                                    datalist={packagepointtolist}
                                                    fieldid={visitpointtoid}
                                                    SelectionChange={(e) => handlevisittopoint(e)}
                                                    isdisable={editableflag}
                                                    isactivedisable={endpointdisabled}
                                                    issearchable={true}
                                                />
                                                {formerror.PackageEndPoint && <p className='text-danger text-start'>{formerror.PackageEndPoint}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 '>
                                        <div className='row'>
                                            <div class="col-lg-4 col-md-12 mb-1">
                                                <label class="col-form-label required">Package Theme</label>
                                            </div>
                                            <div class="col-lg-8 mb-1 col-md-12">

                                                <Select
                                                    className="dropdown select_dropdown"
                                                    options={packagetheme}
                                                    value={packagetheme?.filter(function (packagetheme) {
                                                        return packagetheme.value === themebyid;
                                                    })}// set list of the data
                                                    // value={packagethemelist.filter(obj => themebyid.includes(obj.value))}
                                                    onChange={handlethemebychange} // assign onChange function
                                                    isDisabled={editableflag}
                                                    isSearchable
                                                />

                                                {formerror.ThemeList && <p className='text-danger text-start'>{formerror.ThemeList}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-1 col-md-12">
                                        <div class="row">
                                            {/* this label for only Gallery image  galleryimgpath */}
                                            {/* <label for="inputPassword6" class="col-form-label">Gallery Display</label> */}
                                            {themeimgpath && themeimgpath?.map((themeimg, index) => {
                                                if (themebycounter > 0) {
                                                    return (
                                                        <div className="col-lg-2 col-md-3 col-6 mx-auto py-2" key={index} >
                                                            <img src={themeimg.ThemePath} key={index} className="img_thumb w-100" alt='' />
                                                            <button className='closebtnimg'
                                                                onClick={(e) => removeThmeImage(themeimg.Id, themeimg.ThemeId, e)}
                                                                type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                                                                disabled={editableflag}
                                                            >&times;</button>

                                                        </div>

                                                    );
                                                }
                                            })}

                                            {/* </div> */}
                                        </div>
                                    </div>

                                    <div className="form_border mt-4 mx-2">
                                        {formerror.overviewCityDetail && <p className='mt-2 text-danger text-center'>{formerror.overviewCityDetail}</p>}
                                        {overviewCityLengthError && <p className='mt-2 text-danger text-center'>{overviewCityLengthError}</p>}
                                        {formerror.totalNightsPending && <p className="mt-2 text-center text-danger"> {formerror.totalNightsPending} </p>}
                                        {overviewCityNightError && <p className="mt-2 text-center text-danger"> {overviewCityNightError} </p>}
                                        <div className='col-lg-12 col-sm-12 mt-3'>
                                            <div className='row'>
                                                {/* <div className='col-lg-5 col-sm-5'> */}
                                                <div className='col-lg-6 col-sm-6'>
                                                    <div className='row'>
                                                        <div class="col-lg-4 col-md-12 mb-1">
                                                            <label class="col-form-label required">City</label>
                                                        </div>
                                                        <div class="col-lg-8 mb-1 col-md-12">
                                                            {/* <Select
                                                                className="dropdown select_dropdown"
                                                                options={overViewCity}
                                                                value={overViewCity?.filter(function (overviewCity) {
                                                                    return overviewCity.value === overviewCityid;
                                                                })}
                                                                onChange={handlecityDatachange}
                                                                isDisabled={editableflag}
                                                                isSearchable
                                                            /> */}
                                                            <CustomSelection
                                                                datalist={overViewCity}
                                                                fieldid={overviewCityid}
                                                                SelectionChange={handlecityDatachange}
                                                                isdisable={editableflag}
                                                                isactivedisable={citydisable}
                                                                issearchable={true}
                                                            />
                                                            {/* {overviewCityError && <p className='text-danger text-start'>{overviewCityError}</p>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-5 col-sm-5'> */}
                                                <div className='col-lg-6 col-sm-6'>
                                                    <div className='row'>
                                                        <div class="col-lg-4 col-md-12 mb-1">
                                                            <label class="col-form-label required">Night</label>
                                                        </div>
                                                        <div class="col-lg-8 mb-1 col-md-12">
                                                            <Select
                                                                className="dropdown select_dropdown"
                                                                options={nightlist}
                                                                value={nightlist?.filter(function (night) {
                                                                    return night.value === nightid;
                                                                })}
                                                                onChange={handleNightChange}
                                                                isDisabled={editableflag}
                                                                isSearchable
                                                            />
                                                            {/* {overviewNightError && <p className='text-danger text-start'>{overviewNightError}</p>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-2 col-sm-2'>
                                                                    <div className='row'>
                                                                        <div class="col-lg-12 mb-1 col-md-12">
                                                                            <button type="button" class="btn btn-primary mx-1" id="btnAdd"> Add </button>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                <div class="d-flex justify-content-center mt-3">
                                                    <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleOverviewAdd} disabled={editableflag}> {overviewbtntext} </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="mb-3 row py-3">
                                                    <div class="col-lg-12 mb-1">
                                                        <div class="row">
                                                            <div class="col-lg-12 mb-1 table-scrollable">
                                                                <table id="data" className="table table-hover" >
                                                                    <thead>
                                                                        <tr>
                                                                            <th>City
                                                                            </th>
                                                                            <th className='text-center'>Night
                                                                            </th>
                                                                            <th className='text-end pe-4'>Edit
                                                                            </th>
                                                                            <th className='text-end pe-4'>Remove
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="location-data">
                                                                        {overviewCityDetails?.map((element, index) => {

                                                                            return (
                                                                                <tr class="ReactTable" key={index}>
                                                                                    <td>{element.city}</td>
                                                                                    <td className='text-center'>{element.night}</td>
                                                                                    <td className='text-end'><button type="button" onClick={(e) => { handleEditOverviewCity(element.venPackageOverviewCityId, e) }} class="btn btn-primary mx-1" id="btnEdit" disabled={editableflag}>Edit</button></td>
                                                                                    <td className='text-end'><button type="button" onClick={(e) => { removeverviewCity(element.venPackageOverviewCityId, e) }} class="btn btn-primary mx-1" id="btnAdd" disabled={editableflag}>Remove</button></td>
                                                                                </tr>
                                                                            );

                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorOverview