import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Upload } from 'antd';
import UploadButton from './UploadButton';

function HotelAndRoomImages({ formValue, setFormValue }) {
  return (
    <div>
      {formValue?.imageSection.length > 0
        && formValue.imageSection.map((img) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<i class="fa-solid fa-plus" style={{ color: "#B22500" }}></i>}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>{img.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className='row'>
                  <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                    <UploadButton
                      formValue={formValue}
                      setFormValue={setFormValue}
                    />
                  </div>
                  {img.images.length > 0 && (
                    img.images.map((photo) => (
                      <div className='col-xxl-3 col-lg-4 col-md-4 col-sm-4 col-12 rounded'>
                        <img
                          className='rounded  multipleUploader '
                          src={URL.createObjectURL(photo?.ImageFile)}
                          // alt={image.ImgName}
                          height={'100%'}
                          width={'100%'}
                        />
                      </div>
                    ))
                  )}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}

export default HotelAndRoomImages