import { useMemo, useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export function onlyAlphabets(data) {
  try {
    let value = data;
    value = value.replace(/[^a-z\s]/gi, '')
    return value;
  }
  catch (error) {
    return error;
  }
}
export function onlyNumeric(numdata) {
  try {
    let numvalue = numdata;
    numvalue = numvalue.replace(/[^0-9]/, '')
    return numvalue;
  }
  catch (error) {
    return error;
  }
}

export function alphaNumeric(data) {
  try {
    let numvalue = data;
    numvalue = numvalue.replace(/[^A-Za-z0-9]/g, '')
    return numvalue;
  }
  catch (error) {
    return error;
  }
}
export function alphaNumericspace(data) {
  try {
    let numvalue = data;
    numvalue = numvalue.replace(/[^A-Za-z0-9 ]/g, '')
    return numvalue;
  }
  catch (error) {
    return error;
  }
}

export function PhoneNmber(num) {
  try {
    let numvalue = num;
    numvalue = numvalue.replace(/[^0-9\+\-\(\)]/, '')
    return numvalue;
  }
  catch (error) {
    return error;
  }
}
export function normalizeInput(value, previousValue) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};
export function NumericWithDecimal(num) {
  try {
    let numvalue = num;
    numvalue = numvalue.replace(/[^0-9\.]/, '')
    return numvalue;
  }
  catch (error) {
    return error;
  }
}
export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isValidPhonenumber(num) {
  return /^[\d\(\)\-+]+$/m.test(num);
};

export function validateURL(URL) {
  return /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(URL)

}
export function useSortableData(items, config = null) {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, dir) => {

    let direction = dir;
    //   if (
    //         sortConfig &&
    //         sortConfig.key === key &&
    //         sortConfig.direction === 'ascending'
    //      ) 
    //   {
    //         direction = 'descending';
    //   }
    setSortConfig({ key, direction });
  };
  //setDestinationListData(sortedItems)
  return { items: sortedItems, requestSort, sortConfig };
}

export function generatePDF(columns, rows, filename, filetitle, orientationtype = "p") {
  const pdf = new jsPDF(orientationtype, "pt", "a4");
  let textX = (pdf.internal.pageSize.getWidth() - pdf.getTextWidth(filetitle)) / 2

  pdf.text(filetitle, textX, 40);
  pdf.autoTable(columns, rows, {
    startY: 65,
    theme: "grid",
    styles: {
      font: "times",
      minCellWidth: 33,
      cellPadding: 3.5,
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    headStyles: {
      textColor: [0, 0, 0],
      halign: "center",
      fontStyle: "bold",
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
      fillColor: [166, 204, 247]
    },
    alternateRowStyles: {
      fillColor: [212, 212, 212],
      textColor: [0, 0, 0],
      lineWidth: 0.5,
      lineColor: [0, 0, 0]
    },
    rowStyles: {
      lineWidth: 0.5,
      lineColor: [0, 0, 0]
    },
    tableLineColor: [0, 0, 0]
  });
  pdf.save(filename);
}
export function isValidImage(FileName) {
  return /\.(png|jpeg|jpg|gif)$/i.test(FileName)
}
export function isValidImageFormate(FileName) {
  return /\.(png|jpeg)$/i.test(FileName)
}
export function isValidVideo(DocName) {
  return /\.(mov|mp4|mp3|wmv|avi)$/i.test(DocName)
}
export function isNoSpecialCharacter(strvalue) {
  return /^[A-Za-z0-9 ]+$/.test(strvalue)
}

export function numberAndSpecialCharacter(value) {
  const regex = /^[0-9!`@#$%^&.*+?&^_`{|}~-]*$/;
  const inputIsValid = regex.test(value);
  return inputIsValid
}

export function onlyNumbersAndSpacialCharacters(str) {
  // return /^[0-9@~`!@#$%^&*()_=+{}[|';:\]"\\/?>.<,-\s]*$/.test(str);
  try {
    let value = str;
    value = value.replace(/[^0-9@~`!@#$%^&*()_=+{}[|';:\]"\\/?>.<,-\s]/g, '')
    return value;
  }
  catch (error) {
    return error;
  }
}