import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { ExportExcel } from '../commonClass/ExportExcel';
import { generatePDF } from '../commonClass/CommonValidation';
import { loaderActions } from '../../_actions/loader.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../customControl/CustomTable';

const AirInventoryList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [serchdisable, setSerachDisable] = useState(false);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('10');
    const [clrshow, setsclshow] = useState(false);

    const [AirListData, setAirListData] = useState([])
    const getbookingtrans = async () => {
        // const bookingtrans = await getAllData("/master/v1/getbookingtrans")
        // setAirListData(bookingtrans.data)
    }

    const user = useSelector((store) => store.userstatus.tba);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [

        {
            label: 'Travel Scope',
            value: '1'
        },
        {
            label: 'Trip Type',
            value: '2'
        },
        {
            label: 'Supplier Name',
            value: '3'
        },
        {
            label: 'Supplier Code',
            value: '4'
        },
        {
            label: 'Package Name',
            value: '5'
        },
        {
            label: 'Airline Code',
            value: '6'
        },
        {
            label: 'Flight Number',
            value: '7'
        },
        // {
        //     label: 'Created Date',
        //     value: '8'
        // },
        {
            label: 'Active',
            value: '9'
        },
        {
            label: 'All',
            value: '10'
        },

    ];
    const [filteritems, setFilterItems] = useState([]);

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        // const allbookingtrans = await getAllData('/master/v1/getbookingtrans')
        // setFilterItems(allbookingtrans.data)
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getbookingtrans();
            setsclshow(false)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getbookingtrans();
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.TravelScope?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.TripType?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.SupplierName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.SupplierCode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.PackageName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.AirlineCode?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.FlightNumber?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            // else if (searchby === '8') {
            //     filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            else if (searchby === '9') {
                if (searchvalue.toLowerCase() === "active".toLowerCase()) {
                    filterdata = filteritem?.filter((issec) => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === "inactive".toLowerCase()) {
                    filterdata = filteritem?.filter((issec) => issec.IsActive === false);
                }
                else if ("active".toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec) => issec.IsActive === true || issec.IsActive === false);
                }
                else if ("inactive".toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec) => issec.IsActive === false);
                }
            }
            else if (searchby === '10') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TripType.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.AirlineCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNumber.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TripType.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.AirlineCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNumber.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TripType.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.AirlineCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNumber.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.TravelScope.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TripType.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.AirlineCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNumber.toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.TravelScope.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.TripType.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.SupplierName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.SupplierCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.PackageName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.AirlineCode.toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.FlightNumber.toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            // setAirListData(filterdata)
        }

    }

    const columns = [
        {
            name: "Travel Scope",
            sortable: true,
            float: "left",
            selector: (air) => air.TravelScope
        },
        {
            name: "Trip Type",
            sortable: true,
            float: "left",
            selector: (air) => air.TripType
        },
        {
            name: "Supplier Name",
            sortable: true,
            float: "left",
            selector: (air) => air.SupplierName
        },
        {
            name: "Supplier Code",
            sortable: true,
            float: "left",
            selector: (air) => air.SupplierCode
        },
        {
            name: "Package Name",
            sortable: true,
            float: "left",
            selector: (air) => air.PackageName
        },
        {
            name: "Airline Code",
            sortable: true,
            float: "left",
            selector: (air) => air.AirlineCode
        },
        {
            name: "Flight Number",
            sortable: true,
            float: "left",
            selector: (air) => air.FlightNumber
        },
        {
            name: "Created Date",
            sortable: true,
            float: "left",
            selector: (air) => formatDate(air.CreatedDate)
        },
        {
            name: "Active",
            sortable: true,
            float: "left",
            selector: (air) => air.isActive ? "Active" : "InActive"
        },
        {
            name: "Edit",
            // selector: (air) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(air.airlineId)}></i></button>
            selector: (air) => <button type='button' className='border-0 p-0 bg-body set_disableclr' ><i className="fa fa-edit" onClick={() => handleEditMode(air.airlineId)}></i></button>
        },
        {
            name: "View",
            selector: (air) => <i className="fa fa-eye" onClick={() => handleViewMode(air.airlineId)}></i>
        },
    ]

    const handleCreateAirInventory = () => {
        navigate("/Airinventorymaster")
    }

    const handleEditMode = (id) => {
        let airlistId = 0;

        AirListData?.map(obj => {
            airlistId = obj.airlistId;
        });
        navigate('/Airinventorymaster', { state: { disabled: false, editdisabled: true, viewtype: 'Update', supplierId: id } });
    }

    const handleViewMode = (id) => {
        let airlistId = 0;

        AirListData?.map(obj => {
            airlistId = obj.airlistId;
        });
        navigate('/Airinventorymaster', { state: { disabled: true, editdisabled: true, viewtype: 'View', supplierId: id } });
    }

    // const AirListData = [
    //     {
    //         TravelScope: 'TravelScope',
    //         TripType: 'TripType',
    //         SupplierName: 'SupplierName',
    //         SupplierCode: 'SupplierCode',
    //         PackageName: 'PackageName',
    //         AirlineCode: 'AirlineCode',
    //         FlightNumber: 'FlightNumber',
    //         CreatedDate: new Date(),
    //         isActive: 'isActive',
    //     }
    // ]

    //#region Date format
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    //#endregion

    //#region Exports
    const PDFExport = () => {

        const columns = ["Travel Scope", "Trip Type", "Supplier Name", "Supplier Code", "Package Name", "Airline Code", "Flight Number", "Created Date", "Active"];

        let rows = [];
        for (let i = 0; i < AirListData?.length; i++) {
            let temp = [
                AirListData[i].TravelScope,
                AirListData[i].TripType,
                AirListData[i].SupplierName,
                AirListData[i].SupplierCode,
                AirListData[i].PackageName,
                AirListData[i].AirlineCode,
                AirListData[i].FlightNumber,
                AirListData[i].CreatedDate,
                `${AirListData[i].isActive ? 'Active' : 'InActive'}`
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, 'AirInventoryList', 'Air Inventory')
    };

    const ExcelExport = () => {
        const dispalyname = ["Travel Scope", "Trip Type", "Supplier Name", "Supplier Code", "Package Name", "Airline Code", "Flight Number", "Created Date", "Active"];
        const fields = ['TravelScope', 'TripType', 'SupplierName', 'SupplierCode', 'PackageName', 'AirlineCode', 'FlightNumber', 'CreatedDate', 'isActive'];
        ExportExcel(dispalyname, fields, AirListData, "AirInventory", "AirInventoryList.xls");
    };
    //#endregion

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getbookingtrans().then(() => {
                dispatch(loaderActions.end());
            });
            setitems();
        } else {
            ClearLocalStorage();
            dispatch({
                type: "SESSIONOUT",
                message: "Your Session Expired. Please Login Again!",
            });
        }
    }, []);

    // useEffect(() => {
    //     let pagelink = user?.find(x => {
    //         if (x.PageLink === window.location.pathname)
    //             return x.MenuId
    //     });
    //     let mid = user?.find((x) => x.MenuId === pagelink.MenuId);
    //     if (mid?.URightView === false && mid?.URightView !== undefined) {
    //         navigate("/dashboard");
    //     }
    //     user?.filter((x) => x.MenuId === pagelink.MenuId).map((obj) => {
    //         setUserExport(obj.URightExport);
    //     });
    // }, [user]);

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Air Inventory List</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        {/* <button type="button" disabled={!useradd} class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={handleOpenSupplier} >Create New Supplier</button> */}
                        <button type="button" class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={handleCreateAirInventory} >Create Air Inventory</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={AirListData}
                        PdfExport={PDFExport}
                        ExportToExcel={ExcelExport}
                        disabled={!userexport}
                    />

                </form>
                {/* <Routes>
                        <Route path="/*" element={<Suppliermaster />} />
                </Routes> */}
            </div>
        </>
    )
}

export default AirInventoryList
