import React, { useEffect, useRef, useState } from 'react'
import CustomSelection from '../../customControl/CustomSelection'
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { TimePicker } from 'antd';
import Switch from 'react-switch';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import { ImageDelete, ImageUpload } from '../../ImageUpload/ImageCommon';
import dayjs from 'dayjs';

const CruiseInventoryMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Cruise = useLocation();
    const mediacollection = useState([{
        DocName: '',
        FilePath: '',
        FileName: '',
        SeqNo: 0
    }]);
    const [cruiseId, setCruiseId] = useState(0);
    const [retriveData, setRetriveData] = useState([]);
    const [datacollection, setDataCollection] = useState(mediacollection);
    const [counter, setCounter] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(false);
    const [cleardisable, setClearDisable] = useState(false);
    const [backdisable, setBackDisable] = useState(false);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [travelScopeList, setTravelScopList] = useState([]);
    const [travelTypeList, setTravelTypeList] = useState([]);
    const [supplierCode, setSupplierCode] = useState('');
    const [portCode, setPortCode] = useState('')
    const [departureDate, setDepartureDate] = useState(null);
    const [departureTime, setDepartureTime] = useState(null)
    const [arrivalDate, setArrivalDate] = useState(null);
    const [arrivalTime, setArrivalTime] = useState(null);
    const [supplierAllList, setSupplierAllList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [supplierdisable, setSupplierDisable] = useState(false);
    const [supplierid, setSupplierId] = useState(0);
    const [locationAllList, setLocationAllList] = useState([]);
    const [departurelocationList, setDepartureLocationList] = useState([]);
    const [departurelocationdisable, setDepartureLocationDisable] = useState(false);
    const [departurelocationid, setDepartureLocationId] = useState(0);
    const [arrivallocationList, setArrivalLocationList] = useState([]);
    const [arrivallocationdisable, setArrivalLocationDisable] = useState(false);
    const [arrivallocationid, setArrivalLocationId] = useState(0);
    const [packageAllList, setPackageAllList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [packagedisable, setPackageDisable] = useState(false);
    const [packageid, setPackgeId] = useState(0);
    const [portAllList, setPortAllList] = useState([]);
    const [departureportList, setDeparturePortList] = useState([]);
    const [departureportdisable, setDeparturePortDisable] = useState(false);
    const [departureportid, setDeparturePortId] = useState(0);
    const [arrivalportList, setArrivalPortList] = useState([]);
    const [arrivalportdisable, setArrivalPortDisable] = useState(false);
    const [arrivalportid, setArrivalPortId] = useState(0);
    const [mealid, setMealId] = useState(false);
    const [formValue, setFormValue] = useState({
        InventoryCruiseId: 0,
        TravelScopeId: 2,
        TravelTypeId: 2,
        PackageId: 0,
        VPackageId: 0,
        SupplierId: 0,
        CruiseName: "",
        CruiseNo: "",
        DepartureDate: null,
        DepartureTime: null,
        DepartureLocationId: 0,
        DeparturePortId: 0,
        ArrivalDate: null,
        ArrivalTime: null,
        ArrivalLocationId: 0,
        ArrivalPortId: 0,
        TravelDuration: '',
        IsMeal: false,
        ImageName: "ImageName1",
        ImagePath: "ImagePath1",
        IsActive: false,
        UserId: 0
    });
    const getalldropdown = async () => {
        await getAllData("/master/v1/getotherinventorycruise").then((data) => {
            setTravelScopList(data.data[0].travelScopeList);
            setTravelTypeList(data.data[0].travelTypeList);
            setSupplierAllList(data.data[0].supplierList);
            setSupplierList(data.data[0].supplierList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.supplierId,
                label: el.supplierName,
                supplierCode: el.supplierCode,
            })))
            setLocationAllList(data.data[0].locationList);
            setDepartureLocationList(data.data[0].locationList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.locationId,
                label: el.city
            })))
            setArrivalLocationList(data.data[0].locationList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.locationId,
                label: el.city
            })))
            setPackageAllList(data.data[0].packageList);
            setPackageList(data.data[0].packageList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.packageId === 0 ? el.vPackageId : el.packageId,
                packageId: el.packageId,
                vPackageId: el.vPackageId,
                label: el.packageName
            })))
            setPortAllList(data.data[0].portList);
            setDeparturePortList(data.data[0].portList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.accessPointPortId,
                label: el.portName,
                portCode: el.portCode
            })));
            setArrivalPortList(data.data[0].portList.filter(IsAct => IsAct.isActive === true).map((el) => ({
                value: el.accessPointPortId,
                label: el.portName,
                portCode: el.portCode
            })))
            if (Cruise.state.InventoryCruiseId > 0) {
                handleRetrive();
                setClearDisable(true)
            }

            dispatch(loaderActions.end());
        })
    }
    const mealList = [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
    ]
    const handleChangeScope = (e, id) => {
        setFormValue({ ...formValue, TravelScopeId: id });
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleChangeType = (e, id) => {
        setFormValue({ ...formValue, TravelTypeId: id })
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleSupplier = (e) => {
        setFormValue({ ...formValue, SupplierId: e.value })
        setSupplierId(e.value);
        setSupplierCode(e.supplierCode);
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleDepartureLocation = (e) => {
        setFormValue({ ...formValue, DepartureLocationId: e.value })
        setDepartureLocationId(e.value);
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleArrivalLocation = (e) => {
        setFormValue({ ...formValue, ArrivalLocationId: e.value })
        setArrivalLocationId(e.value)
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleDateChange = (fieldname, e) => {
        if (fieldname === 'DepartureDate') {
            if (e === null) {
                setFormValue({ ...formValue, DepartureDate: null })
            }
            else {
                setFormValue({ ...formValue, DepartureDate: formatDate(e) + "T" + "00:00:00" })
            }
            setDepartureDate(e);
        }
        else if (fieldname === 'ArrivalDate') {
            if (e === null) {
                setFormValue({ ...formValue, ArrivalDate: null })
            }
            else {
                setFormValue({ ...formValue, ArrivalDate: formatDate(e) + "T" + "00:00:00" })
            }
            setArrivalDate(e)
        }
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleTime = (time, el) => {
        setEditDisabled(true);
        let x = new Date(time)
        let hours = x.getHours();
        let minut = x.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minut < 10) {
            minut = '0' + minut
        }
        if (el === "DepartureTime") {
            if (time === null) {
                setFormValue({ ...formValue, DepartureTime: null })
            }
            else {
                setFormValue({ ...formValue, DepartureTime: formatDate(time) + "T" + hours + ':' + minut })
            }
            setDepartureTime(time);
        }
        if (el === "ArrivalTime") {
            if (time === null) {
                setFormValue({ ...formValue, ArrivalTime: null })
            }
            else {
                setFormValue({ ...formValue, ArrivalTime: formatDate(time) + "T" + hours + ':' + minut })
            }
            setArrivalTime(time);
        }
        setEditDisabled(false);
        setFormErrors({});
    }
    const handlepackage = (e) => {
        setFormValue({ ...formValue, PackageId: e.packageId, VPackageId: e.vPackageId })
        if (e.vPackageId === 0) {
            setPackgeId(e.packageId)
        }
        else {
            setPackgeId(e.vPackageId)
        }
        setEditDisabled(false);
        setFormErrors({});
    }

    const handleChange = (e) => {
        var result;
        if ([e.target.name][0] === 'price' || [e.target.name][0] === 'dayHours') {
            result = e.target.value
        }
        else {
            result = e.target.value;
        }
        setFormValue({ ...formValue, [e.target.name]: result })
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleDeparturePort = (e) => {
        setFormValue({ ...formValue, DeparturePortId: e.value });
        setDeparturePortId(e.value);
        setPortCode(e.portCode)
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleArrivalPort = (e) => {
        setFormValue({ ...formValue, ArrivalPortId: e.value });
        setArrivalPortId(e.value);
        setEditDisabled(false);
        setFormErrors({});
    }
    const handleMeal = (e) => {
        setFormValue({ ...formValue, IsMeal: e.value });
        setMealId(e.value)
        setEditDisabled(false);
        setFormErrors({});
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    }

    //#region Active/Inactive button
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormErrors({});
        }
        setModalShow(false);
    }
    //#endregion


    //#region Clear form
    const handlebackClick = () => {
        clear();
        navigate('/Cruiseinventorylist');
    }
    const handleClear = () => {
        clear();
    }
    function clear() {
        setIsSubmit(false);
        setButtonAddEditText('Add');
        setFormValue({
            ...formValue,
            TravelScopeId: 2,
            TravelTypeId: 2,
            PackageId: 0,
            VPackageId: 0,
            SupplierId: 0,
            CruiseName: "",
            CruiseNo: "",
            DepartureDate: null,
            DepartureTime: null,
            DepartureLocationId: 0,
            DeparturePortId: 0,
            ArrivalDate: null,
            ArrivalTime: null,
            ArrivalLocationId: 0,
            ArrivalPortId: 0,
            TravelDuration: 0,
            IsMeal: 0,
            ImageName: "ImageName1",
            ImagePath: "ImagePath1",
            IsActive: false,
            UserId: 0
        });
        setFormErrors({});
        // document.getElementById("txtbannerimages").value = "";
        setActiveId(false);
        setEditDisabled(false);
    }
    //#endregion
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validate(formValue));
    };
    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        // form.datacollection = datacollection?.map(value => ({
        //     SeqNo: value.SeqNo,
        //     DocName: value.DocName,
        //     FileName: value.FileName,
        //     FilePath: value.FilePath
        // }));
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                //alert('Data Inserted Successfully');
                const SupplierData = await postUsersData('/master/v1/insertinventorycruise', formValue)
                const status = SupplierData.status;
                if (status) {
                    // const filelen = filedatacollection?.length;
                    // for (var i = 0; i < filelen; i++) {
                    //     try {
                    //         await handleimageupload('CMS/SupplierDetail/AffilitionCertificate/', filedatacollection[i].FileName, filedatacollection[i].File);
                    //     }
                    //     catch (err) {
                    //         dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //         setDisabled(false);
                    //         setClearDisable(false);
                    //         setEditDisabled(false);
                    //         setBackDisable(false);
                    //         return;
                    //     }

                    // }

                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    clear();
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const SupplierData = await patchUsersData('/master/v1/updateinventorycruise', formValue)
                const status = SupplierData.status;
                //const  status = true;
                if (status) {
                    // const deletefile = deletefilelist?.length;
                    // if (deletefile > 0) {
                    //     for (var d = 0; d < deletefile; d++) {
                    //         try {
                    //             await handleimageDelete(deletefilelist[d].FileName);
                    //         }
                    //         catch (err) {
                    //             dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //             // dispatch({ type: 'ALERTING', message: err })
                    //             // alert(err);
                    //             setDisabled(false);
                    //             setClearDisable(false);
                    //             setEditDisabled(false);
                    //             setBackDisable(false);
                    //             return;
                    //         }
                    //     }
                    // }

                    // const filelen = filedatacollection?.length;
                    // for (var i = 0; i < filelen; i++) {
                    //     try {
                    //         await handleimageupload('CMS/SupplierDetail/AffilitionCertificate/', filedatacollection[i].FileName, filedatacollection[i].File);
                    //     }
                    //     catch (err) {
                    //         dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //         // dispatch({ type: 'ALERTING', message: err })
                    //         // alert(err);
                    //         setDisabled(false);
                    //         setClearDisable(false);
                    //         setEditDisabled(false);
                    //         setBackDisable(false);
                    //         return;
                    //     }

                    // }
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    clear();
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: SupplierData.message })
                    setDisabled(false);
                    setClearDisable(false);
                    setEditDisabled(false);
                    setBackDisable(false);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }
    const validate = (values) => {
        setIsSubmit(true);
        const error = {}
        if (values.PackageId === 0 && values.VPackageId === 0) {
            error.PackageId = "Please select package name";
            setIsSubmit(false);
        }
        if (values.SupplierId === 0) {
            error.SupplierId = "Please select supplier name";
            setIsSubmit(false);
        }
        if (!values.CruiseName || values.CruiseName?.trim()?.length === 0) {
            error.CruiseName = "Please enter cruise Name";
            setIsSubmit(false);
        }
        if (!values.CruiseNo || values.CruiseNo?.trim()?.length === 0) {
            error.CruiseNo = "Please enter cruise No";
            setIsSubmit(false);
        }
        if (values.DepartureDate === null) {
            error.DepartureDate = "Please enter departure Date"
            setIsSubmit(false);
        }
        if (values.DepartureTime === null) {
            error.DepartureTime = "Please enter departure Time"
            setIsSubmit(false);
        }
        if (values.DepartureLocationId === 0) {
            error.DepartureLocationId = "Please select departure city";
            setIsSubmit(false);
        }
        if (values.DeparturePortId === 0) {
            error.DeparturePortId = "Please select departure port";
            setIsSubmit(false);
        }
        if (values.ArrivalDate === null) {
            error.ArrivalDate = "Please enter arrival date"
            setIsSubmit(false);
        }
        if (new Date(values.ArrivalDate) < new Date(values.DepartureDate)) {
            error.ArrivalDate = "Please enter valid arrival date"
            setIsSubmit(false);
        }
        if (values.ArrivalTime === null) {
            error.ArrivalTime = "Please enter arrival time"
            setIsSubmit(false);
        }
        if (values.ArrivalLocationId === 0) {
            error.ArrivalLocationId = "Please select arrival city";
            setIsSubmit(false);
        }
        if (values.ArrivalPortId === 0) {
            error.ArrivalPortId = "Please select arrival port";
            setIsSubmit(false);
        }
        if (values.IsMeal === 0) {
            error.IsMeal = "Please select meal";
            setIsSubmit(false);
        }
        if (values.ImagePath === 'string' && values.ImagePath?.trim()?.length === 0) {
            error.ImagePath = "Please select document";
            setIsSubmit(false);
        }
        if (values.IncorporationNo?.length > 20) {
            error.IncorporationNo = "Maximum length is 20 characters.";
            setIsSubmit(false);
        }
        // if (values.IncorporationCertiPath?.length > 0 && incorporationfiletype !== "pdf") {
        //     error.IncorporationCertiPath = "Only PDF file can be uploaded.";
        //     setIsSubmit(false);
        // }
        const datacollectionlen = datacollection?.filter(obj => {
            return obj.SeqNo > 0;
        });
        return error;
    }
    const handleRetrive = async () => {
        setButtonAddEditText("Update");
        const InventoryCruiseId = Cruise.state.InventoryCruiseId;
        await postUsersData('/master/v1/getinventorycruisebyId?InventoryCruiseId=' + InventoryCruiseId).then((res) => {
            console.log(res,'res')
            setRetriveData(res.data)
        })
    }
    const RetriveData = () => {
        retriveData?.map(obj => {
            setFormValue({
                ...formValue,
                InventoryCruiseId: obj.InventoryCruiseId,
                TravelScopeId: obj.TravelScopeId,
                TravelTypeId: obj.TravelTypeId,
                PackageId: obj.PackageId,
                VPackageId: obj.VPackageId,
                SupplierId: obj.SupplierId,
                CruiseName: obj.CruiseName,
                CruiseNo: obj.CruiseNo,
                DepartureDate: obj.DepartureDate,
                DepartureTime: formatDate(new Date()) + "T" + obj.DepartureTime.hours + ":" + obj.DepartureTime.minutes + ':00',
                DepartureLocationId: obj.DepartureLocationId,
                DeparturePortId: obj.DeparturePortId,
                ArrivalDate: obj.ArrivalDate,
                ArrivalTime: formatDate(new Date()) + "T" + obj.ArrivalTime.hours + ":" + obj.ArrivalTime.minutes + ':00',
                ArrivalLocationId: obj.ArrivalLocationId,
                ArrivalPortId: obj.ArrivalPortId,
                TravelDuration: obj.TravelDuration,
                IsMeal: obj.IsMeal,
                ImageName: obj.ImageName,
                ImagePath: obj.ImagePath,
                IsActive: obj.IsActive,
            });
            if (obj.PackageId === 0) {
                setPackgeId(obj.VPackageId)
            }
            else {
                setPackgeId(obj.PackageId)
            }
            setSupplierId(obj.SupplierId);
            setSupplierCode(obj.SupplierCode);
            setDepartureDate(new Date(obj.DepartureDate));
            setDepartureTime(dayjs(formatDate(new Date()) + "T" + obj.DepartureTime.hours + ":" + obj.DepartureTime.minutes + ':00'));
            setDepartureLocationId(obj.DepartureLocationId);
            setDeparturePortId(obj.DeparturePortId);
            setArrivalDate(new Date(obj.ArrivalDate));
            setArrivalTime(dayjs(formatDate(new Date()) + "T" + obj.ArrivalTime.hours + ":" + obj.ArrivalTime.minutes + ':00'));
            setArrivalLocationId(obj.ArrivalLocationId);
            setArrivalPortId(obj.ArrivalPortId);
            setMealId(obj.IsMeal);
            setPortCode(obj.PortCode)
            // Check In-Active Data and add in Dropdown...
        });
    }
    useEffect(() => {
        if (retriveData?.length === 1) {
            RetriveData();
            dispatch(loaderActions.end());
        }
    }, [retriveData]);
    const PackageId = useRef(null);
    const SupplierId = useRef(null);
    const DepartureLocationId = useRef(null);
    const DeparturePortId = useRef(null);
    const ArrivalLocationId = useRef(null);
    const ArrivalPortId = useRef(null);


    useEffect(() => {
        // Be sure that sequence of form fields and sequence of formErrors variables/fields are same, because every time focusing is done by the formErrors Array's top value. 
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setClearDisable(true);
            setEditDisabled(true);
            setBackDisable(true);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "PackageId" || id === "SupplierId" || id === "DepartureLocationId" || id === "DeparturePortId" || id ==="ArrivalLocationId" || id ==="ArrivalPortId") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            formValue.UserId = token?.userMasterID
            if (Cruise.state != null) {
                getalldropdown()
                setCruiseId(Cruise.state.InventoryCruiseId);
                clear();
                setDisabled(Cruise.state.disabled);
                setEditDisabled(Cruise.state.editdisabled);
            }
            else {
                navigate('/Intracitylistmaster')
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    function formateTime(time) {
        let x = new Date(time)
        let hours = x.getHours();
        let minut = x.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minut < 10) {
            minut = '0' + minut
        }
        return time === null ? null : [hours, minut].join(':');
    }
    const getTotalDuration = (date1, date2) => {
        let dt1 = new Date(date1)
        let dt2 = new Date(date2)

        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        let time = diff / 3600
        let HourMinute = JSON.stringify(time).split('.')
        let hour = HourMinute[0]
        let minute = Math.round((time - hour) * 60)
        const duration = (hour >= 10 ? hour : '0' + hour) + ":" + (minute >= 10 ? minute : '0' + minute)
        setFormValue({ ...formValue, TravelDuration: duration })
    }
    useEffect(() => {
        if (departureTime !== null && arrivalTime !== null && departureDate !== null && arrivalDate !== null) {
            let d = formatDate(formValue.DepartureDate) + "T" + formateTime(formValue.DepartureTime)
            let a = formatDate(formValue.ArrivalDate) + "T" + formateTime(formValue.ArrivalTime)
            getTotalDuration(d, a)
        }

    }, [departureTime, arrivalTime, departureDate, arrivalDate])

    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }

    return (
        <>

            <div className="container-fluid mt-4 heading_color">
                <h3>Cruise Inventory</h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="mb-3">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Travel Scope</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        {travelScopeList?.map((el) => (
                                                            <>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={'TravelScope' + el.travelScopeId}
                                                                    name="TravelScopeRadio"
                                                                    value={formValue.TravelScopeId}
                                                                    checked={formValue.TravelScopeId === el.travelScopeId}
                                                                    onChange={(e) => handleChangeScope(e, el.travelScopeId)}
                                                                    disabled={disabled}
                                                                />
                                                                <label className='form-check-label ms-1 me-3' htmlFor="TravelScopeRadio1">{el.travelScope}</label>
                                                            </>
                                                        ))}
                                                        {/* {formErrors.TripName && <p className='text-danger text-start'>{formErrors.TripName}</p>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Trip Type</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        {travelTypeList?.map((el) => (
                                                            <div className="form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id={'TravelTypeRadio1' + el.travelTypeId}
                                                                    name="TravelTypeRadio"
                                                                    value={formValue.TravelTypeId}
                                                                    checked={formValue.TravelTypeId === el.travelTypeId}
                                                                    onChange={(e) => handleChangeType(e, el.travelTypeId)}
                                                                    disabled={disabled}
                                                                />
                                                                <label className='form-check-label ms-1' htmlFor="TravelTypeRadio1">One-Way</label>
                                                            </div>
                                                        ))}
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Select Package Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            id='PackageId'
                                                            ref={PackageId}
                                                            options={packageList}
                                                            value={packageList?.filter(function (packageList) {
                                                                return (packageList.value === packageid);
                                                            })}// set list of the dataalue 
                                                            onChange={handlepackage} // assign onChange function
                                                            isDisabled={disabled}

                                                        />
                                                        {formErrors.PackageId && <p className='text-danger text-start mb-0'>{formErrors.PackageId}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown select_dropdown"
                                                            id='SupplierId'
                                                            ref={SupplierId}
                                                            options={supplierList}
                                                            value={supplierList?.filter(function (supplierList) {
                                                                return supplierList.value === supplierid;
                                                            })}// set list of the dataalue 
                                                            onChange={handleSupplier} // assign onChange function
                                                            isDisabled={disabled ? true : false}
                                                        />
                                                        {formErrors.SupplierId && <p className='text-danger text-start mb-0'>{formErrors.SupplierId}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Code</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="suppliercode"
                                                            name="SupplierCode"
                                                            value={supplierCode}
                                                            // onChange={handleChange}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Cruise Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="CruiseName"
                                                            name="CruiseName"
                                                            value={formValue.CruiseName}
                                                            onChange={handleChange}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {formErrors.CruiseName && <p className='text-danger text-start mb-0'>{formErrors.CruiseName}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Cruise Number</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="CruiseNo"
                                                            name="CruiseNo"
                                                            value={formValue.CruiseNo}
                                                            onChange={handleChange}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {formErrors.CruiseNo && <p className='text-danger text-start mb-0'>{formErrors.CruiseNo}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="form_border my-3">
                                    <h5 className="mt-2">Departure City</h5>
                                    <div className="my-3">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='DepartureDate'
                                                                            selected={departureDate}
                                                                            onChange={(e) => handleDateChange('DepartureDate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Departuredate")} id="calendarBtn1" Disabled={disabled} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                    {formErrors.DepartureDate && <p className='text-danger text-start mb-0'>{formErrors.DepartureDate}</p>}
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <TimePicker
                                                                        id='DepartureTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled}
                                                                        placeholder='--:--'
                                                                        value={departureTime}
                                                                        format={'HH:mm'}
                                                                        onChange={(e) => handleTime(e, 'DepartureTime')} />
                                                                    {formErrors.DepartureTime && <p className='text-danger text-start mb-0'>{formErrors.DepartureTime}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown select_dropdown"
                                                                ref={DepartureLocationId}
                                                                id="DepartureLocationId"
                                                                options={departurelocationList}
                                                                value={departurelocationList?.filter(function (departurelocationList) {
                                                                    return departurelocationList.value === departurelocationid;
                                                                })}// set list of the dataalue 
                                                                onChange={handleDepartureLocation} // assign onChange function
                                                                isDisabled={disabled}

                                                            />
                                                            {formErrors.DepartureLocationId && <p className='text-danger text-start mb-0'>{formErrors.DepartureLocationId}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Port Name</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown select_dropdown"
                                                                id='"DeparturePortId'
                                                                ref={DeparturePortId}
                                                                options={departureportList}
                                                                value={departureportList?.filter(function (departureportList) {
                                                                    return departureportList.value === departureportid;
                                                                })}// set list of the dataalue 
                                                                onChange={handleDeparturePort} // assign onChange function
                                                                isDisabled={disabled}

                                                            />
                                                            {formErrors.DeparturePortId && <p className='text-danger text-start mb-0'>{formErrors.DeparturePortId}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Port Code</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="portcode"
                                                                name="PortCode"
                                                                value={portCode}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form_border my-3">
                                    <h5 className="mt-2">Arrival City</h5>
                                    <div className="my-3">

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='ArrivalDate'
                                                                            selected={arrivalDate}
                                                                            onChange={(e) => handleDateChange('ArrivalDate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Arrivaldate")} id="calendarBtn1" Disabled={disabled} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                    {formErrors.ArrivalDate && <p className='text-danger text-start mb-0'>{formErrors.ArrivalDate}</p>}
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <TimePicker
                                                                        id='ArrivalTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled}
                                                                        placeholder='--:--'
                                                                        value={arrivalTime}
                                                                        format={'HH:mm'}
                                                                        onChange={(e) => handleTime(e, 'ArrivalTime')} />

                                                                    {formErrors.ArrivalTime && <p className='text-danger text-start mb-0'>{formErrors.ArrivalTime}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown select_dropdown"
                                                                ref={ArrivalLocationId}
                                                                id="ArrivalLocationId"
                                                                options={arrivallocationList}
                                                                value={arrivallocationList?.filter(function (arrivallocationList) {
                                                                    return arrivallocationList.value === arrivallocationid;
                                                                })}// set list of the dataalue 
                                                                onChange={handleArrivalLocation} // assign onChange function
                                                                isDisabled={disabled}

                                                            />
                                                            {formErrors.ArrivalLocationId && <p className='text-danger text-start mb-0'>{formErrors.ArrivalLocationId}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Arrival Port Name</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                ref={ArrivalPortId}
                                                                id="ArrivalPortId"
                                                                className="dropdown select_dropdown"
                                                                options={arrivalportList}
                                                                value={arrivalportList?.filter(function (arrivalportList) {
                                                                    return arrivalportList.value === arrivalportid;
                                                                })}// set list of the dataalue 
                                                                onChange={handleArrivalPort} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {formErrors.ArrivalPortId && <p className='text-danger text-start mb-0'>{formErrors.ArrivalPortId}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Total Duration</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="TravelDuration"
                                                                name="TravelDuration"
                                                                value={formValue.TravelDuration}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Meal</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                id="IsMeal"
                                                                className="dropdown select_dropdown"
                                                                options={mealList}
                                                                value={mealList?.filter(function (mealList) {
                                                                    return mealList.value === mealid;
                                                                })}// set list of the dataalue 
                                                                onChange={handleMeal} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {formErrors.IsMeal && <p className='text-danger text-start mb-0'>{formErrors.IsMeal}</p>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className='d-lg-flex'>
                                    <div className="mt-1 col-lg-12 col-sm-12">
                                        <div className='row'>
                                            <div className="col-lg-2 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                                <label htmlFor="inputPassword6" className="col-form-label">Upload Document</label>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-1">
                                                <input
                                                    name='UploadDocument'
                                                    type="text"
                                                    id="uploaddocument"
                                                    className="form-control"
                                                    value={formValue.ImageName || ''}
                                                    // onChange={onChange}
                                                    disabled={disabled}
                                                    placeholder='Upload Document'
                                                />
                                                {/* {formErrors.Affiliations && <p className='text-danger text-start mb-0'>{formErrors.Affiliations}</p>}
                                                {formafflitionerror && <p className='text-danger text-start mb-0'>{formafflitionerror}</p>} */}
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-12 mb-lg-1 mb-md-1">
                                                <div className="input-group  mb-1">
                                                    <input
                                                        name='UploadDocumentfile'
                                                        type="file"
                                                        id="UploadDocumentfile"
                                                        className="form-control btn_add"
                                                        accept=".pdf"
                                                        // onChange={handleuploadAffilitionCertifycate}
                                                        disabled={disabled ? true : false}
                                                    />
                                                    <label className="input-group-text" htmlFor="UploadDocumentfile">Browse</label>

                                                </div>
                                                {/* {affilitionstypeerror && <p className='text-danger text-start mb-0'>{affilitionstypeerror}</p>} */}
                                            </div>
                                            <div className="mb-1 col-lg-1 col-md-12 col-sm-12">
                                                <div className="col-lg-1 col-md-12 col-sm-12 justify-content-md-center d-lg-block d-md-flex mb-lg-1">
                                                    {/* <button type="button" onClick={handleClick} className="btn btn-primary mx-1" id="btnAdd" disabled={addisenableaff ? false : true}>Add</button> */}
                                                    <button type="button" className="btn btn-primary mx-lg-1" id="btnAdd" >Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {datacollection?.map((element, index) => {
                                    if (counter > 0) {
                                        return (
                                            <div className='d-lg-flex d-md-flex' key={index}>
                                                <div className="mb-1 col-lg-10 ms-lg-5">
                                                    <div className='row'>
                                                        <div className=" col-lg-3 mb-1"></div>
                                                        <div className=" col-lg-3 col-md-6 col-sm-12 mb-1">
                                                            <input
                                                                // onChange={handleOnChange}
                                                                className="form-control"
                                                                key={index}
                                                                value={element.DocName || ''}
                                                                readOnly
                                                                type="text"
                                                            ></input>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-1 mb-md-1">
                                                            <div className="input-group  mb-1">
                                                                <input
                                                                    type="text"
                                                                    key={index}
                                                                    value={element.FileName || ''}
                                                                    //onChange={handleOnChange}
                                                                    readOnly
                                                                    className="form-control btn_add"
                                                                    id="inputGroupFile02" />
                                                                <label className="input-group-text" htmlFor="inputGroupFile02">Browse</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-1 mt-lg-0 mt-md-1 col-lg-2 me-lg-5">
                                                    <div className="col-lg-1 col-md-2 col-sm-12 mb-lg-1">
                                                        {/* <button type="button" className="btn btn-primary mx-1" id="btnRemove" onClick={(e) => { removeAffilitionDoc(element.SeqNo, e); }} disabled={disabled ? true : false}>&times;</button> */}
                                                        <button type="button" className="btn btn-primary mx-1" id="btnRemove" disabled={disabled ? true : false}>&times;</button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                })}

                                <div className="col-lg-6 col-sm-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="active" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-8 " disabled={false}>
                                            <Switch
                                                id='active'
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-5 d-flex justify-content-center">
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={(e) => onSubmitForm(e)} disabled={editdisabled}>{buttonaddeditText}</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={cleardisable} >Clear</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable}>Back</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default CruiseInventoryMaster