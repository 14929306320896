import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { getAllData } from '../../../services/ApiCallingUserServices';
import { useSelector } from "react-redux";
import { GetMenuIdFromStorage } from '../../commonClass/Menudect';
import { generatePDF } from '../../commonClass/CommonValidation';
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch } from "react-redux";
import { loaderActions } from '../../../_actions/loader.actions';
import CustomTable from '../../../customControl/CustomTable';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

function AttractionsList() {
    const user = useSelector(store => store.userstatus.tba);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userRights, setUserRights] = useState({});
    const [attractionsList, setAttractionsList] = useState([]);
    const [searchby, setSearchby] = useState('8');
    const [searchValue, setSearchValue] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const [serchdisable, setSerchdisable] = useState(false)
    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        };
        setUserRights(mid);
    }, [user]);

    const createNewAttraction = () => {
        navigate('/Attractionsmaster', {
            state: {
                disabled: false,
                clearDisable: false,
                editdisabled: false,
                attraction: [],
                viewtype: 'Add',
                attractionId: 0,
                attractionsList: attractionsList,
                userRights: userRights
            }
        });
    }

    const getAttractionData = async () => {
        await getAllData('/master/v1/getallattractionbasic').then((attractionList) => {
            setAttractionsList((attractionList.data !== null && attractionList.data !== undefined) ? attractionList.data : [])
            setFilterData(attractionList.data);
            dispatch(loaderActions.end());
        });
    }

    const updateAttractionData = async () => {
        await getAllData('/master/v1/getallattractionbasic').then((attractionList) => {
            setFilterData(attractionList.data);
        });
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getAttractionData();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    const columns = [
        {
            name: 'Attraction Name',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.AttractionName
        },
        {
            name: 'Theme',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.ThemeName
        },
        {
            name: 'Type',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.TypeName
        },
        {
            name: 'Category',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.CategoryName
        },
        {
            name: 'City',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.City
        },
        {
            name: 'State',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.StateName
        },
        {
            name: 'Active',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => data?.IsActive ? 'Active' : 'In Active'
        },
        {
            name: 'Created Date',
            sortable: true,
            // float: "left",
            // reorder: true,
            selector: (data) => formatDate(data?.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (data) => userRights?.URightEdit ? <button type='button' className="border-0 p-0 bg-body set_disableclr"
                disabled={!userRights?.URightEdit}
            ><Link
                className="border-0 p-0 bg-body set_disableclr remove_hover_effect"
                to="/Attractionsmaster"
                state={{
                    disabled: false,
                    clearDisable: true,
                    editdisabled: true,
                    attraction: data,
                    viewtype: 'update',
                    attractionId: data?.AttractionBasicId,
                    attractionsList: attractionsList,
                    userRights: userRights
                }}>
                    <i className="fa fa-edit"
                    >
                    </i>
                </Link>
            </button> : <button type='button' className='border-0 p-0 bg-body set_disableclr'
                disabled={!userRights?.URightEdit}
            ><i className="fa fa-edit"
            ></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (data) => <button type='button' className='border-0 p-0 bg-body set_disableclr '
                disabled={!userRights?.URightView}
            >
                <Link to="/Attractionsmaster"
                    className='remove_hover_effect'
                    state={{
                        disabled: true,
                        clearDisable: true,
                        editdisabled: true,
                        attraction: data,
                        viewtype: 'view',
                        attractionId: data?.AttractionBasicId,
                        attractionsList: attractionsList,
                        userRights: userRights
                    }}>
                    <i className="fa fa-eye "
                    ></i>
                </Link>
            </button>

        }
    ];

    const serachoption = [{
        label: 'Attraction Name',
        value: '1'
    },
    {
        label: 'Theme',
        value: '2'
    },
    {
        label: 'Type',
        value: '3'
    },
    {
        label: 'Category',
        value: '4'
    },
    {
        label: 'City',
        value: '5'
    },
    {
        label: 'State',
        value: '6'
    },
    {
        label: 'Active',
        value: '7'
    },
    {
        label: 'All',
        value: '8'
    }];




    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch(e);
        };
    };

    const handleSearchBy = (val) => {
        setSearchby(val.value);
    };
    const handleChangeSearchValue = (e) => {
        setsclshow(true);
        setSearchValue(e.target.value);
        if (e.target.value === '') {
            getAttractionData();
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        var filteritem = filterData;

        if (searchValue.trim().length > 0 && searchby) {
            updateAttractionData();
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.AttractionName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.ThemeName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.TypeName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec?.CategoryName?.toLowerCase().includes(searchValue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec?.City?.toLowerCase()?.includes(searchValue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec?.StateName?.toLowerCase()?.includes(searchValue.toLowerCase()));
            }
            else if (searchby === '7') {
                if (searchValue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchValue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '8') {
                if (searchValue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec =>
                        issec.AttractionName?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.ThemeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.TypeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.CategoryName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.StateName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || formatDate(issec?.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchValue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec =>
                        issec?.AttractionName?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.ThemeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.TypeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.CategoryName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.StateName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || formatDate(issec?.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec =>
                        issec.AttractionName.toLowerCase().includes(searchValue.toLowerCase())
                        || issec.ThemeName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.TypeName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.CategoryName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.City.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchValue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec =>
                        issec.AttractionName.toLowerCase().includes(searchValue.toLowerCase())
                        || issec.ThemeName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.TypeName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.CategoryName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.City.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec.StateName.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec =>
                        issec?.AttractionName?.toLowerCase().includes(searchValue.toLowerCase())
                        || issec?.ThemeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.TypeName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.CategoryName?.toLowerCase().includes(searchValue.trim().toLowerCase())
                        || issec?.City.toLowerCase()?.includes(searchValue.trim().toLowerCase())
                        || issec?.StateName.toLowerCase()?.includes(searchValue.trim().toLowerCase())
                        || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
                    );
                }
            }
            setAttractionsList(filterdata)
        }
    }

    const clearsearch = () => {
        getAttractionData();
        setSearchValue("");
        // setSearchby('8');
        setsclshow(false);
        // getAddOnList();
    };

    const formatDateRequired = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    function PdfExport() {
        const columns = [
            "Attraction Name",
            "Theme",
            "Type",
            "Category",
            "City",
            "State",
            "Active",
            "Created Date",
        ];
        var rows = [];
        for (let i = 0; i < attractionsList?.length; i++) {
            var temp = [
                attractionsList[i].AttractionName,
                attractionsList[i].ThemeName,
                attractionsList[i].TypeName,
                attractionsList[i].CategoryName,
                attractionsList[i].City,
                attractionsList[i].StateName,
                `${attractionsList[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(attractionsList[i]?.CreatedOn),
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "Attractionmaster", "Attraction Master")
    };
    const ExportToExcel = () => {
        const displayname = [
            "Attraction Name",
            "Theme",
            "Type",
            "Category",
            "City",
            "State",
            "Active",
            "Created Date",
        ];
        const fields = ['AttractionName', 'ThemeName', 'TypeName', 'CategoryName', 'City', 'StateName', 'IsActive', 'CreatedOn'];
        ExportExcel(displayname, fields, attractionsList, 'Attraction Master', 'Attractionmaster.xls')
    }
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Attraction List</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button type="button"
                            disabled={!userRights?.URightAdd}
                            class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd"
                            onClick={createNewAttraction}
                        >
                            Create Attraction
                        </button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    // isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        onKeyDown={onKeyDownHandler}
                                        type="text" id="btnSearchText" class="form-control"
                                        placeholder='Enter keyword' name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchValue}
                                    //  disabled={serchdisable ? true : false}
                                    />
                                    <button
                                        className={`${clrshow === true ? "d-block" : "d-none"}`}
                                        disabled={serchdisable ? true : false}
                                        onClick={clearsearch}
                                        type="reset"
                                    >&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                                    //  disabled={serchdisable ? true : false} 
                                    onClick={handleSearch}
                                >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={attractionsList}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userRights?.URightExport}
                    />
                </form>
            </div>
        </>
    )
}

export default AttractionsList