import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select';
import Switch from 'react-switch';
// import { getAllData } from '../../services/ApiCallingUserServices'
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import CustomSelection from '../../../../customControl/CustomSelection';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import { useDispatch } from "react-redux";
import { ClearLocalStorage } from '../../../commonClass/Session';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { onlyNumeric } from '../../../commonClass/CommonValidation';


function AddonmasterCreateForm({
  newAddOn,
  errors,
  disabled,
  setErrors,
  userRights,
  setDisabled,
  setNewAddOn,
  editdisabled,
  defaultObject,
  setEditDisabled,
  buttonaddeditText,
  setButtonAddEditText,
  setSerachDisable,
  getAddOnList,
  seletedImagePath,
  setSelectedImagePath,
  clearsearch
}) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [city, setCity] = useState([]);
  const [suppliers, setSuppliers] = useState([])
  const [allLocation, setAllLocation] = useState([]);
  const [selectedImage, setSeletedImage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  // image handle State
  const cityId = useRef(null);

  const handleAddOnForm = (name, value) => {
    let addOnDetails = { ...newAddOn };

    if (name === 'price') {
      //delete Price other propertice on change price option
      addOnDetails = deleteExtraProperty(addOnDetails, value);
    }
    setErrors({})
    setEditDisabled(false)
    setNewAddOn({ ...addOnDetails, [name]: value })
  };


  const onChangeAllowOnlyNumber = (name, value) => {
    const val = onlyNumeric(value) ? Number(onlyNumeric(value)) : ''
    handleAddOnForm(name, val);
    // if (/^\d*$/.test(value) ||) {
    //   handleAddOnForm(name, Number(value))
    //   // setErrors({ ...errors, [name]: '' })
    // }
    // else {
    //   handleAddOnForm(name, newAddOn[name] ? newAddOn[name] : '')
    // }
  }

  function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
  };

  function isValidImage(FileName) {
    return /\.(png|jpeg|jpg)$/i.test(FileName)
  }

  const handleUploadImageLoad = (e) => {
    const files = e.target.files[0];
    let fileSize = files.size / 1024;
    fileSize = fileSize / 1024

    if (newAddOn.ImagePath) {
      removeImage(newAddOn.ImagePath);
    }
    if (fileSize <= 5 && isValidImage(files.name)) {
      const filecom = getDateCombination();
      const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
      setNewAddOn({ ...newAddOn, ImageName: filename, ImagePath: 'CMS/AddOn/' + filename })
      setErrors({ ...errors, addOnImage: '' });
      setSeletedImage(files);
      setEditDisabled(false)
      setSelectedImagePath('')
    }
    else {
      setErrors({ ...errors, addOnImage: 'Kindly upload image in Jpeg or PNG format with max size 5 mb' })
      document.getElementById("addonImange").value = "";
      setNewAddOn({ ...newAddOn, ImageName: '', ImagePath: '' })
      return;
    }
  };

  const removeImage = () => {
    document.getElementById("addonImange").value = "";
    setNewAddOn({ ...newAddOn, ImageName: '', ImagePath: '' })
    setSeletedImage('');
    setEditDisabled(false)
    setSelectedImagePath('')
  };

  const isValidFormField = () => {
    let errors = {};
    let isError = false
    if (!newAddOn?.LocationId) {
      errors['LocationId'] = 'Kindly select the city';
      isError = true
    }
    if (!newAddOn?.AddOnName) {
      errors['AddOnName'] = 'Kindly enter add on name';
      isError = true
    }
    if (!newAddOn?.SupplierId) {
      errors['SupplierId'] = 'Kindly select the supplier';
      isError = true
    }
    if (newAddOn?.price === 'perPersion') {
      if (typeof newAddOn?.AdultPrice === 'string' || newAddOn?.AdultPrice === undefined) {
        errors['AdultPrice'] = 'Kindly enter adult price';
        isError = true
      }

      if (typeof newAddOn?.ChildPrice === 'string' || newAddOn?.ChildPrice === undefined) {
        errors['ChildPrice'] = 'Kindly enter child price';
        isError = true
      }
    } else {
      if (typeof newAddOn.TotalPirce === 'string' || newAddOn?.TotalPirce === undefined) {
        errors['TotalPirce'] = 'Kindly enter total price';
        isError = true
      }
    }
    setErrors(errors);
    return isError
  };


  const getAddOnCityOption = async () => {
    await getAllData('/master/v1/getaddoncity').then((cityOption) => {
      setCity(cityOption.data)
    });
  };

  const getAddonSupplier = async () => {
    await getAllData('/master/v1/getaddonsupplier').then((supplier) => {
      setSuppliers(supplier.data)
    });
  };

  useEffect(() => {
    getAddOnCityOption();
    getAddonSupplier();
  }, [])


  const cityOptions = useMemo(() => city.filter(city => city.IsActive).map((city) => ({
    label: city.City,
    value: city.LocationId,
    IsActive: city.IsActive
  }
  )), [city, buttonaddeditText]);

  const cityDisable = (LocationId) =>
    city.find((city) => city.LocationId === LocationId);

  const getCityOption = (cityOptions) => {
    if (buttonaddeditText === 'Add') {
      return cityOptions
    } else {
      let currentCity = cityDisable(newAddOn.LocationId)
      return currentCity?.IsActive === true ? cityOptions : [...cityOptions, {
        label: currentCity.City,
        value: currentCity.LocationId,
        IsActive: currentCity.IsActive
      }]
    }
  }
  const supplierOptions = useMemo(
    () => suppliers.filter(supplier => supplier.IsActive).map((supplier) => (
      {
        label: supplier.SupplierName,
        value: supplier.SupplierId,
        IsActive: supplier.IsActive
      }
    ))
    , [suppliers]);

  const getalllocationbyCity = async (locationid) => {
    if (locationid) {
      const locid = { LocationId: locationid }
      await postUsersData('/master/v1/getlocationbyid', locid).then((AllLocationData) => {
        setAllLocation(AllLocationData.data);
      });
    }
  }

  useEffect(() => {
    getalllocationbyCity(newAddOn?.LocationId)
  }, [newAddOn.LocationId])


  const continent = newAddOn.LocationId ?
    allLocation?.map(value => ({
      label: value.continentName,
      value: 1
    })) : []


  const country =
    newAddOn.LocationId ? allLocation?.map(value => ({
      label: value.countryName,
      value: 1
    })) : []

  const region = newAddOn.LocationId ? allLocation?.map(value => ({
    label: value.regionName,
    value: 1
  })) : []

  const state = newAddOn.LocationId ? allLocation?.map(value => ({
    label: value.stateName,
    value: 1
  })) : []

  const deleteExtraProperty = (addOnDetails, value) => {
    if (value === 'perPersion') {
      delete addOnDetails['TotalPirce']
    } else {
      delete addOnDetails['AdultPrice'];
      delete addOnDetails['ChildPrice']
    }
    return addOnDetails;
  };

  const handleimageupload = async (pathdirectory, filename, file) => {
    await ImageUpload(pathdirectory, filename, file);
  };

  const handleimageDelete = async (filename) => {
    await ImageDelete(filename);
  };

  const handleSubmit = async () => {
    let statusoftoken = await verifytoken();
    const form = newAddOn
    if (!isValidFormField()) {
      if (buttonaddeditText === 'Add') {
        if (statusoftoken) {
          setDisabled(true)

          let status = false;
          const addOndata = await postUsersData('/master/v1/insertaddon', form);
          status = addOndata.status;

          if (status !== 400) {
            try {
              if (!addOndata.status && !addOndata.data) {
                setDisabled(false);
                setEditDisabled(true);
                return;
              }
              if (addOndata.status) {
                if (addOndata.data > 0) {
                  await handleimageupload('CMS/AddOn/', form.ImageName, selectedImage);
                  clear();
                  dispatch({ type: 'ALERTING', message: addOndata.message });
                  setNewAddOn(defaultObject);
                  getAddOnList();
                  setDisabled(false)
                }
                else {
                  dispatch({ type: 'ALERTING', message: addOndata.message })
                  return;
                }
              }

            }
            catch (err) {
              dispatch({ type: 'ALERTING', message: err })
              setDisabled(false);
              setEditDisabled(true);
              return;
            }
          }
          else {
            dispatch({ type: 'ALERTING', message: addOndata.message })
            setDisabled(false);
            setEditDisabled(true);
            return;
          }
        }
        else {
          ClearLocalStorage();
          dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
      }
      else {
        if (statusoftoken) {
          let status1 = false;
          const addonUpdate = await patchUsersData('/master/v1/updateaddon', form)
          status1 = addonUpdate.status;
          if (status1 !== 400) {
            try {
              if (!addonUpdate.status && !addonUpdate.data) {
                setDisabled(false);
                setEditDisabled(true);
                return;
              }
              if (addonUpdate.status) {

                if (seletedImagePath === '') {
                  await handleimageupload('CMS/AddOn/', form.ImageName, selectedImage);
                }

                try {
                  if (form.ImagePath.length === 0) {
                    await handleimageDelete(form.ImagePath);
                  }
                }
                catch (err) {
                  dispatch({ type: 'ALERTING', message: err })
                  setDisabled(false);
                  setEditDisabled(true);
                  return;
                }
                clear();
                getAddOnList();
                dispatch({ type: 'ALERTING', message: addonUpdate.message })
              }

            }
            catch (err) {
              dispatch({ type: 'ALERTING', message: err })
              setDisabled(false);
              setEditDisabled(true);
              return;
            }
          }
          else {
            dispatch({ type: 'ALERTING', message: addonUpdate.message })
            setDisabled(false);
            setEditDisabled(true);
            return;
          }
        }
        else {
          ClearLocalStorage();
          dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
      }
    }
  }



  function clear() {
    setButtonAddEditText('Add');
    setNewAddOn(defaultObject);
    setErrors({});
    setSerachDisable(false);
    setSeletedImage('');
    setSelectedImagePath('');
    clearsearch();
    document.getElementById("addonImange").value = "";
    setDisabled(false);
  };

  const handleIsActive = (value) => {
    if (value === true) {
      handleAddOnForm('IsActive', !newAddOn.IsActive);
      setModalShow(false);
    } else {
      setModalShow(false)
    }
  };

  return (
    <div className="container-fluid ">
      <form>
        <div className="mb-3 row ">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className=" row justify-content-lg-around justify-content-md-start">

              <div className='col-lg-2 col-sm-6 '>
                <div className="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" className="col-form-label required">Continent</label>
                </div>
                <div className="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={continent?.[0]}
                    options={continent}
                  />
                </div>
              </div>
              <div className='col-lg-2 col-sm-6 '>
                <div className="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" className="col-form-label required">Country</label>
                </div>
                <div className="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={country}
                    options={country}
                  />
                </div>
              </div>
              <div className='col-lg-2 col-sm-6 '>
                <div className="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" className="col-form-label required">Region</label>
                </div>
                <div className="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={region}
                    options={region}
                  />
                </div>
              </div>
              <div className='col-lg-2 col-sm-6 '>
                <div className="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6" className="col-form-label required">State</label>
                </div>
                <div className="col-lg-12 mb-1 col-md-12">
                  <Select className="dropdown select_dropdown"
                    isDisabled
                    value={state}
                    options={state}
                  />
                </div>
              </div>
              <div className='col-lg-2 col-sm-6 '>
                <div className="col-lg-12 col-md-12">
                  <label htmlFor="inputPassword6"
                    className="col-form-label required"
                  >City</label>
                </div>
                <div className="col-lg-12 mb-1 col-md-12">

                  <CustomSelection
                    selId="CityId"
                    selRef={cityId}
                    datalist={getCityOption(cityOptions)}
                    fieldid={newAddOn.LocationId}
                    SelectionChange={(value) => {
                      handleAddOnForm('LocationId', value.value);
                    }}
                    isdisable={disabled}
                    isactivedisable={newAddOn.LocationId ? cityDisable(newAddOn.LocationId)?.IsActive === true ? false : true : false}
                    issearchable={true}
                  />
                  {errors.LocationId && <p className='text-danger text-start'>{errors.LocationId}</p>}
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row justify-content-between mx-lg-2 mt-3">
                <div className="col-lg-5 col-md-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1 ">
                      <label htmlFor="" className='col-form-label required'> Add on name</label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                      <input
                        type="text"
                        id='AddOnName'
                        name='AddOnName'
                        className='form-control'
                        placeholder='Add On Name'
                        value={newAddOn.AddOnName}
                        disabled={disabled}
                        maxLength={150}
                        onChange={(e) => handleAddOnForm(e.target.name, e.target.value)}
                      />
                      {errors.AddOnName && <p className='text-danger text-start'>{errors.AddOnName}</p>}
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-1 ">
                      <label htmlFor="SupplierId" className='col-form-label required'>Select Supplier</label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 mb-1">
                      <Select
                        id='supplierId'
                        name='SupplierId'
                        className="dropdown select_dropdown"
                        options={supplierOptions}
                        value={supplierOptions?.filter((supplier) =>
                          supplier.value === newAddOn.SupplierId
                        )}
                        onChange={(value) => { handleAddOnForm('SupplierId', value.value) }}
                        isDisabled={disabled}
                      />
                      {errors?.SupplierId && <p className='text-danger text-start'>{errors?.SupplierId}</p>}
                    </div>
                  </div>
                </div>

              </div>

              <div className="row justify-content-between mx-lg-2 mt-3">
                <div className="col-lg-5 col-md-6">
                  <div className='row'>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <label htmlFor="addOnImage" className="col-form-label"> Allocate Inventory</label>
                    </div>
                    <div className=" col-lg-8 col-md-12 col-sm-12">
                      <input
                        type="text"
                        id="allocateInventory"
                        className="form-control"
                        name="AllocateInventory"
                        value={newAddOn.AllocateInventory}
                        onChange={(e) => {
                          onChangeAllowOnlyNumber(e.target.name, e.target.value);
                        }}
                        placeholder='Allocate Inventory'
                        maxLength={3}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className='row'>
                    <div className="col-lg-4 col-md-12  mb-1">
                      <label htmlFor="addOnImage" className="col-form-label">Upload Image</label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                      <div className="input-group">

                        <input
                          name='addOnImage'
                          type="file"
                          id="addonImange"
                          onChange={handleUploadImageLoad}
                          className="form-control btn_add"
                          accept=".png,.jpg"
                          disabled={disabled}
                        />
                        <label className="input-group-text" htmlFor="addonImange">Browse</label>
                      </div>
                      {errors?.addOnImage && <p className='text-danger text-start'>{errors?.addOnImage}</p>}
                      {newAddOn.ImagePath && <p className='text-start text-break' data-bs-toggle="tooltip" data-bs-placement="left" title={newAddOn.ImagePath}>
                        {newAddOn.ImagePath}</p>}
                    </div>
                  </div>
                </div>

              </div>

              <div className="row justify-content-between mx-lg-2 mt-lg-2">
                <div className='col-lg-5 col-md-5 col-sm-12'>

                  <div className='row'>
                    <div className="col-lg-4 col-md-12 col-sm-12 ">
                      <label htmlFor="inputPassword6" className="col-form-label required"> Price</label>
                    </div>

                    <div className=" col-lg-8 col-md-12 col-sm-12 align-self-center mt-lg-2">
                      <div className='justify-content-between align-self-center d-flex'>
                        <div className=" form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="price"
                            id="perPersion"
                            value="perPersion"
                            checked={newAddOn.price === "perPersion" ? true : false}
                            onChange={(e) => {
                              handleAddOnForm(e.target.name, e.target.value);
                            }}
                            required
                            disabled={disabled}
                          />
                          <label className="form-check-label" htmlFor="perPersion">Per person</label>
                        </div>
                        <div className=" form-check form-check-inline ">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="price"
                            id="totalPrice"
                            value="totalPrice"
                            checked={newAddOn.price === "totalPrice" ? true : false}
                            onChange={(e) => {
                              handleAddOnForm(e.target.name, e.target.value);

                            }}
                            required
                            disabled={disabled}
                          />
                          <label className="form-check-label" htmlFor="totalPrice">Total Price</label>
                        </div>
                      </div>

                      {
                        newAddOn.price === 'perPersion' ?
                          <div className='row justify-content-between'>
                            <div className='col-lg-4'>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <label htmlFor="adult" className="col-form-label required"> Adult</label>
                              </div>
                              <input
                                type="text"
                                id="AdultPrice"
                                className="form-control w-5"
                                name="AdultPrice"
                                value={newAddOn.AdultPrice}
                                onChange={(e) => onChangeAllowOnlyNumber(e.target.name, e.target.value)}
                                placeholder="Adult Price"
                                maxLength={5}
                                disabled={disabled}
                              />
                              {errors.AdultPrice && <p className='text-danger text-start'>{errors.AdultPrice}</p>}
                            </div>

                            <div className='col-lg-4'>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <label htmlFor="inputPassword6" className="col-form-label required"> Child</label>
                              </div>
                              <input
                                type="text"
                                id="ChildPrice"
                                className="form-control"
                                name="ChildPrice"
                                value={newAddOn.ChildPrice}
                                onChange={(e) => onChangeAllowOnlyNumber(e.target.name, e.target.value)}
                                placeholder="Child"
                                maxLength={5}
                                disabled={disabled}
                              />
                              {errors.ChildPrice && <p className='text-danger text-start'>{errors.ChildPrice}</p>}
                            </div>
                          </div>

                          :
                          <div className='row d-flex justify-content-end mt-lg-1'>
                            <div className='col-lg-6 col-sm-12 d-flex justify-content-end'>
                              {/* <div className="col-lg-12 col-md-12 col-sm-12">
                                <label htmlFor="inputPassword6" className="col-form-label required"> Adult</label>
                              </div> */}
                              <div className=" col-lg-8 col-md-6 col-sm-12">
                                <input type="text"
                                  id="totalPirce"
                                  className="form-control"
                                  name="TotalPirce"
                                  value={newAddOn.TotalPirce}
                                  pattern="/^\d*$/"
                                  onChange={(e) => onChangeAllowOnlyNumber(e.target.name, e.target.value)}
                                  placeholder="Total Pirce"
                                  maxLength={5}
                                  disabled={disabled}
                                />
                                {errors.TotalPirce && <p className='text-danger text-start'>{errors.TotalPirce}</p>}
                              </div>
                            </div>
                          </div>

                      }
                    </div>

                  </div>
                  <div className='row mt-3'>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <label for="inputPassword6" className="col-form-label"> Active</label>
                    </div>

                    <div className=" col-lg-8 col-md-12 col-sm-12 align-self-center">
                      <Switch
                        checked={newAddOn.IsActive}
                        name='IsActive'
                        className="react-switch"
                        value={newAddOn.IsActive}
                        onChange={(e) => {
                          if (buttonaddeditText === 'Add') {
                            handleAddOnForm('IsActive', !newAddOn.IsActive);
                          } else {
                            setModalShow(true)
                          }
                        }}
                        disabled={disabled}
                      />
                      <ConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        noconfirm={() => handleIsActive(false)}
                        yesconfirm={() => handleIsActive(true)}
                      />
                      {/* {formErrors.BannerName && <p className='text-danger text-start'>{formErrors.BannerName}</p>} */}
                    </div>

                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 text-center ">
                  {selectedImage &&
                    (
                      <div className="mx-auto mt-2 test ">
                        <img
                          src={URL.createObjectURL(selectedImage)} alt='' className='img-fluid w-75'
                        />
                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                      </div>
                    )}

                  {!selectedImage && seletedImagePath && (
                    <div className="mx-auto mt-2" >
                      <img
                        src={seletedImagePath} className="img-fluid w-75" alt=''
                      />
                      <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-4">
          {buttonaddeditText === "Add" ?
            <button type="button" class="btn btn-primary mx-1" id="btnAdd" disabled={!userRights?.URightAdd || disabled} onClick={(e) => handleSubmit(e)}  >Add</button> :
            <button class="btn btn-primary mx-1" id="btnAdd" type='button' disabled={!userRights?.URightEdit || editdisabled} onClick={(e) => handleSubmit(e)} >Update</button>
          }
          <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={() => clear()}  >Clear</button>
        </div>
      </form>
    </div>
  )
}

export default memo(AddonmasterCreateForm);