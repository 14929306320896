/* eslint-disable no-useless-concat */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../../../assets/css/master.css';
import Select from 'react-select';
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { onlyAlphabets, generatePDF } from '../../commonClass/CommonValidation'
import Switch from 'react-switch';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../customControl/CustomTable';

const Airlinemaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // var store = useContext(ReactReduxContext).store.getState();

    //////////////////////////////declaration of variable/////////////////////////////
    //#region declaration of variable///////////////////////////////////////////////
    // const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [postdata, setPostData] = useState([]);
    const [activeId, setActiveId] = useState(false);
    const [airlinelistdata, setAirlineListData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);

    const [airlineerrorMessage, setAirlineErrorMessage] = useState('');
    const [airlinelengerrorMessage, setAirlinelengErrorMessage] = useState('');
    const [codeerrorMessage, setCodeErrorMessage] = useState('');
    const [codelengerrorMessage, setCodelengErrorMessage] = useState('');
    const [airlineduplicateerrorMessage, setAirlineDuplicateErrorMessage] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const isSuccess = useSelector(store => store.alertMess.isSuccess)
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const serachoption = [
        {
            label: 'Airline Name',
            value: '1'
        },
        {
            label: 'Airline Code',
            value: '2'
        },
        {
            label: 'Active',
            value: '3'
        },
        // {
        //     label: 'Created Date',
        //     value: '5'
        // },
        {
            label: 'All',
            value: '4'
        },

    ];

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');

    //#endregion ////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////use effect section////////////////////////////////
    //#region use effect section
    useEffect(() => {
    }, [airlineerrorMessage, airlinelengerrorMessage, codeerrorMessage, codelengerrorMessage]);
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getAirlineDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])
    useEffect(() => {
        let pagelink = user?.find(x =>{
            if( x.PageLink === window.location.pathname)
                return  x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        // let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        if (mid?.URightView === false && mid?.URightView!== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId ===  pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);
          
        });
    }, [user])
 
    useEffect(() => {
    }, [postdata])

    //#endregion
    ///////////////////////////////////////////////////////////////////////////////////

    ////////////handle section of table add, edit,retrive/////////////////////////////
    //#region handle section of table add, edit,retrive

    // set form date for add, edit and view
    const [formValue, setFormValue] = useState(
        {
            AirlineId: 0,
            AirlineName: '',
            AirlineCode: '',
            IsActive: false,
        }
    );
    // set form date for add, edit and view

    const validateData = () => {
        let error = []
        if (typeof formValue.AirlineName === 'string' && formValue.AirlineName?.trim()?.length === 0) {
            setAirlineErrorMessage('Please enter Airline name.')
            error.push('AirlineName');
        }
        if (typeof formValue.AirlineName === 'string' && formValue.AirlineName?.trim()?.length > 50) {
            setAirlinelengErrorMessage('Maximum length allowed for Airline name is 50 characters.');
            error.push('AirlineName');
        }
        if (typeof formValue.AirlineCode === 'string' && formValue.AirlineCode?.trim()?.length === 0) {
            setCodeErrorMessage('Please enter Airline Code.')
            error.push('AirlineCode');
        }
        if (typeof formValue.AirlineCode === 'string' && formValue.AirlineCode?.trim()?.length > 6) {
            setCodelengErrorMessage('Maximum length allowed for Airline code is 6 digits.');
            error.push('AirlineCode');
        }
        document.getElementById(error[0]).focus();
    }

    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (formValue.AirlineName?.trim()?.length === 0 || formValue.AirlineName?.trim()?.length > 50 || formValue.AirlineCode?.trim()?.length === 0 || formValue.AirlineCode?.trim()?.length > 6) {
                    validateData();
                    return;
                }
                else {
                    if ((typeof formValue.AirlineName === 'string' && formValue.AirlineName?.trim()?.length > 0) && (typeof formValue.AirlineCode === 'string' && formValue.AirlineCode?.trim()?.length > 0)) {
                        var filterdata;
                        filterdata = airlinelistdata?.filter(issec => issec.airlineName.toLowerCase() === formValue.AirlineName.toLowerCase() && issec.airlineCode.toLowerCase() === formValue.AirlineCode.toLowerCase());
                        if (filterdata?.length > 0) {
                            setAirlineDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }
                    if (statusoftoken) {
                        const AirlineData = await postUsersData("/master/v1/insertairline", formValue);
                        setPostData(AirlineData.data);
                        if (!AirlineData.status && !AirlineData.data) {
                            setAirlineDuplicateErrorMessage(AirlineData.message);
                            return;
                        }
                        if (AirlineData.status) {
                            if (AirlineData.data > 0) {
                                setitems();
                                dispatch({ type: 'ALERTING', message: AirlineData.message })
                                // alert('Airline master Record saved successfully');
                                //window.location.reload(false);
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: AirlineData.message })
                                // alert('Airline master Record not saved successfully')
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: AirlineData.message })
                            // alert('Something went wrong!')
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                        // alert('Your Session Expired. Please Login Again!');
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                // alert('Your Session Expired. Please Login Again!');
            }
        }
        else {
            if (statusoftoken) {
                if (formValue.AirlineName?.trim()?.length === 0 || formValue.AirlineName?.trim()?.length > 50 || formValue.AirlineCode?.trim()?.length === 0 || formValue.AirlineCode?.trim()?.length > 6) {
                    validateData();
                    return;
                }
                else {
                    if ((typeof formValue.AirlineName === 'string' && formValue.AirlineName?.trim()?.length > 0) && (typeof formValue.AirlineCode === 'string' && formValue.AirlineCode?.trim()?.length > 0)) {
                        var filterdata;
                        filterdata = airlinelistdata?.filter(issec => issec.airlineId !== formValue.AirlineId && issec.airlineName.toLowerCase() === formValue.AirlineName.toLowerCase() && issec.airlineCode.toLowerCase() === formValue.AirlineCode.toLowerCase());
                        if (filterdata?.length > 0) {
                            setAirlineDuplicateErrorMessage('You can not enter duplicate value.');
                            return;
                        }
                    }

                    if (statusoftoken) {
                        const AirlineData = await patchUsersData("/master/v1/updateairline", formValue);
                        setPostData(AirlineData.data);
                        if (!AirlineData.status && !AirlineData.data) {
                            setAirlineDuplicateErrorMessage(AirlineData.message);
                            return;
                        }
                        if (AirlineData.status) {
                            setitems();
                            dispatch({ type: 'ALERTING', message: AirlineData.message })
                            // alert('Airline master Record updated successfully');
                            //window.location.reload(false);
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: AirlineData.message })
                            // alert('Something went wrong!' + 'err')
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                        // alert('Your Session Expired. Please Login Again!');
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                // alert('Your Session Expired. Please Login Again!');
            }
        }
    }


    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }

    function RetriveData(id) {
        setAirlineErrorMessage('');
        setCodeErrorMessage('');
        setAirlinelengErrorMessage('');
        setCodelengErrorMessage('');
        setAirlineDuplicateErrorMessage('');
        airlinelistdata?.map(obj => {
            if (obj.airlineId === id) {
                // let active = !obj.isActive;
                { formValue.AirlineName = obj.airlineName }
                { formValue.AirlineCode = obj.airlineCode }
                { formValue.AirlineId = obj.airlineId }
                { formValue.IsActive = obj.isActive }
                setActiveId(obj.isActive);
            }
        });
        setFormValue({ ...formValue, formValue })
    }
    //#endregion //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value
    const getAirlineDataList = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const AirlineListdata = await getAllData("/master/v1/getallairline");
            //setDestinationListDataP(DestinationListData.data);
            setAirlineListData(AirlineListdata.data);
        }
    }

    const onChange = (e) => {
        var result;

        if ([e.target.name][0] === 'AirlineName') {
            result = onlyAlphabets(e.target.value)

        }
        else {
            result = e.target.value;
        }
        setEditDisabled(true);
        setAirlineErrorMessage('');
        setAirlinelengErrorMessage('');
        setCodeErrorMessage('');
        setCodelengErrorMessage('');
        setAirlineDuplicateErrorMessage('');
        setFormValue({ ...formValue, [e.target.name]: result });
    };

    // const handleSectionChange = (e) => {
    //     // { formValue.SectionId = e.value }
    //     // setSectionId(e.value);
    //     setAirlineDuplicateErrorMessage('');
    //     // setSectionErrorMessage('');
    //     setEditDisabled(true);
    //     //{formValue.SectionName = e.label}
    // }
    // const handleisActive = (e) => {
    //     // {formValue.InActive = e.target.checked}
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }

    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            { formValue.IsActive = e }
            setActiveId(!activeId)
            setAirlineErrorMessage('');
            setAirlinelengErrorMessage('');
            setCodeErrorMessage('');
            setCodelengErrorMessage('');
            setAirlineDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
            // setAirlineErrorMessage('');
            // setAirlinelengErrorMessage('');
            // setCodeErrorMessage('');
            // setCodelengErrorMessage('');
            // setAirlineDuplicateErrorMessage('');
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            { formValue.IsActive = !activeId }
            setActiveId(!activeId)
            setEditDisabled(true);
            setAirlineErrorMessage('');
            setAirlinelengErrorMessage('');
            setCodeErrorMessage('');
            setCodelengErrorMessage('');
            setAirlineDuplicateErrorMessage('');
        }
        setModalShow(false);
    }

    const handleClearClick = () => {
        //window.location.reload();
        clear();
    }
    function clear() {

        setAirlineErrorMessage('');
        setCodeErrorMessage('');
        setAirlinelengErrorMessage('');
        setCodelengErrorMessage('');
        setAirlineDuplicateErrorMessage('');
        setFormValue({ ...formValue, AirlineName: '', AirlineCode: '', IsActive: false });
        setActiveId(false);
        //{ formValue.InActive = false}
        getAirlineDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('4');
        setSearchValue('');
        setsclshow(false)

    }
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////// handle table pagignation.//////////////////////////////////////////
    //#region table Pagination
    const columns = [
        // {
        //     name: 'Sr No',
        //     minWidth: "8%",
        //     selector: (location) => location.destinationId,

        // },
        {
            name: 'Airline Name',
            sortable: true,
            float: "left",
            // minWidth: "50%",
            selector: (air) => air.airlineName,
            // style:  { backgroundColor: 'green' }
        },

        {
            name: 'Airline Code',
            sortable: true,
            float: "left",
            selector: (air) => air.airlineCode
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'Active' : 'InActive'}`}
            // </td>

            selector: (air) => air.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (air) => formatDate(air.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.destinationId)}> <i className="fa fa-edit"></i></td >
            selector: (air) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(air.airlineId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            //selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.destinationId)}><i className="fa fa-eye"></i></td>
            selector: (air) => <i className="fa fa-eye" onClick={() => handleViewMode(air.airlineId)}></i>
        }
    ];
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////

    /////////////////////handle search value//////////////////////////////////////////////
    //#region handle search value////////////////////////////////////////////////////////
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const AirlineListData = await getAllData("/master/v1/getallairline");
        setFilterItems(AirlineListData.data);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getAirlineDataList();
            setsclshow(false)
        }

    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '4') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.airlineName.toLowerCase().includes(searchvalue.toLowerCase()) || issec.airlineCode.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setAirlineListData(filterdata)
        }
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ////////////////handle export section///////////////////////////////////////////////
    //#region handle export section ////////////////////////////////////////////////////
    const PdfExport = () => {
        //print
        const columns = [
            "Airline Name",
            "AirlineCode",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < airlinelistdata?.length; i++) {
            var temp = [
                airlinelistdata[i].airlineName,
                airlinelistdata[i].airlineCode,
                `${airlinelistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(airlinelistdata[i].createdDate),
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "AirlineMaster", "Airline Master")
    }

    const ExportToExcel = () => {
        const dispalyname = ['Airline Name', 'Airline Code', 'Active', 'Created Date'];
        const fields = ['airlineName', 'airlineCode', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, airlinelistdata, "AirlineMaster", "AirlineMaster.xls");
    }


    Array.prototype.forEach.call(document.querySelectorAll('.clearable-input'), function (el) {
        var input = el.querySelector('input');

        conditionallyHideClearIcon();
        input.addEventListener('input', conditionallyHideClearIcon);
        el.querySelector('[data-clear-input]').addEventListener('click', function (e) {
            //getAirlineDataList();
            input.value = '';
            conditionallyHideClearIcon();
            setSearchValue('');
            getAirlineDataList();

        });

        function conditionallyHideClearIcon(e) {
            var target = (e && e.target) || input;
            target.nextElementSibling.style.display = target.value ? 'block' : 'none';
        }
    });
    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getAirlineDataList();
    }
    //#endregion ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Airline Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="">
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {airlineduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {airlineduplicateerrorMessage} </div>}
                                <div class="mb-lg-1 col-lg-2 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required">Airline Name</label>
                                </div>
                                <div class="mb-lg-1 col-lg-4 col-md-8 col-sm-12">
                                    <input type="text" id="AirlineName" class="form-control" name="AirlineName" value={formValue.AirlineName} onChange={onChange} disabled={disabled ? true : false} placeholder="Airline Name" />
                                    {airlineerrorMessage && <div className="text-danger"> {airlineerrorMessage} </div>}
                                    {airlinelengerrorMessage && <div className="text-danger"> {airlinelengerrorMessage} </div>}

                                </div>

                                <div class="mb-lg-1 col-lg-2 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label required" >Airline Code</label>
                                </div>
                                <div class="mb-lg-1 col-lg-4 col-md-8 col-sm-12">
                                    <input type="text" id="AirlineCode" class="form-control" name="AirlineCode" value={formValue.AirlineCode} onChange={onChange} disabled={disabled ? true : false} placeholder="Airline Code" />
                                    {codeerrorMessage && <div className="text-danger"> {codeerrorMessage} </div>}
                                    {codelengerrorMessage && <div className="text-danger"> {codelengerrorMessage} </div>}

                                </div>

                                <div class=" col-lg-2 col-md-12 col-sm-12">
                                    <label for="inputPassword6" class="col-form-label">Active</label>
                                </div>
                                <div class=" col-auto col-lg-4">
                                    <Switch
                                        onChange={handleisActive}
                                        checked={activeId}
                                        className="react-switch"
                                        disabled={disabled ? true : false}
                                    />
                                </div>
                                {/* <div class=" col-auto col-lg-4"> */}
                                <ConfirmationModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    noconfirm={() => handleConfirmation(false)}
                                    yesconfirm={() => handleConfirmation(true)}
                                />

                                {/* </div> */}
                            </div>
                        </div>

                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button>
                            : <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}  >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    defaultValue={serachoption[3]}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}
                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                {/* <div class="clearable-input w-100">
                                    <input type="text" id="btnSearchText" class="form-control" name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <span data-clear-input>&times;</span>
                                </div> */}
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={airlinelistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default Airlinemaster;


