import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData } from '../../../services/ApiCallingUserServices';
import { generatePDF } from '../../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import CustomTable from '../../../customControl/CustomTable';


const Hotellistmasterold = () => {
    const dispatch = useDispatch();
    /////////////////////declartion of variable ///////////////////////////////////////
    //#region declartion of variable
    const navigate = useNavigate();
    // const [postdata, setPostData] = useState([]);
    // const [activeId, setActiveId] = useState();
    const [hotellistD, setHotelList] = useState([]);
    //const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    // const [disabled, setDisabled] = useState(false);
    // const [editdisabled, setEditDisabled] = useState(true);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('7');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    //const { items, requestSort, sortConfig } = useSortableData(taglistdata);

    const serachoption = [
        {
            label: 'Hotel Name',
            value: '1'
        },
        {
            label: 'Address',
            value: '2'
        },
        {
            label: 'Area',
            value: '3'
        },
        {
            label: 'Rating',
            value: '4'
        },
        {
            label: 'Google Location',
            value: '5'
        },
        {
            label: 'Active',
            value: '6'
        },
        // {
        //     label: 'Created Date',
        //     value: '8'
        // },
        {
            label: 'All',
            value: '7'
        },

    ];

    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////set form date for add, edit and view////////////////////


    //////////////////////////////use effect section //////////////////////////////////
    //#region  use effect section
    useEffect(() => {
        let token = GetLocalStorage();
        dispatch(loaderActions.start());
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            gethoteldatalist().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])

    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);

        if (window.location.pathname !== '/dashboard') {

            let pagelink = user?.find(x => {
                if (x.PageLink === window.location.pathname)
                    return x.MenuId
            });
            let mid = user?.find(x => x.MenuId === pagelink.MenuId);
            if (mid?.URightView === false && mid?.URightView !== undefined) {
                navigate('/dashboard');
            }
            user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
                setUserAdd(obj.URightAdd);
                setUserEdit(obj.URightEdit);
                setUserExport(obj.URightExport);

            });
        }
    }, [user])
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////////////


    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value


    const gethoteldatalist = async () => {
        const hotellistD = await getAllData('/master/v1/getallhotel');
        setHotelList(hotellistD.data);
    }



    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////serch and export sction////////////////////////////////////////////
    //#region handling search 

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const hotellistD = await getAllData('/master/v1/getallhotel');
        setFilterItems(hotellistD.data);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            gethoteldatalist();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.Address.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.Area.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.IsActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.CreatedDate).toLowerCase().includes("%#$&%^"));
                }

            }
            else if (searchby === '7') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === false);
                    filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Address.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Area.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()) || issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Address.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Area.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()) || issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Address.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Area.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()) || issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    //filterdata = filteritem.filter(issec => issec.inActive === true);
                    filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Address.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Area.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()) || issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.Name.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Address.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Area.toLowerCase().includes(searchvalue.toLowerCase()) || issec.Rating.toString().toLowerCase().includes(searchvalue.toLowerCase()) || issec.GoogleLocation.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.CreatedDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue) || 'inactive'.toLowerCase().includes(searchvalue));
                }

            }
            setHotelList(filterdata)
        }


    }
    // useEffect(() => {
    //     console.log(hotellistD, 'hotellistD')
    // }, [])

    function PdfExport() {
        //print
        const columns = [
            "Name",
            "Address",
            "Area",
            "Rating",
            "GoogleLocation",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < hotellistD?.length; i++) {
            var temp = [
                hotellistD[i].Name,
                hotellistD[i].Address,
                hotellistD[i].Area,
                hotellistD[i].Rating,
                hotellistD[i].GoogleLocation,
                `${hotellistD[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(hotellistD[i].CreatedDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "HotelMaster", "Hotel Master")
    }
    // function ExportToExcel() {
    //     const fields = ['Name', 'Address', 'Area', 'Rating', 'GoogleLocation', 'GoogleLocation', 'IsActive'];
    //     const data = hotellistD;
    //     const fileName = 'Hotelmasterexcel'
    //     const exportType = 'xls'
    //     exportFromJSON({ data, fileName, fields, exportType })
    // }
    const ExportToExcel = () => {
        const dispalyname = ['Name', 'Address', 'Area', 'Rating', 'GoogleLocation', 'Active', 'Created Date'];
        const fields = ['Name', 'Address', 'Area', 'Rating', 'GoogleLocation', 'IsActive', 'CreatedDate'];
        ExportExcel(dispalyname, fields, hotellistD, "HotelMaster", "HotelMaster.xls");
    }

    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////handle table Pagination///////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Hotel Name',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.Name,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Address',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.Address,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Area',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.Area,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Rating',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.Rating,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Google Location',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.GoogleLocation,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (hotel) => hotel.IsActive ? 'Active' : 'InActive'
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (hotel) => formatDate(hotel.CreatedDate),
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Edit',
            // float:"right",
            selector: hotel => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(hotel.HotelId)}></i></button>
            // <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} onClick={() => handleEditMode(hotel.HotelId)}> <i className="fa fa-edit"></i></td >

            // selector: (hotel) => <i className="fa fa-edit" onClick={() => handleEditMode(hotel.HotelId)}></i>
        },
        {
            name: 'View',
            // float:"right",
            selector: hotel => <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} onClick={() => handleViewMode(hotel.HotelId)}><i className="fa fa-eye"></i></td>
            // selector: (hotel) => <i className="fa fa-eye" onClick={() => handleViewMode(hotel.HotelId)}></i>
        }
    ];



    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        gethoteldatalist();
    }
    //#endregion
    ////////////////////////////////////////////////////////////////////////////////////////

    const handleOpenSupplier = () => {

        navigate('/AddHotel', { state: { disabled: false, editdisabled: false, viewtype: 'Add', HotelId: 0 } });
    }

    const handleEditMode = (id) => {
        dispatch(loaderActions.start());
        let hotelId = id;

        navigate('/AddHotel', { state: { disabled: false, editdisabled: true, viewtype: 'Update', HotelId: hotelId } });
    }

    const handleViewMode = (id) => {
        dispatch(loaderActions.start());
        let hotelId = id;

        navigate('/AddHotel', { state: { disabled: true, editdisabled: true, viewtype: 'View', HotelId: hotelId } });
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Hotel List Master</h3>
            </div>
            <div class="ontainer-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="d-flex justify-content-start ms-lg-3">
                        <button disabled={!useradd} type="button" class="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={handleOpenSupplier} >Create New Hotel</button>
                    </div>
                    <br />
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" name="SearchValue" placeholder='Enter keyword' onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={hotellistD}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default Hotellistmasterold