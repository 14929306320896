import React from 'react'

const checkIsValidImage = (newFiles) => {
    let isValidImg = true
    Object.entries(newFiles).forEach((file) => {
        const files = file[1];
        let fileSize = files?.size / 1024;
        fileSize = fileSize / 1024
        // !isValidImageFormate(file[1].name) ||
        // if (fileSize > 5) {
        //     isValidImg = false;
        //     return;
        // }
    });

    return isValidImg;
};

function getDateCombination() {
    var today = new Date();
    let date =
        today.getFullYear().toString() +
        (today.getMonth() + 1).toString() +
        today.getDate().toString();
    let time =
        today.getHours().toString() +
        (today.getMinutes() + 1).toString() +
        today.getSeconds().toString();
    return date + "_" + time;
}


function UploadButton({ formValue, setFormValue, type }) {

    const handleFileChange = (e) => {
        let newFiles = e?.target?.files;
        const isValidImage = checkIsValidImage(newFiles);
        // const totalImage = files.PropertyRoomDetailImgCollection.length + newFiles.length

        // if (totalImage > 5) {
        //     // setErrors({ ...errors, ImageError: "Maximum limit is 5" });
        //     return;
        // } else {
        //     // setErrors(defaultError)
        // }

        if (isValidImage) {
            const imageList = Object.entries(newFiles)?.reduce((acc, file, index) => {
                const filecom = getDateCombination();
                const filename = filecom + "_1_" + file[1].name.replace(/ /g, "_").replace(/-/g, "");
                acc.push({
                    ImgName: filename,
                    ImgPath: `UTHS${localStorage.getItem('POwner')}/${filename}`,
                    ImageFile: file[1],
                    needToUpload: true
                });
                return acc;

            }, []);
            setFormValue({ ...formValue, ["imageSection"]: [...formValue?.["imageSection"].map((img) => ({ ...img, images: imageList })) ]})
            // setFiles({ ...files, PropertyRoomDetailImgCollection: [...files.PropertyRoomDetailImgCollection, ...imageList] });
            // setErrors({
            //     ImageError: ''
            // })
        } else {
            // setErrors({ ...errors, ImageError: "Kindly upload in Jpeg or PNG format with max size 5 mb" })
        }
    };

    return (
        <label
            htmlFor='multiImage_uploader_AddRoom'
            className='multipleUploader rounded  bg-gray m-1 d-flex justify-content-center align-items-center cursor_pointer h-100'
            style={{ backgroundColor: '#FEEEEA' }}
        >
            <input
                type="file"
                multiple
                className='d-none'
                onChange={handleFileChange}
                id='multiImage_uploader_AddRoom'
                accept=".png,.jpeg,.jpg"
            />
            <div className='text-center py-5'>
                <div className=''>
                    <span>
                        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8704 33.4582H16.2748C16.842 32.4777 17.1667 31.3392 17.1667 30.1249L30.8704 30.1249V6.79158H10.1296V23.4684C9.05432 23.5272 8.04688 23.841 7.16667 24.3501V6.79158C7.16667 5.87492 7.45679 5.0902 8.03704 4.43742C8.61728 3.78464 9.31481 3.45825 10.1296 3.45825H30.8704C31.6852 3.45825 32.3827 3.78464 32.963 4.43742C33.5432 5.0902 33.8333 5.87492 33.8333 6.79158V30.1249C33.8333 31.0416 33.5432 31.8263 32.963 32.4791C32.3827 33.1319 31.6852 33.4582 30.8704 33.4582ZM29.3889 26.7916H16.2748C15.6065 25.6363 14.6015 24.7002 13.3939 24.1174L16.0556 20.1249L19.3889 25.1249L23.8333 18.4583L29.3889 26.7916ZM15.5 30.1249C15.5 32.8863 13.2614 35.1249 10.5 35.1249C7.73858 35.1249 5.5 32.8863 5.5 30.1249C5.5 27.3635 7.73858 25.1249 10.5 25.1249C13.2614 25.1249 15.5 27.3635 15.5 30.1249ZM10.0238 30.6011H7.16667V29.6487H10.0238V26.7916H10.9762V29.6487H13.8333V30.6011H10.9762V33.4583H10.0238V30.6011Z" fill="#B22500" />
                        </svg>
                    </span>
                    <h5 style={{ color: "#B22500", fontWeight: '700', fontSize: "18px", lineHeight: '27px' }}>Add Image</h5>
                </div>

                <div className='mt-4' style={{ fontWeight: '400', fontSize: "14px", lineHeight: '21px' }}>
                    Upto five photos can be <br /> uploaded at once
                </div>
            </div>
        </label>
    )
}

export default UploadButton