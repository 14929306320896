import React, { useEffect, useMemo, useRef, useState } from 'react'
import Switch from 'react-switch';
import CustomSelection from '../../../../customControl/CustomSelection';
import { onlyNumeric } from '../../../commonClass/CommonValidation';
import CreateTripSupplier from './CreateTripSupplier';
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { clearerrorfalse, cleartrue, tripSupplierCollectionNo, tripSupplierCollectionYes } from '../../../../_actions/user.action';

const Standardservice = (props) => {

    const {
        id,
        location
    } = props

    const isclearerror = useSelector(store => store.isClear.clearerror);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formError, setFormError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(false);
    const [cleardisable, setClearDisabled] = useState(false);
    const [attractionDisabled, setAttractionDisabled] = useState(false);

    const [buttonaddeditText, setButtonAddEditText] = useState("Save");
    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);

    const SightseeingId = useRef()
    const AttractionId = useRef()

    const [formValue, setFormValue] = useState({
        PlannerId: 0,
        PlannerServiceTypeId: id,
        SightseeingId: 0,
        State: '',
        City: '',
        Hours: '',
        KMS: '',
        IsAttraction: true,
        AttractionBasicId: 0,
        DataCollection: [],
        IsActive: false,
        UserId: 0
    })

    const [SightseeingList, setSightseeingList] = useState([])
    const [AttractionList, setAttractionList] = useState([])

    const [sightseeingactive, setSightseeingActive] = useState(false);
    const [sightid, setSightid] = useState(0)
    const [Attractionactive, setAttractionActive] = useState(false);
    const [attractionid, setAttractionid] = useState(0)

    const getplanner = async () => {

        const sightseeing = await getAllData('/master/v1/getplannersightseeing')
        setSightseeingList(sightseeing?.data?.filter(ss => ss.IsActive === true)?.map(obj => ({
            label: obj?.SightseeingName,
            value: obj?.SightseeingId,
            City: obj?.City,
            StateName: obj?.StateName,
            Hours: obj?.Hours
        })))

        const attraction = await getAllData('/master/v1/getplannerattraction')
        setAttractionList(attraction?.data?.filter(ss => ss.IsActive === true)?.map(obj => ({
            label: obj?.AttractionName,
            value: obj?.AttractionBasicId
        })))
    }


    const handleSightseeingName = (e) => {
        setFormError('')
        dispatch(tripSupplierCollectionYes())
        setSightid(e?.value)
        setFormValue({ ...formValue, SightseeingId: e?.value, State: e?.StateName, City: e?.City, Hours: e?.Hours })
        setEditDisabled(false)
    }

    const handleKMSChange = (e) => {
        setFormError('')
        dispatch(tripSupplierCollectionYes())
        let result = onlyNumeric(e.target.value) ? Number(onlyNumeric(e.target.value)) : ''
        setFormValue({ ...formValue, KMS: result })
        setEditDisabled(false)
    }

    const [checkAttractionIncluded, setCheckAttractionIncluded] = useState(true)
    const handleAttractionIncludedChange = (name, e) => {
        setFormError('')
        dispatch(tripSupplierCollectionYes())
        setAttractionid(0)
        setEditDisabled(false)
        if (name === 'yes') {
            setCheckAttractionIncluded(e.target.checked)
            setAttractionDisabled(false)
            setFormValue({ ...formValue, IsAttraction: true })
        }
        else {
            setCheckAttractionIncluded(!e.target.checked)
            setAttractionDisabled(true)
            setFormValue({ ...formValue, IsAttraction: false ,AttractionBasicId: 0 })
        }

    }

    const handleAttraction = (e) => {
        setFormError('')
        dispatch(tripSupplierCollectionYes())
        setEditDisabled(false)
        setAttractionid(e.value)
        setFormValue({ ...formValue, AttractionBasicId: e.value })
    }

    const handleisActive = (e) => {
        if (buttonaddeditText === 'Save') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setFormError('')
            dispatch(tripSupplierCollectionYes())
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setFormError('')
            dispatch(tripSupplierCollectionYes())
            setEditDisabled(false)
        }
        setModalShow(false);
    }


    const validation = (value) => {
        setIsSubmit(true);
        let error = {};
        if (sightid === 0) {
            error.SightseeingId = "Kindly select the sightseeing name";
            setIsSubmit(false);
        }
        if (value.IsAttraction && attractionid === 0) {
            error.AttractionId = "Kindly select the attraction";
            setIsSubmit(false);
        }
        if (value.DataCollection?.length === 0) {
            setIsSubmit(false);
            dispatch(tripSupplierCollectionNo())
        }
        return error;
    };

    useEffect(() => {
        if (Object.keys(formError)?.length === 0 && isSubmit) {
            handleSubmitData();
        }
        if (Object.keys(formError)?.length > 0) {
            let id = Object.keys(formError)[0];
            if (id === "SightseeingId") {
                SightseeingId.current.focus();
            }
            if (id === "AttractionId") {
                AttractionId.current.focus();
            } else {
                document.getElementById(id)?.focus();
            }
        }
    }, [formError]);


    const handleAdd = (e) => {
        e.preventDefault();
        setFormError(validation(formValue));
    }

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        formValue.KMS = formValue.KMS === '' ? 0 : formValue.KMS

        if (buttonaddeditText === 'Save') {
            if (statusoftoken) {
                const StandardInsert = await postUsersData("/master/v1/insertssplanner", formValue);

                if (StandardInsert.status) {
                    if (StandardInsert.data > 0) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: StandardInsert.message })
                        handleClear();
                    }
                    else {
                        dispatch({ type: 'ALERTINGMESSAGE', message: StandardInsert.message })
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: StandardInsert.message })
                    return;
                }

            }
            else {
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const StandardUpdate = await patchUsersData("/master/v1/updatessplanner", formValue);

                if (StandardUpdate.status) {
                    if (StandardUpdate.data > 0) {
                        dispatch({ type: 'ALERTINGMESSAGE', message: StandardUpdate.message })
                        handleClear();
                    }
                    else {
                        dispatch({ type: 'ALERTINGMESSAGE', message: StandardUpdate.message })
                        return;
                    }
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: StandardUpdate.message })
                    return;
                }

            }
            else {
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }

    const handleClear = () => {
        setFormValue({
            ...formValue,
            SightseeingId: 0,
            State: '',
            City: '',
            Hours: '',
            KMS: '',
            IsAttraction: true,
            AttractionBasicId: 0,
            DataCollection: [],
            IsActive: false,
        })
        setSightid(0)
        setAttractionid(0)
        setIsSubmit(false);
        setFormError('')
        setActiveId(false)
        setCheckAttractionIncluded(true)
        setAttractionDisabled(false)
        dispatch(cleartrue())

    }

    const handlebackClick = () => {
        navigate('/Tripplannerlist')
    }


    const RetriveData = async (PlannerId) => {
        if (PlannerId > 0) {
            const retrievesightseeing = await getAllData('/master/v1/getplannersightseeing')

            const TripData = await postUsersData('/master/v1/getplannerbyid', { PlannerId })

            const sightseeingData = retrievesightseeing?.data?.filter(ss => ss.IsActive === true && ss.SightseeingId === TripData?.data[0]?.SightseeingId).find(x => x)

            setSightid(TripData?.data[0]?.SightseeingId)

            formValue.SightseeingId = TripData?.data[0]?.SightseeingId
            formValue.State = sightseeingData?.StateName
            formValue.City = sightseeingData?.City
            formValue.Hours = sightseeingData?.Hours
            formValue.KMS = TripData?.data[0]?.KMS
            formValue.IsAttraction = TripData?.data[0]?.IsAttraction
            formValue.IsActive = TripData?.data[0]?.IsActive
            formValue.DataCollection = TripData?.data[0]?.DataCollection.map((el) => ({
                seqNo: el.seqNo,
                SupplierId: el.supplierId,
                SupplierName: el.supplierName,
                SupplierCode: el.supplierCode,
                Sedan: el.sedan,
                Innova: el.innova,
                Tempo: el.tempo,
                Coach:el.coach
            }))
            formValue.AttractionBasicId = TripData?.data[0]?.AttractionBasicId
            setCheckAttractionIncluded(TripData?.data[0]?.IsAttraction)
            setAttractionDisabled(TripData?.data[0]?.IsAttraction === true ? false : true)
            setAttractionid(TripData?.data[0]?.AttractionBasicId)
            setActiveId(TripData?.data[0]?.IsActive)
        }

    }


    useMemo(() => {
        if (isclearerror) {
            setFormError('')
            dispatch(clearerrorfalse())
        }
    }, [isclearerror])

    useEffect(() => {
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            // getvehiclelistdetails().then(() => {
            //     dispatch(loaderActions.end());
            // });
            if (location?.state != null) {
                if (location?.state?.viewtype === "Update") {
                    setButtonAddEditText(location?.state?.viewtype)
                }
                formValue.PlannerId = location?.state?.tripplannerId
                setDisabled(location?.state?.disabled);
                setEditDisabled(location?.state?.editdisabled);
                setClearDisabled(location?.state?.cleardisable);
                RetriveData(location?.state?.tripplannerId)
                getplanner()
                formValue.UserId = token?.userMasterID
            }
            else {
                navigate('/Tripplannerlist')
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])


    return (
        <>

            <div className="row">
                <div className="col-lg-6 col-sm-6 ">
                    <div className="row ">
                        <div className="col-lg-4 col-md-12  ">
                            <label className="col-form-label required">Sightseeing Name</label>
                        </div>
                        <div className="col-lg-8 my-1 col-md-12 ">
                            <CustomSelection
                                selId="SightseeingId"
                                selRef={SightseeingId}
                                datalist={SightseeingList}
                                fieldid={sightid}
                                SelectionChange={handleSightseeingName}
                                isdisable={disabled}
                                isactivedisable={sightseeingactive}
                                issearchable={true}
                            />
                            {formError.SightseeingId && <p className="text-danger mb-0">{formError.SightseeingId}</p>}
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-6 ">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-1">
                            <label className="col-form-label">State</label>
                        </div>
                        <div className="col-lg-8 my-1 col-md-12">
                            <input
                                className='form-control'
                                type="text"
                                name="state"
                                id="state"
                                placeholder='State'
                                defaultValue={formValue.State}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-6 mt-lg-4">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-1">
                            <label className="col-form-label">City</label>
                        </div>
                        <div className="col-lg-8 my-1 col-md-12">
                            <input
                                className='form-control'
                                type="text"
                                name="city"
                                id="city"
                                defaultValue={formValue.City}
                                placeholder='City'
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-6 mt-lg-1 standard_service_margin">
                    <div className="row">

                        <div className="col-lg-6 col-sm-6">
                            <div className="d-flex flex-column align-items-start">
                                <label className='text-start' htmlFor="checkin">Hours</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="Hours"
                                    name="Hours"
                                    placeholder='Hours'
                                    defaultValue={formValue.Hours}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="d-flex flex-column align-items-start">
                                <label className='text-start' htmlFor="cabin">KMS</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="KMS"
                                    name="KMS"
                                    placeholder='KMS'
                                    maxLength={4}
                                    value={formValue.KMS}
                                    onChange={(e) => handleKMSChange(e)}
                                    disabled={disabled ? true : false}
                                    autoComplete='off'
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                    <div className="row ">
                        <div className="col-lg-4 col-md-12 mb-1">
                            <label className="col-form-label required" htmlFor="VehicleName">Attraction Included</label>
                        </div>
                        <div className="col-lg-8 mb-1 col-md-12 align-self-center">
                            <div className="form-check-inline col-lg-6 col-md-6 col-12 me-0">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="AttractionRadio"
                                    id={"AttractionRadio1"}
                                    value={1}
                                    key={1}
                                    checked={checkAttractionIncluded ? true : false}
                                    onChange={(e) => handleAttractionIncludedChange('yes', e)}
                                    disabled={disabled ? true : false}
                                />
                                <label className="form-check-label ms-1" htmlFor={"AttractionRadio1"} >Yes</label>
                            </div>
                            <div className="form-check-inline col-lg-6 col-md-6 col-12 me-0">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="AttractionRadio"
                                    id={"AttractionRadio2"}
                                    value={2}
                                    key={2}
                                    checked={!checkAttractionIncluded ? true : false}
                                    onChange={(e) => handleAttractionIncludedChange('no', e)}
                                    disabled={disabled ? true : false}
                                />
                                <label className="form-check-label ms-1" htmlFor={"AttractionRadio2"} >No</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-6 ">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-1">
                            <label className="col-form-label required">Select Attraction</label>
                        </div>
                        <div className="col-lg-8 my-1 col-md-12">
                            <CustomSelection
                                selId="SightseeingId"
                                selRef={AttractionId}
                                datalist={AttractionList}
                                fieldid={attractionid}
                                SelectionChange={handleAttraction}
                                isdisable={disabled || attractionDisabled}
                                isactivedisable={Attractionactive}
                                issearchable={true}
                            />
                            {formError.AttractionId && <p className="text-danger mb-0">{formError.AttractionId}</p>}
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <label htmlFor="active" className="col-form-label">Active</label>
                        </div>
                        <div className="col-auto col-lg-8 " disabled={false}>
                            <Switch
                                id='active'
                                onChange={handleisActive}
                                checked={activeId}
                                className="react-switch"
                                disabled={disabled ? true : false}
                            />
                        </div>
                        <ConfirmationModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            noconfirm={() => handleConfirmation(false)}
                            yesconfirm={() => handleConfirmation(true)}
                        />
                    </div>
                </div>

                <CreateTripSupplier
                    supplierList={formValue.DataCollection}
                    setSupplier={setFormValue}
                    location={location}
                    setEditDisabled={setEditDisabled}
                    disabled={disabled}
                />

                <div className="mt-5 d-flex justify-content-center">
                    <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAdd} disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                    <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={disabled || cleardisable ? true : false} >Clear</button>
                    <button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} >Back</button>
                </div>
            </div>

        </>
    )
}

export default Standardservice
