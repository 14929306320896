import React, { useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BlockRoomModal = () => {
    const [formValue, setFormValue] = useState({
        id: 1,
        RoomType: null,
        FromDate: null,
        ToDate: null,
        Days: "",
        Reason: ""
    })
    const roomtype = [
        { id: 1, label: "Single" },
        { id: 2, label: "Double" }
    ]
    const mealtype = [
        { id: 1, label: "Meal 1" },
        { id: 2, label: "Meal 2" }
    ]
    const days = [
        { id: 1, label: "Day 1" },
        { id: 2, label: "Day 2" }
    ]

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? "" : [day, month, year].join('/');
    }


    const onChange = (name, value) => {
        setFormValue(form => ({ ...form, [name]: value }))
    }

    const [dateRangeValue, setDateRangeValue] = useState('')

    const handleDateRange = (update) => {
        console.log(update, 'update')
        setFormValue(form => ({ ...form, FromDate: update[0], ToDate: update[1] }))
        setDateRangeValue(`${formatDate(update[0])} - ${formatDate(update[1])}`)
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };


    return (
        <>
            <form action="" className='py-2'>
                <div className='row'>
                    <div className="col-12 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                options={roomtype}
                                id="select-roomtype"
                                value={roomtype?.find((obj) => obj.id === formValue.RoomType) || null}
                                name='RoomType'
                                onChange={(e, newValue) => onChange("RoomType", newValue.id)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Room Type" variant="filled"
                                        className={`${formValue.RoomType <= 0 ? 'red_border' : ''}`}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>

                    <div className="col-12 mb-4 lengthfull">
                        <div className="row">
                            <div className="col-6">
                                <div className="text-container">
                                    <TextField
                                        fullWidth
                                        id="select-date"
                                        variant='filled'
                                        label="Date Range (From-To)"
                                        value={dateRangeValue}
                                        onClick={(e) => openDatePicker("selectdate")}
                                        className={`${formValue.FromDate !== null && formValue.ToDate !== null ? '' : 'red_border'}`}
                                    />
                                    <div>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            className='form-control d-none'
                                            id='selectdate'
                                            selected={formValue.FromDate}
                                            placeholderText="Date Range (From-To)"
                                            selectsRange={true}
                                            autoComplete='off'
                                            showMonthDropdown
                                            startDate={formValue.FromDate}
                                            endDate={formValue.ToDate}
                                            onChange={handleDateRange}
                                        />
                                    </div>
                                    <span className="example-custom-input bulkCalendarBtn"
                                        onClick={(e) => openDatePicker("select-date")}
                                    ><i className="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            <div className="col-6">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={days}
                                        id="select-days"
                                        value={days?.find((obj) => obj.id === formValue.Days) || null}
                                        name='Days'
                                        onChange={(e, newValue) => onChange("Days", newValue.id)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Days" variant="filled" />
                                        )}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            id="reason"
                            variant='filled'
                            label="Reason"
                            name='Reason'
                            value={formValue.Reason}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            className={`${formValue.Reason === "" ? "red_border" : ""}`}
                        />
                    </div>

                    <div className="col-12">
                        <button type="button" className='btn bulk_apply_btn btn-primary w-100'>Apply</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default BlockRoomModal
