import React, { useEffect, useMemo, useState } from 'react'
import RichTextBox from '../../../customControl/RichTextBox'
import Switch from 'react-switch';
import Select from 'react-select';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import { htmlToText } from 'html-to-text';
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../_actions/loader.actions';
import { GetMenuIdFromStorage } from '../../commonClass/Menudect';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../customControl/CustomTable';

const FAQ = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formFaq, setFormFaq] = useState({
        FAQId: 1,
        FAQTABId: 0,
        Question: '',
        Answer: '',
        IsActive: false,
    })

    const [formError, setFormError] = useState({
        duplicate: '',
        question: '',
        answer: ''
    })

    let errors = {
        duplicate: '',
        question: '',
        answer: ''
    }

    const [filteritems, setFilterItems] = useState([]);
    const [FaqListData, setFaqListData] = useState([]);
    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);
    const [richboxheight, setRichBoxHeight] = useState(false)
    const [retrivecomplete, setRetriveComplete] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [serchdisable, setSerachDisable] = useState(false);
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('4');
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    //#region colums of Data tables
    const columns = [
        {
            name: 'Question',
            sortable: true,
            float: "left",
            selector: (faq) => faq.question,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (faq) => faq.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (faq) => formatDate(faq.createdDate)
        },
        {
            name: 'Edit',
            selector: (faq) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(faq.faqId)}></i></button>
        },
        {
            name: 'View',
            selector: (faq) => <i className="fa fa-eye" onClick={() => handleViewMode(faq.faqId)}></i>
        }
    ];
    //#endregion

    //#region Question text box
    const handleOnChange = (e) => {
        setFormFaq({ ...formFaq, [e.target.name]: e.target.value })
        setEditDisabled(true);
        setFormError({ ...formError, question: '', answer: '', duplicate: '' })
    }
    //#endregion

    //#region Answer Rich Text Box
    const answerconfig = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            height: '100%',
            maxHeight: richboxheight === true ? 200 : 500,
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "image", "link", "select", "video"],
            toolbarAdaptive: false,
            //autofocus: true,
            defaultMode: "1",
            disabled: disabled ? true : false
        }),
        [disabled, richboxheight]
    );

    const handleAnswerChange = (e) => {
        setFormError({ ...formError, question: '', answer: '', duplicate: '' })
        const str = e.replace('<p><br></p>', '')
        formFaq.Answer = str
        const html = htmlToText(str);
        if (html.length !== (retrivecomplete)) {
            setEditDisabled(true);
        }
    }
    //#endregion

    //#region Active/Inactive button
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formFaq.IsActive = e
            setActiveId(!activeId)
            setFormError({ ...formError, question: '', answer: '', duplicate: '' })
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formFaq.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormError({ ...formError, question: '', answer: '', duplicate: '' })
        }
        setModalShow(false);
    }
    //#endregion

    //#region validations
    const validateData = () => {
        let error = []
        if (typeof formFaq.Question === 'string' && formFaq.Question?.trim()?.length === 0) {
            errors.question = 'Please enter Question'
            // errors.duplicate = 'You can not enter duplicate value.'
            error.push('Question')
        }
        if (typeof formFaq.Answer === 'string' && formFaq.Answer?.trim()?.length === 0) {
            errors.answer = 'Please enter Answer'
            error.push('Answer')
        }
        // if (typeof formFaq.Answer === 'string' && formFaq.Answer?.trim()?.length > 2000) {
        //     errors.answer = 'Maximum character length is 2000'
        //     error.push('Answer')
        // }
        setFormError(errors)
        document.getElementById(error[0]).focus();

    }
    //#endregion

    //#region Add/Update button
    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === "Add") {
            if (statusoftoken) {
                if (formFaq.Question?.trim()?.length === 0 || formFaq.Answer?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    // if ((typeof formFaq.Question === 'string' && formFaq.Question?.trim()?.length > 0)) {
                    //     let filterdata;
                    //     filterdata = FaqListData?.filter(issec => issec.question.toLowerCase() === formFaq.Question.toLowerCase());
                    //     if (filterdata?.length > 0) {
                    //         errors.duplicate = 'You can not enter duplicate value.'
                    //         setFormError(errors)
                    //         return;
                    //     }
                    // }
                    if (statusoftoken) {
                        const FaqData = await postUsersData('/master/v1/insertfaq', formFaq)
                        if (!FaqData.status && !FaqData.data) {
                            errors.duplicate = FaqData.message
                            setFormError(errors)
                            return;
                        }
                        if (FaqData.status) {
                            if (FaqData.data > 0) {
                                setitems();
                                dispatch({ type: 'ALERTING', message: FaqData.message })
                                clear();
                                getFAQDataList(formFaq.FAQTABId);

                            }
                            else {
                                dispatch({ type: 'ALERTING', message: FaqData.message })
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: FaqData.message })
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (formFaq.Question?.trim()?.length === 0 || formFaq.Answer?.trim()?.length === 0) {
                    validateData();
                    return;
                }
                else {
                    // if ((typeof formFaq.Question === 'string' && formFaq.Question?.trim()?.length > 0)) {
                    //     let filterdata;
                    //     filterdata = FaqListData?.filter(issec => issec.question.toLowerCase() === formFaq.Question.toLowerCase());
                    //     if (filterdata?.length > 0) {
                    //         errors.duplicate = 'You can not enter duplicate value.'
                    //         setFormError(errors)
                    //         return;
                    //     }
                    // }
                    if (statusoftoken) {
                        const FaqData = await patchUsersData("/master/v1/updatefaq", formFaq);
                        if (!FaqData.status && !FaqData.data) {
                            errors.duplicate = FaqData.message
                            setFormError(errors)
                            return;
                        }
                        if (FaqData.status) {
                            setitems();
                            dispatch({ type: 'ALERTING', message: FaqData.message })
                            clear();
                            getFAQDataList(formFaq.FAQTABId);
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: FaqData.message })
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }
    //#endregion

    //#region clear button
    const handleClearClick = () => {
        clear();
    }

    function clear() {
        setFormError({ ...formError, question: '', answer: '', duplicate: '' })
        setFormFaq({ ...formFaq, Question: '', Answer: '', IsActive: false })
        setActiveId(false);
        // getFAQDataList(formFaq.FAQTABId);
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('4');
        setSearchValue('');
        setsclshow(false)
        if (document.querySelector('.jodit-wysiwyg') !== null) {
            document.querySelector('.jodit-wysiwyg').innerHTML = '';
        }
    }
    //#endregion

    //#region search FAQs by credentials
    const serachoption = [
        {
            label: 'Question',
            value: '1'
        },
        {
            label: 'Active',
            value: '3'
        },
        // {
        //     label: 'Created Date',
        //     value: '5'
        // },
        {
            label: 'All',
            value: '4'
        },

    ];

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const FAQTABId = formFaq.FAQTABId
        const FAQListData = await postUsersData("/master/v1/getallfaq", { FAQTABId });
        setFilterItems(FAQListData.data);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
        setsclshow(true)
        if (e.target.value === '') {
            getFAQDataList(formFaq.FAQTABId);
            setsclshow(false)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getFAQDataList(formFaq.FAQTABId);
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = async () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            // setitems();
            const FAQTABId = formFaq.FAQTABId
            const FAQListData = await postUsersData("/master/v1/getallfaq", { FAQTABId });
            // var filteritem = filteritems;
            var filteritem = FAQListData.data;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            // else if (searchby === '5') {
            //     filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '4') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.question.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setFaqListData(filterdata == undefined ? [] : filterdata)
        }
    }
    //#endregion

    //#region Date format
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    //#endregion

    //#region Edit and View Button
    const handleEditMode = (id) => {
        RetriveData(id);
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }
    //#endregion

    //#region change FAQ Tab
    const handleFAQTab = (e, name, id) => {
        if (formFaq.FAQTABId !== id) {
            clear();
            dispatch(loaderActions.start())
            setFAQTab(FAQTab?.map((obj) => {
                if (obj.FAQTABName === name) {
                    return { ...obj, IsDefault: true }
                }
                else {
                    return { ...obj, IsDefault: false }
                }
            }))
            setTimeout(() => {
                getFAQDataList(id)
            }, 1000);

            setFormFaq({ ...formFaq, Question: '', Answer: '', IsActive: false, FAQTABId: id })
        }
    }

    const [FAQTab, setFAQTab] = useState([])

    const FaqTabs = async () => {
        const faqtab = await getAllData('/master/v1/getfaqtab')
        setFAQTab(faqtab.data)
        const id = faqtab.data?.map(obj => {
            if (obj.IsDefault) {
                return obj.FAQTABId
            }
        })?.find(x => x !== undefined ? x : '')

        setFormFaq({ ...formFaq, FAQTABId: id !== undefined ? id : faqtab?.data[0]?.FAQTABId })
        setTimeout(() => {
            getFAQDataList(id !== undefined ? id : faqtab?.data[0]?.FAQTABId)
        }, 1000);
    }
    //#endregion

    //#region retrive data in Data Table
    function RetriveData(id) {
        FaqListData?.map(obj => {
            if (obj.faqId === id) {
                formFaq.Question = obj.question
                formFaq.Answer = obj.answer
                formFaq.FAQId = obj.faqId
                formFaq.IsActive = obj.isActive
                setActiveId(obj.isActive);
            }
        });
        setFormFaq({ ...formFaq, formFaq })
        setRetriveComplete(htmlToText(formFaq.Answer).length);
    }

    const getFAQDataList = async (id) => {
        // const FAQTABId = formFaq.FAQTABId
        const FAQTABId = id
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const FAQListdata = await postUsersData("/master/v1/getallfaq", { FAQTABId });
            if (FAQListdata.status) {
                setFaqListData(FAQListdata.data);
            }
            else {
                setFaqListData([]);
            }
        }
        else {
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }
    //#endregion

    //#region useEffects
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            setFormFaq({ ...formFaq, Question: '', Answer: '', IsActive: false })
            // if (Window.$name && Window.$userToken) {
            FaqTabs()
            // getFAQDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, [])
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        // });
        // if (!viewrights) {
        //     navigate('/Dashboard');
        // }
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);

        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 1253) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);
    //#endregion

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>FAQ</h3>
            </div>
            <div className="container-fluid form_border pb-5 my-3">
                <div className="mt-4 mb-3 row mx-1">
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1"></div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-lg-1">
                        {/* <div className="faq_tab d-flex justify-content-lg-start justify-content-center align-items-center flex-wrap">
                            {
                                FAQTab?.map((tab) => {
                                    return (
                                        <>
                                            <button type="button" className={`btn me-lg-1 ms-lg-0 mx-1 mb-1 ${tab.IsDefault ? 'active' : ''}`} onClick={(e) => handleFAQTab(e, tab.FAQTABName, tab.FAQTABId)}> {tab.FAQTABName} </button>
                                        </>
                                    )
                                })
                            }
                        </div> */}
                        <div className="faq_tab d-flex justify-content-center align-items-center flex-wrap">
                            <div className='container mt-3 tab_section px-0'>
                                <ul class="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">
                                    {FAQTab?.map((tab) => {
                                        if (tab.IsDefault === true) {
                                            return (
                                                <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                                                    <a class="nav-link active" id={tab.FAQTABId} data-bs-toggle="pill"
                                                        type="button" role="tab" aria-controls="pills-about"
                                                        aria-selected="true" onClick={(e) => handleFAQTab(e, tab.FAQTABName, tab.FAQTABId)}><strong>{tab.FAQTABName}</strong></a>
                                                </li>
                                            )
                                        }
                                        else if (formFaq.FAQTABId === tab.FAQTABId) {
                                            return (
                                                <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                                                    <a class="nav-link active" id={tab.FAQTABId} data-bs-toggle="pill"
                                                        type="button" role="tab" aria-controls="pills-tips"
                                                        aria-selected="false" onClick={(e) => handleFAQTab(e, tab.FAQTABName, tab.FAQTABId)}><strong >{tab.FAQTABName}</strong></a>
                                                </li>
                                            )
                                        }
                                        else {
                                            return (
                                                <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                                                    <a class="nav-link" id={tab.FAQTABId} data-bs-toggle="pill"
                                                        type="button" role="tab" aria-controls="pills-tips"
                                                        aria-selected="false" onClick={(e) => handleFAQTab(e, tab.FAQTABName, tab.FAQTABId)}><strong >{tab.FAQTABName}</strong></a>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        {formError.duplicate && <div className="text-center text-danger pb-3"> {formError.duplicate} </div>}
                                        <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label htmlFor="questionFaq1" className="col-form-label required">Question</label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            {/* <textarea className='form-control w-100' name="Question" id="questionFaq1" maxLength={250} value={formFaq.Question} onChange={(e) => handleOnChange(e)} ></textarea> */}
                                            <input type='text' className='form-control' name="Question" id="questionFaq1" maxLength={250} value={formFaq.Question} onChange={(e) => handleOnChange(e)} disabled={disabled ? true : false}></input>
                                            {formError.question && <div className="text-start text-danger"> {formError.question} </div>}
                                        </div>

                                        <div className="col-lg-3 col-md-12 col-sm-12 mb-lg-1">
                                            <label htmlFor="Answer" className="col-form-label required">Answer</label>
                                        </div>
                                        <div className=" col-lg-9 col-md-12 col-sm-12 mb-lg-1">
                                            <RichTextBox field="Answer" fieldvalue={formFaq.Answer} tabIndex={1} OnChangeofRichBox={handleAnswerChange} textconfig={answerconfig} />
                                            {formError.answer && <div className="text-start text-danger"> {formError.answer} </div>}
                                        </div>

                                        <div className="col-lg-3 col-md-12 col-sm-12">
                                            <label htmlFor="active" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-6 " disabled={false}>
                                            <Switch
                                                id='active'
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the datavalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={FaqListData}
                        PdfExport={false}
                        ExportToExcel={false}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default FAQ
