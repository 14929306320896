/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect } from 'react'
import BasicInformation from './Partial/BasicInformation'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loaderActions } from '../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import Pricing from './Partial/Pricing';
import AdditionInformation from './Partial/AdditionInformation';
import { verifytoken } from '../../../services/ApiCallingUserServices';
function Attractionsmaster() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loaderActions.start());
        // let token = GetLocalStorage();
        // if (token && token.userName && token.token) {
        //     if (location.state === null) {
        //         navigate('/AttractionsList');
        //         dispatch(loaderActions.end());
        //     }
        //     dispatch(loaderActions.end());
        // }
        // else {
        //     ClearLocalStorage();
        //     dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        // }
        isLocation()

    }, [location])

    const isLocation = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (location.state === null) {
                navigate('/AttractionsList');
                dispatch(loaderActions.end());
            }
            dispatch(loaderActions.end());
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }


    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Attraction Master</h3>
            </div>
            <div className='container-fluid mt-3 tab_section px-0'>
                <ul class="nav nav-pills nav-justified mb-3  pt-3" id="pills-tab" role="tablist">
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5 mt-1" role="presentation">
                        <a class="nav-link active" id="pills-basic-info-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-basic-info" type="button" role="tab" aria-controls="pills-about"
                            aria-selected="true">
                            <strong>Basic Information</strong>


                        </a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation" >
                        <a class="nav-link" id="pills-additional"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-addition-info" type="button" role="tab" aria-controls="pills-tips"
                            aria-selected="false"
                        >
                            <strong >Additional Information</strong>
                        </a>
                    </li>
                    <li class="nav-item mx-lg-5 mx-md-1 mx-5  mt-1" role="presentation">
                        <a class="nav-link" id="pills-pricing-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-pricing" type="button" role="tab" aria-controls="pills-club"
                            aria-selected="false"
                        >
                            <strong >Pricing</strong>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-basic-info" role="tabpanel"
                    aria-labelledby="pills-basic-info-tab">
                    {/* <div class="tab-pane fade show" id="pills-basic-info" role="tabpanel"
                        aria-labelledby="pills-basic-info-tab"> */}
                    <BasicInformation
                        disabled={location?.state?.disabled}
                        attraction={location?.state?.attraction}
                        editdisabled={location?.state?.editdisabled}
                        clearDisable={location?.state?.clearDisable}
                        viewtype={location?.state?.viewtype}
                        attractionId={location?.state?.attractionId}
                        attractionsList={location?.state?.attractionsList}
                        userRights={location?.state?.userRights}
                    />
                    {/* <SupplierAccount SupplierId={supplier.state?.supplierId} IsDisable={supplier.state?.disabled} /> */}
                    {/* </div> */}
                </div>
                <div class="tab-pane fade show" id="pills-addition-info" role="tabpanel"
                    aria-labelledby="pills-additional">
                    <AdditionInformation
                        disable={location?.state?.disabled}
                        attraction={location?.state?.attraction}
                        editdisable={location?.state?.editdisabled}
                        clearDisable={location?.state?.clearDisable}
                        viewtype={location?.state?.viewtype}
                        attractionId={location?.state?.attractionId}
                        attractionsList={location?.state?.attractionsList}
                        userRights={location?.state?.userRights}
                    />
                </div>
                <div class="tab-pane fade show" id="pills-pricing" role="tabpanel"
                    aria-labelledby="pills-pricing-tab">
                    <Pricing
                        disabled={location?.state?.disabled}
                        attraction={location?.state?.attraction}
                        editdisabled={location?.state?.editdisabled}
                        clearDisable={location?.state?.clearDisable}
                        viewtype={location?.state?.viewtype}
                        attractionId={location?.state?.attractionId}
                        attractionsList={location?.state?.attractionsList}
                        userRights={location?.state?.userRights}
                    />
                </div>

            </div>
        </>
    )
}

export default memo(Attractionsmaster);