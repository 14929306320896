import * as React from 'react';
import { Modal } from 'react-bootstrap'
import InventoryModal from './bulkmodals/InventoryModal';
import RatesModal from './bulkmodals/RatesModal';
import RestrictionsModal from './bulkmodals/RestrictionsModal';
import BlockRoomModal from './bulkmodals/BlockRoomModal';
import UnblockRoomModal from './bulkmodals/UnblockRoomModal';
import BlackoutModal from './bulkmodals/BlackoutModal';
import SetDefaultRatesModal from './bulkmodals/SetDefaultRatesModal';
import SetDefaultInventoryModal from './bulkmodals/SetDefaultInventoryModal';

export default function BulkUpdateModal(props) {
    const { open, onClose, title, isEdit, selectedCellDate, RoomAvailabilityDates } = props

    const roomtype = [
        { id: 1, label: "Single" },
        { id: 2, label: "Double" }
    ]
    const days = [
        { id: 1, label: "Day 1" },
        { id: 2, label: "Day 2" }
    ]
    const forms = [
        { seq: 1, label: 'Room Type', type: 'select', target: { name: "RoomType", value: null }, column: { double: false, position: 1 }, paramName: 'RoomType', required: true, options: roomtype },
        { seq: 2, label: 'Date Range (From-To)', type: 'date range', target: [{ name: "FromDate", value: null }, { name: "ToDate", value: null }], column: { double: true, position: 1 }, paramName: ['FromDate', 'ToDate'], required: true },
        { seq: 3, label: 'Days', type: 'select', target: { name: "Days", value: null }, column: { double: true, position: 2 }, paramName: 'Days', required: false, options: days },
        { seq: 4, label: 'Available Quantity', type: 'text', target: { name: "AvailableQuantity", value: "" }, column: { double: true, position: 1 }, paramName: 'AvailableQuantity', required: false },
        { seq: 5, label: 'On Hold', type: 'text', target: { name: "OnHold", value: "" }, column: { double: true, position: 2 }, paramName: 'OnHold', required: false },
    ]

    const RenderModal = React.useMemo(() => {
        switch (title.name) {
            case 'Inventory': return <InventoryModal isEdit={isEdit} selectedCellDate={selectedCellDate} RoomAvailabilityDates={RoomAvailabilityDates} />;
            case 'Rates': return <RatesModal isEdit={isEdit} selectedCellDate={selectedCellDate} RoomAvailabilityDates={RoomAvailabilityDates} />;
            case 'Restrictions': return <RestrictionsModal isEdit={isEdit} selectedCellDate={selectedCellDate} RoomAvailabilityDates={RoomAvailabilityDates} />;
            case 'Block Rooms': return <BlockRoomModal />;
            case 'Unblock Rooms': return <UnblockRoomModal />;
            case 'Blackout': return <BlackoutModal />;
            case 'Set Default Rates': return <SetDefaultRatesModal />;
            case 'Set Default Inventory': return <SetDefaultInventoryModal />;
            default: return ''
        }
    }, [title, isEdit, selectedCellDate])

    return (
        <React.Fragment>
            <Modal
                show={open}
                onHide={() => onClose()}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="sign-in-class"
                centered
            >
                <div className="bulk_update_modal p-4">
                    <div className="d-flex justify-content-between align-items-center bulk_update_modal_header">
                        <div className="bulk_update_modal_title">
                            {title.alias}
                        </div>
                        <div role='button' onClick={onClose}>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    {/* <CommonBulk forms={forms} /> */}
                    {RenderModal}
                </div>
            </Modal>
        </React.Fragment>
    );
}
