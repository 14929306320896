import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Switch from "react-switch";
import { generatePDF, onlyNumeric } from "../../commonClass/CommonValidation";
import { useNavigate } from "react-router-dom";
import { getAllData, postUsersData, verifytoken } from "../../../services/ApiCallingUserServices";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ClearLocalStorage, GetLocalStorage } from "../../commonClass/Session";
import { ExportExcel } from "../../commonClass/ExportExcel";
import { ConfirmationModal } from "../../../customControl/ConfirmationModal";
import BusVehicleMaster from "./Partial/BusVehicleMaster";
import CustomTable from "../../../customControl/CustomTable";

function Vehicalmaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [vehicleoptionId, setVehicleoptionId] = useState(1);
  // const vehicleOption = [
  //   { label: "Car And Tempo Traveller", value: 1 },
  //   { label: "Bus", value: 2 },
  // ];
  const [vehicleOption, setVehicleOption] = useState([])

  const getvehicleheaderlist = async () => {
    await getAllData('/master/v1/getvehicleheaderlist').then(response => {
      setVehicleOption(response.data)
    })
  }

  const handleVehicleOptionChange = (e) => {
    setVehicleoptionId(e.value);
  };

  const [modalShow, setModalShow] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [btntxt, setBtnTxt] = useState("Add");
  const [editdisabled, setEditDisabled] = useState(true);
  const [disable, setDisable] = useState(false);
  const [fieldDisable, setFieldDisable] = useState(false);

  const [vehicleDataList, setVehicleDataList] = useState([]);
  const [vehicleDataColumn, setVehicleDataColumn] = useState([]);
  const [filteritems, setFilterItems] = useState([]);

  const [searchby, setSerachBy] = useState("8");
  const [search, setSearch] = useState("");

  const [duplicateValue, setDuplicateValue] = useState();
  const [clrshow, setsclshow] = useState(false);

  const user = useSelector((store) => store.userstatus.tba);
  const [useradd, setUserAdd] = useState(true);
  const [useredit, setUserEdit] = useState(true);
  const [userexport, setUserExport] = useState(true);

  const [formValue, setFormValue] = useState({
    VehicleDetailId: 0,
    VehicleHeaderId: 0,
    VehicleName: "",
    VehicleCategoryId: 0,
    SeatingCapacity: 0,
    VehicleTypeId: 0,
    BaggageCapacity: 0,
    IsActive: false,
  });

  const [formError, setFormError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [SelectCategoryId, setSelectCategoryId] = useState(0);
  const [VehicleTypeId, setVehicleTypeId] = useState(0);

  //#region On Change Methods
  const handleChange = (e) => {
    setFormError("");
    let result;
    if ([e.target.name][0] === "SeatingCapacity" || [e.target.name][0] === "BaggageCapacity") {
      result = Number(onlyNumeric(e.target.value));
    }
    if ([e.target.name][0] === "VehicleName") {
      result = e.target.value
    }
    setDuplicateValue("");
    setEditDisabled(true);
    setFormValue({ ...formValue, [e.target.name]: result });
  };

  // const selectCategoryOption = [
  //   { label: "Hatchback", value: 1 },
  //   { label: "Sedan", value: 2 },
  //   { label: "Luxury Sedan", value: 3 },
  //   { label: "SUV", value: 4 },
  //   { label: "MUV", value: 5 },
  //   { label: "Tempo Traveller", value: 6 },
  //   { label: "Maharaja Tempo Traveller", value: 7 },
  //   { label: "Sharing Tempo Traveller", value: 8 },
  //   { label: "Deluxe Coach", value: 9 },
  //   { label: "Luxury Coach", value: 10 },
  //   { label: "Semi Deluxe Coach", value: 11 },
  //   { label: "Sharing Coach", value: 12 },
  //   { label: "limousine car", value: 13 },
  //   { label: "Minivan", value: 14 },
  //   { label: "Caravans", value: 15 },
  //   { label: "Tata Winger", value: 16 },
  // ];

  const [selectCategoryOption, setSelectCategoryOption] = useState([])

  const getvehiclecategorylist = async () => {
    await getAllData('/master/v1/getvehiclecategorylist').then(response => {
      setSelectCategoryOption(response.data)
    })
  }

  const handleSelectCategoryChange = (e) => {
    setFormError("");
    setDuplicateValue("");
    setEditDisabled(true);
    setSelectCategoryId(e.value);
    formValue.VehicleCategoryId = e.value
  };

  // const vehicleTypeOption = [
  //   { label: "AC", value: 1 },
  //   { label: "Non AC", value: 2 },
  // ];
  const [vehicleTypeOption, setVehicleTypeOption] = useState([])

  const getvehicletypelist = async () => {
    await getAllData('/master/v1/getvehicletypelist').then(response => {
      setVehicleTypeOption(response.data)
    })
  }
  const handleVehicleTypeChange = (e) => {
    setFormError("");
    setDuplicateValue("");
    setEditDisabled(true);
    setVehicleTypeId(e.value);
    formValue.VehicleTypeId = e.value
  };

  const handleisActive = (e) => {
    // if(editdisabled){
    if (btntxt === "Add") {
      formValue.IsActive = !activeId;
      setActiveId(!activeId);
      setFormError("");
      setDuplicateValue("");
    } else {
      setModalShow(true);
    }
  };

  const handleConfirmation = (e) => {
    //setYesNoConfirm(e)
    if (e === true) {
      formValue.IsActive = !activeId;
      setActiveId(!activeId);
      setEditDisabled(true);
      setFormError("");
      setDuplicateValue("");
    }
    setModalShow(false);
  };
  //#endregion

  //#region validations
  const validation = (value) => {
    setIsSubmit(true);
    let error = {};
    if (value.VehicleName?.trim()?.length === 0) {
      error.VehicleName = "Kindly enter the Vehicle Name";
      setIsSubmit(false);
    }
    // if (value.VehicleName?.trim()?.length > 0 && value.VehicleName?.trim()?.length < 2) {
    //   error.VehicleName = "Minimum length is 2 characters";
    //   setIsSubmit(false);
    // }
    if (SelectCategoryId === 0) {
      error.selectCategoryId = "Kindly select Vehicle Category";
      setIsSubmit(false);
    }
    if (value.SeatingCapacity?.length === 0 || value.SeatingCapacity === 0) {
      error.SeatingCapacity = "Kindly enter the Seating Capacity";
      setIsSubmit(false);
    }
    if (VehicleTypeId === 0) {
      error.vehicleTypeId = "Kindly select Vehicle Type";
      setIsSubmit(false);
    }
    return error;
  };

  const selectCategoryId = useRef();
  const vehicleTypeId = useRef();

  useEffect(() => {
    if (Object.keys(formError)?.length === 0 && isSubmit) {
      handleSubmitData();
    }
    if (Object.keys(formError)?.length > 0) {
      let id = Object.keys(formError)[0];
      if (id === "selectCategoryId") {
        selectCategoryId.current.focus();
      }
      if (id === "vehicleTypeId") {
        vehicleTypeId.current.focus();
      } else {
        document.getElementById(id)?.focus();
      }
    }
  }, [formError]);
  //#endregion

  //#region Add/Update Data
  const handleAdd = e => {
    e.preventDefault();
    setFormError(validation(formValue));
  };

  const handleSubmitData = async () => {
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      // if (formValue.VehicleName?.trim()?.length > 0 && SelectCategoryId !== 0 && VehicleTypeId !== 0 && formValue.SeatingCapacity !== 0) {
      //   let filterData;
      //   const CategoryName = selectCategoryOption?.filter((category) => category.value === SelectCategoryId).map(obj => obj.label).find(x => x)
      //   const TypeName = vehicleTypeOption?.filter((type) => type.value === VehicleTypeId).map(obj => obj.label).find(x => x)
      //   if (btntxt === "Add") {
      //     filterData = vehicleDataList?.filter((row) =>
      //       row.VehicleName.toLowerCase() === formValue.VehicleName.trim().toLowerCase() &&
      //       row.VehicleCategory.toLowerCase() === CategoryName.toLowerCase() &&
      //       row.VehicleType.toLowerCase() === TypeName.toLowerCase() &&
      //       row.SeatingCapacity === formValue.SeatingCapacity);
      //     if (filterData?.length > 0) {
      //       setDuplicateValue("You can not enter duplicate value.");
      //       setIsSubmit(false)
      //       return;
      //     }
      //   }
      //   else {
      //     filterData = vehicleDataList?.filter((row) =>
      //       row.VehicleDetailId !== formValue.VehicleDetailId &&
      //       row.VehicleName.toLowerCase() === formValue.VehicleName.trim().toLowerCase() &&
      //       row.VehicleCategory.toLowerCase() === CategoryName.toLowerCase() &&
      //       row.VehicleType.toLowerCase() === TypeName.toLowerCase() &&
      //       row.SeatingCapacity === formValue.SeatingCapacity);
      //     if (filterData?.length > 0) {
      //       setDuplicateValue("You can not enter duplicate value.");
      //       setIsSubmit(false)
      //       return;
      //     }
      //   }
      // }
      if (statusoftoken) {
        setDisable(true);
        setEditDisabled(false);
        formValue.VehicleHeaderId = 1
        formValue.VehicleName = formValue.VehicleName.trim()
        const VehicleData = await postUsersData("/master/v1/insertorupdatevehicledetails", formValue);

        if (VehicleData.status) {
          if (VehicleData.data > 0) {
            setitems()
            dispatch({ type: 'ALERTING', message: VehicleData.message })
            clear();
          }
          else {
            dispatch({ type: 'ALERTING', message: VehicleData.message })
            return;
          }
        }
        else {
          dispatch({ type: 'ALERTING', message: VehicleData.message })
          return;
        }
      }
      else {
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      }
    }
    else {
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }

  }
  //#endregion

  //#region Clear Data
  const handleClear = (e) => {
    clear();
  };

  function clear() {
    setIsSubmit(false)
    setFormValue({
      ...formValue,
      VehicleName: "",
      VehicleDetailId: 0,
      VehicleHeaderId: 0,
      VehicleCategoryId: 0,
      SeatingCapacity: 0,
      VehicleTypeId: 0,
      BaggageCapacity: 0,
      IsActive: false,
    });
    setSelectCategoryId(0)
    setVehicleTypeId(0)
    setFormError("");
    setDuplicateValue("");
    setSearch("");
    setActiveId(false);
    getvehiclelistdetails();
    setBtnTxt("Add");
    setEditDisabled(true);
    setDisable(false);
    setFieldDisable(false);
    setSerachBy("8");
    setsclshow(false);
  }
  //#endregion

  //#region searching column vice
  const caroption = [
    { value: "1", label: "Vehicle Name" },
    { value: "2", label: "Vehicle Category" },
    { value: "3", label: "Seating Capacity" },
    { value: "4", label: "Vehicle Type" },
    { value: "5", label: "Baggage Capacity" },
    { value: "6", label: "Active" },
    // { value: "7", label: "Create Date" },
    { value: "8", label: "All" },
  ];

  // Getting Vehicle Data from API--------------------------------------------------------------

  const getvehiclelistdetails = async () => {
    const VehicleData = await getAllData("/master/v1/getvehiclelistdetails?VehicleHeaderId=" + vehicleoptionId);
    setVehicleDataList(VehicleData.data);
    // setVehicleDataColumn(VehicleData.data[0].vehicleColumn);
  };

  // Getting Vehicle Data from API--------------------------------------------------------------

  const setitems = async () => {
    const VehicleData = await getAllData("/master/v1/getvehiclelistdetails?VehicleHeaderId=" + vehicleoptionId);
    setFilterItems(VehicleData.data);
  };

  const handleSearchBy = (e) => {
    setSerachBy(e.value);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setsclshow(true);
    if (e.target.value === "") {
      getvehiclelistdetails();
      setsclshow(false);
    }
  };

  const clearsearch = () => {
    setSearch("");
    setsclshow(false);
    getvehiclelistdetails();
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      HandleChangeClickSearch();
    }
  };

  const HandleChangeClickSearch = () => {
    if (typeof search === "string" && search?.trim()?.length !== 0 && searchby > 0) {
      setitems();
      var filteritem = filteritems;
      var filterData;
      if (searchby === "1") {
        filterData = filteritem?.filter((row) => row.VehicleName.toLowerCase().includes(search.toLowerCase()));
        setVehicleDataList(filterData);
      }
      else if (searchby === "2") {
        filterData = filteritem?.filter((row) => row.VehicleCategory.toLowerCase().includes(search.toLowerCase()));
        setVehicleDataList(filterData);
      }
      else if (searchby === "3") {
        filterData = filteritem?.filter((row) => JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()));
        setVehicleDataList(filterData);
      }
      else if (searchby === "4") {
        if (search.toLowerCase() === "ac".toLowerCase()) {
          filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'AC'.toLowerCase());
        }
        else if (search.toLowerCase() === "non ac".toLowerCase()) {
          filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase());
        }
        else if ("ac".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'AC'.toLowerCase() || row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase());
        }
        else if ("non ac".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase());
        }
        // filterData = filteritem?.filter((row) => row.VehicleType.toLowerCase().includes(search.toLowerCase()));
        setVehicleDataList(filterData === undefined ? [] : filterData);
      }
      else if (searchby === "5") {
        filterData = filteritem?.filter((row) => JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase()));
        setVehicleDataList(filterData);
      }
      else if (searchby === "6") {
        if (search.toLowerCase() === "active".toLowerCase()) {
          filterData = filteritem?.filter((row) => row.IsActive === true);
        }
        else if (search.toLowerCase() === "inactive".toLowerCase()) {
          filterData = filteritem?.filter((row) => row.IsActive === false);
        }
        else if ("active".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter((row) => row.IsActive === true || row.IsActive === false);
        }
        else if ("inactive".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter((row) => row.IsActive === false);
        }
        setVehicleDataList(filterData === undefined ? [] : filterData);
      }
      else if (searchby === "8") {
        if (search.toLowerCase() === "active".toLowerCase()) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase()) ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              row.IsActive === true
          );
        }
        else if (search.toLowerCase() === "inactive".toLowerCase()) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase()) ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              row.IsActive === false
          );
        }
        else if ("active".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase()) ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              row.IsActive === true ||
              row.IsActive === false
          );
        }
        else if ("inactive".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase()) ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              row.IsActive === false
          );
        }
        else if (search.toLowerCase() === "ac".toLowerCase()) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleType.toLowerCase() === 'AC'.toLowerCase() ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase())
          );
        }
        else if (search.toLowerCase() === "non ac".toLowerCase()) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase() ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase())
          );
        }
        else if ("ac".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleType.toLowerCase() === 'AC'.toLowerCase() || row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase() ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase())
          );
        }
        else if ("non ac".toLowerCase().includes(search.toLowerCase())) {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleType.toLowerCase() === 'Non AC'.toLowerCase() ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase())
          );
        }
        else {
          filterData = filteritem?.filter(
            (row) =>
              row.VehicleName.toLowerCase().includes(search.toLowerCase()) ||
              row.VehicleCategory.toLowerCase().includes(search.toLowerCase()) ||
              JSON.stringify(row.SeatingCapacity).toLowerCase().includes(search.toLowerCase()) ||
              formatDate(row.CreatedOn)?.toLowerCase().includes(search?.toLowerCase()) ||
              JSON.stringify(row.BaggageCapacity).toLowerCase().includes(search.toLowerCase())
          );
        }
        setVehicleDataList(filterData);
      }
    }
  };
  //#endregion

  //#region Data Grid Columns with Edit, View and retrieve
  // const columns = vehicleDataColumn.map((columnName) => ({
  //     name: columnName.name,
  //     selector: (row) => columnName.fieldName === 'isActive' ? (row.isActive ? 'Active' : 'InActive') : (columnName.fieldName === 'createdDate' ? formatDate(row.createdDate) : (columnName.fieldName === "updatedDate" ? formatDate(row.UpdatedDate) : (columnName.name === 'Edit' ? <i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i> : (columnName.name === 'View' ? <i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i> : row[columnName.fieldName])))),
  //     // selector: (row) => columnName.name === 'Vehicle Name' ? row.VehicleName : (columnName.name === 'Pax Capacity' ? row.paxCapacity : (columnName.name === 'Active' ? (row.isActive ? "Active" : "InActive") : (columnName.name === 'CreatedDate' ? formatDate(row.createdDate) : (columnName.name === 'Edit' ? <i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i> : <i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i>)))),
  //     sortable: columnName.sortable,
  //     reorder: columnName.reorder,
  //     float: columnName.float,
  //     omit: columnName.visibility ? false : true,
  // }));

  //Columns
  const columns = [
    {
      name: "Vehicle Name",
      sortable: true,
      float: "left",
      selector: (row) => row.VehicleName,
    },
    {
      name: "Vehicle Category",
      sortable: true,
      float: "left",
      selector: (row) => row.VehicleCategory,
    },
    {
      name: "Seating Capacity",
      sortable: true,
      float: "left",
      selector: (row) => row.SeatingCapacity,
    },
    {
      name: "Vehicle Type",
      sortable: true,
      float: "left",
      selector: (row) => row.VehicleType,
    },
    {
      name: "Baggage Capacity",
      sortable: true,
      float: "left",
      selector: (row) => row.BaggageCapacity,
    },
    {
      name: "Active",
      sortable: true,
      float: "left",
      selector: (row) => (row.IsActive ? "Active" : "InActive"),
    },
    {
      name: "Create Date",
      sortable: true,
      float: "left",
      selector: (row) => formatDate(row.CreatedOn),
    },
    {
      name: "Edit",
      selector: (row) => (<button type="button" className="border-0 p-0 bg-body set_disableclr" disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEdit(row.VehicleDetailId)}></i></button>),
    },
    {
      name: "View",
      selector: (row) => (<i className="fa fa-eye" onClick={() => handleView(row.VehicleDetailId)}></i>),
    },
  ];

  const handleEdit = (id) => {
    setDisable(true);
    setFieldDisable(false);
    setEditDisabled(false);
    setBtnTxt("Update");
    RetriveData(id);
  };

  const handleView = (id) => {
    setDisable(true);
    setFieldDisable(true);
    setEditDisabled(false);
    RetriveData(id);
  };

  const RetriveData = async (id) => {
    const VehicleDataEditDetail = await getAllData('/master/v1/getvehicledetailsbyid?VehicleDetailId=' + id)
    setFormError("");
    setDuplicateValue("");
    VehicleDataEditDetail.data?.map((obj) => {
      if (obj.VehicleDetailId === id) {
        formValue.VehicleName = obj.VehicleName;
        formValue.VehicleCategoryId = obj.VehicleCategoryId;
        formValue.VehicleDetailId = obj.VehicleDetailId;
        formValue.SeatingCapacity = obj.SeatingCapacity;
        formValue.VehicleTypeId = obj.VehicleTypeId;
        formValue.BaggageCapacity = obj.BaggageCapacity;
        formValue.IsActive = obj.IsActive;
        setActiveId(obj.IsActive);
        setSelectCategoryId(obj.VehicleCategoryId);
        setVehicleTypeId(obj.VehicleTypeId);
      }
    });
    // setFormValue({ ...formValue, formValue });
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }
  //#endregion

  //#region Exports
  const PDFExport = () => {
    const columns = ["Vehicle Name", "Vehicle Category", "Seating Capacity", "Vehicle Type", "Baggage Capacity", "Active"];

    let rows = [];
    for (let i = 0; i < vehicleDataList?.length; i++) {
      let temp = [
        vehicleDataList[i].VehicleName,
        vehicleDataList[i].VehicleCategory,
        vehicleDataList[i].SeatingCapacity,
        vehicleDataList[i].VehicleType,
        vehicleDataList[i].BaggageCapacity,
        `${vehicleDataList[i].IsActive ? 'Active' : 'InActive'}`,
      ];
      rows = [...rows, temp]
    }
    generatePDF(columns, rows, 'CarAndTempoTraveller', 'Car and Tempo Traveller')
  };

  const ExcelExport = () => {
    const dispalyname = ['Vehicle Name', 'Vehicle Category', 'Seating Capacity', 'Vehicle Type', 'Baggage Capacity', 'Active'];
    const fields = ['VehicleName', 'VehicleCategory', 'SeatingCapacity', 'VehicleType', 'BaggageCapacity', 'IsActive'];
    ExportExcel(dispalyname, fields, vehicleDataList, "CarAndTempoTraveller", "CarAndTempoTraveller.xls");
  };
  //#endregion

  //#region UseEffect
  useEffect(() => {
    dispatch(loaderActions.start());
    setFormError("");
    setDuplicateValue("");
    let token = GetLocalStorage();
    if (token && token.userName && token.token) {
      getvehiclelistdetails().then(() => {
        dispatch(loaderActions.end());
      });
      setitems();
    } else {
      ClearLocalStorage();
      alert("Your Session Expired. Please Login Again!");
      navigate("/");
    }
  }, [vehicleoptionId]);

  useEffect(() => {
    getvehicleheaderlist()
    getvehiclecategorylist()
    getvehicletypelist()
  }, [])

  useEffect(() => {
    if (vehicleoptionId === 1) {
      clear()
    }
  }, [vehicleoptionId])

  useEffect(() => {
    // let MENUID = GetMenuIdFromStorage()
    // let viewrights = false;
    // user?.filter(x => x.MenuId === MENUID).map((obj) => {
    //   viewrights = obj.URightView;
    //   setUserAdd(obj.URightAdd);
    //   setUserEdit(obj.URightEdit);
    //   setUserExport(obj.URightExport);
    //   if (!viewrights) {
    //     navigate('/dashboard');
    // }
    // });
    // let MENUID = GetMenuIdFromStorage();
    let pagelink = user?.find(x => {
      if (x.PageLink === window.location.pathname)
        return x.MenuId
    });
    let mid = user?.find((x) => x.MenuId === pagelink.MenuId);
    if (mid?.URightView === false && mid?.URightView !== undefined) {
      navigate("/dashboard");
    }
    user?.filter((x) => x.MenuId === pagelink.MenuId).map((obj) => {
      setUserAdd(obj.URightAdd);
      setUserEdit(obj.URightEdit);
      setUserExport(obj.URightExport);
    });
  }, [user]);
  //#endregion

  return (
    <>
      <div className="container-fluid mt-4 heading_color">
        <h3>Vehicle Master</h3>
      </div>
      <div className="container-fluid form_border py-5 mb-3">
        <div className="row my-3 mx-1">
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <Select
              options={vehicleOption}
              // defaultValue={vehicleOption[0]}
              value={vehicleOption?.filter(function (vehicleoption) {
                return vehicleoption.value === vehicleoptionId;
              })}
              onChange={(e) => handleVehicleOptionChange(e)}
            />
          </div>
        </div>
        {vehicleoptionId === 1 ? (
          <>
            <div className="mb-3 mt-5">
              <form action="">
                <div className="mb-3 row mx-1">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                      {duplicateValue && (<div className="text-danger pb-3 text-center">{duplicateValue}{" "}</div>)}
                      <div className="col-lg-6 col-sm-6 ">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 mb-1">
                            <label className="col-form-label required" htmlFor="VehicleName">Vehicle Name</label>
                          </div>
                          <div className="col-lg-8 mb-1 col-md-12">
                            <input
                              className="form-control"
                              type="text"
                              id="VehicleName"
                              name="VehicleName"
                              value={formValue.VehicleName}
                              onChange={handleChange}
                              placeholder="Vehicle Name"
                              maxLength={100}
                              disabled={fieldDisable ? true : false}
                            />
                            {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6 ">
                        <div className="row">
                          <div class="col-lg-4 col-md-12 mb-1">
                            <label class="col-form-label required">
                              Select Category
                            </label>
                          </div>
                          <div class="col-lg-8 mb-1 col-md-12">
                            <Select
                              ref={selectCategoryId}
                              id="SelectCategoryId"
                              className="dropdown select_dropdown"
                              options={selectCategoryOption}
                              value={selectCategoryOption?.filter(function (category) {
                                return category.value === SelectCategoryId;
                              })}
                              onChange={(e) => handleSelectCategoryChange(e)}
                              isDisabled={fieldDisable ? true : false}
                              isSearchable
                            />
                            {formError.selectCategoryId && (
                              <div className="text-danger">
                                {" "}
                                {formError.selectCategoryId}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6 ">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 mb-1">
                            <label
                              className="col-form-label required"
                              htmlFor="SeatingCapacity"
                            >
                              Seating Capacity
                            </label>
                          </div>
                          <div className="col-lg-8 mb-1 col-md-12">
                            <input
                              className="form-control"
                              type="text"
                              id="SeatingCapacity"
                              name="SeatingCapacity"
                              value={formValue.SeatingCapacity === 0 ? '' : formValue.SeatingCapacity}
                              onChange={handleChange}
                              placeholder="Seating Capacity"
                              maxLength={2}
                              disabled={fieldDisable ? true : false}
                            />
                            {formError.SeatingCapacity && (
                              <div className="text-danger">
                                {" "}
                                {formError.SeatingCapacity}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6 ">
                        <div className="row">
                          <div class="col-lg-4 col-md-12 mb-1">
                            <label class="col-form-label required">
                              Vehicle Type
                            </label>
                          </div>
                          <div class="col-lg-8 mb-1 col-md-12">
                            <Select
                              ref={vehicleTypeId}
                              id="VehicleTypeId"
                              className="dropdown select_dropdown"
                              options={vehicleTypeOption}
                              value={vehicleTypeOption?.filter(function (type) {
                                return type.value === VehicleTypeId;
                              })}
                              onChange={(e) => handleVehicleTypeChange(e)}
                              isDisabled={fieldDisable ? true : false}
                              isSearchable
                            />
                            {formError.vehicleTypeId && (<div className="text-danger">{formError.vehicleTypeId}</div>)}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6 ">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 mb-1">
                            <label
                              className="col-form-label"
                              htmlFor="BaggageCapacity"
                            >
                              Baggage Capacity
                            </label>
                          </div>
                          <div className="col-lg-8 mb-1 col-md-12">
                            <input
                              className="form-control"
                              type="text"
                              id="BaggageCapacity"
                              name="BaggageCapacity"
                              value={formValue.BaggageCapacity === 0 ? '' : formValue.BaggageCapacity}
                              onChange={handleChange}
                              placeholder="Baggage Capacity"
                              maxLength={3}
                              disabled={fieldDisable ? true : false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="col-lg-6 col-sm-6">
                          <div className="row">
                            <div className="col-lg-4 col-md-12 mb-1">
                              <label className="col-form-label">Active</label>
                            </div>
                            <div className="col-auto col-lg-8" disabled={false}>
                              <Switch
                                onChange={handleisActive}
                                checked={activeId}
                                className="react-switch"
                                disabled={fieldDisable ? true : false}
                              />
                            </div>
                            <ConfirmationModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              noconfirm={() => handleConfirmation(false)}
                              yesconfirm={() => handleConfirmation(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-4">
                  {useradd === false && btntxt === "Add" ?
                    (<button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={(e) => handleAdd(e)} disabled={!useradd}>{btntxt}</button>)
                    :
                    (<button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={(e) => handleAdd(e)} disabled={editdisabled ? false : true}>{btntxt}</button>)
                  }
                  <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear}>Clear</button>
                </div>

                <div className="row">
                  <div className="d-lg-flex d-md-flex d-sm-block justify-content-end">
                    <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                      <Select
                        className="dropdown"
                        options={caroption}
                        defaultValue={caroption[6]}
                        value={caroption?.filter(function (serach) {
                          return serach.value === searchby;
                        })}
                        onChange={handleSearchBy}
                        isDisabled={disable ? true : false}
                      ></Select>
                    </div>
                    <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                      <div className="clrbutton">
                        <input
                          type="text"
                          onKeyDown={onKeyDownHandler}
                          id="btnSearchText"
                          className="form-control br-4"
                          value={search}
                          placeholder="Enter keyword"
                          disabled={disable ? true : false}
                          onChange={handleChangeSearch}
                        />
                        <button
                          className={`${clrshow === true ? "d-block" : "d-none"
                            }`}
                          disabled={disable ? true : false}
                          onClick={clearsearch}
                          type="reset"
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                    <div className="p-2 text-center">
                      <button
                        type="button"
                        value={search}
                        className="btn btn-primary mx-1"
                        onClick={HandleChangeClickSearch}
                        disabled={disable ? true : false}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <CustomTable
                  columns={columns}
                  data={vehicleDataList}
                  PdfExport={PDFExport}
                  ExportToExcel={ExcelExport}
                  disabled={!userexport}
                />
              </form>
            </div>
          </>
        ) : (
          ""
        )}
        {vehicleoptionId === 2 ? (
          <>
            <BusVehicleMaster vehicleoptionId={2} />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Vehicalmaster;
