import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../../../../customControl/CustomTable';
import CreateRoomForm from './partial/CreateRoomForm';


function Rooms() {
    const navigate = useNavigate();
    let type = localStorage.getItem('type')

    const [isCreateRoom, setIsCreateRoom] = useState(false)

    const columns = [];

    return (
        <div class="container-fluid mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-4'>
                    <h3><i onClick={() => navigate(`/HotelListing/${type}/amenities`)} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Rooms</h3>
                </div>
                <div className='col-8 text-end'>
                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" >Save</button>
                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => navigate(`/HotelListing/${type}/images`)}>Next</button>
                </div>
            </div>

            {isCreateRoom ? (
                <CreateRoomForm
                    setIsCreateRoom={setIsCreateRoom}
                />
            ) : (
                <>
                    <div className='row my-2 ms-2'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill w-auto " id="btnAdd"
                            onClick={() => { setIsCreateRoom(true) }}
                        >Create Room</button>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={[]}
                        PdfExport={false}
                        ExportToExcel={false}
                        disabled={true}

                    />
                </>
            )}

        </div>

    )
}

export default Rooms