import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BulkUpdateModal from './partial/BulkUpdateModal'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const RateAndInventory = () => {
    const navigate = useNavigate()
    const type = localStorage.getItem('type')

    const handleBack = () => {
        navigate(`/HotelListing/${type}/images`)
    }
    const handleNext = () => {
        navigate(`/HotelListing/${type}/policiesandrules`)
    }


    const BulkUpdateDrodownList = [
        { id: 1, name: 'Inventory', alias: 'Bulk Inventory' },
        { id: 2, name: 'Rates', alias: 'Bulk Rates' },
        { id: 3, name: 'Restrictions', alias: 'Bulk Restrictions' },
        { id: 4, name: 'Block Rooms', alias: 'Block Room' },
        { id: 5, name: 'Unblock Rooms', alias: 'Unblock Room' },
        // { id: 6, name: 'Blackout', alias: 'Blackout' },
        { id: 7, name: 'Set Default Rates', alias: 'Set Default Rates' },
        { id: 8, name: 'Set Default Inventory', alias: 'Set Default Inventory' },
    ]

    const [formValue, setFormValue] = useState({
        id: 1,
        BulkType: null,
        RoomType: 1,
        RoomTypeName: "Delux",
        SelecteDate: null,
        Days: "",
    })

    const RoomAvailabilityDates = {
        StartDate: '2024-12-10',
        EndDate: '2025-02-28'
    }
    const lastRequiredDate = new Date(new Date(RoomAvailabilityDates.EndDate).setDate(new Date(RoomAvailabilityDates.EndDate).getDate() - 4))


    const [modalTitle, setModalTitle] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [modalTypeEdit, setModalTypeEdit] = useState(false)
    const [selectedCellDate, setSelectedCellDate] = useState(null)

    const handleModal = (name) => {
        setModalTitle(name)
        setOpenModal(true)
        setModalTypeEdit(false)
        setSelectedCellDate(null)
    }
    const onClose = () => {
        setOpenModal(false)
    }

    const bulktype = [
        { id: 1, label: 'Inventory' },
        { id: 2, label: 'Rates' },
        { id: 3, label: 'Restrictions' },
    ]

    const roomtype = [
        { id: 1, label: "Delux" },
        { id: 2, label: "Premium" },
        { id: 3, label: "Suite" },
        { id: 4, label: "Standard" },
        { id: 5, label: "AC" },
        { id: 6, label: "Non AC" },
        { id: 7, label: "Super Deluxe" },
    ]

    const onChange = (name, value) => {
        if (name === 'RoomType') {
            setFormValue(form => ({ ...form, RoomType: value?.id, RoomTypeName: value?.label }))
        }
        else {
            let result;
            if (name === 'BulkType') {
                result = value?.map(obj => obj?.id)
            }
            else {
                result = value;
            }
            setFormValue(form => ({ ...form, [name]: result }))
        }
    }

    const [dateRangeValue, setDateRangeValue] = useState('')

    const handleDateRange = (update) => {
        setFormValue(form => ({ ...form, SelecteDate: update }))
        setDateRangeValue(`${formatDate(update)}`)

        setStartDate(moment(update))
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? "" : [day, month, year].join('/');
    }

    function formFormatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === '' ? '' : [year, month, day].join('-');
    }

    const calanderStartDate = moment().startOf('day') <= new Date(RoomAvailabilityDates.StartDate) ? moment(new Date(RoomAvailabilityDates.StartDate)) : moment().startOf('day')

    const [startDate, setStartDate] = useState(calanderStartDate);
    const goToPreviousWeek = (e) => {
        e.preventDefault()
        if (startDate.clone().subtract(5, 'days') <= new Date(RoomAvailabilityDates.StartDate)) {
            setStartDate(moment(new Date(RoomAvailabilityDates.StartDate)));
        }
        else {
            setStartDate(startDate.clone().subtract(5, 'days'));
        }
    };

    const goToNextWeek = (e) => {
        e.preventDefault()
        if (startDate.clone().add(9, 'days') > new Date(RoomAvailabilityDates.EndDate)) {
            setStartDate(moment(lastRequiredDate));
        }
        else {
            setStartDate(startDate.clone().add(5, 'days'));
        }
    };

    const renderCalendar = () => {
        const days = [];
        let currentDate = startDate.clone();

        for (let i = 0; i < 5; i++) {
            days.push(
                <th key={currentDate.format('YYYY-MM-DD')}>
                    {
                        // eslint-disable-next-line no-loop-func
                        (() => {
                            if (i === 0) {
                                return (
                                    <div className="position-relative">
                                        <button
                                            className="slideButton slidePrev"
                                            id="slidePrev"
                                            type="button"
                                            disabled={startDate <= new Date(RoomAvailabilityDates.StartDate)}
                                            onClick={(e) => goToPreviousWeek(e)}
                                        >
                                            <img src={require("../../../../../../assets/img/expand_more.png")} alt="prev" />
                                        </button>
                                        <div className="date-and-day">
                                            <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                            <div className="header_day">{currentDate.format('dddd')}</div>
                                        </div>
                                    </div>
                                )
                            }
                            else if (i === 4) {
                                return (
                                    <div className="position-relative">
                                        <div className="date-and-day">
                                            <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                            <div className="header_day">{currentDate.format('dddd')}</div>
                                        </div>
                                        <button
                                            className="slideButton slideNext"
                                            id="slideNext"
                                            type="button"
                                            disabled={startDate.clone().add(4, 'days') >= new Date(RoomAvailabilityDates.EndDate)}
                                            onClick={(e) => goToNextWeek(e)}
                                        >
                                            <img src={require("../../../../../../assets/img/expand_more.png")} alt="next" />
                                        </button>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="date-and-day">
                                        <div className="text-nowrap header_date">{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')}-{currentDate.format('MMM')}-{currentDate.format('YYYY')}</div>
                                        <div className="header_day">{currentDate.format('dddd')}</div>
                                    </div>
                                )
                            }
                        })()
                    }

                </th>

            );
            currentDate = currentDate.clone().add(1, 'day');
        }
        return days;
    };


    //#region 
    // const renderCalendarInventory = (roomData, name) => {
    //     const days = [];
    //     let currentDate = startDate.clone();

    //     for (let i = 0; i < 5; i++) {
    //         days.push(
    //             <td className="inventory_disable" key={currentDate.format('YYYY-MM-DD') + 'stock'}>

    //                 {
    //                     // eslint-disable-next-line no-loop-func
    //                     collection?.map((obj) => {
    //                         if (obj?.InvDate === (currentDate.format('YYYY-MM-DD') + "T00:00:00") && roomData?.propertyRoomId === obj?.PropertyRoomId) {
    //                             return (
    //                                 <input
    //                                     className={`common-input`}
    //                                     type="text"
    //                                     maxLength={5}
    //                                     // value={obj?.[name]}
    //                                     // placeholder="0"
    //                                     value={0}
    //                                     disabled={currentDate < moment(new Date()).clone().subtract(1, 'day') ? true : ['', '', 'DiscountedPrice']?.includes(name) === true ? true : false}
    //                                     onChange={(e) => {
    //                                         if (name === 'Discount') {
    //                                             if (onlyNumeric(e.target.value) <= 100)
    //                                                 handleOnChange(roomData?.propertyRoomId, name, onlyNumeric(e.target.value), obj?.InvDate)
    //                                         }
    //                                         else if (name === 'InventoryCount') {
    //                                             if (onlyNumeric(e.target.value) < 100)
    //                                                 handleOnChange(roomData?.propertyRoomId, name, onlyNumeric(e.target.value), obj?.InvDate)
    //                                         }
    //                                         else if (name === 'Rate1Person' || name === 'Rate2Person' || name === 'Rate3Person' || name === 'Rate4Person') {
    //                                             handleOnChange(roomData?.propertyRoomId, name, onlyNumeric(e.target.value), obj?.InvDate)
    //                                         }
    //                                     }}
    //                                 />
    //                             )
    //                         }
    //                     })
    //                 }
    //             </td>

    //         );
    //         currentDate = currentDate.clone().add(1, 'day');
    //     }
    //     return days;
    // };
    //#endregion

    //#region 
    const handleOnChange = (e) => {

    }
    //#endregion

    //#region 
    // const getRate = (roomList) => {
    //     const rates = []
    //     for (let i = 1; i <= roomList?.maxOccupancy; i++) {
    //         rates.push(
    //             <tr>
    //                 <td className="text-end">
    //                     <div className="d-flex justify-content-end align-items-center user-rate">
    //                         <span className="single-multi-user">
    //                             {i}
    //                         </span>
    //                         <i className={`fa-solid ${i > 1 ? 'fa-user-group' : 'fa-user'} multi-user-rate`}></i>
    //                         Rate:
    //                     </div>
    //                 </td>
    //                 {renderCalendarInventory(roomList, `Rate${i}Person`)}
    //             </tr>
    //         )

    //     }
    //     return rates
    // }
    //#endregion

    const renderCalendarInventoryStatic = (name, type) => {
        const days = [];
        let currentDate = startDate.clone();

        for (let i = 0; i < 5; i++) {
            const cellDate = currentDate.format(`yyyy-MM`) + '-' + (Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D'))
            days.push(
                <td className="inventory_disable" key={`${name}_${i}`} onDoubleClick={(e) => handleEdit(e, type, new Date(cellDate))}>
                    <div className={`common-input`}>
                        {/* {currentDate.format(`yyyy-MM`)}-{Number(currentDate.format('D')) > 9 ? currentDate.format('D') : '0' + currentDate.format('D')} */}
                        00
                    </div>
                </td>

            );
            currentDate = currentDate.clone().add(1, 'day');
        }
        return days;
    };
    const handleEdit = (e, name, cellDate) => {
        e.preventDefault()
        setModalTitle(name)
        setOpenModal(true)
        setModalTypeEdit(true)
        setSelectedCellDate(cellDate)
    }

    return (
        <>
            {
                openModal &&
                <BulkUpdateModal
                    open={openModal}
                    onClose={onClose}
                    title={modalTitle}
                    isEdit={modalTypeEdit}
                    selectedCellDate={selectedCellDate}
                    RoomAvailabilityDates={RoomAvailabilityDates}
                />
            }
            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-8'>
                        <h3><i onClick={() => handleBack()} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Rate & Inventory</h3>
                    </div>
                    <div className='col-4 text-end'>
                        {/* <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd">Save</button> */}
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => handleNext()}>Next</button>
                    </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mb-4 mt-3">
                    <div
                        id="bulk_update_id"
                        role="button"
                        className="me-2 dropdown-toggle bulk_update_btn px-3 my-1 text-danger border-start"
                        data-bs-toggle="dropdown" aria-expanded="false"
                    >
                        Bulk Update
                    </div>
                    <div className="dropdown-menu dropdown-menu-end ms-4 dropdown_zindexset bulkupdate_dropdown" aria-labelledby="bulk_update_id">
                        <div className="bulk_dropdown">
                            {
                                BulkUpdateDrodownList?.map(element => {
                                    return (
                                        <div key={element.id} className="bulk_dropdown_item" onClick={() => handleModal(element)}>
                                            {element.name}
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div className="low-inventory-dates px-3 my-1 border-start">
                        {/* <i class="fa-solid fa-expand "></i> */}
                        <i class="fa-solid fa-magnifying-glass" role='button'></i>
                    </div>
                </div>

                <div className="row rate_invenroty_searching mb-4">

                    <div className="col-md-6 col-12 mb-1">
                        <FormControl fullWidth>
                            <Autocomplete
                                options={roomtype}
                                id="select-roomtype"
                                value={roomtype?.find((obj) => formValue.RoomType === obj?.id) || null}
                                name='RoomType'
                                onChange={(e, newValue) => onChange("RoomType", newValue ? newValue : null)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Room Type" variant="filled" />
                                )}
                            />
                        </FormControl>
                    </div>

                    {/* <div className="col-md-4 col-12 mb-1">
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                options={bulktype}
                                id="select-bulktype"
                                value={bulktype?.filter((obj) => formValue.BulkType?.includes(obj?.id)) || null}
                                onChange={(e, newValue) => onChange('BulkType', newValue)}
                                renderOption={(props, option, { selected }) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <li key={key} {...optionProps}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Rates / Inventory / Restrictions" variant="filled"
                                    />
                                )}
                            />
                        </FormControl>
                    </div> */}


                    <div className="col-md-6 col-12 mb-1 rates">
                        <div className="text-container">
                            <TextField
                                fullWidth
                                id="inventoryselectdate"
                                variant='filled'
                                label="Select Date"
                                value={dateRangeValue}
                                onClick={(e) => openDatePicker("inventorydaterange")}
                            // className={`${formValue.FromDate !== null && formValue.ToDate !== null ? '' : 'red_border'}`}
                            />
                            <div>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className='form-control d-none'
                                    id='inventorydaterange'
                                    selected={formValue.SelecteDate}
                                    placeholderText="Select Date"
                                    autoComplete='off'
                                    showMonthDropdown
                                    onChange={(e) => handleDateRange(e)}
                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                    maxDate={lastRequiredDate}
                                    showYearDropdown
                                    dropdownMode='select'
                                    onFocus={(e) => e.target.readOnly = true}
                                />
                            </div>
                            <span className="example-custom-input bulkCalendarBtn"
                                onClick={(e) => openDatePicker("inventoryselectdate")}
                            ><i className="fa fa-calendar"></i></span>
                        </div>
                    </div>

                </div>




                <div className="table_hotel_individual_rates_inventory">
                    <div className="hotel_individual_rates_inventory">
                        <table className="rates-inventory-table-calender">
                            <thead>
                                <tr>
                                    <th className="rates_inventory_header_title">
                                        <h6 className='fw-bold'>Dates</h6>
                                    </th>
                                    {renderCalendar()}
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>


                    <div className="accordion hotel_rate_inventory" id='accordionRoom2'>

                        <div className="accordion-item">
                            <div
                                className="inventory-room-name accordion-button collapsed position-relative"
                                data-bs-toggle="collapse"
                                data-bs-target={`#room-type-${formValue.RoomTypeName?.replace(' ', '-')?.toLowerCase()}`}
                                aria-expanded="false"
                                aria-controls={`room-type-${formValue.RoomTypeName?.replace(' ', '-')?.toLowerCase()}`}
                            >
                                {formValue.RoomTypeName}
                                <div className="room_count">100</div>
                            </div>
                            <div
                                id={`room-type-${formValue.RoomTypeName?.replace(' ', '-')?.toLowerCase()}`}
                                className="accordion-collapse collapse"
                            >
                                <div className="accordion inventory_data" id={`${formValue.RoomTypeName?.replace(' ', '-')?.toLowerCase()}`}>

                                    <div className="accordion-item">
                                        <div className="position-relative">

                                            <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" onClick={(e) => handleEdit(e, { id: 1, name: 'Inventory', alias: 'Inventory' }, null)}>Edit</button>

                                            <div
                                                className="inventory-room-name-data accordion-button collapsed position-relative"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#deluxInventory'}
                                                aria-expanded="false"
                                                aria-controls={'deluxInventory'}
                                            >
                                                Inventory (Units)
                                                <div className="etm_room_count">10</div>
                                            </div>
                                            <div
                                                id={'deluxInventory'}
                                                className="accordion-collapse collapse"
                                            >
                                                <table className="rates-inventory-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="rates_inventory_header_title"></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label>ETM Allocation</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('ETMAllocation', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label>On Hold</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('Hold', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label className="text-right">Booked</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('Booked', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label className="text-right">Open</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('Open', { id: 1, name: 'Inventory', alias: 'Inventory' })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <div className="position-relative">

                                            <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" onClick={(e) => handleEdit(e, { id: 2, name: 'Rates', alias: 'Rates' }, null)}>Edit</button>

                                            <div
                                                className="inventory-room-name-data accordion-button collapsed position-relative"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#deluxRates'}
                                                aria-expanded="false"
                                                aria-controls={'deluxRates'}
                                            >
                                                Rates (₹)
                                            </div>
                                            <div
                                                id={'deluxRates'}
                                                className="accordion-collapse collapse"
                                            >
                                                <table className="rates-inventory-table">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2} className="rates_inventory_header_title"></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className='colspan_2'>
                                                        <tr>
                                                            <td rowSpan={6} className='col_1'>
                                                                <label>Room Only (EPAI)</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>1 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('epaiadult1', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">2 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('epaiadult2', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>Extra Adult with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('epaiadultwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child without  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('epaichildwithoutbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('epaichildwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                    </tbody>

                                                    <tbody className='colspan_2'>
                                                        <tr>
                                                            <td rowSpan={6} className='col_1'>
                                                                <label>With Breakfast (CPAI)</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>1 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('cpaiadult1', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">2 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('cpaiadult2', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>Extra Adult with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('cpaiadultwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child without  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('cpaichildwithoutbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('cpaichildwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                    </tbody>

                                                    <tbody className='colspan_2'>
                                                        <tr>
                                                            <td rowSpan={6} className='col_1'>
                                                                <label>With Breakfast, Lunch Or dinner (MAPAI)</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>1 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('mapaiadult1', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">2 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('mapaiadult2', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>Extra Adult with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('mapaiadultwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child without  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('mapaichildwithoutbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('mapaichildwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                    </tbody>

                                                    <tbody className='colspan_2'>
                                                        <tr>
                                                            <td rowSpan={6} className='col_1'>
                                                                <label>With Breakfast, Lunch And dinner (APAI)</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>1 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('apaiadult1', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">2 Adult</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('apaiadult2', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label>Extra Adult with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('apaiadultwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child without  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('apaichildwithoutbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start col_2">
                                                                <label className="text-right">Extra Child with  bed / Mattress</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('apaichildwithbed', { id: 2, name: 'Rates', alias: 'Rates' })}
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <div className="position-relative">

                                            <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn edit_option" onClick={(e) => handleEdit(e, { id: 3, name: 'Restrictions', alias: 'Restrictions' }, null)}>Edit</button>

                                            <div
                                                className="inventory-room-name-data accordion-button collapsed position-relative"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#deluxRestrictions'}
                                                aria-expanded="false"
                                                aria-controls={'deluxRestrictions'}
                                            >
                                                Restrictions (Days)
                                            </div>
                                            <div
                                                id={'deluxRestrictions'}
                                                className="accordion-collapse collapse"
                                            >
                                                <table className="rates-inventory-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="rates_inventory_header_title"></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label>Minimum Length of stay</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('minstay', { id: 3, name: 'Restrictions', alias: 'Restrictions' })}
                                                        </tr>
                                                        <tr>
                                                            <td className="text-start">
                                                                <label className="text-right">Maximum Length of stay</label>
                                                            </td>
                                                            {renderCalendarInventoryStatic('maxstay', { id: 3, name: 'Restrictions', alias: 'Restrictions' })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </>
    )
}

export default RateAndInventory
