import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllData } from "../../../services/ApiCallingUserServices";
import { loaderActions } from "../../../_actions/loader.actions";
import { ClearLocalStorage, GetLocalStorage } from "../../commonClass/Session";
import { generatePDF } from "../../commonClass/CommonValidation";
import { ExportExcel } from "../../commonClass/ExportExcel";
import Select from 'react-select';
import CustomTable from "../../../customControl/CustomTable";

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}


function JobApplication() {
  const user = useSelector(store => store.userstatus.tba);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userRights, setUserRights] = useState({});
  const [allJobs, setAllJobs] = useState([])
  const [jobList, setJobList] = useState([]);

  const [attractionsList, setAttractionsList] = useState([]);
  const [searchby, setSearchby] = useState('9');
  const [searchValue, setSearchValue] = useState('');
  const [clrshow, setsclshow] = useState(false);
  const [serchdisable, setSerchdisable] = useState(false)
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    let pagelink = user?.find(x => {
      if (x.PageLink === window.location.pathname)
        return x.MenuId
    });
    if (pagelink?.URightView === false && pagelink?.URightView !== undefined) {
      navigate('/dashboard');
    };
    setUserRights(pagelink);
  }, [user]);



  useEffect(() => {
    dispatch(loaderActions.start());
    let token = GetLocalStorage();

    if (token && token.userName && token.token) {
      getJobVacanyyList().then(() => { dispatch(loaderActions.end()) });
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }
  }, []);

  const getJobVacanyyList = async () => {
    const getJobList = await getAllData('/master/v1/getjobapplist');
    if (getJobList.status) {
      setJobList(getJobList.data);
      setAllJobs(getJobList.data)
    }
  };

  const columns = [
    {
      name: 'Job Code',
      sortable: true,
      float: "left",
      selector: (data) => data?.JodeCode
    },
    {
      name: 'Name of Applicant',
      sortable: true,
      float: "left",
      selector: (data) => data?.NameOfApplicant
    },
    {
      name: 'Email',
      sortable: true,
      float: "left",
      selector: (data) => data?.EmailId
    },
    {
      name: 'Mobile Number',
      sortable: true,
      float: "left",
      selector: (data) => data?.MobileNo
    },
    {
      name: 'Applied Position',
      sortable: true,
      float: "left",
      selector: (data) => data?.JobTitle
    },
    {
      name: 'Total Experience',
      sortable: true,
      float: "left",
      selector: (data) => data?.TotalExp
    },
    {
      name: 'Job Location',
      sortable: true,
      float: "left",
      selector: (data) => data?.City
    },
    {
      name: 'Date',
      sortable: true,
      float: "left",
      selector: (data) => formatDate(data?.CreatedOn)
    },
    {
      name: 'Resume',
      sortable: true,
      float: "left",
      selector: (data) => data.ResumePath !== "" ? <a
        href={"https://static-ethics.sgp1.digitaloceanspaces.com/etm/" + data.ResumePath}
        target='_blank'
        rel="noopener noreferrer"
        className="cursor_pointer"
      > Preview </a>
        :
        ""

    },
    {
      name: 'Other Documents',
      sortable: true,
      float: "left",
      selector: (data) => data.OtherDocPath !== "" ? <a
        href={"https://static-ethics.sgp1.digitaloceanspaces.com/etm/" + data.OtherDocPath}
        target='_blank'
        rel="noopener noreferrer"
        className="cursor_pointer"
      > Preview </a>
        :
        ""
    },
    {
      name: 'View',
      float: "left",
      selector: (data) =>
        userRights?.URightView ?
          <button
            type='button'
            className='border-0 p-0 bg-body set_disableclr '
            disabled={!userRights?.URightView}
          >
            <Link to="/ApplicationDetails"
              className='remove_hover_effect'
              state={{
                data: data,
                viewtype: 'view',
                JobAppId: data?.JobAppId,
                userRights: userRights
              }}>
              <i className="fa fa-eye "
              ></i>
            </Link>
          </button>
          :
          ""

    }
  ];
  const serachoption = [
    {
      label: 'Job Code',
      value: '1'
    },
    {
      label: 'Name of Applicant',
      value: '2'
    },
    {
      label: 'Email',
      value: '3'
    },
    {
      label: 'Mobile Number',
      value: '4'
    },
    {
      label: 'Applied Position',
      value: '5'
    },
    {
      label: 'Total Experience',
      value: '6'
    },
    {
      label: 'Job Location',
      value: '7'
    },
    {
      label: 'Date',
      value: '8'
    },
    {
      label: 'All',
      value: '9'
    },
  ];

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e);
    };
  };

  const handleSearchBy = (val) => {
    setSearchby(val.value);
  };
  const handleChangeSearchValue = (e) => {
    setsclshow(true);
    setSearchValue(e.target.value);
    if (e.target.value === '') {
      getJobVacanyyList();
      setsclshow(false);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();

    if (searchValue.trim().length > 0 && searchby) {
      var filteritem = filterData;
      if (filteritem?.length < allJobs?.length) {
        filteritem = allJobs
      }
      var filterdata;

      if (searchby === '1') {
        filterdata = filteritem?.filter(issec => issec?.JodeCode?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '2') {
        filterdata = filteritem?.filter(issec => issec?.NameOfApplicant?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '3') {
        filterdata = filteritem?.filter(issec => issec?.EmailId?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '4') {
        filterdata = filteritem?.filter(issec => issec?.MobileNo?.toString()?.toLowerCase().includes(searchValue.toLowerCase()));
      }
      else if (searchby === '5') {
        filterdata = filteritem?.filter(issec => issec?.JobTitle?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '6') {
        filterdata = filteritem?.filter(issec => issec?.TotalExp?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '7') {
        filterdata = filteritem?.filter(issec => issec?.City?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '8') {
        filterdata = filteritem?.filter(issec => formatDate(issec?.CreatedOn)?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }
      else if (searchby === '9') {
        filterdata = filteritem?.filter(issec =>
          issec?.JodeCode?.toLowerCase().includes(searchValue.toLowerCase())
          || issec?.NameOfApplicant?.toLowerCase().includes(searchValue.trim().toLowerCase())
          || issec?.EmailId?.toLowerCase().includes(searchValue.trim().toLowerCase())
          || issec?.MobileNo?.toString()?.toLowerCase().includes(searchValue.trim().toLowerCase())
          || issec?.JobTitle?.toLowerCase()?.includes(searchValue.trim().toLowerCase())
          || issec?.TotalExp?.toLowerCase()?.includes(searchValue.trim().toLowerCase())
          || issec?.City?.toLowerCase().includes(searchValue.trim().toLowerCase())
          || formatDate(issec.CreatedOn)?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      setJobList(filterdata)
    }
  }

  const clearsearch = () => {
    getJobVacanyyList();
    setSearchValue('')
  };

  const formatDateRequired = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  function PdfExport() {
    const columns = [
      "Job Code",
      "Name of Applicant",
      "Email",
      "Mobile Number",
      "Applied Position",
      "Total Experience",
      "Job Location",
      "Date",
      // "Resume",
      // "Other Documents",
    ];
    var rows = [];
    for (let i = 0; i < jobList?.length; i++) {
      var temp = [
        jobList[i].JodeCode,
        jobList[i].NameOfApplicant,
        jobList[i].EmailId,
        jobList[i].MobileNo,
        jobList[i].JobTitle,
        jobList[i].TotalExp,
        jobList[i].City,
        formatDate(jobList[i].CreatedOn),
        // jobList[i].ResumePath,
        // jobList[i].OtherDocPath,
      ];
      rows = [...rows, temp]
    }
    generatePDF(columns, rows, "EtmApplicationList", "ETM Application List")
  };


  const ExportToExcel = () => {
    const jobListData = jobList?.map((job) => ({ ...job, ApplyLimit: job.ApplyLimit * job.NoOfVacancy }))
    const displayname = [
      "Job Code",
      "Name of Applicant",
      "Email",
      "Mobile Number",
      "Applied Position",
      "Total Experience",
      "Job Location",
      "Date",
      // "Resume",
      // "Other Documents",
    ];
    const fields = ['JodeCode', 'NameOfApplicant', 'EmailId', 'MobileNo', 'JobTitle', 'TotalExp', 'City', 'CreatedOn'
      // , 'ResumePath', 'OtherDocPath'
    ];
    ExportExcel(displayname, fields, jobListData, 'ETM Application List', 'EtmApplicationList.xls')
  };

  return (
    <>
      <div class="container-fluid mt-4 heading_color">
        <h3>Application List</h3>
      </div>
      <div class="container-fluid form_border py-5 my-3">
        <form action="" >

          <br />
          <div class="row">
            <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
              <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                <Select
                  className="dropdown"
                  options={serachoption}
                  value={serachoption?.filter(function (serachoption) {
                    return serachoption.value === searchby;
                  })}
                  onChange={handleSearchBy} // assign onChange function
                  isDisabled={serchdisable ? true : false}
                  isSearchable={false}

                />
              </div>
              <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                <div className='clrbutton'>
                  <input
                    onKeyDown={onKeyDownHandler}
                    type="text" id="btnSearchText" class="form-control"
                    placeholder='Enter keyword' name="SearchValue"
                    onChange={handleChangeSearchValue}
                    value={searchValue}
                    disabled={serchdisable ? true : false}
                  />
                  <button
                    className={`${clrshow === true ? "d-block" : "d-none"}`}
                    disabled={serchdisable ? true : false}
                    onClick={clearsearch}
                    type="reset"
                  >&times;</button>
                </div>
              </div>
              <div class="p-2 text-center">
                <button type="button" class="btn btn-primary mx-1" id="btnSearch"
                  disabled={serchdisable ? true : false}
                  onClick={handleSearch}
                >Search</button>
              </div>
            </div>

          </div>

          <CustomTable
            className={"job_application"}
            columns={columns}
            data={jobList}
            PdfExport={PdfExport}
            ExportToExcel={ExportToExcel}
            disabled={!userRights?.URightExport}
          />
        </form>
      </div>
    </>
  )
}



export default JobApplication;
