import React, { useEffect, useState } from 'react'
import CustomSelection from '../../customControl/CustomSelection'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import { TimePicker } from 'antd';
import Switch from 'react-switch';
import { ConfirmationModal } from '../../customControl/ConfirmationModal';


const AirInventoryMaster = () => {

    const navigate = useNavigate()

    const mediacollection = useState([{
        DocName: '',
        FilePath: '',
        FileName: '',
        SeqNo: 0
    }]);
    const [datacollection, setDataCollection] = useState(mediacollection);
    const [counter, setCounter] = useState(0);

    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(false);
    const [cleardisable, setClearDisable] = useState(false);
    const [backdisable, setBackDisable] = useState(false);

    const [buttonaddeditText, setButtonAddEditText] = useState("Add");

    const [modalShow, setModalShow] = useState(false)
    const [activeId, setActiveId] = useState(false);

    const [formValue, setFormValue] = useState({
        TravelScope: 0,
        TripType:0,
        PackageName:"",
        SupplierName:"",
        SupplierCode:0,
    })

    const handleChange = () => {

    }

    const [stopValues, setStopValues] = useState([])
    const [stopIdDup, setStopIdDup] = useState([
        "1", "3"
    ])
    const stopOptions = [
        {
            label: "Non-Stop",
            value: "1"
        },
        {
            label: "1 Stop",
            value: "2"
        },
        {
            label: "2 Stop",
            value: "3"
        },
    ]

    // useEffect(() => {
    //     let stopOpt = []
    //     stopIdDup?.map(val => {
    //         stopOptions?.filter(id => {
    //             if (val === id.value) {
    //                 stopOpt.push(id)
    //             }
    //         }
    //         )
    //     })
    //     setStopValues(stopOpt)
    // }, [])

    const handleStopOptions = (e) => {
        console.log(e, "e")
        let stops = e?.map(obj => obj.value)
        console.log(stops, "stops")
        setStopValues(e)
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    }

    //#region Active/Inactive button
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            // setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            // setFormError({ ...formError, duplicate: '', faqcategoryname: '' })
        }
        setModalShow(false);
    }
    //#endregion


    //#region Clear form
    const handlebackClick = () => {
        clear();
        navigate('/Airinventorylist');
    }
    const handleClear = () => {
        clear();
    }
    function clear() {

        // setIsSubmit(false);
        setButtonAddEditText('Add');
        resetDisableState();
        // setHotelRoomPrice([]);
        setFormValue({
            ...formValue, HotelId: 0, LocationId: 0, Rating: 0,
            Name: '', Address: '', GoogleLocation: '', Area: '',
            IsActive: false, HotelContactNo: [], HotelReservation: [],
            HotelManagementContactNo: [], HotelEmail: [], HotelDocument: [],
            HotelPricing: [], HotelContactNumber: '', ReservationNameD: '',
            ReservationNumberD: '', ManagementNameD: '', ManagementNumberD: '',
            HotelEmailD: '', RoomTypeD: '', RoomAllocation: 0, UserId: 0
        });
        // setPricing([]);
        // setRoomType([]);
        // setBannerDocList([]);
        // setUploadImage([]);
        // setFormErrors1([]);
        // setSpokeNumberError('');
        // setSpokeNameError('');
        // setManagementNameError('');
        // setManagementNumberError('');
        // setHotelEmailError('');
        // setHotelContactNoError('');
        // setRoomTypeError('');
        // setRoomAllocationError('');
        // setPriceRoomError('');
        // setFormErrors('');
        // setImageError('');
        // setVideoError('');
        // setImageRoomError('');
        // setImageCountError('');
        // setCityId(0);
        // setHotelContactNoDataCollection([]);
        // setReservationSpokeDetails([]);
        // setManagementContactNo([]);
        // setHotelEmailDataCollection([]);
        // setBannerImage();
        // setBannerVideo();
        // setRating(0);
        resetDisableState();
        // setDuplicateDataError('');
        // document.getElementById("txtbannerimages").value = "";
        // document.getElementById("txtbannerVideo").value = "";
        // setActiveId(false);
        // setDuplicateDataError('');
        // setCityDisable(false);
    }
    function resetDisableState() {
        setDisabled(false);
        // setBannerImgDisable(false);
        // setBannerVideoDisable(false);
        // setEditDisabled(false);
        // setAddContactNoDisable(false);
        // setAddManagementNoDisable(false);
        // setAddSpokeNoDisable(false);
        // setHotelEmailAddDisable(false);
        setBackDisable(false);
        setClearDisable(false);

    }
    //#endregion

    return (
        <>

            <div className="container-fluid mt-4 heading_color">
                <h3>Air Inventory</h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">

                                <div className="mb-3">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Travel Scope</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <div className="form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="TravelScopeRadio1"
                                                                name="TravelScopeRadio"
                                                                value={formValue.TravelScopeId}
                                                                checked={true}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className='form-check-label ms-1' htmlFor="TravelScopeRadio1">Domestic</label>
                                                        </div>

                                                        <div className="form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="TravelScopeRadio2"
                                                                name="TravelScopeRadio"
                                                                value={formValue.TravelScopeId}
                                                                checked={true}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className='form-check-label ms-1' htmlFor="TravelScopeRadio2">International</label>
                                                        </div>
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Trip Type</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <div className="form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="TravelTypeRadio1"
                                                                name="TravelTypeRadio"
                                                                value={formValue.TravelTypeId}
                                                                checked={true}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className='form-check-label ms-1' htmlFor="TravelTypeRadio1">One-Way</label>
                                                        </div>

                                                        <div className="form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="TravelTypeRadio2"
                                                                name="TravelTypeRadio"
                                                                value={formValue.TravelTypeId}
                                                                checked={true}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className='form-check-label ms-1' htmlFor="TravelTypeRadio2">Round Trip</label>
                                                        </div>

                                                        <div className="form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="TravelTypeRadio3"
                                                                name="TravelTypeRadio"
                                                                value={formValue.TravelTypeId}
                                                                checked={true}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            <label className='form-check-label ms-1' htmlFor="TravelTypeRadio3">Multi-City</label>
                                                        </div>
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Enter Package Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            // options={serachoption}
                                                            // value={serachoption?.filter(function (serachoption) {
                                                            //     return serachoption.value === searchby;
                                                            // })}// set list of the dataalue 
                                                            // onChange={handleSearchBy} // assign onChange function
                                                            isDisabled={disabled ? true : false}

                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Name</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            // options={serachoption}
                                                            // value={serachoption?.filter(function (serachoption) {
                                                            //     return serachoption.value === searchby;
                                                            // })}// set list of the dataalue 
                                                            // onChange={handleSearchBy} // assign onChange function
                                                            isDisabled={disabled ? true : false}

                                                        />

                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Supplier Code</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="suppliercode"
                                                            name="SupplierCode"
                                                            value={formValue.SupplierCode}
                                                            onChange={handleChange}
                                                            disabled
                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Airline Code</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="airlinecode"
                                                            name="AirlineCode"
                                                            value={formValue.AirlineCode}
                                                            onChange={handleChange}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Flight Number</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="flightnumber"
                                                            name="FlightNumber"
                                                            value={formValue.FlightNumber}
                                                            onChange={handleChange}
                                                            disabled={disabled ? true : false}
                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row">

                                            <div className="col-lg-6 col-sm-6 ">
                                            </div>

                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 mb-1">
                                                        <label className="col-form-label required">Stops</label>
                                                    </div>
                                                    <div className="col-lg-8 my-1 col-md-12">
                                                        <Select
                                                            className="dropdown"
                                                            options={stopOptions}
                                                            value={stopValues}
                                                            onChange={handleStopOptions} // assign onChange function
                                                            isDisabled={disabled ? true : false}
                                                            isMulti
                                                        // components={{
                                                        //     Option: InputOption
                                                        // }}

                                                        />
                                                        {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-12 col-sm-12 mt-5">
                                    <div className="row">
                                        <h3 className="">Departure Airport</h3>
                                    </div>
                                </div>
                                <div className="form_border">
                                    <div className="my-4">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='Departuredate'
                                                                            // selected={departureDate}
                                                                            // onChange={(e) => handleDateChange('DepartureDate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Departuredate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    {/* <TimePicker id='DepTime' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={departureTime} format={'HH:mm'} onChange={handleDeparture} /> */}
                                                                    <TimePicker
                                                                        id='DepartureTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled ? true : false}
                                                                        placeholder='--:--'
                                                                        format={'HH:mm'} />
                                                                </div>
                                                            </div>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Airport Code</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />

                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Airport Name</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="departureairportname"
                                                                name="DepartureAirportName"
                                                                value={formValue.DepartureAirportName}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Terminal ID</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="departureterminalid"
                                                                name="DepartureTerminalID"
                                                                value={formValue.DepartureTerminalID}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-12 col-sm-12 mt-5">
                                    <div className="row">
                                        <h3 className="">Arrival Airport</h3>
                                    </div>
                                </div>
                                <div className="form_border mb-4">
                                    <div className="my-4">

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Date & Time</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    <div className="text-container">
                                                                        <DatePicker
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className='form-control'
                                                                            id='Arrivaldate'
                                                                            // selected={departureDate}
                                                                            // onChange={(e) => handleDateChange('DepartureDate', e)}
                                                                            disabled={disabled ? true : false}
                                                                            placeholderText="dd/mm/yyyy"
                                                                            // onInputClick={true}
                                                                            minDate={new Date()}
                                                                            maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                                            autoComplete='off'
                                                                        />
                                                                        <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("Arrivaldate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6 mb-1">
                                                                    {/* <TimePicker id='Arrival' className='form-control defo_border' disabled={disabled ? true : false} placeholder='--:--' value={departureTime} format={'HH:mm'} onChange={handleDeparture} /> */}
                                                                    <TimePicker
                                                                        id='ArrivalTime'
                                                                        className='form-control defo_border'
                                                                        disabled={disabled ? true : false}
                                                                        placeholder='--:--'
                                                                        format={'HH:mm'} />
                                                                </div>
                                                            </div>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">City</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Airport Code</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />

                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Airport Name</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="arrivalairportname"
                                                                name="ArrivalAirportName"
                                                                value={formValue.ArrivalAirportName}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Terminal ID</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="arrivalterminalid"
                                                                name="ArrivalTerminalID"
                                                                value={formValue.ArrivalTerminalID}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Total Duration</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                id="totalduration"
                                                                name="TotalDuration"
                                                                value={formValue.TotalDuration}
                                                                onChange={handleChange}
                                                                disabled={disabled ? true : false}
                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Meal</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-6 ">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required"> Booking Class</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <Select
                                                                className="dropdown"
                                                                // options={serachoption}
                                                                // value={serachoption?.filter(function (serachoption) {
                                                                //     return serachoption.value === searchby;
                                                                // })}// set list of the dataalue 
                                                                // onChange={handleSearchBy} // assign onChange function
                                                                isDisabled={disabled ? true : false}

                                                            />
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-12">
                                            <div className="row">

                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="row d-flex ">
                                                        <div className="col-lg-4 col-md-12 mb-1">
                                                            <label className="col-form-label required">Baggage</label>
                                                        </div>
                                                        <div className="col-lg-8 my-1 col-md-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="d-flex flex-column align-items-start">
                                                                        <label className='text-start' htmlFor="checkin">Check-in</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            id="checkin"
                                                                            name="CheckIn"
                                                                            value={formValue.CheckIn}
                                                                            onChange={handleChange}
                                                                            disabled={disabled ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className="d-flex flex-column align-items-start">
                                                                        <label className='text-start' htmlFor="cabin">Cabin</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            id="cabin"
                                                                            name="Cabin"
                                                                            value={formValue.Cabin}
                                                                            onChange={handleChange}
                                                                            disabled={disabled ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* {formError.VehicleName && (<div className="text-danger">{" "}{formError.VehicleName}{" "} </div>)} */}
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className='d-lg-flex'>
                                    <div className="mt-1 col-lg-12 col-sm-12">
                                        <div className='row'>
                                            <div className="col-lg-2 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                                <label htmlFor="inputPassword6" className="col-form-label">Upload Document</label>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-12 mb-1">
                                                <input
                                                    name='UploadDocument'
                                                    type="text"
                                                    id="uploaddocument"
                                                    className="form-control"
                                                    value={formValue.UploadDocument || ''}
                                                    // onChange={onChange}
                                                    disabled={disabled ? true : false}
                                                    placeholder='Upload Document'
                                                />
                                                {/* {formErrors.Affiliations && <p className='text-danger text-start'>{formErrors.Affiliations}</p>}
                                                        {formafflitionerror && <p className='text-danger text-start'>{formafflitionerror}</p>} */}
                                            </div>
                                            <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-1 mb-md-1">
                                                <div className="input-group  mb-1">
                                                    <input
                                                        name='UploadDocumentfile'
                                                        type="file"
                                                        id="UploadDocumentfile"
                                                        className="form-control btn_add"
                                                        accept=".pdf"
                                                        // onChange={handleuploadAffilitionCertifycate}
                                                        disabled={disabled ? true : false}
                                                    />
                                                    <label className="input-group-text" htmlFor="UploadDocumentfile">Browse</label>

                                                </div>
                                                {/* {affilitionstypeerror && <p className='text-danger text-start'>{affilitionstypeerror}</p>} */}
                                            </div>
                                            <div className="mb-1 col-lg-1 col-md-12 col-sm-12 me-lg-5">
                                                <div className="col-lg-1 col-md-12 col-sm-12 justify-content-md-center d-lg-block d-md-flex mb-lg-1">
                                                    {/* <button type="button" onClick={handleClick} className="btn btn-primary mx-1" id="btnAdd" disabled={addisenableaff ? false : true}>Add</button> */}
                                                    <button type="button" className="btn btn-primary mx-lg-1" id="btnAdd" >Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {datacollection?.map((element, index) => {
                                    if (counter > 0) {
                                        return (
                                            <div className='d-lg-flex d-md-flex' key={index}>
                                                <div className="mb-1 col-lg-10 ms-lg-5">
                                                    <div className='row'>
                                                        <div className=" col-lg-3 mb-1"></div>
                                                        <div className=" col-lg-3 col-md-6 col-sm-12 mb-1">
                                                            <input
                                                                // onChange={handleOnChange}
                                                                className="form-control"
                                                                key={index}
                                                                value={element.DocName || ''}
                                                                readOnly
                                                                type="text"
                                                            ></input>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-lg-1 mb-md-1">
                                                            <div className="input-group  mb-1">
                                                                <input
                                                                    type="text"
                                                                    key={index}
                                                                    value={element.FileName || ''}
                                                                    //onChange={handleOnChange}
                                                                    readOnly
                                                                    className="form-control btn_add"
                                                                    id="inputGroupFile02" />
                                                                <label className="input-group-text" htmlFor="inputGroupFile02">Browse</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-1 mt-lg-0 mt-md-1 col-lg-2 me-lg-5">
                                                    <div className="col-lg-1 col-md-2 col-sm-12 mb-lg-1">
                                                        {/* <button type="button" className="btn btn-primary mx-1" id="btnRemove" onClick={(e) => { removeAffilitionDoc(element.SeqNo, e); }} disabled={disabled ? true : false}>&times;</button> */}
                                                        <button type="button" className="btn btn-primary mx-1" id="btnRemove" disabled={disabled ? true : false}>&times;</button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                })}

                                <div className="col-lg-6 col-sm-6">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="active" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-8 " disabled={false}>
                                            <Switch
                                                id='active'
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-5 d-flex justify-content-center">
                        {/* <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={(e) => onSubmitForm(e)} disabled={editdisabled ? true : false}>{buttonaddeditText}</button> */}
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" disabled={editdisabled ? true : false}>{buttonaddeditText}</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClear} disabled={cleardisable ? true : false} >Clear</button>
                        <button type="button" className="btn btn-primary mx-1" id="btnVisible" onClick={handlebackClick} disabled={backdisable ? true : false}>Back</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default AirInventoryMaster
