import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs, TabPanel, Grid } from '@mui/material';
import RoomBasicInfo from './RoomBasicInfo';
import RoomAmenities from './RoomAmenities';


function CreateRoomForm({setIsCreateRoom}) {
    const navigate = useNavigate();
    let type = localStorage.getItem('type');
    const [value, setValue] = React.useState("basic-info");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        console.log("value", value)
    }, [value]);

    return (
        <div className=' mt-4'>

            <div className='row my-2'>
                <div className='col-8'>
                    <h4>Room</h4>
                </div>
                <div className='col-4 text-end'>
                    <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={()=>{setIsCreateRoom(false)}} >Add</button>
                </div>
            </div>

            <div className='w-100'>
                <Box sx={{ width: '100%', borderBottom: 2 }}>
                    <Tabs value={value}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        
                    >
                        <Tab
                            onClick={(e) => { handleChange(e, "basic-info") }}
                            label="Basic Info"
                            value="basic-info"
                            sx={{ width: '100%', textAlign: 'center' }}
                            className='room-tabs'
                        />
                        <Tab
                            onClick={(e) => { handleChange(e, "amenities") }}
                            value="amenities"
                            label="Item Two"
                            sx={{ width: '100%', textAlign: 'center' }}
                            className='room-tabs'
                        />
                    </Tabs>

                </Box>
                {
                    value === 'basic-info' ? (<RoomBasicInfo></RoomBasicInfo>) : (<RoomAmenities></RoomAmenities>)
                }
            </div>

        </div>
    )
}

export default CreateRoomForm