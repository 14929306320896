import React from 'react'
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Radio, Select, TextField, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetLocalStorage } from '../../../../../../commonClass/Session';
import CountComponent from '../../../../../../../customControl/CountComponent';

function RoomBasicInfo() {

  const userId = GetLocalStorage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //helper state
  const [save, setSave] = useState(false)
  const [next, setNext] = useState(true);
  const [count, setCount] = useState(0)

  //form value and error
  const basicDefault = {
    IsChannelManagers: true,
    ChannelManagerId: null,
    HotelName: "",
    HotelTypeId: null,
    StartingYear: null,
    AuthorizedStarCategory: null,
    AsPerLocation: null,
    AsPerSize: null,
    AsPerFacilities: null,
    ReservationTeam_Name: "",
    ReservationTeam_Phone: "",
    ReservationTeam_Email: "",
    Management_Name: "",
    Management_Phone: "",
    Management_Email: "",
    IsActive: true,
    UserId: userId?.userMasterID
  }
  const [formValue, setFormValue] = useState(basicDefault);
  const [formError, setFormError] = useState(basicDefault);

  const handleChange = (name, value) => {
    setFormError(basicDefault);
    setSave(false);
    setFormValue((preData) => (
      {
        ...preData,
        [name]: value
      }
    ));
  };

  const checkBoxData = [
    { label: "No View", id: 1 },
    { label: "Hill View", id: 2 },
    { label: "River View", id: 3 },
    { label: "Bay View", id: 4 },
    { label: "Landmark View", id: 5 },
    { label: "Beach View", id: 6 },
    { label: "Harbour View", id: 7 },
    { label: "Sea View", id: 8 },
    { label: "Pool View", id: 9 },
    { label: "Lake View", id: 10 },
    { label: "City Terrace View", id: 11 },
    { label: "Golf Course View", id: 12 },
    { label: "Backwater View", id: 13 },
    { label: "Park View", id: 14 },
    { label: "Airport View", id: 15 },
    { label: "Marine View", id: 16 },
    { label: "Valley View", id: 17 },
    { label: "Garden View", id: 18 },
    { label: "Palace View", id: 19 },
    { label: "Jungle View", id: 20 },
    { label: "Courtyard View", id: 21 },
    { label: "Resort View", id: 22 },
    { label: "Lagoon View", id: 23 },
    { label: "Country Side View", id: 24 },
    { label: "Inter-Costal View", id: 25 },
  ]

  return (
    <form action="" className='py-3 my-1'>
      <div className='row mt-3'>
        <div className='col-lg-4 col-md-6 col-12 mb-1'>
          <FormControl className='w-100' variant="filled">
            <TextField id="filled-basic" label="Room display name" variant="filled"
              type="text" name='ReservationTeam_Name'
            // className={`w-100  ${formValue.ReservationTeam_Name?.trim()?.length <= 0 ? 'red_border' : ''}`}
            // value={formValue?.ReservationTeam_Name}
            // inputProps={{ maxLength: 50 }}
            // error={formError?.ReservationTeam_Name ? true : false}
            // helperText={formError?.ReservationTeam_Name}
            // onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </FormControl>
        </div>

        <div className='col-lg-4 col-md-6 col-12 mb-1'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='col-lg-4 col-md-6 col-12 mb-1'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Bed Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='col-lg-12 col-md-12 col-12 mt-2'>
          <TextField
            label="Room Description"
            multiline
            rows={4}  // Number of visible rows
            // value={value}
            // onChange={handleChange}
            variant="filled"
            fullWidth
          />
        </div>


        <div className='row mt-3' >
          <div className='col-sm-4 col-8 align-content-center'>
            <h6 className='fw-bold room_header'>Room Count </h6>
            <h6 className='count_label'>Quantity of a room in this category</h6>
          </div>
          <div className='col-sm-4 col-4'>
            <CountComponent
              count={count}
              getCount={(count) => { setCount(count) }}
              id={count}
              option={{ MinValue: 1, MaxValue: 6, Id: 1 }}
            />
          </div>
        </div>

        <h6 className='mt-3 fw-bold room_header'>Room Occupancy</h6>
        <div className='row' >
          <div className='col-sm-4 col-8 align-content-center'>
            <h6 className='count_label mb-0'>Adult - Age 12+ years</h6>
          </div>
          <div className='col-sm-4 col-4'>
            <CountComponent
              count={count}
              getCount={(count) => { setCount(count) }}
              id={count}
              option={{ MinValue: 1, MaxValue: 6, Id: 1 }}
            />
          </div>

        </div>

        <div className='row  align-content-center' >
          <div className='col-sm-4 col-8 align-content-center'>
            <h6 className='count_label mb-0'>Child - Age 6-12 years</h6>
          </div>
          <div className='col-sm-4 col-4'>
            <CountComponent
              count={count}
              getCount={(count) => { setCount(count) }}
              id={count}
              option={{ MinValue: 1, MaxValue: 6, Id: 1 }}
            />
          </div>
        </div>

        <div className='row align-content-center' >
          <div className='col-sm-4 col-8 align-content-center'>
            <h6 className='count_label mb-0'>Child - Age 2-6 years</h6>
          </div>
          <div className='col-sm-4 col-4 align-content-center'>
            <CountComponent
              count={count}
              getCount={(count) => { setCount(count) }}
              id={count}
              option={{ MinValue: 1, MaxValue: 6, Id: 1 }}
            />
          </div>
        </div>

        <div className='row align-content-center' >
          <div className='col-sm-4 col-8 align-content-center'>
            <h6 className='count_label mb-0'>Infra - Age upto 2 years</h6>
          </div>

          <div className='col-sm-4 col-4'>
            <CountComponent
              count={count}
              getCount={(count) => { setCount(count) }}
              id={count}
              option={{ MinValue: 1, MaxValue: 6, Id: 1 }}
            />
          </div>
        </div>

        <div className='row mt-3' >
          <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
            <h6 className='fw-bold room_header'>Extra Bedding?</h6>
          </div>

          <div className='col-md-6 col-sm-4 col-12 d-flex'>
            <Radio
              // checked={formValue?.IsChannelManagers === true}
              name='IsChannelManagers'
              // onChange={(e) => handleChange(e.target.name, true)}
              value="true"
              id='channel_yes'
              inputProps={{ 'aria-label': 'true' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_yes" >Allowed</InputLabel>
            <Radio
              // checked={formValue?.IsChannelManagers === false}
              // onChange={(e) => {
              // handleChange(e.target.name, false)
              // handleChange('ChannelManagerId', null)
              // }}
              value="false"
              id='channel_no'
              name='IsChannelManagers'
              inputProps={{ 'aria-label': 'false' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_no">Not Allowed</InputLabel>
          </div>
        </div>

        <div className='col-lg-4 col-sm-6 col-12 mt-2'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='row mt-3' >
          <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
            <h6 className='fw-bold room_header'>Meal is provided in this room type?</h6>
          </div>

          <div className='col-lg-4 col-md-4 col-sm-4 col-12 d-flex'>
            <Radio
              // checked={formValue?.IsChannelManagers === true}
              name='IsChannelManagers'
              // onChange={(e) => handleChange(e.target.name, true)}
              value="true"
              id='channel_yes'
              inputProps={{ 'aria-label': 'true' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_yes" >Yes</InputLabel>
            <Radio
              // checked={formValue?.IsChannelManagers === false}
              // onChange={(e) => {
              // handleChange(e.target.name, false)
              // handleChange('ChannelManagerId', null)
              // }}
              value="false"
              id='channel_no'
              name='IsChannelManagers'
              inputProps={{ 'aria-label': 'false' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_no">No</InputLabel>
          </div>
        </div>

        <div className='col-lg-4 col-sm-6 col-12 mt-2'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='col-lg-4 col-sm-6 col-12 mt-2'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='row mt-3'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12 align-content-center'>
            <h6 className='fw-bold room_header'>Visitors entry?</h6>
          </div>

          <div className='col-md-6 col-sm-4 col-12 d-flex'>
            <Radio
              // checked={formValue?.IsChannelManagers === true}
              name='IsChannelManagers'
              // onChange={(e) => handleChange(e.target.name, true)}
              value="true"
              id='channel_yes'
              inputProps={{ 'aria-label': 'true' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_yes" >Allowed</InputLabel>
            <Radio
              // checked={formValue?.IsChannelManagers === false}
              // onChange={(e) => {
              // handleChange(e.target.name, false)
              // handleChange('ChannelManagerId', null)
              // }}
              value="false"
              id='channel_no'
              name='IsChannelManagers'
              inputProps={{ 'aria-label': 'false' }}
            // disabled={location?.state?.disabled}
            />
            <InputLabel id="channel_no">Not Allowed</InputLabel>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 mt-2'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <div className='col-lg-4 col-sm-6 col-12 mt-2'>
          <FormControl className='w-100' variant="filled">
            <Autocomplete
              // options={star}
              id="AsPerFacilities-id"
              // value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
              name='AsPerFacilities'
              // onChange={(e, newValue) => handleChange('AsPerFacilities', newValue.id)}
              renderInput={(params) => (
                <TextField {...params} label="Room Type" variant="filled"
                  className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                  helperText={formError?.AsPerFacilities}
                  error={formError?.AsPerFacilities ? true : false}
                />
              )}
              disableCloseOnSelect
            />
          </FormControl>
        </div>

        <h6 className='mt-3 fw-bold room_header'>Room View</h6>
        <div className='row'>
          {checkBoxData.map((view) => (
            <FormControlLabel className='col-4 mx-0'
              control={
                <Checkbox checked={true} onChange={handleChange} name="gilad" />
              }
              label={view.label}
            />
          ))}

        </div>
      </div>
    </form>
  )
}

export default RoomBasicInfo