import React from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData } from '../../../services/ApiCallingUserServices';
import { generatePDF } from '../../commonClass/CommonValidation';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import CustomTable from '../../../customControl/CustomTable';



const Userlist = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clrshow, setsclshow] = useState(false);
    const [userlist, setUserList] = useState([]);
    const [searchby, setSerachBy] = useState('7');
    const [searchvalue, setSearchValue] = useState();
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const serachoption = [
        {
            label: 'Sr No',
            value: '1'
        },
        {
            label: 'Name',
            value: '2'
        },
        {
            label: 'Gender',
            value: '3'
        },
        {
            label: 'Department',
            value: '8'
        },
        {
            label: 'Role',
            value: '4'
        },
        {
            label: 'Email',
            value: '5'
        },
        {
            label: 'Mobile Number',
            value: '6'
        },
        {
            label: 'All',
            value: '7'
        },

    ];
    const getuserdatalist = async () => {
        // const usrdata = await getAllData('/auth/v1/getadminuserlist');
        const usrdata = await getAllData('/auth/v1/getadminuserlist?UserType=' + 0)
        setUserList(usrdata.data);
    }
    const columns = [
        {
            name: 'Sr No.',
            sortable: true,
            float: "left",
            selector: (user) => user.sqnNo,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Name',
            sortable: true,
            float: "left",
            selector: (user) => user.fullName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Gender',
            sortable: true,
            float: "left",
            selector: (user) => user.gender,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Department',
            sortable: true,
            float: "left",
            selector: (user) => user.departmentName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Role',
            sortable: true,
            float: "left",
            selector: (user) => user.roleName,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Email',
            sortable: true,
            float: "left",
            selector: (user) => user.userEmail,
            // style:  { backgroundColor: 'green' }
        },
        {
            name: 'Mobile Number',
            sortable: true,
            float: "left",
            selector: (user) => user.contactNo,
            // style:  { backgroundColor: 'green' }
        },
        // {
        //     name: 'Active',
        //     sortable: true,
        //     float:"left",
        //     selector: (hotel) => hotel.IsActive ? 'Active' : 'InActive'
        //     // style:  { backgroundColor: 'green' }
        // },
        // {
        //     name: 'Created Date',
        //     sortable: true,
        //     float:"left",
        //     // selector: (hotel) => formatDate(hotel.CreatedDate),
        //     // style:  { backgroundColor: 'green' }
        // },
        {
            name: 'Edit',
            // float:"right",
            selector: user => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEdit(user.userMasterID)}></i></button>
            // selector: (hotel) => <i className="fa fa-edit" onClick={() => handleEditMode(hotel.HotelId)}></i>
        },
        // {
        //     name: 'View',
        //     // float:"right",
        //     // selector: hotel => <td style={{ textAlign: "center", width: "5%", cursor: "pointer" }} onClick={() => handleViewMode(hotel.HotelId)}><i className="fa fa-eye"></i></td>
        //     // selector: (hotel) => <i className="fa fa-eye" onClick={() => handleViewMode(hotel.HotelId)}></i>
        // }
    ];
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getuserdatalist();
            setsclshow(false)
        }
    }
    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };
    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue.trim().length !== 0 && searchby > 0) {
            setUserList();
            var filteritem = userlist;
            var filterdata;
            // if (searchby === '1') {
            //     filterdata = filteritem?.filter(issec => issec.sqnNo?.toString().toLowerCase().includes(searchvalue.toLowerCase()));
            // }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.gender?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '8') {
                filterdata = filteritem?.filter(issec => issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec =>
                    // issec.sqnNo?.toString().toLowerCase().includes(searchvalue.toLowerCase()) ||
                    issec.fullName?.toLowerCase().includes(searchvalue.toLowerCase())
                    || issec.gender?.toLowerCase().includes(searchvalue.toLowerCase())
                    || issec.roleName?.toLowerCase().includes(searchvalue.toLowerCase())
                    || issec.userEmail?.toLowerCase().includes(searchvalue.toLowerCase())
                    || issec.contactNo?.toLowerCase().includes(searchvalue.toLowerCase())
                    || issec.departmentName?.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            setUserList(filterdata)
        }
    }
    useEffect(() => {
        getuserdatalist();
    }, [])
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage();
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });

    }, [user])
    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getuserdatalist();
    }
    const handleOpen = () => {
        navigate('/Useradd', { state: { disabled: false, editdisabled: false, viewtype: 'Add', Id: 0 } });
    }
    const handleEdit = (id) => {
        dispatch(loaderActions.start());
        let Id = 0;
        userlist?.map((obj) => {
            Id = obj.userMasterID;
        });
        navigate('/Useradd', { state: { disabled: false, editdisabled: true, viewtype: 'Update', Id: id } });
    }
    const PdfExport = () => {
        const columns = [
            "Sr No",
            "Name",
            "Gender",
            "Role",
            "Email",
            "Mobile Number"
        ];
        var rows = [];

        for (let i = 0; i < userlist.length; i++) {
            /*for (var key in json[i]) {
              var temp = [key, json[i][key]];
              rows.push(temp);
            }*/
            var temp = [
                userlist[i].sqnNo,
                userlist[i].fullName,
                userlist[i].gender,
                userlist[i].departmentName,
                userlist[i].roleName,
                userlist[i].userEmail,
                userlist[i].contactNo
            ];
            rows = [...rows, temp]
            // rows.push(temp);
        }
        generatePDF(columns, rows, "UserList", "User List")
    }
    const ExportToExcel = () => {
        const dispalyname = ['Sr No', 'Name', 'Gender', 'Department', 'Role', 'Email', 'Mobile Number'];
        const fields = ['sqnNo', 'fullName', 'gender', 'departmentName', 'roleName', 'userEmail', 'contactNo'];
        ExportExcel(dispalyname, fields, userlist, "UserList", "UserList.xls");
    }
    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>User List</h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="d-flex justify-content-start ms-lg-3">
                        <button type="button" disabled={!useradd} className="btn btn-primary mx-1" to="Suppliermaster" id="btnAdd" onClick={handleOpen} >Create New User</button>
                    </div>
                    <br />
                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    //isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input onKeyDown={onKeyDownHandler} type="text" id="btnSearchText" className="form-control" name="SearchValue" placeholder='Enter keyword'
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue}
                                    //disabled={serchdisable ? true : false} 
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch"
                                    //disabled={serchdisable ? true : false}
                                    onClick={handleClickSearchValue}
                                >Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        className={"user_role"}
                        columns={columns}
                        data={userlist}
                        PdfExport={false}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default Userlist