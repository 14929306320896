import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RatesModal = (props) => {
    const { isEdit, selectedCellDate, RoomAvailabilityDates } = props;
    useEffect(() => {
        if (selectedCellDate !== null) {
            setDateRangeValue(`${formatDate(new Date(selectedCellDate))} - ${formatDate(new Date(selectedCellDate))}`)
        }
    }, [selectedCellDate])
    const [formValue, setFormValue] = useState({
        id: 1,
        RoomType: null,
        FromDate: selectedCellDate || null,
        ToDate: selectedCellDate || null,
        Days: "",

        MealType: null,
        Adult1: "",
        Adult2: "",
        ExtraAdultWithBed: "",
        ChildWithBed: "",
        ChildWithOutBed: "",
    })
    console.log(formValue, 'formValue-rates')
    const roomtype = [
        { id: 1, label: "Single" },
        { id: 2, label: "Double" }
    ]
    const mealtype = [
        { id: 1, label: "Meal 1" },
        { id: 2, label: "Meal 2" }
    ]
    const days = [
        { id: 1, label: "Day 1" },
        { id: 2, label: "Day 2" }
    ]

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = '' + d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? "" : [day, month, year].join('/');
    }


    const onChange = (name, value) => {
        setFormValue(form => ({ ...form, [name]: value }))
    }

    const [dateRangeValue, setDateRangeValue] = useState('')

    const handleDateRange = (update, isEdit) => {
        console.log(update, 'update')
        if (isEdit) {
            setFormValue(form => ({ ...form, FromDate: update }))
            setDateRangeValue(`${formatDate(update)} - ${formatDate(update)}`)
        }
        else {
            setFormValue(form => ({ ...form, FromDate: update[0], ToDate: update[1] }))
            setDateRangeValue(`${formatDate(update[0])} - ${formatDate(update[1])}`)
        }
    }

    const openDatePicker = (e) => {
        //open date picker on click date icon 
        document.querySelector("#" + e).click();
    };


    return (
        <>
            <form action="" className='py-2'>
                <div className='row'>
                    {
                        !isEdit &&
                        <div className="col-12 mb-4">
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={roomtype}
                                    id="select-roomtype"
                                    value={roomtype?.find((obj) => obj.id === formValue.RoomType) || null}
                                    name='RoomType'
                                    onChange={(e, newValue) => onChange("RoomType", newValue.id)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Room Type" variant="filled"
                                            className={`${formValue.RoomType <= 0 ? 'red_border' : ''}`}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                    }

                    <div className="col-12 mb-4">
                        <FormControl fullWidth>
                            <Autocomplete
                                options={mealtype}
                                id="select-mealtype"
                                value={mealtype?.find((obj) => obj.id === formValue.MealType) || null}
                                name='MealType'
                                onChange={(e, newValue) => onChange("MealType", newValue.id)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Meal Plan" variant="filled"
                                        className={`${formValue.MealType <= 0 ? 'red_border' : ''}`}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-12 lengthfull">
                                <div className="text-container">
                                    <TextField
                                        fullWidth
                                        id="select-date"
                                        variant='filled'
                                        // label={`${isEdit ? "Select Date" : "Date Range (From-To)"}`}
                                        label="Select Date"
                                        value={dateRangeValue}
                                        disabled={selectedCellDate !== null}
                                        onClick={(e) => openDatePicker("selectdate")}
                                        className={`${(isEdit ? formValue.FromDate !== null : (formValue.FromDate !== null && formValue.ToDate !== null)) ? '' : 'red_border'}`}
                                    />
                                    <div>
                                        {
                                            isEdit ?
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue.FromDate}
                                                    placeholderText="Select Date"
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue.FromDate}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                                    maxDate={new Date(RoomAvailabilityDates.EndDate)}
                                                />
                                                :
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control d-none'
                                                    id='selectdate'
                                                    selected={formValue.FromDate}
                                                    placeholderText="Date Range (From-To)"
                                                    selectsRange={true}
                                                    autoComplete='off'
                                                    showMonthDropdown
                                                    startDate={formValue.FromDate}
                                                    endDate={formValue.ToDate}
                                                    onChange={(e) => handleDateRange(e, isEdit)}
                                                    minDate={new Date(RoomAvailabilityDates.StartDate)}
                                                    maxDate={new Date(RoomAvailabilityDates.EndDate)}
                                                />
                                        }
                                    </div>
                                    <span className={`example-custom-input bulkCalendarBtn ${selectedCellDate !== null ? 'disableCalBtn' : ''}`}
                                        onClick={(e) => openDatePicker("select-date")}
                                    ><i className="fa fa-calendar"></i></span>
                                </div>
                            </div>
                            {/* <div className="col-6">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={days}
                                        id="select-days"
                                        value={days?.find((obj) => obj.id === formValue.Days) || null}
                                        name='Days'
                                        onChange={(e, newValue) => onChange("Days", newValue.id)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Days" variant="filled" />
                                        )}
                                    />
                                </FormControl>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-1-adult"
                                    variant='filled'
                                    label="Price for 1 Adult"
                                    name='Adult1'
                                    value={formValue.Adult1}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    className={`${formValue.Adult1 === "" ? 'red_border' : ''}`}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-2-adult"
                                    variant='filled'
                                    label="Price for 2 Adult"
                                    name='Adult2'
                                    value={formValue.Adult2}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    className={`${formValue.Adult2 === "" ? 'red_border' : ''}`}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <TextField
                            fullWidth
                            id="extra-adult-with-bed"
                            variant='filled'
                            label="Extra Adult with  bed / Mattress"
                            name='ExtraAdultWithBed'
                            value={formValue.ExtraAdultWithBed}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            className={`${formValue.ExtraAdultWithBed === "" ? 'red_border' : ''}`}
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <div className="row">
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-child-without-bed"
                                    variant='filled'
                                    label="Extra Child without bed / Mattress"
                                    name='ChildWithOutBed'
                                    value={formValue.ChildWithOutBed}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    className={`${formValue.ChildWithOutBed === "" ? 'red_border' : ''}`}
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    fullWidth
                                    id="price-for-child-with-bed"
                                    variant='filled'
                                    label="Extra Child with bed / Mattress"
                                    name='ChildWithBed'
                                    value={formValue.ChildWithBed}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    className={`${formValue.ChildWithBed === "" ? 'red_border' : ''}`}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="col-12">
                        <button type="button" className='btn bulk_apply_btn btn-primary w-100'>Apply</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default RatesModal
